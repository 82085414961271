import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

@Injectable({ providedIn: "root" })
export class DoNotShowSidebarOnRefreshGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isPageRefresh()) {
      console.warn("Sidebar not allowed on refresh.");
      this.router.navigateByUrl("/");
      return false;
    }
    return true;
  }

  // private getUrlRoot(routerStateSnapshot: RouterStateSnapshot): UrlTree {
  //   const urlTree = this.router.parseUrl(routerStateSnapshot.url);
  //   console.log("urlTree: ", urlTree);
  //   const segment = urlTree.root;
  //   console.log("segment secondary: ", segment);

  //   while (segment && segment.children.primary.segments.length >1) {
  //     console.log("segment children: ", segment.children);
  //      delete segment.children.primary.segments[segment.children.primary.segments.length -1];

  //     // segment = segment.children[ PRIMARY_OUTLET ];
  //     console.log("urlTree: ", urlTree);
  //   }

  //   return urlTree;
  // }

  // I determine if the current route-request is part of a page refresh.
  private isPageRefresh(): boolean {
    return !this.router.navigated;
  }
}
