import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { faCircleNotch, faSync, faMapMarkedAlt, faEraser, faCopy } from '@fortawesome/free-solid-svg-icons';
import { transform } from 'ol/proj';
import { UtilsService } from 'src/app/servizi/utils.service';
import { HttpClient } from '@angular/common/http';
import { EBWApiResponse } from 'src/app/modelli/api.response.model';
/**
 * Componente per la trasformazione di coordinate
 */
@Component({
   selector: 'ebw-trasforma-coordinate',
   templateUrl: './trasforma-coordinate.component.html',
   styleUrls: ['./trasforma-coordinate.component.scss']
})
export class TrasformaCoordinateComponent implements OnInit {

   /**
    * Longitudine e latitudine in ingresso
    */
   _longitudineIn: number = 0;
   _latitudineIn: number = 0;
   /**
    * Longitudine e latitudine in uscita in decimali
    */
   _longitudineOutDecimal: number = 0;
   _latitudineOutDecimal: number = 0;
   /**
    * Longitudine e latitudine in uscita in gradi
    */
   _longitudineOutDegrees: string = '';
   _latitudineOutDegrees: string = '';


   @Input() centroMappa3857: number[];

   /**
    * SR di default per tutto il pacchetto
    */
   @Input() defaultSR: any;

   /**
    * Array di SR dei possibili sistemi di riferimento per la conversione ottenuti dalla configurazione
    */
   @Input() arraySR: any[] = [];

   /**
    * Icona per la trasformazione
    */
   faSync = faSync;
   /**
    * Icona per la visualizzazione sulla mappa delle coordinate
    */
   faMapMarkedAlt = faMapMarkedAlt;
   /**
    * Icona per il reset della form
    */
   faEraser = faEraser;
   /**
    * Icona per la copia dei campi di output
    */
   faCopy = faCopy;

   /**
    * Icona per il Reset
    */
   faCircleNotch = faCircleNotch;

   formTrasformaCoordinate: FormGroup;

   /**
    * Variabili per la visibilit� dei campi in base al sr selezionato
    */
   srUscitaPiano = true;
   srUscitaGeografico = false;

   // decimal / degrees
   rapprSelected: string = "decimal";

   @Output() centraMappaAllaCoordinata: EventEmitter<number[]> = new EventEmitter();
   @Output() getCentroMappa3857: EventEmitter<null> = new EventEmitter();

   constructor(
      private utilsService: UtilsService,
      private httpClient: HttpClient
   ) { }

   ngOnInit(): void {
      let form = {
         'epsgIn': new FormControl(3857, Validators.required),
         'lonGradiIn': new FormControl(),
         'lonPrimiIn': new FormControl(),
         'lonSecondiIn': new FormControl(),
         'xIn': new FormControl(),
         'latGradiIn': new FormControl(),
         'latPrimiIn': new FormControl(),
         'latSecondiIn': new FormControl(),
         'yIn': new FormControl(),
         'epsgOut': new FormControl(0, Validators.required),
         'lonOut': new FormControl(),
         'latOut': new FormControl(),
         'xOut': new FormControl(),
         'yOut': new FormControl()
      }
      this.formTrasformaCoordinate = new FormGroup(form);
   }

   aggiornataEPSGIngresso(event) {
      this.aggiornataLongitudine(event)
      this.aggiornataLatitudine(event);
   }

   aggiornataEPSGUscita(event) {

      // Svuoto i campi dei risultati e poi visualizzo i campi in base al sistema di riferimento selezionato
      this.formTrasformaCoordinate.get('lonOut').setValue('');
      this.formTrasformaCoordinate.get('latOut').setValue('');
      this.formTrasformaCoordinate.get('xOut').setValue('');
      this.formTrasformaCoordinate.get('yOut').setValue('');
      this._longitudineOutDecimal = 0;
      this._longitudineOutDegrees = '';
      this._latitudineOutDecimal = 0;
      this._latitudineOutDegrees = '';

      if (this.formTrasformaCoordinate.value.epsgOut != 0) {
         if (this.getTipoSviluppoSR(this.formTrasformaCoordinate.value.epsgOut) == 'piano') {
            this.srUscitaPiano = true;
            this.srUscitaGeografico = false;
         } else {
            this.srUscitaPiano = false;
            this.srUscitaGeografico = true;
         }
      }
   }

   /**
    * Converte il valore della longitudine in caso sia stato modificato manualmente.
    * @param   event evento 'change'
    */
   aggiornataLongitudine(event) {
      if (!this.formTrasformaCoordinate.valid) {
         return;
      }
      if (this.getTipoSviluppoSR(this.formTrasformaCoordinate.value.epsgIn) == 'geografico') {
         this._longitudineIn = this.convertiGradiInDecimali(
            Number(this.formTrasformaCoordinate.value.lonGradiIn),
            Number(this.formTrasformaCoordinate.value.lonPrimiIn),
            Number(this.formTrasformaCoordinate.value.lonSecondiIn));
      } else {
         this._longitudineIn = Number(this.formTrasformaCoordinate.value.xIn);
      }
      console.log("longitudine IN", this._longitudineIn)
   }

   /**
    * Converte il valore della latitudine in caso sia stato modificato manualmente.
    * @param   event evento 'change'
    */
   aggiornataLatitudine(event) {
      if (!this.formTrasformaCoordinate.valid) {
         return;
      }

      if (this.getTipoSviluppoSR(this.formTrasformaCoordinate.value.epsgIn) == 'geografico') {
         this._latitudineIn = this.convertiGradiInDecimali(
            Number(this.formTrasformaCoordinate.value.latGradiIn),
            Number(this.formTrasformaCoordinate.value.latPrimiIn),
            Number(this.formTrasformaCoordinate.value.latSecondiIn));
      } else {
         this._latitudineIn = Number(this.formTrasformaCoordinate.value.yIn);
      }
      console.log("latitudine IN", this._latitudineIn)
   }

   /**
    * Effettua il reset del form.
    */
   resetForm() {
      // this.formTrasformaCoordinate.patchValue({
      //    epsgIn: 3857,
      //    lonIn: this._longitudineIn,
      //    xIn: 0,
      //    latIn: this._latitudineIn,
      //    yIn: 0,
      //    epsgOut: 0,
      //    lonOut: "",
      //    xOut: 0,
      //    latOut: "",
      //    yOut: 0
      // });

      // this._longitudineOutDecimal = 0;
      // this._longitudineOutDegrees = '';
      // this._latitudineOutDecimal = 0;
      // this._latitudineOutDegrees = '';

      this.formTrasformaCoordinate.patchValue({
         epsgIn: 3857,
         epsgOut: 0,

         xIn: undefined,
         yIn: undefined,

         lonIn: this._longitudineIn,
         latIn: this._latitudineIn,

         lonOut: undefined,
         latOut: undefined,

         xOut: undefined,
         yOut: undefined,

         lonGradiIn: undefined,
         lonPrimiIn: undefined,
         lonSecondiIn: undefined,

         latGradiIn: undefined,
         latPrimiIn: undefined,
         laSecondiIn: undefined

      });

     // lonGradiIn
      this._longitudineOutDecimal = undefined;
      this._longitudineOutDegrees = undefined;
      this._latitudineOutDecimal = undefined;
      this._latitudineOutDegrees = undefined;

      //document.forms["rappresentazioneRadio"]["decimal"].checked = true;
      this.rapprSelected = "decimal";
   }

   /**
    * Emette l'evento per centrare la mappa sulle coordinate.
    */
   visualizzaCoordinate() {
      const API_URL = this.utilsService.creaURL('geo_utils', 'transform_coords');
      console.log("visualizzaCoordinate() DOMANDA", this._longitudineIn, this._latitudineIn, this.formTrasformaCoordinate.value.epsgIn);
      var params = {
         "coords": [[this._longitudineIn, this._latitudineIn]],
         "fromSRID": this.formTrasformaCoordinate.value.epsgIn,
         "toSRID": 3857
      }
      this.utilsService.postRequest(API_URL, params).subscribe((response: EBWApiResponse) => {
         console.log("visualizzaCoordinate() RESPONSE", response);
         if (response.error === 0 && response.status === 0) {
            this.centraMappaAllaCoordinata.emit([response.data.coords[0][0], response.data.coords[0][1]])
         }
      })

   }

   /**
    * Trasforma le coordinate
    */
   trasformaCoordinate() {
      const API_URL = this.utilsService.creaURL('geo_utils', 'transform_coords');
      var params = {
         "coords": [[this._longitudineIn, this._latitudineIn]],
         "fromSRID": this.formTrasformaCoordinate.value.epsgIn,
         "toSRID": this.formTrasformaCoordinate.value.epsgOut
      }
      console.log("trasformaCoordinate() params: ", params);
      this.utilsService.postRequest(API_URL, params).subscribe((response: EBWApiResponse) => {
         console.log("trasformaCoordinate()", response);
         if (response.error === 0 && response.status === 0) {
            //Ottengo dall'API i risultati in decimali
            this._longitudineOutDecimal = response.data.coords[0][0];
            this._latitudineOutDecimal = response.data.coords[0][1];
            //Converto i risultati in gradi
            this._longitudineOutDegrees = this.convertiDecimaliInGradi(this._longitudineOutDecimal);
            this._latitudineOutDegrees = this.convertiDecimaliInGradi(this._latitudineOutDecimal);

            if (this.getTipoSviluppoSR(this.formTrasformaCoordinate.value.epsgOut) == 'geografico') {
               this.formTrasformaCoordinate.get('lonOut').setValue(this._longitudineOutDecimal);
               this.formTrasformaCoordinate.get('latOut').setValue(this._latitudineOutDecimal);
            } else {
               this.formTrasformaCoordinate.get('xOut').setValue(this._longitudineOutDecimal);
               this.formTrasformaCoordinate.get('yOut').setValue(this._latitudineOutDecimal);
            }

            //Imposto il radio button della rappresentazione a decimal come di default, se la rappresentazione � di tipo geografico
            //(il tipo piano ha solo rappresentazione decimale)
            if (this.getTipoSviluppoSR(this.formTrasformaCoordinate.value.epsgOut) == 'geografico') {
               //document.forms["rappresentazioneRadio"]["decimal"].checked = true;
               this.rapprSelected = "decimal";
            }
         }
      })
   }

   /**
    * Gestisce la funzione di copia negli appunti
    * @param field Il campo da copiare
    */
   copy(field) {
      var element = document.getElementById(field) as HTMLInputElement;
      element.select();
      document.execCommand('copy');
   }

   /**
    * Gestisce il cambio di rappresentazione delle coordinate risultanti in seguito alla selezione sul radio button
    * @param type Il tipo di rappresentazione selezionata
    */
   aggiornaRappresentazione(type) {
      if (type == 'decimal') {
         this.formTrasformaCoordinate.get('xOut').setValue(this._longitudineOutDecimal);
         this.formTrasformaCoordinate.get('yOut').setValue(this._latitudineOutDecimal);
         this.formTrasformaCoordinate.get('lonOut').setValue(this._longitudineOutDecimal);
         this.formTrasformaCoordinate.get('latOut').setValue(this._latitudineOutDecimal);
      } else {
         this.formTrasformaCoordinate.get('xOut').setValue(this._longitudineOutDegrees);
         this.formTrasformaCoordinate.get('yOut').setValue(this._latitudineOutDegrees);
         this.formTrasformaCoordinate.get('lonOut').setValue(this._longitudineOutDegrees);
         this.formTrasformaCoordinate.get('latOut').setValue(this._latitudineOutDegrees);
      }
   }

   /**
    * Converte il numero in input in gradi
    * @param decNum Il numero da convertire in gradi
    */
   convertiDecimaliInGradi(decNum: number, array: boolean = false) {
      var gradi = decNum.toString().split('.')[0];
      var primi = ((decNum - parseInt(gradi)) * 60).toString().split('.')[0];
      var secondi = ((((decNum - parseInt(gradi)) * 60) - parseInt(primi)) * 60).toFixed(2);

      if (array) {
         return `${[gradi, primi, secondi].join("|")}`;
      } else {
         return gradi + "� " + primi + "\' " + secondi + "\"";
      }
   }

   /**
    * Converte i numeri in input in un numero in decimali
    * @param gradi I gradi della coordinata
    * @param primi I primi della coordinata
    * @param secondi I secondi della coordinata
    */
   convertiGradiInDecimali(gradi: number, primi: number, secondi: number) {
      return gradi + ((secondi / 60 + primi) / 60);
   }

   getTipoSviluppoSR(epsg: number) {
      if (epsg == 0) {
         return ''
      }

      // var epsgEl = this.arraySR.find(el => { return el.epsg == epsg })
      // console.log("++ ", epsgEl)
      // var tipoEpsg = (!!epsgEl && !!epsgEl.tipo) ? epsgEl.tipo : '';
      // return tipoEpsg;
      return this.arraySR.find(el => { return el.epsg == epsg }).tipo
   }


   impostaCoordinate() {
      console.log("impostaCoordinate()", this.centroMappa3857);
      const API_URL = this.utilsService.creaURL('geo_utils', 'transform_coords');
      var params = {
         "coords": [[this.centroMappa3857[0], this.centroMappa3857[1]]],
         "fromSRID": '3857',
         "toSRID": this.formTrasformaCoordinate.value.epsgIn
      }
      this.utilsService.postRequest(API_URL, params).subscribe((response: EBWApiResponse) => {
         console.log("trasformaCoordinate()", response);
         if (response.error === 0 && response.status === 0) {
            //Ottengo dall'API i risultati in decimali

            if (this.getTipoSviluppoSR(this.formTrasformaCoordinate.value.epsgIn) == 'geografico') {
               var latGradi = this.convertiDecimaliInGradi(response.data.coords[0][1], true).split("|");
               console.log("latitudine", latGradi)
               this.formTrasformaCoordinate.get('latGradiIn').setValue(latGradi[0]);
               this.formTrasformaCoordinate.get('latPrimiIn').setValue(latGradi[1]);
               this.formTrasformaCoordinate.get('latSecondiIn').setValue(latGradi[2]);
               var lonGradi = this.convertiDecimaliInGradi(response.data.coords[0][0], true).split("|");
               console.log("longitudine", lonGradi)
               this.formTrasformaCoordinate.get('lonGradiIn').setValue(lonGradi[0]);
               this.formTrasformaCoordinate.get('lonPrimiIn').setValue(lonGradi[1]);
               this.formTrasformaCoordinate.get('lonSecondiIn').setValue(lonGradi[2]);
            } else {
               this.formTrasformaCoordinate.get('xIn').setValue(response.data.coords[0][0]);
               this.formTrasformaCoordinate.get('yIn').setValue(response.data.coords[0][1]);
            }

            this.aggiornataLatitudine(null);
            this.aggiornataLongitudine(null);

            if (this.formTrasformaCoordinate.value.epsgOut != 0) {
               this.trasformaCoordinate();
            }
         }
      })
   }
}
