import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EBWModalMessage, EBWModalMessageCustom, EBWModalType, EBWModalShortMessage } from './model/ebw-modal.model';
import { EbwModalComponent } from './modal/ebw-modal.component';

/** Servizio per gestire le modali */
@Injectable({
   providedIn: 'root'
})
export class EbwModalService {

   /** Riferimento alla modale aperta */
   modalRef: NgbModalRef = null;

   /** Costruttore del servizio */
   constructor(
      private modalService: NgbModal,
      private logger: NGXLogger) { }

   /**
    * Metodo per istanziare una modale custom
    * @param message messaggio da utilizzare per istanziare la modale
    * @param options parametri di tipo {@link https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions NgbModalOptions} per personalizzare la modale
    * @returns ritorna il riferimento alla modale attiva (attenzione se è già stata istanziata una modale)
    */
   openCustomModal(message: EBWModalMessageCustom, options?: NgbModalOptions): NgbModalRef {
      if (this.modalRef === null) {
         this.modalRef = this.modalService.open(EbwModalComponent, options);
         this.modalRef.componentInstance.message = message;
         this.modalRef.result.finally(() => this.modalRef = null);

      } else {
         this.logger.warn('[EBW-MODAL-SERVICE]: Attenzione --> Modale già occupata! (può essere aperta una sola modale alla volta)')
      }
      return this.modalRef;
   }

   /**
    * Metodo per istanziare una modale di INFORMAZIONE
    * @param message messaggio da utilizzare per istanziare la modale
    * @returns ritorna il riferimento alla modale istanziata
    */
   openInfoModal(message: EBWModalMessage, options?: NgbModalOptions): NgbModalRef {
      const customMessage: EBWModalMessageCustom = JSON.parse(JSON.stringify(message));
      customMessage.type = EBWModalType.Info;

      return this.openCustomModal(customMessage, options);
   }

   /**
    * Metodo per istanziare una modale di WARNING
    * @param message messaggio da utilizzare per istanziare la modale
    * @returns ritorna il riferimento alla modale istanziata
    */
   openWarningModal(message: EBWModalMessage): NgbModalRef {
      const customMessage: EBWModalMessageCustom = JSON.parse(JSON.stringify(message));
      customMessage.type = EBWModalType.Warning;

      return this.openCustomModal(customMessage);
   }

   /**
    * Metodo per istanziare una modale di ERROR
    * @param message messaggio da utilizzare per istanziare la modale
    * @returns ritorna il riferimento alla modale istanziata
    */
   openErrorModal(message: EBWModalMessage): NgbModalRef {
      const customMessage: EBWModalMessageCustom = JSON.parse(JSON.stringify(message));
      customMessage.type = EBWModalType.Error;

      return this.openCustomModal(customMessage);
   }

   /**
    * Metodo per istanziare una modale di CONFIRM
    * @param message messaggio da utilizzare per istanziare la modale
    * @returns ritorna il riferimento alla modale istanziata
    */
   openConfirmModal(message: EBWModalMessage): NgbModalRef {
      const customMessage: EBWModalMessageCustom = JSON.parse(JSON.stringify(message));
      customMessage.type = EBWModalType.Confirm;

      return this.openCustomModal(customMessage);
   }

   /**
    * Metodo per istanziare una modale di SUCCESS
    * @param message messaggio da utilizzare per istanziare la modale
    * @returns ritorna il riferimento alla modale istanziata
    */
   openSuccessModal(message: EBWModalMessage): NgbModalRef {
      const customMessage: EBWModalMessageCustom = JSON.parse(JSON.stringify(message));
      customMessage.type = EBWModalType.Success;

      return this.openCustomModal(customMessage);
   }

   /**
    * Metodo per istanziare una modale di LOADING (infinito) da che è possibile chiudere tramite il metodo --> forceCloseCurrentModal()
    * @param message messaggio da utilizzare per istanziare la modale
    * @returns ritorna il riferimento alla modale istanziata
    */
   openLoadingModal(message: EBWModalShortMessage): NgbModalRef {

      const customMessage: EBWModalMessageCustom = JSON.parse(JSON.stringify(message));
      customMessage.type = EBWModalType.Loading;

      const modalOptions: NgbModalOptions = {
         size: 'sm',
         centered: true,
         backdrop: 'static',
         keyboard: false
      };

      return this.openCustomModal(customMessage, modalOptions);
   }

}
