import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'nullToEmptyString'
})
export class NullToEmptyStringPipe implements PipeTransform {

   /**
    * Converte i valori null in stringa vuota ''
    * @param value valore da convertire
    */
   transform(value: any): string {
      if (value == null)
         value = '';

      return value;
   }

}
