import { Component, OnInit } from '@angular/core';
import { faSort, faArrowCircleUp, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from 'src/app/servizi/utils.service';
import { GestioneLoginService } from 'src/app/servizi/login/gestione-login.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { PayloadUtente } from 'src/app/modelli/api.response.model';

/** Componente utilizzato per simulare il login con un'utente della lista */
@Component({
   selector: 'app-simulazione-utente',
   templateUrl: './simulazione-utente.component.html',
   styleUrls: ['./simulazione-utente.component.css']
})
export class SimulazioneUtenteComponent implements OnInit {

   /** tabella pronta  */
   ready: boolean = false;

   /** array degli utenti  */
   utenti: any;

   /** array degli utenti (Filtrati) */
   utentiView: any;

   /** icona nessun ordinamento */
   sortNo = faSort;

   /** icona ordinamento crescente */
   sortUp = faArrowCircleUp;

   /** icona ordinamento descrescente */
   sortDn = faArrowCircleDown;

   /** campo di ordinamento attuale */
   sorted: string;

   /** direzione di ordinamento */
   sortDir = 1;

   constructor(
      private router: Router,
      private utilsServ: UtilsService,
      private loginServ: GestioneLoginService,
      private logger: NGXLogger
   ) { }

   /** ngOnInit */
   ngOnInit() {

      // chiedo l'elenco di tutti gli utenti
      const uteUrl = this.utilsServ.creaURL('utenti', 'view' );
      const utePar = {
         attributes: {
            utenti: ['nome', 'cognome'],
            auth: ['username', 'password'],
            gruppi: ['nome'],
            ruoli: ['nome'],
            amministrazioni: ['denominazione']
         },
         order: 'username'
      };
      this.utilsServ.postRequest(uteUrl, utePar)
         .subscribe(
            (resp) => {
               if (resp.error === 0 && resp.status === 0) {
                  this.utenti = resp.data;
                  // formatto gli utenti
                  this.utenti.forEach(u => {
                     u.username = u.auth.username;
                     u.pwdcrypt = u.auth.password;
                     u.gruppo = u.auth.gruppi ? u.auth.gruppi.nome : '-';
                     u.ruolo = u.auth.ruoli ? u.auth.ruoli.nome : '-';
                     u.amministrazione = u.auth.gruppi.nome + ' - ' + u.auth.amministrazioni.denominazione;
                     delete u.auth;
                  });
                  this.utentiView = this.utenti;
                  // console.warn('this.utenti',this.utenti)
                  // tabella pronta
                  this.ready = true;
               }
            },
            (error) => this.logger.error(error)
         );
   }


   /**
    * metodo di ordinamento della tabella attraverso il sort dell'array di view
    * @param {string} fieldName nome del campo sul quale effettuare l'ordinamento
    */
   sort(fieldName: string) {
      this.sorted = fieldName;
      this.utentiView = this.utentiView.sort((a, b) => {
         if (this.utilsServ.nullToString(a[fieldName]) < this.utilsServ.nullToString(b[fieldName])) {
            return 1 * this.sortDir;
         } else {
            return -1 * this.sortDir;
         }
      });
      this.sortDir = -this.sortDir;
   }


   /**
    * filtro per la tabella utenti
    * @param {HTMLInputElement} fNome campo nome in input
    * @param {HTMLInputElement} fCogn campo cognome in input
    * @param {HTMLInputElement} fUser campo username in input
    * @param {HTMLInputElement} fAmmi campo amministrazione in input
    * @param {HTMLInputElement} fGrup campo gruppo in input
    * @param {HTMLInputElement} fRuol campo ruolo in input
    */
   filtraUtentiAbil(
      fNome: HTMLInputElement,
      fCogn: HTMLInputElement,
      fUser: HTMLInputElement,
      fRuol: HTMLInputElement,
      fGrup: HTMLInputElement,
      fAmmi: HTMLInputElement) {

      if ((fNome.value.length + fCogn.value.length + fUser.value.length + fRuol.value.length + fGrup.value.length + fAmmi.value.length) === 0) {
         this.utentiView = this.utenti;
      } else {
         this.utentiView = this.utenti.filter(u => {
            try {
               return (
                  this.utilsServ.nullToString(u.nome).includes(fNome.value.toUpperCase()) &&
                  this.utilsServ.nullToString(u.cognome).includes(fCogn.value.toUpperCase()) &&
                  this.utilsServ.nullToString(u.username).includes(fUser.value.toUpperCase()) &&
                  this.utilsServ.nullToString(u.ruolo).includes(fRuol.value.toUpperCase()) &&
                  this.utilsServ.nullToString(u.gruppo).includes(fGrup.value.toUpperCase()) &&
                  this.utilsServ.nullToString(u.amministrazione).includes(fAmmi.value.toUpperCase())
               );
            } catch {
               this.logger.error(u);
            }
         });
      }
   }


   /**
    * metodo di ordinamento della tabella attraverso il sort dell'array di view
    * @param {string} fieldName nome del campo sul quale effettuare l'ordinamento
    */
   onSimula(utente: any) {
      console.warn('Simulazione Utente -> ', utente);

      // rimuovo il token attuale
      this.loginServ.setToken('');

      // provo ad ottenere un token per l'utente scelto
      this.loginServ.getJWT(utente.username, utente.pwdcrypt, true)
         .subscribe(resp => {
            if (resp.error === 0 && resp.status === 0) {
               console.warn('TOKEN GENERATO', resp.data.token);
               // salvo il token nel servizio
               this.loginServ.setToken(resp.data.token);

               // decodifico il token generato
               this.loginServ.decodeJWT()
                  .subscribe(resp2 => {
                     if (resp2.error === 0 && resp2.status === 0) {
                        // prelevo il payload
                        console.warn('PAYLOAD', resp2.data.payload.user);
                        const payload: PayloadUtente = resp2.data.payload.user;

                        const currentUser: PayloadUtente = this.loginServ.getPayload();
                        // imposto il campo impersonificato da
                        payload.impersonificato_da = currentUser;

                        // salvo il payload nel servizio
                        this.loginServ.setPayload(payload);
                        // segnalo l'avvenuto login
                        this.loginServ.segnalaLogin(payload);
                        // redirect sulla home
                        this.router.navigate(['']);
                     } else {
                        // errore nel recuperare il payload
                        console.warn(resp);
                     }
                  });
            } else {
               // autenticazione fallita
               console.warn(resp);
            }
         });
   }

}
