
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { faBroadcastTower, faFileUpload, faSearch, faCircleNotch, faCopy, faMapMarkedAlt, faCheck, faArchive} from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { EbwModalService } from '../../library/ebw-modals-angular/ebw-modal.service';
import { EBWApiResponse } from '../../model/api.response.model';
import { MapUtilsService } from '../../service/map-utils.service';
import { MapComponent } from '../../map.component';

// import { GestioneLoginService } from 'src/app/servizi/login/gestione-login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'ebw-ricerca-geometrica',
  templateUrl: './ricerca-geometrica.component.html',
  styleUrls: ['./ricerca-geometrica.component.scss']
})

export class RicercaGeometricaComponent implements OnInit {

   @Output() apriToolDisegno: EventEmitter<any> = new EventEmitter();
   @Output() resetEmitter: EventEmitter<any> = new EventEmitter();
   @Output() disegnaGeometrie: EventEmitter<any> = new EventEmitter();

   /** Elemento confermato dal tool di disegno */
   private _elementoConfermatoObj: any;

   /** Metodo Set utilizzato per intercettare i cambiamenti del valore dell'elemento confermato dal tool di disegno */
   @Input() set elementoConfermatoObj(value: any) {
       this._elementoConfermatoObj = value;
       this.gestisciElementoConfermato(this._elementoConfermatoObj);
   }

   /** Indica se il tool di disegno è aperto */
   @Input() toolDisegnoOpen: boolean;

   @ViewChild('inputFile',  { static: false }) inputFile: ElementRef;

   EPSG = 3857;

   /** Icone */
   icons = {
      faMapMarkedAlt,
      faCheck,
      faCopy,
      faCircleNotch,
      faSearch,
      faFileUpload,
      faBroadcastTower,
      faArchive
   };

   /** Server API (backend) */
   token: string;

   /** Indica se la geometria è stata modificata con il tool di disegno */
   //geometriaIsChanged: boolean = false;

   /**
    * Risultato della ricerca geometrica
    * particelle o bts
    */
   resultList: any = [];

   /**
    * Tipo di ricerca: particelle o bts
    */
   searchType: string;

   /** Valore che deve essere copiato */
   inputToCopyText:string = "";

   /** File contenente le geometrie da caricare */
   fileToUpload: File = null;

   /** Array contenente tutte le geometrie, caricate o inserite a mano */
   geomObjs: any = [];

   /** Costruttore */
   constructor(
      private map: MapComponent,
      private mapUtils: MapUtilsService,
      private ebwModalService: EbwModalService,
      private modal: NgbModal,
      // private loginService: GestioneLoginService,
   ) {}

   /** OnInit */
   ngOnInit() {
      this.token = this.mapUtils.getToken();
   }

   /** Funzione che consente di aprire il tool di disegno e inserire una nuova geometria */
   callToolDisegno() {
      this.apriToolDisegno.emit()
   }

   /** Funzione che consente di aprire il tool di disegno e modificare una geometria */
   callToolDisegnoEdit(geom){
      // TODO
      /**
       * Rimuovere temporaneamente l'elemento dall'array geomObjs o memorizzarlo da qualche parte
       * Se CONFERMA --> aggiungi in geomObjs
       * Se ANNULLA --> rimetti l'elemento memorizzato o non fare nulla
       * PROBLEMA, se non lo rimuovo subito da geomObjs come lo riconosco???
       */
      // GB serve?? this.toolDisegnoOpen = true;
      this.apriToolDisegno.emit(geom)

      //this._elementoConfermatoObj)
   }

   /** Gestione della geometria restituita dal tool di disegno */
   gestisciElementoConfermato(el){
      this.geomObjs.push(el);
      console.log(">>> OBJs",this.geomObjs)

      // GB serve?? this.toolDisegnoOpen = false;
     // this.geometriaIsChanged = true;
      this.resultList = undefined;
   }

   /** Reset della deometria inserita e dell'elenco delle particelle */
   onReset(){
     // this.geometriaIsChanged = false;
      this._elementoConfermatoObj = undefined;
      this.resultList = undefined;

      this.geomObjs = [];
      this.inputFile.nativeElement.value = null;

      // Pulire i riferimenti alla vecchia feature/geometria in MapComponent
      this.resetEmitter.emit();
   }


   /**
    * Avvio della ricerca
    */
   onSearchOld(type){
      var format;

      this.searchType = type;

      /** Il cerchio va gestito ad hoc */
      if(this._elementoConfermatoObj.geom.type == "Circle"){
         format = "cerchio"
      }else{
         format = "GeoJSON"
      }

      var params = {
         geometry: JSON.stringify(this._elementoConfermatoObj.geom),
         format: format
      }

      console.log("++++ PARAMS ++++", params.geometry)

      var url;
      if(this.searchType == 'particelle'){
         url = this.mapUtils.creaURL('catasto', 'getFeatureInfo');
      }else if(type == 'bts'){
         url = this.mapUtils.creaURL('inwit', 'getFeatureInfo');
      }

      this.mapUtils.postRequest(url, params)
         .subscribe(
            (resp: any) => {

               // if(resp.error == 0 && resp.status == 0){
               if(resp && resp.features.length > 0){

                  this.resultList = resp.features;

               }else if (resp && resp.features.length <= 0){
                  this.ebwModalService.openInfoModal({
                     title: "Nessun risultato",
                     content: "Nessun risultato trovato",
                  });
               }
               else{
                  this.ebwModalService.openErrorModal({
                     title: "Errore applicativo",
                     content: resp.message
                  });
               }
         }
      );
   }


   /**
    * Effettua la ricerca delle particelle o delle stazioni BTS
    * @param type tipo di ricercs: particelle o bts
    * @param geom geometria da utilizzare per la ricerca
    */
   onSearch(type, geom){
      var format;
      this.searchType = type;

      if(!geom){
         this.ebwModalService.openInfoModal({ title: 'Attenzione', content: 'Geometria non presente.' });
         return;
      }

      /** Il cerchio va gestito ad hoc */
      if(geom.type == "Circle"){
         format = "cerchio"
      }else{
         format = "GeoJSON"
      }

      var params;

      // Da csv
      if(geom.srid){
         params = {
            geometry: JSON.stringify(geom),
            format: format,
            srid: geom.srid
         }
      }
      // Da tool disegno
      else{
         params = {
            geometry: JSON.stringify(geom),
            format: format
         }
      }

      var url;
      if(this.searchType == 'particelle'){
         url = this.mapUtils.creaURL('catasto', 'getFeatureInfo');
      }else if(type == 'bts'){
         url = this.mapUtils.creaURL('inwit', 'getFeatureInfo');
      }

      this.mapUtils.postRequest(url, params)
         .subscribe(
            (resp: any) => {

               if(resp && resp.features.length > 0){

                  this.resultList = resp.features;

               }else if (resp && resp.features.length <= 0){
                  this.ebwModalService.openInfoModal({
                     title: "Nessun risultato",
                     content: "Nessun risultato trovato",
                  });
               }
               else{
                  this.ebwModalService.openErrorModal({
                     title: "Errore applicativo",
                     content: resp.message
                  });
               }
         }
      );
   }

   /**
    * Per popolare il campo di input con il valore che devce essere copiato.
    * Questo deve essere fatto perché la funzione element.select() usata per la copia, può
    * essere eseguita solo su <input> e <textarea>
    */
   popolaInput(mode, text?){

      this.inputToCopyText = "";

      /** Copia di una riga singola */
      if(mode == "single"){
         this.inputToCopyText = text;
      }
      /** Copia di tutte le righe */
      else if(mode == "all"){
         let i = 0;

         this.resultList.forEach(p => {
            if(i != 0){
               this.inputToCopyText += ";\n";
            }
            console.log("P--->", p)
            this.inputToCopyText += p.properties.name;
            i++;
         });
      }

      this.copy();
   }


   /**
    * Funzione che copia negli appunti il contenuto del campo #inputToCopyText
    * (equivalente a ctrl+c)
    */
   copy(){
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = this.inputToCopyText;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
   }


   /**
    * Caricamento geometrie da file
    *
    * Esempio CSV
    *
      nome;lat;lon;epsg,raggio;
      Pippo;42-06-18.73N;11-49-42.65E;4326;100m
      Pluto;41-56-23.04N;12-08-53.59E;4326;100m
      Paperino;37-20-02.40N;15-05-21.30E;4326;100m
      Eta Beta;41-56-36.33N;12-42-56.13E;4326;100m

    */
   async onLoad(evt){

      this.fileToUpload = evt.target.files[0];

      let csvData;
      let jsonData;

      this.fileToUpload = evt.target.files[0];
      const reader = new FileReader();

      reader.readAsText(this.fileToUpload);

      reader.onload = (e) => {

         csvData = reader.result.toString().trim();
         //console.log(">> CSVData", csvData);

         jsonData = this.csvJSON(csvData);

         //console.log(">> JSONData", jsonData)

         var promises = [];

         jsonData.forEach(el => {

            /** Conversione coordinate da gradi a decimali */
            let arr = el.lat.split("-");

            let latDecimal = this.convertiGradiInDecimali(parseInt(arr[0]), parseInt(arr[1]), parseFloat(arr[2].slice(0, -1)));

            arr = el.lon.split("-");
            let lonDecimal = this.convertiGradiInDecimali(parseInt(arr[0]), parseInt(arr[1]), parseFloat(arr[2].slice(0, -1)));

            promises.push(new Promise(function(resolve, reject) {
                // TODO if !el.epsg   --> 4326
               this.trasformaCoordinate(lonDecimal, latDecimal, parseInt(el.epsg), this.EPSG).subscribe((response: EBWApiResponse) => {

                  if (response.error === 0 && response.status === 0) {

                     // Ottengo dall'API i risultati in decimali
                     // TODO controllo coords[0]
                     let _longitudineOutDecimal = response.data.coords[0][0];
                     let _latitudineOutDecimal = response.data.coords[0][1];

                     let obj = {
                        currentObj: {nome: el.nome},
                        geom: {
                        coordinates: [_longitudineOutDecimal, _latitudineOutDecimal],
                        crs: {
                           properties: {name: "EPSG:" + this.EPSG},
                           type: "name"
                        },
                        radius: parseFloat(el.raggio),
                        type: "Circle"

                        },

                        type: "ricerca_geometrica"
                     }

                     this.geomObjs.push(obj);
                     resolve("");
                  }
               })
            }.bind(this)
            ));

         });

         Promise.all(promises).then(function(values) {
            this.disegnaGeometrie.emit(this.geomObjs);
         }.bind(this)
         );


      }

   }


   /**
    * Per convertire un CSV in un JSON
    */
   csvJSON(csv){

      var lines = csv.split("\n");

      var result = [];

      var headers = lines[0].split(";");
      console.log("HEADERS >> ", headers)

      for(var i = 1; i < lines.length; i++){

         var obj = {};
         var currentline = lines[i].split(";");

         for(var j = 0; j < headers.length; j++){
            obj[headers[j].trim()] = currentline[j].trim();
         }
         result.push(obj);
      }

      return result; //JavaScript object
    }


   /**
    * Converte i numeri in input in un numero in decimali
    * @param gradi I gradi della coordinata
    * @param primi I primi della coordinata
    * @param secondi I secondi della coordinata
    */
   convertiGradiInDecimali(gradi: number, primi: number, secondi: number) {
      var decimal = gradi + ((secondi / 60 + primi) / 60);
      return decimal.toFixed(6);
   }

   /**
    * Trasforma le coordinate
    */
  trasformaCoordinate(_longitudineIn, _latitudineIn, epsgIn, epsgOut): Observable<any> {
      const API_URL = this.mapUtils.creaURL('geo_utils', 'transform_coords');
      var result = [];

      var params = {
         "coords": [[_longitudineIn,_latitudineIn]],
         "fromSRID": epsgIn,
         "toSRID": epsgOut
      }

      return this.mapUtils.postRequest(API_URL, params);
   }


   /** Apre la visualizzazione del report associato alla particella */
   // TODO test su AWS
   viewReport(el){

      //TODO
      // Vedi avviaReport in ricerca-dati-catastali.component

      console.log("viewReport EL --> ", el);

      /** Recupero la particella */
      const API_URL_VIEW = this.mapUtils.creaURL('catasto', 'view');

      // let selectObj: any = this.listComponent.results.find((x: any) => x.id == id);
      const params_view = {
         token: this.token,
         where: {
            codice_comune: el.properties.comune,
            codice_sezione: el.properties.sezione,
            numero: el.properties.numero,
            codice_foglio: el.properties.foglio,
            denominatore: el.properties.denominatore
         }
      };

      console.log("params_view", params_view);
      //return;

      this.mapUtils.postRequest(API_URL_VIEW, params_view).subscribe((response: EBWApiResponse) => {

         if (response.error === 0 && response.status === 0) {
            if (response.data.length > 0) {
               const current = response.data[0];

              //  const report = this.modal.open(ReportCatastoComponent, {
              //     ariaLabelledBy: 'modal-basic-title',
              //     centered: true,
              //     windowClass: 'modalFull95'
              //  });
              //  report.componentInstance.particellaParams = current;
            } else {
               this.ebwModalService.openWarningModal({ title: 'Attenzione', content: 'Dati non trovati' });
            }
         } else {
            this.ebwModalService.openErrorModal({ title: 'Errore di richiesta al server', content: response.message });
         }
      });
   }




}
