import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { faLink, faTimes, faCheck, faCircle, faMinus, faDotCircle, faDrawPolygon, faParking, faListAlt, faTrash, faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';

import { Feature, Overlay } from 'ol';
import { Layer, Vector } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import { Vector as VectorSource } from 'ol/source';
import { Style, Icon } from 'ol/style';

import { LineString, Polygon, Point, Circle, Geometry, SimpleGeometry } from 'ol/geom';

import { MapService, TipoLayer, PosizioneLayer } from '../../service/map.service';

import { GeoUtilsData } from '../../model/api.model';

// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { WKT, GeoJSON} from 'ol/format';
import { fromCircle } from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';

import { EBWModalResult } from '../../library/ebw-modals-angular/model/ebw-modal.model';
import { EbwModalService } from '../../library/ebw-modals-angular/ebw-modal.service';
import { Coordinate } from 'ol/coordinate';
import OverlayPositioning from 'ol/OverlayPositioning';
import GeometryType from 'ol/geom/GeometryType';


@Component({
   selector: 'ebw-tool-disegno',
   templateUrl: './tool-disegno.component.html',
   styleUrls: ['./tool-disegno.component.scss']
})

export class ToolDisegnoComponent implements OnInit, OnDestroy, AfterViewInit {

   /** RIFERIMENTI */
   @ViewChild('coordsListContainer', { static: false }) coordsListContainer: ElementRef;
   @ViewChild('customConfirmModal', { static: false }) customConfirmModal: ElementRef;

   /** INPUT */

   /** Per nascondere il bottone 'Elimina' se non necessario */
   @Input() visibilitaBtnElimina;

   /** Per nascondere il bottone 'Annulla' se non necessario */
   @Input() visibilitaBtnAnnulla;

   /**
    * Oggetto passato in input nel caso in cui ho aperto il tool di disegno a
    * partire da uno specifico elemento contenuto tra i risultati di una lista
    */
   @Input() currentObj: any;

   /** Tipo dell'oggetto passato in input, ad esempio occupazioni, oggetti, ingiunzioni, ecc... */
   @Input() currentObjType: string;

   /**
    * Eventuali Dati passati in input al tool di disegno, ad esmpio nel caso in cui sto inserendo una nuova ingiunzione,
    * vengono passate le informazioni dell'atto per costruire il nome della geometria
    */
   @Input() currentData: any;

   /** SRID di default utilizzato in tutto il pacchetto */
   @Input() defaultSR: any;

   /** Oggetto layer di riferimento */
   @Input() layerDefault: BaseLayer;

   /** Identificativo stile del layer di default */
   @Input() layerDefaultStyleId: string;

   /** Feature selezionata per le transformazioni */
   @Input() selectedFeature: Feature;

   /** Centro della mappa sempre aggiornato
    * [0] --> longitudine
    * [1] --> latitudine
    */
   @Input() centroMappa;
   @Input() viewMappa;

   /** Resta in ascolto di cambiamenti al valore di triggerSelectedFeature: è stata selezionata una geometria */
   @Input() set triggerSelectedFeature(v: number) {
      this.gestisciSelezione();
   }

   // @Input() set triggerToolDisegnoAttivo(attivo: boolean){
   //    if(!attivo)
   //       alert("TOOL DISEGNO DISABILITATO")
   //    else
   //       alert("TOOL DISEGNO ABILITATO")
   // }

   /**
    * Per drill down - Array contenente le features presenti in un determinato pixel
    * TD = Tool Disegno
    */
   @Input() featuresAtPixelTD: Feature[];

   /** Resta in ascolto di cambiamenti al valore di triggerSelectedFeature:
    * è stata selezionata una geometria
    * // NOTE Secondo me non è necessario
    */
   @Input() set triggerfeaturesAtPixelTD(v: number) {
      console.log('DRILL - Le fetures presenti nel pixel sono: ', this.featuresAtPixelTD);
   }

   /**
    * Dimensione array cambiamenti
    */
   @Input() historyChangesSize: number;


   /**
    * Per gestire la visibilità della toolbar
    */
   @Input() toolbarVisible: boolean;

   /** Variabile usata per nascondere il pannello coordinate senza 'distruggere' il componente */
   @Input() displayPc: boolean;

   /** Tool della toolbar selezionato di default */
   @Input() toolbarDefaultSelectedTool: string = '';


   /** Indica se iltasti 'Annulla' e 'X' del pannello coordinate sono abilitati a chiudere sia il pannello coordinate che la toolbar */
   @Input() closeAll: boolean = false;

   /** Tool abilitati */
   @Input() enabledTools? : any = {
     point: true,
     line: false,
     polygon: true,
     circle: true,
     particella: false
   }


   /** OUTPUT */

   /** Emitter per cancellare la feature da eliminare */
   @Output() featureToDelete: EventEmitter<Feature> = new EventEmitter();

   /** Evento per l'inserimento del layer di precisione sulla mappa */
   @Output() addPrecisionLayer: EventEmitter<BaseLayer> = new EventEmitter();

   /** Evento per la rimozione di una tooltip */
   @Output() removeTooltip: EventEmitter<Overlay> = new EventEmitter();

   /** Evento per aggiungere una tooltip */
   @Output() addTooltip: EventEmitter<Overlay> = new EventEmitter();

   /** Evento per abilitare una nuova modalità es. selezione */
   @Output() changedEditMode: EventEmitter<string> = new EventEmitter();

   /** Eventi per aggiornare la storia dei cambiamenti */
   @Output() alteredFeatureHistory: EventEmitter<{ original: Feature, altered: Feature }> = new EventEmitter();
   @Output() insertedFeatureHistory: EventEmitter<{ altered: Feature }> = new EventEmitter();
   @Output() deletedFeatureHistory: EventEmitter<{ original: Feature }> = new EventEmitter();

   // GB serve veramente ??? Si perchè qualcuno imposta lo stile alla feature selezionata non so chi e quindi lo uso per toglierlo
   // In modo che non rimanga vedre una volta fatto 'Annulla'/'Conferma'
   // @Output() selectedFeatureFromPanel: EventEmitter<Feature> = new EventEmitter();

   /** Deselezione della feature selezionata */
   @Output() deselectFeature: EventEmitter<any> = new EventEmitter();

   /** Evento per effettuare l'undo della modifica */
   @Output() undoModify: EventEmitter<boolean> = new EventEmitter();

   /** Per gestire il Drill Down e notificare alla mappa che è stata selezionata una feature dal pannello di gestione della selezione di feature multiple */
   @Output() selectedFeatureFromPanel: EventEmitter<Feature> = new EventEmitter();

   @Output() pulisciTutto: EventEmitter<any> = new EventEmitter();

   /** SID3 TOOL PARTICELLA */
   /** Emettitore evento per tool particella */
   @Output() btnToolParticella: EventEmitter<boolean> = new EventEmitter();

   /** SID3 TOOL AGGANCIA PUNTO */
   @Output() btnToolAgganciaPunto: EventEmitter<any> = new EventEmitter();

   /** Variabile che indica lo stato del tool particella */
   // statoToolParticella: boolean = false;

   @Output() toolDisegnoInitialized: EventEmitter<any> = new EventEmitter();

   @Output() chiudiToolDisegnoEmitter: EventEmitter<any> = new EventEmitter();

   /** Evento che contiene l'elemento(inclusa la geometria) inserito con il tool di disegno e confermata con il tasto 'Conferma' */
   @Output() toolDisegnoElementoConfermatoEmitter: EventEmitter<any> = new EventEmitter();

   /** COSTANTI */

   /** Stile dei punti sulla mappa */
   STYLE_ID_PRECISION_LAYER = 'BlueFill';
   STYLE_ID_SELECT_FOR_DRILL_DOWN = 'YellowPoint';
   UNCONFIRMED_PRECISION_FEATURE_TAG = 'unconfirmedFeature';
   EPSG_MAP = 3857;

   EPSG_LIST = [
      {value: 3857, label: 'WGS 84 / Spherical Mercator'},
      {value: 4326, label: 'World Geodetic System 1984'}];


   GEOM_TYPE_LABEL = {
      Circle: 'Cerchio',
      Polygon: 'Poligono',
      LineString: 'Linea',
      Point: 'Punto'
   };

   GEOM_TYPE_ICON = {
      Point: faCircle,
      LineString: faMinus,
      Polygon: faDrawPolygon,
      Circle: faDotCircle
   };


   /**
    * Tooltip che consentono di distinguere le coordinate,
    * variano in base all'EPSG
    * es. EPSG 3857: X/Y, EPSG 4326: LAT/LONG
    */
   ttCoordX = 'X';
   ttCoordY = 'Y';

   /** Punto di cui ricavare i riferimenti catastali */

   /** ICONE */
   faLink = faLink;
   faCircle = faCircle;
   faMinus = faMinus;
   faDotCircle = faDotCircle;
   faDrawPolygon = faDrawPolygon;
   faParking = faParking;
   faListAlt = faListAlt;
   faTrash = faTrash;
   faPlus = faPlus;
   faUndo = faUndo;
   faCheck = faCheck;
   faTimes = faTimes;

   /** VARIABILI */

   /** Riferimento alla form nella Toolbar */
   public toolbarForm: FormGroup;

   /** Riferimento alla form nel Pannello Coordinate */
   public pannelloCoordinateForm: FormGroup;

   /** Per rendere visibile la form solo quando è pronta */
   formReady: boolean = false;

   /** Contatore di feature */
   precisionCounterFeaturePlaceholder: number;

   /** Layer per disegni di precisione */
   precisionLayer: BaseLayer;

   /** Sistema di rimerimento */
   selectedSRID: number;

   /** Tipo della geometria selezionata */
   tipoGeometriaSelezionata: string = '';

   /** Booleano per abilitare/disabilitare i tools nella toolbar mentre sto disegnando, finché non confermo o annullo la figura */
   disegnoAttivoDisabilitaTool: boolean = false;

   /** Per gestire la visualizzazione del pannello delle coordinate */
   pannelloCoordinateAbilitato: boolean = false;

   /** Proprietà della feature selezionata */
   proprietaFeatureSelezionata = {};

   /** ID della feature selezionata */
   idFeatureSelezionata: string;

   /** Contiene la feature selezionta  prima dell'eventuale modifica */
   originalFeature: Feature;

   // Indica se sono state fatte modifiche alla geometria
   isEdit = false;

   /** Elemento restituito dal tool di disegno contenente le informazioni dell'elemento confermato */
   elementoConfermato: any = {};


   /** METODI */

   /** Costruttore */
   constructor(
      private formBuilder: FormBuilder,
      private servizioMappa: MapService,
      private ebwModalService: EbwModalService,
      private logger: NGXLogger) { }

   /**
    * initForms
    * Inizializza le form
    */
   initForms() {

      /** Inizializzazione controllo Toolbar */
      this.toolbarForm = this.formBuilder.group({
         /** Tipo della geometria: Radio Buttons Group punto, linea, poligono, cerchio, particella */
         disegno: '',
      });

      /** Inizializzazione pannello coordinate */
      this.pannelloCoordinateForm = this.formBuilder.group({
         epsg: this.EPSG_MAP,
         nome: '',
         raggio: ''
      });

      /** Resta in ascolto di cambiamenti al valore del controllo 'disegno' che indica la geometria selezionata */
      this.toolbarForm.controls.disegno.valueChanges.subscribe(val => this.gestisciBottoniDisegno());

      /** Resta in ascolto di cambiamenti al valore del controllo 'epsg' che indica il sistema di riferimento selezionato */
      this.pannelloCoordinateForm.controls.epsg.valueChanges.subscribe(val => this.gestisciCambioEpsg());

      /** Nel caso della ricerca geometrica, il pannello coordinate deve essere inizialmente aperto */
      if(this.currentObjType == "ricerca_geometrica"){
         this.pannelloCoordinateAbilitato = true;
      }
      /** Chiudo il pannello delle coordinate */
      this.pannelloCoordinateAbilitato = false;

      /** Abilito i tools */
      this.disegnoAttivoDisabilitaTool = false;

      /** La form è pronta e può essere visualizzata */
      this.formReady = true;

   }

   /**
    * ngOnDestroy
    */
   ngOnDestroy() {

      console.log('-- CHIUSURA DEL TOOL DI DISEGNO --');

      /**
       * Quando il tool di disegno viene chiuso
       */

      /** Disabilito la modalità disegno */
      this.endDraw();

      /** Pulisco i layer di default */
      ((this.layerDefault as Layer).getSource() as VectorSource).getFeatures().forEach((f: Feature) => {
         this.removeTooltip.emit(f.get('tooltip'));
         f.unset('tooltip');
      });

      /**
       * Il layer di default deve essere pulito solo nel caso in cui non è stato passato alcun oggetto in input,
       * ad esempio quando accedo al tool di disegno tramite la mappa currentObj non sarà valorizzato mentre invece,
       * quando vi accedo dalle variazioni geometriche, currentObj sarà valorizzato
       */
      if (!!!this.currentObj && this.currentObjType != 'ricerca_geometrica') {
        ((this.layerDefault as Layer).getSource() as VectorSource).clear();
      }

      /** Pulisco il Layer di precisione */
      this.clearPrecisionLayer();

      /** Chiusura del pannello di selezione multipla */
     // this.chiudiPannelloSelezioneMultipla();
      this.featuresAtPixelTD = [];

      /** Deselezione dell'eventuale feature selezionata */
      this.deselectFeature.emit();

      /** Svuoto i campi dell'eventuale feature selzionata */
      this.selectedFeature = null;
      this.idFeatureSelezionata = undefined;
      this.proprietaFeatureSelezionata = {};

      this.pulisciTutto.emit();

   }


   /**
    * ngAfterViewInit
    */
   ngAfterViewInit() {

      /**
       * Se ho aperto il tool di disegno a partire dagli elementi restituiti in una lista (elenco),
       * allora il tool di disegno deve essere aperto automaticamente sulla geometria dell'oggetto in questione
       */

      /** Necessario per evitare ExpressionChangedAfterItHasBeenCheckedError */
      setTimeout(() => {
         this.toolDisegnoInitialized.emit();
      });

   }


   /**
    * ngOnInit
    */
   ngOnInit() {

      // TODO DA SPOSTARE??
      /** Inizializzazione Toolbar e pannello coordinate*/
      this.initForms();

      this.featuresAtPixelTD =[];
      // this.historyChangesSize = 0;

      /** Inizializzazione Layers */
      this.selectedSRID = this.defaultSR.epsg;

      /** Inizializzazione della sorgente sulla quale verrà inserito un layer vettoriale */
      const vectorSource = new VectorSource({});

      /** Creazione layer per disegno di precisione */
      this.precisionLayer = new Vector({
         source: vectorSource,
         visible: true,
         zIndex: 1000
      });

      /** Impostazioni del nuovo layer : precisionLayer */
      /** Titolo */
      this.precisionLayer.set(MapService.TITOLO_LAYER_KEY, 'precision_draw');
      /** Tipo */
      this.precisionLayer.set(MapService.TIPO_LAYER_KEY, TipoLayer.TEMP);
      /** Posizione */
      this.precisionLayer.set(MapService.POSIZIONE_LAYER_KEY, PosizioneLayer.OVERLAY);

      /** Emissione dell'evento per l'aggiunta del layer alla mappa */
      this.addPrecisionLayer.emit(this.precisionLayer);

      /**
       * PRECISION LAYER - ADD FEATURE
       *
       * Gestione evento ADDFEATURE della VectorSource associata al precisionLayer
       * Evento generato ogni volta che viene inserita una nuova feature nel layer di precisione
       * (Layer temporaneo di disegno)
       */
      vectorSource.on('addfeature', (sourceEvent) => {

         /** Recupera dalla form il nome che l'utente ha dato alla della feature inserita */
         const featureName = this.pannelloCoordinateForm.controls.nome.value;

         /**
          * PUNTO
          * Se è stato inserito un punto (In modalità precisione)
          */

         if (sourceEvent.feature.getGeometry().getType() === 'Point') {
            /** Se la feature inserita non ha il placeholder lo imposto */
            if (!!!sourceEvent.feature.get('precisionCounterPlaceholder')) {
               sourceEvent.feature.set('precisionCounterPlaceholder', this.precisionCounterFeaturePlaceholder);
               this.precisionCounterFeaturePlaceholder++;
            }

            /** La feature è sempre confermata chiamando -> this.confirmPoint(sourceEvent.feature); */
            if (!sourceEvent.feature.get('notSetUnconfirmedTag')) {
               /** Imposto la nuova feature come non confermata */
               sourceEvent.feature.set('featureTag', this.UNCONFIRMED_PRECISION_FEATURE_TAG);

               /** Generazione di un ID univoco per la feature inserita */
               sourceEvent.feature.setId(this.generateFeatureId());
            }

            /** Inserimento del nome della feature tra le proprietà */
            if (featureName != null) {
               const p = sourceEvent.feature.getProperties();
               p.nome = featureName;
               sourceEvent.feature.setProperties(p);

               sourceEvent.feature.set('highlight', true);
            }

            /** Confermo sempre il punto */
            this.confirmPoint(sourceEvent.feature);

            this.transformSinglePrecisionFeatureForVisualization(this.selectedSRID, sourceEvent.feature);

         } else if (sourceEvent.feature.getGeometry().getType() === 'Circle') {

            /** Se la feature inserita non ha il placeholder lo imposto */
            if (!!!sourceEvent.feature.get('precisionCounterPlaceholder')) {
               sourceEvent.feature.set('precisionCounterPlaceholder', this.precisionCounterFeaturePlaceholder);
               this.precisionCounterFeaturePlaceholder++;
            }

            /** La feature è sempre confermata chiamando -> this.confirmPoint(sourceEvent.feature); */
            if (!sourceEvent.feature.get('notSetUnconfirmedTag')) {
               /** Imposto la nuova feature come non confermata */
               sourceEvent.feature.set('featureTag', this.UNCONFIRMED_PRECISION_FEATURE_TAG);

               /** Se sono in modifica c'è una feature selezionata quindi uso l'id della feature selezionata */
               if (!!this.idFeatureSelezionata) {
                  sourceEvent.feature.setId(this.idFeatureSelezionata);
               } else {
                  sourceEvent.feature.setId(this.generateFeatureId());
               }
            }

            /** Inserimento del nome della feature tra le proprietà */
            if (featureName != null) {
               const p = sourceEvent.feature.getProperties();
               p.nome = featureName;
               sourceEvent.feature.setProperties(p);
            }

            /** Confermo sempre il punto */
            this.confirmPoint(sourceEvent.feature);

            this.transformSinglePrecisionFeatureForVisualization(this.selectedSRID, sourceEvent.feature);

         } // Cerchio

      }); // addfeature


      /**
      * LAYER DEFAULT - ADD FEATURE
      * Ogni volta che viene aggiunta una feature nella modalità standard oppure viene confermata una
      * figura in modalità precisione passa qui
      * sourceEvent è un obj fatto così --> {feature: Feature, target: VectorSource}
      */
      ((this.layerDefault as Layer).getSource() as VectorSource).on('addfeature', (sourceEvent) => {


         const featureName = this.pannelloCoordinateForm.controls.nome.value;

         /** Se ho salvato delle proprietà per la feature selezionata le risetto */
         if (!!this.proprietaFeatureSelezionata && Object.keys(this.proprietaFeatureSelezionata).length > 0) {
            const properties = this.concatProperties(sourceEvent.feature.getProperties(), this.proprietaFeatureSelezionata);
            sourceEvent.feature.setProperties(properties);
         }

         /** Ho già id, non faccio niente */
         if (sourceEvent.feature.getId() !== undefined) {
         } else if (!!this.idFeatureSelezionata) {
            sourceEvent.feature.setId(this.idFeatureSelezionata);

         /** Inserimento */
         } else {
            /** Altrimenti ne assegno uno generata */
            this.idFeatureSelezionata = this.generateFeatureId();
            sourceEvent.feature.setId(this.idFeatureSelezionata);
         }


         /** Il campo nome della geometria è facoltativo */
         const p = sourceEvent.feature.getProperties();
         p.nome = featureName;
         sourceEvent.feature.setProperties(p);

         /** Emetto evento per l'inserimento nella storia delle modifiche (CASO NUOVO INSERIMENTO) */
         if (!this.isPannelloCoordDisabled()) {

            if (!this.isEdit) {
            this.insertedFeatureHistory.emit({ altered: sourceEvent.feature });
            }

            /** Se è presente la feature originale elimino il riferimento (CASO MODIFICA) */
            if (!!this.originalFeature) {

               /** Se le geometrie sono diverse emetto l'evento per aggiornare la storia dei cambiamenti */
               if (!this.geometriesAreEquals(this.originalFeature, sourceEvent.feature)) {
                  this.alteredFeatureHistory.emit({ original: this.originalFeature, altered: sourceEvent.feature });
               }

               this.originalFeature = undefined;
            }
         }

         if (this.selectedFeature == null) {
            this.idFeatureSelezionata = undefined;
            this.proprietaFeatureSelezionata = {};
         }

         this.endDraw();
      });

   }



   /**
    * Funzione per confrontare se le due geometrie passate in input sono uguali.
    */
   geometriesAreEquals(feature1: Feature, feature2: Feature) {
     let geom1: Geometry;
     let geom2: Geometry;

      if (this.tipoGeometriaSelezionata === 'Circle') {
         /** Conversione del un cerchio in poligono */
         // Polygon
         geom1 = fromCircle(
            feature1.getGeometry() as Circle // Circle
         );

         /** Conversione del un cerchio in poligono */
         // Polygon
         geom2 = fromCircle(
            feature2.getGeometry()  as Circle// Circle
         );
      } else {
         geom1 = feature1.getGeometry();
         geom2 = feature2.getGeometry();
      }
      try {

         const formatWKT = new WKT();
         const tmp1 = formatWKT.writeGeometry(geom1);
         const tmp2 = formatWKT.writeGeometry(geom2);

         return tmp1 === tmp2;

      } catch (err) {
         console.log('geometriesAreEquals --> ERROR : ', err);
         return false;
      }
   }

   // TODO
   /** Gestisce il Drill down in caso di più features nel pixel selezionato
    * @param f Feature selezionata dal pannellino di gestione della selezione di feature multiple nel pixel
    * (SELEZIONE MULTIPLA CON DRILL DOWN)
    */
   gestisciFeatureSelezioneMultipla(f) {
      /** Features presenti nel pixel selezionato */
      // TODO Emetti evento selezione feature

      /** Recupero la fetaure selezionata dal layer */
      this.selectedFeature = ((this.layerDefault as Layer).getSource() as VectorSource).getFeatureById(f.getId());
      this.chiudiPannelloSelezioneMultipla();
      this.editFeaturePrecision();
   }

   /**
    * Bottone chiudi nel pannello di selezione features multiple
    */
   chiudiPannelloSelezioneMultipla() {
      this.featuresAtPixelTD = [];
   }

   /**
    * Gestione visibilità pannello di selezione multipla
    */
   isFeatureAtPixelTDVisible() {

      // if(!!this.featuresAtPixelTD){
      // console.log("-- isFeatureAtPixelTDVisible", this.featuresAtPixelTD)
      // console.log("-- isFeatureAtPixelTDVisible", this.featuresAtPixelTD.length)
      // }

      /** Se ci sono più features selezionate apre il pannello di selezione multipla */
      if (!!this.featuresAtPixelTD && this.featuresAtPixelTD.length > 1) {
         return true;
      } else {
         return false;
      }
   }

   /**
    * Consente di visualizzare la feature interessata
    * Si attiva nell'evento on hover della feature nel pannellino di gestione della selezione di feature multiple
    * @param f Feature interessata
    */
   illuminaFeature(f) {
      let featureFromLayer = ((this.layerDefault as Layer).getSource() as VectorSource).getFeatureById(f.getId());
      featureFromLayer.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_SELECT_FOR_DRILL_DOWN));

   }

   /**
   * Ripristina lo stile di default
   * Si attiva nell'evento on hover della feature nel pannellino di gestione della selezione di feature multiple
   * @param f Feature interessata
   */
   spegniFeature(f) {
      const featureFromLayer = ((this.layerDefault as Layer).getSource() as VectorSource).getFeatureById(f.getId());
      featureFromLayer.setStyle(this.servizioMappa.buildFeatureStyle(this.layerDefaultStyleId));
   }

   /**
    * Gestisce la feature selezionata (SELEZIONE SINGOLA)
    */
   gestisciSelezione() {
      this.chiudiPannelloSelezioneMultipla();
      this.editFeaturePrecision();
   }


   /**
    * Abilita modifica di precisione per una certa feature
    */
   editFeaturePrecision() {
console.log("+++++++this.currentObj ++++++++++++++++", this.currentObj)
      let nomeGeom = '';

      if (typeof (this.selectedFeature) === 'undefined' || this.selectedFeature == null) {

         // Inserimento NUOVA geometria associata ad un oggetto presente nel DB
         if (!!this.currentObj && !!this.currentObjType) {

            switch (this.currentObjType) {
               case 'oggetto':
                  if (!!this.currentObj.tipo_oggetto_codice && !!this.currentObj.progressivo) {
                     nomeGeom = this.currentObj.tipo_oggetto_codice + '' + this.currentObj.progressivo;
                  }
               break;

               case 'occupazione':
                  if (!! this.currentObj.descrizione) {
                     nomeGeom = this.currentObj.descrizione;
               }

               break;

               case 'ingiunzione':
                  if (!!this.currentObj.numero && !!this.currentObj.anno) {
                     nomeGeom = this.currentObj.numero + '/' + this.currentObj.anno;
                  }
               break;

               case 'vincolo':
                  // Per la geometria ingombro da disegnare non è previsto un nome quindi lascio il campo vuoto e lo disabilito da map.component
               break;

               case 'ordinanza':
                  if(!!this.currentObj.nome)
                     nomeGeom = this.currentObj.nome;
               break;

               case 'ricerca_geometrica':
                  if(!!this.currentObj.currentObj && !!this.currentObj.currentObj.nome){
                     nomeGeom = this.currentObj.currentObj.nome;
                  }
                  // Per la geometria ingombro da disegnare non è previsto un nome quindi lascio il campo vuoto e lo disabilito da map.component
               break;
            }


            /**
             * Se è stato passato il nome della geometria lo recupero, ad esempio
             * se l'utente ha inserito e confermato la geometria ma non l'ha ancora salvata nel db
             */
            if (!!this.currentObj.nome) {
               nomeGeom = this.currentObj.nome;
            }

            /**
             * Se l'utente ha inserito e confermato la geometria ma non l'ha ancora salvata nel db
             */

            /** Preimposto il nome della geometria */
            if (!!this.pannelloCoordinateForm) {
               if (!!nomeGeom) {
                  this.pannelloCoordinateForm.controls.nome.setValue(nomeGeom);
               }

               this.pannelloCoordinateForm.controls.nome.disable();
            }

            /** Imposto il sistema di coordinate di default */
            this.selectedSRID = this.defaultSR.epsg;
         }
         return;
      }

      const feature = this.selectedFeature;

      /** Tipo feature selezionata */
      this.tipoGeometriaSelezionata = feature.getGeometry().getType();

      /** Proprietà feature selezionata */
      this.proprietaFeatureSelezionata = feature.getProperties();

      /** Imposto il sistema di coordinate di default */
      this.selectedSRID = this.defaultSR.epsg;

      /** Salvo una copia della feature prima della modifica */
      this.originalFeature = feature.clone();

      /** Salvo l'id della feature selezionata e lo associo alla feature appena salvata*/
      this.idFeatureSelezionata = feature.getId().toString();
      this.originalFeature.setId(this.idFeatureSelezionata);

      /** Rimuovo la feature dal layer di default e la inserisco nel layer di precisione per la modifica */
      ((this.layerDefault as Layer).getSource() as VectorSource).removeFeature(feature);
      feature.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER));
      ((this.precisionLayer as Layer).getSource() as VectorSource).addFeature(feature);

      /** Divido la feature in punti editabili */
      this.createEditablePointsFromFeature(feature);

      /** Per evitare di fare due volte la startDraw, */
      this.isEdit = true;

      /** Selezione del tool nella toolbar */
      this.toolbarForm.controls.disegno.setValue(this.tipoGeometriaSelezionata);

      const p = feature.getProperties();

      /** Se è un oggetto recuperato dal DB devo recuperare il nome diversamente */
      if (!!this.currentObj && !!this.currentObjType) {

         /** Preimposto il nome della geometria */
         switch (this.currentObjType) {
            case 'oggetto':
               if (!!this.currentObj.tipo_oggetto_codice && !!this.currentObj.progressivo) {
                  nomeGeom = this.currentObj.tipo_oggetto_codice + '' + this.currentObj.progressivo;
               }
               break;

            case 'occupazione':
               if (!! this.currentObj.descrizione) {
                  nomeGeom = this.currentObj.descrizione;
               }
               break;

            case 'ingiunzione':
               if (!!this.currentObj.numero && !!this.currentObj.anno) {
                  nomeGeom = this.currentObj.numero + '/' + this.currentObj.anno;
               }
               break;

            case 'vincolo':
               // Per la geometria ingombro da disegnare non è previsto un nome quindi lascio il campo vuoto e lo disabilito da map.component
               break;
            case 'ordinanza':
               if(!!this.currentObj.nome)
                  nomeGeom = this.currentObj.nome;
               break;
            case 'ricerca_geometrica':
               if(!!this.currentObj.currentObj.nome){
                  nomeGeom = this.currentObj.currentObj.nome;
               }

               // Per la geometria ingombro da disegnare non è previsto un nome quindi lascio il campo vuoto e lo disabilito da map.component
            break;
         }

         if (!!this.pannelloCoordinateForm) {
            if (!!nomeGeom) {
               this.pannelloCoordinateForm.controls.nome.setValue(nomeGeom);

            }

           // Per Inwit non serve disabilitare
           // this.pannelloCoordinateForm.controls.nome.disable();
         }
      } else if (!!p.nome && p.nome != '' && p.nome != null ) {
         this.pannelloCoordinateForm.controls.nome.setValue(p.nome);
      }

   }


   /**
    * Crea i punti che compongono una feaure composta (LineString, Polygon)
    * @param   mainFeature oggetto feature della quale creare i punti
    */
   createEditablePointsFromFeature(mainFeature: Feature) {

      // Se è una feature entrata da fuori (e non creata dal tool di disegno) inizializzo il contatore
      if (!!this.currentObj) {
         this.precisionCounterFeaturePlaceholder = 0;
      }

      switch (mainFeature.getGeometry().getType()) {
         case 'Point':  break;
         case 'LineString':
            /** Recupero le coordinate dei punti */
            const linePoints = (mainFeature.getGeometry() as SimpleGeometry).getCoordinates();

            for (let i = 0; i <= linePoints.length - 1; i++) {
               const coords = linePoints[i];
               /** Creo la feature punto */
               const f = this.createPointFeature(coords);
               /** Aggiungo alla feature il tooltip */
               const ttPoint = this.createPointTooltip(i + 1, coords);
               f.set('tooltip', ttPoint);
               /** Aggiungo lo stile del layer precision alla feature */
               f.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER));
               /** Imposto una proprietà per rendere la feature già confermata */
               f.set('notSetUnconfirmedTag', true);

               // Se è una feature entrata da fuori e non creata dal tool di disegno, devo impostare il placeholder
               if (!!this.currentObj) {
                  f.set('precisionCounterPlaceholder', this.precisionCounterFeaturePlaceholder);
                  this.precisionCounterFeaturePlaceholder++;
               }

               /** Aggiungo la feature al layer precision */
               ((this.precisionLayer as Layer).getSource() as VectorSource).addFeature(f);
            }
            break;
         case 'Polygon':
            const polygonPoints = (mainFeature.getGeometry() as Polygon).getLinearRing(0).getCoordinates();
            for (let i = 0; i <= polygonPoints.length - 2; i++) {
               const coords = polygonPoints[i];
               /** Creo la feature punto */
               const f = this.createPointFeature(coords);
               /** Aggiunto alla feature il tooltip */
               const ttPoint = this.createPointTooltip(i + 1, coords);
               f.set('tooltip', ttPoint);
               /** Aggiungo lo stile del layer precision alla feature */
               f.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER));
               /** Imposto una proprietà per rendere la feature già confermata */
               f.set('notSetUnconfirmedTag', true);

               // Se è una feature entrata da fuori e non creata dal tool di disegno, devo impostare il placeholder
               if (!!this.currentObj) {
                  f.set('precisionCounterPlaceholder', this.precisionCounterFeaturePlaceholder);
                  this.precisionCounterFeaturePlaceholder++;
               }

               /** Aggiungo la feature al layer precision */
               ((this.precisionLayer as Layer).getSource() as VectorSource).addFeature(f);
            }
            break;
         case 'Circle':
            // const circleCoords = mainFeature.getGeometry().getLinearRing().getCoordinates();
            // const circleRadius = mainFeature.getGeometry().getRadius();
            // const circleCenter = mainFeature.getGeometry().getCenter();

            // console.log("+++++++++++++ circleRadius --> ",circleRadius)
            // console.log("+++++++++++++ circleCenter --> ",circleCenter)
            break;
      }
   }

   /**
    * Annulla le modifiche alla figura
    */
   cancelDraw() {
      console.log("************ TOOL DISEGNO cancelDraw")
      /** Termino disegno */
      this.endDraw();

      /** Pulisco layer precisione */
      this.clearPrecisionLayer();

      /** Aggiungo la feature come era prima delle modifiche (se presente) */
      this.resetFeature();

      /** Resetto i pannelli */
      this.resetPanels();

      /** Riabilito i tools nella toolbar */
      this.disegnoAttivoDisabilitaTool = false;

      /** Deselezione della feature selezionata */
      this.deselectFeature.emit();

      /**
       * Se è stato passato un oggetto da modificare oppure se l'opzione closeAll ha valore 'true'
       * allora devo chiudere il tool di disegno
       */

      if(!!this.currentObj || this.closeAll)
          // Chiusura tool di disegno
          this.chiudiToolDisegnoEmitter.emit({type: this.currentObjType});
   }


   /**
    * Reimposta la feature prima delle modifiche
    */
   resetFeature() {
      /** Se esiste imposto la feature precedentemente salvata */
      if (!!this.originalFeature) {
         // TODO GB perchè ho dovuto aggiungere questa riga????
         this.originalFeature.setStyle(this.servizioMappa.buildFeatureStyle(this.layerDefaultStyleId));

         ((this.layerDefault as Layer).getSource() as VectorSource).addFeature(this.originalFeature);
         this.originalFeature = undefined;
         this.idFeatureSelezionata = undefined;
         this.proprietaFeatureSelezionata = {};
      }
   }

   /**
    * Simulazione salvataggio in db
    * Recupero le geometrie
    */
   saveAll() {
      /* this.servizioMappa.salvataggioFeaturesDisegno$.next(this.layerDefault.getSource().getFeatures());
       // TODO devo eliminare le feature una volta salvate?
       this.clearHistory.emit(true);*/
   }


   /**
   * Quando viene invocata emette un evento al padre per effettuare l'annullamento di tutte le modifiche
   * GB Equivale alla 'Annulla'?
   */
   invokeCancel() {
      // TODO
      /* this.cancelModify.emit(true); */
   }


   /**
    * Conferma disegno (lo elimina da precision layer e lo inserisce nel layer default)
    */
   confirmDraw() {

      console.log("FEATURES PRECISION LAYER", ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures());
      /** Geometria da salvare nel db */
      let geomToSave;
      /** Nome / descrizione della geometria, inserito nella form */
      let nomeGeomToSave;

      switch (this.tipoGeometriaSelezionata) {
         case 'Point':
            /** Recupero il punto e lo rimuovo(insieme ai tooltip) dal precision layer */
            const featurePoint = this.insertedPrecisionFeatures()[0];
            /** Creo lo stile per il layer di default, lo assegno alla feature che viene poi aggiunta al layer */
            featurePoint.setStyle(this.servizioMappa.buildFeatureStyle(this.layerDefaultStyleId));

            /** Inserimento del nome della feature tra le proprietà */
            if (this.pannelloCoordinateForm.controls.nome.value != null) {
               const p = featurePoint.getProperties();
               nomeGeomToSave = p.nome = this.pannelloCoordinateForm.controls.nome.value;
               featurePoint.setProperties(p);
            }

            ((this.layerDefault as Layer).getSource() as VectorSource).addFeature(featurePoint);
			geomToSave = featurePoint;
            break;
         case 'LineString':
            /** Recupero le coordinate, creo la linea ed elimino quella dal precision layer(insieme ai tooltip) */
            const confirmedPointsLineString = this.insertedPrecisionFeatures()
               .filter((f: Feature) => f.get('featureTag') !== this.UNCONFIRMED_PRECISION_FEATURE_TAG);
            const featureLineString = this.createLineStringFeature(confirmedPointsLineString);
            /** Creo lo stile per il layer di default, lo assegno alla feature che viene poi aggiunta al layer */
            featureLineString.setStyle(this.servizioMappa.buildFeatureStyle(this.layerDefaultStyleId));

            /** Inserimento del nome della feature tra le proprietà */
            if (this.pannelloCoordinateForm.controls.nome.value != null) {
               const p = featureLineString.getProperties();
               nomeGeomToSave = p.nome = this.pannelloCoordinateForm.controls.nome.value;
               featureLineString.setProperties(p);
            }

            ((this.layerDefault as Layer).getSource() as VectorSource).addFeature(featureLineString);
			geomToSave = featureLineString;
            break;
         case 'Polygon':
            /** Recupero le coordinate, creo la linea ed elimino quella dal precision layer(insieme ai tooltip) */
            const confirmedPointsPolygon = this.insertedPrecisionFeatures()
               .filter((f: Feature) => f.get('featureTag') !== this.UNCONFIRMED_PRECISION_FEATURE_TAG);
            const featurePolygon = this.createPolygonFeature(confirmedPointsPolygon);
            /** Creo lo stile per il layer di default, lo assegno alla feature che viene poi aggiunta al layer */
            featurePolygon.setStyle(this.servizioMappa.buildFeatureStyle(this.layerDefaultStyleId));

            /** Inserimento del nome della feature tra le proprietà */
            if (this.pannelloCoordinateForm.controls.nome.value != null) {
               const p = featurePolygon.getProperties();
               nomeGeomToSave = p.nome = this.pannelloCoordinateForm.controls.nome.value;
               featurePolygon.setProperties(p);
            }

            ((this.layerDefault as Layer).getSource() as VectorSource).addFeature(featurePolygon);
			geomToSave = featurePolygon;
            break;
         case 'Circle':

            /** Recupero la feature */
            const circleFeature = this.insertedPrecisionFeatures()[0];
            console.log("GB CIRCLE FEATURE PROPERTIES confirmDraw --> ", circleFeature.getProperties())
            /** Creo lo stile per il layer di default, lo assegno alla feature che viene poi aggiunta al layer */
            circleFeature.setStyle(this.servizioMappa.buildFeatureStyle(this.layerDefaultStyleId));

            // const p = circleFeature.getProperties();

            // p['radiusMeters'] = this.callConvertiRaggioInMetri(circleFeature.getGeometry().getRadius());

            // console.log("GB salvo i metri tra le prop --> ", p);
            // circleFeature.setProperties(p);

            /** Inserimento del nome della feature tra le proprietà */
            if (this.pannelloCoordinateForm.controls.nome.value != null) {
               const p = circleFeature.getProperties();
               nomeGeomToSave = p.nome = this.pannelloCoordinateForm.controls.nome.value;
               circleFeature.setProperties(p);
            }

            ((this.layerDefault as Layer).getSource() as VectorSource).addFeature(circleFeature);
			geomToSave = circleFeature;
            break;
      }

      /** Termino il disegno */
      this.endDraw();

      /** Pulisco il layer di precisione */
      this.clearPrecisionLayer();
      /** Resetto il pannello e la toolbar */
      this.resetPanels();
      /** Riabilito i tools nella toolbar */
      this.disegnoAttivoDisabilitaTool = false;

      /** Pulisco le variabili da eventuali feature selezionate */
      this.idFeatureSelezionata = undefined;
      this.proprietaFeatureSelezionata = {};

      /** Deselezione della feature selezionata */
      this.deselectFeature.emit();

      console.log("tool curent obj type: ", this.currentObjType);
      console.log("tool curent obj: ", this.currentObj);
      // if (this.currentObjType == 'occupazione') {

      //    geomToSave.setId(Math.floor(Math.random() * 10000));

      //    this.currentObj = {
      //       geometria_occupazione : geomToSave,
      //       descrizione:  nomeGeomToSave
      //    };
      // } else if (this.currentObjType == 'ingiunzione') {

      //    nomeGeomToSave.split('/');

      //    if (!!this.currentData && !!this.currentData.atto_id) {
      //       geomToSave.setId(this.currentData.atto_id);
      //    } else {
      //       geomToSave.setId(Math.floor(Math.random() * 10000));
      //    }

      //    if (!!this.currentData) {
      //       this.currentObj = {
      //          atto_numero:  this.currentData.numero,
      //          atto_anno: this.currentData.anno
      //       };
      //    } else {
      //       this.currentObj = {
      //          atto_numero: undefined,
      //          atto_anno: undefined
      //       };
      //    }

      //    if (this.tipoGeometriaSelezionata == 'Point') {
      //       this.currentObj.geometria_simbolo = geomToSave;
      //    } else if (this.tipoGeometriaSelezionata == 'Polygon') {
      //       this.currentObj.geometria_ingombro = geomToSave;
      //    }
      // } else if (this.currentObjType == 'vincolo'){

      //    geomToSave.setId(Math.floor(Math.random() * 10000));

      //    this.currentObj = {
      //       //geom : geomToSave,
      //       descrizione: nomeGeomToSave
      //    };
      // } else if (this.currentObjType == 'ordinanza'){

      //    // TODO passare id poligono/cerchio
      //    geomToSave.setId(Math.floor(Math.random() * 10000));

      //    this.currentObj = {
      //       geom : geomToSave
      //    };
      // }
      // else if(this.currentObjType == 'ricerca_geometrica'){
      //    this.currentObj = {
      //       nome: nomeGeomToSave
      //    };
      // }
      this.currentObj = {
         name: nomeGeomToSave
      }
      /** Se non è stato passato alcun oggetto al tool di disegno allora non bisogna effettuare il salvataggio nel db  */
      if (!!!this.currentObj && !!!this.currentObjType) {
         return;
      }

      var geoJsonGeom;

      /**
       * Il cerchio in GeoJSON non esiste, quindi non possiamo tradurlo in geometria con librerie come Openlayers.
       * Possiamo utilizzare lo standard del formato GeoJSON mettendoci dentro ciò che ci serve.
       */
      if(geomToSave.getGeometry().getType() == 'Circle'){
         geoJsonGeom = {
            type: "Circle",
            coordinates: geomToSave.getGeometry().getCenter(),
            radius: geomToSave.getGeometry().getRadius()
         }
      }
      else{
         /** Creo la geometria confermata nel db */
         geoJsonGeom = new GeoJSON().writeGeometry(geomToSave.getGeometry(), {
            dataProjection: MapService.EPSG_3857_KEY,
            featureProjection: MapService.EPSG_3857_KEY
         });
         geoJsonGeom = JSON.parse(geoJsonGeom);
      }

      geoJsonGeom.crs = {
         type: 'name',
         properties: { name: MapService.EPSG_3857_KEY }
      }

      this.elementoConfermato = {
         geom: geoJsonGeom,
         type: this.currentObjType,
         currentObj: this.currentObj
      };


      console.log('+++++++ TOOL DI DISEGNO +++++++', this.elementoConfermato);
      /** Emetto l'elemento confermato */
      this.toolDisegnoElementoConfermatoEmitter.emit(this.elementoConfermato);

      /** Chiusura tool di disegno */
      this.chiudiToolDisegnoEmitter.emit();

   }


   /** SID3 TOOL PARTICELLA */
   /** Gestione dei riferimenti catastali della particella */

   /*****************************************************/
   /** FUNZIONI PER LA GESTIONE DEI TOOLS NELLA TOOLBAR */
   /*****************************************************/

   /**
    * RADIO BUTTONS DISEGNO
    * Gestisce i pulsanti della toolbar per il disegno
    */
   gestisciBottoniDisegno() {
      this.tipoGeometriaSelezionata = this.toolbarForm.controls.disegno.value;

      /** SID3 TOOL PARTICELLA */
      if (this.tipoGeometriaSelezionata == 'Particella') {
         // toolParticella();

         /** Se abilitata disabilito la modalità disegno */
         this.endDraw();

         /** Disabilito qualunque interazione (Selezione) */
         this.changedEditMode.emit('none');

         /** Disabilito il Pannello Coordinate se abilitato */
         this.pannelloCoordinateAbilitato = false;

         /** I tools sono ancora abilitati, si disabilitano quando inizio a disegnare */
         this.disegnoAttivoDisabilitaTool = false;

         /** Attivo il pannello di gestione della particella */
         this.btnToolParticella.emit(true);
      } else {
         /** Point / LineString / Polygon / Cerchio */
         // if(this.tipoGeometriaSelezionata !== "Particella"){
            /** Disattivo il pannello di gestione della particella */
            this.btnToolParticella.emit(false);

            /** Disabilito qualunque interazione (Selezione) */
            this.changedEditMode.emit('none');

            /** Abilito il Pannello Coordinate */
            this.pannelloCoordinateAbilitato = true;

            /** Disabilito i tools */
            this.disegnoAttivoDisabilitaTool = true;

            /** Inserimento */
            if (!this.isEdit) {

               this.precisionCounterFeaturePlaceholder = 0;
               if (this.tipoGeometriaSelezionata == 'Circle') {
                  this.startDrawCircle();
               } else {
                  this.startDraw();
               }
            } else {
               /**
                * Se sono in modifica e la geometria selezionata è un punto, allora non avvio il disegno
                * poiché si può inserire solo un punto alla volta
                */

               if (this.tipoGeometriaSelezionata !== 'Point' && this.tipoGeometriaSelezionata !== 'Circle' ) {

                  this.startDraw();
               }
            }
       }
         /** Nel caso della particella la modalità di disegno si attiva quando viene premuto "Inserisci punto" */
         // else {
         //    /** Disabilito qualunque interazione (Selezione) */
         //    this.changedEditMode.emit('none');

         //    /** Disbilito il Pannello Coordinate */
         //    this.pannelloCoordinateAbilitato = false;

         //    /** Disabilito i tools */
         //    this.disegnoAttivoDisabilitaTool = true;

         // }
      // }
      /** TOOL DI DISEGNO */
   }

   riabilitaDopoAgganciaPunto(ph, fap) {

      /** Disabilito qualunque interazione (Selezione) */
      this.changedEditMode.emit('none');

      this.startDraw();

      /** Inserimento della feature restituita nel pannello coordinate e relativo layer di disegno */
      this.aggiungiPunto(ph, fap);
   }

   /**
    * Invocata al cambio del sistema di coordinate
    */
   gestisciCambioEpsg() {
      if (this.pannelloCoordinateForm.controls.epsg.value === 4326) {
            this.ttCoordX = 'Longitudine';
            this.ttCoordY = 'Latitudine';
      } else {
         this.ttCoordX = 'X';
         this.ttCoordY = 'Y';
      }

      this.onChangeSRID();
   }

   /**
    * Metodo richiamato quando viene modificato il sistema di riferimento
    */
   onChangeSRID() {
      this.selectedSRID = this.pannelloCoordinateForm.controls.epsg.value;
      this.transformAllPrecisionFeaturesForVisualization(this.selectedSRID);
   }


   /**
    * Trasforma tutte le coordinate del layer di precisione nel sistema di riferimento richiesto
    * @param   epsgTo sistema di riferimento di destinazione
    */
   transformAllPrecisionFeaturesForVisualization(epsgTo: number) {
      let coords = [];
      this.insertedPrecisionFeatures().forEach((p) => {
         if (this.tipoGeometriaSelezionata == 'Circle') {
            coords.push(p.getGeometry().getCenter());
         } else {
            coords.push(p.getGeometry().getCoordinates());
         }
      });
      this.servizioMappa.transformCoords(coords, this.EPSG_MAP, epsgTo)
         .subscribe((response) => {
            if (response.error !== 0) {
               console.error('ERRORE(APP)_transformCoords', response.message, response.traceback);
            }
            if (response.status !== 0) {
               console.error('ERRORE(PAR)_transformCoords', response.message);
            }
            if (response.error === 0 && response.status === 0) {
               coords = (response.data as GeoUtilsData).coords;
               const features = this.insertedPrecisionFeatures();
               for (let i = 0; i < features.length; i++) {
                  features[i].set('transformedCoords', coords[i]);
               }
            }
         });
   }

   /**
    * PANNELLO COORDINATE
    * Gestisce gli strumenti della toolbar diversi da quelli di disegno
    */
   gestisciPannelloCoordinate() {
      this.pannelloCoordinateAbilitato = !this.pannelloCoordinateAbilitato;
   }


   /*****************************/
   /** FUNZIONALITA' DI DISEGNO */
   /*****************************/

   /** Punto / Linea / Poligono */
   startDraw() {
      this.servizioMappa.interruttoreInterazioneDisegno$.next({
         flag: true,
         geometry: GeometryType.POINT,
         style: this.STYLE_ID_PRECISION_LAYER,
         layer: this.precisionLayer
      });

      /** Disabilita tutte le interazioni con la toolbar */
      this.disegnoAttivoDisabilitaTool = true;
   }

   /** Cerchio */
   startDrawCircle() {
      this.servizioMappa.interruttoreInterazioneDisegno$.next({
         flag: true,
         geometry: GeometryType.CIRCLE,
         style: this.STYLE_ID_PRECISION_LAYER,
         layer: this.precisionLayer
      });

      /** Disabilita tutte le interazioni con la toolbar */
      this.disegnoAttivoDisabilitaTool = true;
   }

   /**
    * Termina figura
    */
   endDraw() {
      this.servizioMappa.interruttoreInterazioneDisegno$.next({
         flag: false,
         geometry: this.tipoGeometriaSelezionata as GeometryType
      });
      this.servizioMappa.interruttoreInterazioneSelezione$.next(true);
      // this.disegnoAttivo = false;
   }


   /************************/
   /** ALTRE FUNZIONALITA' */
   /************************/

   /**
    * Generazione Random di un ID per la nuova feature inserita
    */
   generateFeatureId() {
      return Math.random().toString(36).substr(2, 9);
   }


   /**
    * Funzione per confermare il punto inserito
    * @param   feature oggetto geometria punto
    */
   confirmPoint(feature: Feature) {

      /** Confronto tipo e valore di feature.get('featureTag') e this.UNCONFIRMED_PRECISION_FEATURE_TAG */
      const ttCreation: boolean = feature.get('featureTag') === this.UNCONFIRMED_PRECISION_FEATURE_TAG;

      /** Se la feature è presente tra quelle non confermate */
      if (ttCreation) {
         /** Rimuove il valore UNCONFIRMED_PRECISION_FEATURE_TAG */
         feature.unset('featureTag');

         /** Rimuove l'eventuale tooltip creato in precedenza */
         this.removeTooltip.emit(feature.get('tooltip'));
         feature.unset('tooltip');
      }


      switch (this.tipoGeometriaSelezionata) {
         case 'Point':
            this.endDraw();
            break;

         case 'LineString':
            const linePoints = this.insertedPrecisionFeatures();
            if (ttCreation) {
               const ttLinePoint = this.createPointTooltip(linePoints.length, (feature.getGeometry() as SimpleGeometry).getCoordinates());
               feature.set('tooltip', ttLinePoint);
            }
            if (linePoints.length >= 2) {
               this.removeMultiPointFeature(this.tipoGeometriaSelezionata);
               this.drawLineString(linePoints);
            }
            break;
         case 'Polygon':
            const polygonPoints = this.insertedPrecisionFeatures();
            if (ttCreation) {
               const ttPolygonPoint = this.createPointTooltip(polygonPoints.length, (feature.getGeometry() as SimpleGeometry).getCoordinates());
               feature.set('tooltip', ttPolygonPoint);
            }
            if (polygonPoints.length >= 3) {
               this.removeMultiPointFeature(this.tipoGeometriaSelezionata);
               this.drawPolygon(polygonPoints);
            }
            break;
         case 'Circle':
            const circleFeature = feature;

            /** Salvo il raggio in metri tra le proprietà del cerchio */
            let meters;

            if (!!circleFeature.getProperties().radiusMeters) {
               meters = circleFeature.getProperties().radiusMeters;
            } else {
               meters = this.callConvertiRaggioInMetri((circleFeature.getGeometry() as Circle).getRadius());

               const p = circleFeature.getProperties();
               p.radiusMeters = meters;

               circleFeature.setProperties(p);
            }


            this.pannelloCoordinateForm.controls.raggio.setValue(meters);

            this.endDraw();

            break;
      }
   }


   /**
    * Crea una tooltip nelle coordinate passate con valore del parametro indice.
    * @param   indice    valore tooltip
    * @param   posizione posizion tooltip
    * @returns           l'oggetto tooltip
    */
   createPointTooltip(indice: number, posizione: Coordinate): Overlay {
      const tooltipElement = document.createElement('div');
      tooltipElement.innerHTML = JSON.stringify(indice);
      tooltipElement.style.cssText = 'background-color:#FFFFFFBB; border:1px solid gray; border-radius:10px; font-weight:bold; padding:0px 5px; margin-bottom:2px;';
      const tooltip = new Overlay({
         element: tooltipElement,
         offset: [0, -7],
         positioning: OverlayPositioning.BOTTOM_CENTER,
         position: posizione
      });
      this.addTooltip.emit(tooltip);
      return tooltip;
   }


   /**
    * Trasforma una singola coordinata nel sistema di riferimento richiesto
    * @param   epsgTo  sistema di riferimento di destinazione
    * @param   feature feature di cui trasformare le coordinate
    */
   transformSinglePrecisionFeatureForVisualization(epsgTo: number, feature: Feature) {
      if (this.tipoGeometriaSelezionata == 'Circle') {
         this.servizioMappa.transformCoords([(feature.getGeometry() as Circle).getCenter()], this.EPSG_MAP, epsgTo)
            .subscribe((response) => {
               if (response.error !== 0) {
                  console.error('ERRORE(APP)_transformCoords', response.message, response.traceback);
               }
               if (response.status !== 0) {
                  console.error('ERRORE(PAR)_transformCoords', response.message);
               }
               if (response.error === 0 && response.status === 0) {
                  const coords = (response.data as GeoUtilsData).coords[0];
                  feature.set('transformedCoords', coords);
               }
            });
      } else {
         this.servizioMappa.transformCoords([(feature.getGeometry() as SimpleGeometry).getCoordinates()], this.EPSG_MAP, epsgTo)
            .subscribe((response) => {
               if (response.error !== 0) {
                  console.error('ERRORE(APP)_transformCoords', response.message, response.traceback);
               }
               if (response.status !== 0) {
                  console.error('ERRORE(PAR)_transformCoords', response.message);
               }
               if (response.error === 0 && response.status === 0) {
                  const coords = (response.data as GeoUtilsData).coords[0];
                  feature.set('transformedCoords', coords);
               }
            });
      }
   }

   /**
    * Ritorna un array con i punti inseriti nel layer di precisione, ordinati in base alla loro posizione
    * @returns array di feature di tipo Point
    */
   insertedPrecisionFeatures() {
      let res;

      if (this.tipoGeometriaSelezionata === 'Circle') {
         // Commentato perchè non permette una corretto modifica manuale dei punti
         // this.scrollToBottom();

         res = ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures()
            .filter((el: Feature) => { return el.getGeometry().getType() === 'Circle'; })
            .sort((a: Feature, b: Feature) => { if (a.get('precisionCounterPlaceholder') < b.get('precisionCounterPlaceholder')) { return -1; } else { return 1; } });

         return res;
      } else {
         // this.scrollToBottom();

         res = ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures()
            .filter((el: Feature) => { return el.getGeometry().getType() === 'Point'; })
            .sort((a: Feature, b: Feature) => { if (a.get('precisionCounterPlaceholder') < b.get('precisionCounterPlaceholder')) { return -1; } else { return 1; } });

         return res;
      }
   }

   /**
    * scrollToBottom
    */
   scrollToBottom() {
      if (!!this.coordsListContainer) {
         this.coordsListContainer.nativeElement.scrollTop = this.coordsListContainer.nativeElement.scrollHeight;
      }
   }

   /**
    * Rimuove la feature composta (LineString, Polygon) della tipologia indicata
    * @param   type tipo della geometria da rimuovere
    */
   removeMultiPointFeature(type: string) {
      const res = ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().filter((f: Feature) => { return f.getGeometry().getType() === type; });
      if (res.length > 0) {
         const feature = res[0];
         this.proprietaFeatureSelezionata = this.concatProperties(this.proprietaFeatureSelezionata, feature.getProperties());
         ((this.precisionLayer as Layer).getSource() as VectorSource).removeFeature(feature);
      }
   }

   /**
    * Rimuove la feature composta (LineString, Polygon) della tipologia indicata
    * @param   type tipo della geometria da rimuovere
    */
   removeCircleFeature(type: string) {
      console.log('removeCircleFeature  --> ');
      const res = ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().filter((f: Feature) => { return f.getGeometry().getType() === type; });
      if (res.length > 0) {
         const feature = res[0];
         this.proprietaFeatureSelezionata = this.concatProperties(this.proprietaFeatureSelezionata, feature.getProperties());
         ((this.precisionLayer as Layer).getSource() as VectorSource).removeFeature(feature);

      }
   }

   /**
    * Prende una serie di punti, crea la linea e la aggiunge al precisionLayer
    * @param  points coordinate per creare la linea.
    */
   drawLineString(points: Feature[]) {
      const feature = this.createLineStringFeature(points);
      feature.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER));
      ((this.precisionLayer as Layer).getSource() as VectorSource).addFeature(feature);
   }

   /**
    * Prende una serie di punti, crea il poligono e lo aggiunge al precisionLayer
    * @param   points coordinate per creare il poligono.
    */
   drawPolygon(points: Feature[]) {
      const feature = this.createPolygonFeature(points);
      feature.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER));
      ((this.precisionLayer as Layer).getSource() as VectorSource).addFeature(feature);

   }

   /**
   * Prende una serie di punti, crea il poligono e lo aggiunge al precisionLayer
   * @param   points coordinate per creare il poligono.
   */
  drawCircle(center: Coordinate, radius: number) {
    const feature = this.createCircleFeature(center, radius);
    feature.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER));
    ((this.precisionLayer as Layer).getSource() as VectorSource).addFeature(feature);
 }

   /**
    * Richiamata quando viene aggiornato il raggio del cerchio da pannello coordinate
    */
   updateCircle() {

      const meters = this.pannelloCoordinateForm.controls.raggio.value;
      const radius = this.callConvertiMetriInRaggioOl(meters);
      const feature = this.insertedPrecisionFeatures().length > 0 ? this.insertedPrecisionFeatures()[0] : null;

      const p = feature.getProperties();
      p.radiusMeters = meters;

      feature.setProperties(p);

      if (!isNaN(radius) && !!feature) {
         feature.getGeometry().setRadius(radius);
      } else {
         console.log('TODO: Raggio o centro mancanti');
      }
   }


   /**
    * Aggiorna le coordinate (anche sulla mappa) dopo che sono state effettuate modifiche al pannello
    * @param   event   evento di modifica
    * @param   feature feature da modificare
    */
   updateCoords(event, feature: Feature) {
      /** Nel caso del cerchio sono le coordinate del centro che devono essere trasformate */
      let res;

      res = ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().filter((el: Feature) => { return el.getId() === feature.getId(); });

      if (res.length > 0) {
         const coords = feature.get('transformedCoords');
         if (!!coords) {
            /** Se è inserito un valore non numerico, rimetto il valore originale */
            if (isNaN(parseFloat(event.target.value))) {
               switch (event.target.name) {
                  case 'coord-x':
                     event.target.value = coords[0];
                     break;
                  case 'coord-y':
                     event.target.value = coords[1];
                     break;
               }
               return;
            }

            switch (event.target.name) {
               case 'coord-x':
                  coords[0] = parseFloat(event.target.value);
                  break;
               case 'coord-y':
                  coords[1] = parseFloat(event.target.value);
                  break;
            }

            /** Trasformo le coordinate passate per poter aggiornare la posizione sulla mappa */
            this.servizioMappa.transformCoords([coords], this.selectedSRID, this.EPSG_MAP).subscribe((response) => {
               if (response.error !== 0) {
                  console.error('ERRORE(APP)_transformCoords', response.message, response.traceback);
               }
               if (response.status !== 0) {
                  console.error('ERRORE(PAR)_transformCoords', response.message);
               }
               if (response.error === 0 && response.status === 0) {
                  const transformedCoords = (<GeoUtilsData>(response.data)).coords[0];
                  (feature.getGeometry() as SimpleGeometry).setCoordinates(transformedCoords);
                  feature.set('transformedCoords', coords);

                  /** Se esiste la tooltip */
                  if (!!feature.get('tooltip')) {
                     feature.get('tooltip').setPosition(transformedCoords);
                  }

                  switch (this.tipoGeometriaSelezionata) {
                     case 'Point':
                        /** Punto già spostato, non fare niente */
                        break;
                     // case 'Particella':
                     //    /** Punto già spostato, non fare niente */
                     //    break;
                     case 'LineString':
                        const linePoints = this.insertedPrecisionFeatures(); // this.precisionLayer.getSource().getFeatures().filter((ft: Feature) => {return ft.getGeometry().getType() === 'Point'; });
                        if (linePoints.length >= 2) {
                           this.removeMultiPointFeature(this.tipoGeometriaSelezionata);
                           this.drawLineString(linePoints);
                        }
                        break;
                     case 'Polygon':
                        const polygonPoints = this.insertedPrecisionFeatures(); // this.precisionLayer.getSource().getFeatures().filter((ft: Feature) => {return ft.getGeometry().getType() === 'Point'; });
                        if (polygonPoints.length >= 3) {
                           this.removeMultiPointFeature(this.tipoGeometriaSelezionata);
                           this.drawPolygon(polygonPoints);
                        }
                        break;
                     case 'Circle':
                        const circleCenter = transformedCoords;
                        const circleRadiusMeters = this.pannelloCoordinateForm.controls.raggio.value;
                        const circleRadius = this.callConvertiMetriInRaggioOl(circleRadiusMeters);
                        // Rimozione il vecchio cerchio
                        this.removeCircleFeature(this.tipoGeometriaSelezionata);

                        // if (circleRadius != '')
                        if (circleRadius != 0) {
                           this.drawCircle(circleCenter, circleRadius);
                        }
                        break;
                  }
               }
            });
         } else {
            event.target.value = '-';
         }
      }
   }


   /**
    * concatProperties
    * @param   current   ???
    * @param   previous ???
    */
   concatProperties(current, previous) {
      if (!!previous) {
         if (!!current) {
            Object.entries(previous).forEach((el) => {
               const k = el[0];
               const v = el[1];
               if (k !== 'geometry') {
                  current[k] = v;
               }
            });
            return current;
         }
      }
      return {};
   }

   /**
    * Creazione di una feature di tipo LineString.
    * @param   points coordinate per creare la linea
    */
   createLineStringFeature(points: Feature[]): Feature {
      const lineStringCoords = [];
      points.forEach((p: Feature) => {
         lineStringCoords.push((p.getGeometry() as SimpleGeometry).getCoordinates());
      });
      const feature = new Feature(new LineString(lineStringCoords));
      return feature;
   }

   /**
    * Creazione di una feature di tipo Polygon.
    * @param   points coordinate per creare il poligono
    */
   createPolygonFeature(points: Feature[]): Feature {
      const polygonCoords = [];
      points.forEach((p: Feature) => {
         polygonCoords.push((p.getGeometry() as SimpleGeometry).getCoordinates());
      });
      polygonCoords.push((points[0].getGeometry() as SimpleGeometry).getCoordinates());
      const feature = new Feature({
         geometry: new Polygon([polygonCoords])
      });
      return feature;
   }


   /**
    * Controlla se esistono feature non confermate
    * @returns return true se ci sono feature non confermate
    */
   existsUnconfirmedFeature() {
      let exists = false;
      ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().forEach((f: Feature) => {
         if (f.get('featureTag') === this.UNCONFIRMED_PRECISION_FEATURE_TAG) {
            exists = true;
         }
      });
      return exists;
   }

   /**
    * Controlla se la feature è confermata
    * @param   feature oggetto da controllare
    * @returns         true se la feature è non confermata
    */
   isUnconfirmedFeature(feature: Feature) {
      return feature.get('featureTag') === this.UNCONFIRMED_PRECISION_FEATURE_TAG;
   }

   /**
    * Cancella punto dal precision layer
    * @param   feature oggetto da rimuovere
    */
   deletePoint(feature: Feature) {

      this.removeTooltip.emit(feature.get('tooltip'));
      feature.unset('tooltip');
      ((this.precisionLayer as Layer).getSource() as VectorSource).removeFeature(feature);

      if (this.tipoGeometriaSelezionata != 'Point') {
         this.refreshPointsTooltip();
      }

      switch (this.tipoGeometriaSelezionata) {
         case 'Point':
            /** Riabilito lo strumento di disegno  */
            this.startDraw();
            break;
         case 'LineString':
            const linePoints = this.insertedPrecisionFeatures();
            if (linePoints.length >= 2) {
               this.removeMultiPointFeature(this.tipoGeometriaSelezionata);
               /** Disegno la linea dopo aver rimosso il punto */
               this.drawLineString(linePoints);
            } else {
               this.removeMultiPointFeature(this.tipoGeometriaSelezionata);
            }
            break;
         case 'Polygon':
            const polygonPoints = this.insertedPrecisionFeatures();
            if (polygonPoints.length >= 3) {
               this.removeMultiPointFeature(this.tipoGeometriaSelezionata);
               this.drawPolygon(polygonPoints);
            } else {
               this.removeMultiPointFeature(this.tipoGeometriaSelezionata);
            }
            break;

         case 'Circle':
            /** Pulisco il campo relativo al raggio perché devo ridisegnare il cerchio */
            this.pannelloCoordinateForm.controls.raggio.setValue('');

            /** Riabilito lo strumento di disegno  */
            this.startDrawCircle();
            break;
      }
   }

   /**
    * Prende ogni feature rimuove il suo tooltip e lo ricrea.
    */
   refreshPointsTooltip() {
      const ff = this.insertedPrecisionFeatures();
      for (let i = 0; i < ff.length; i++) {
         const f = ff[i];
         this.removeTooltip.emit(f.get('tooltip'));
         f.unset('tooltip');
         const tt = this.createPointTooltip(i + 1, f.getGeometry().getCoordinates());
         f.set('tooltip', tt);
      }
   }

   /**
    * Premuto bottone 'Conferma'
    */
   gestioneClickConfermaFigura() {
      if (typeof (this.insertedPrecisionFeatures()[0]) !== 'undefined') {
         this.ebwModalService.openConfirmModal({ title: 'Attenzione', content: 'Confermare la figura?' }).result.then((result) => {
            if (result === EBWModalResult.Confirm) {
               this.confirmDraw();
               this.isEdit = false;
            }
         }, (reason) => { });
      } else {
         this.ebwModalService.openInfoModal({ title: 'Conferma', content: 'Non ci sono figure da confermare.' });
      }
   }

   /**
    * Premuto bottone 'Annulla' (nel caso delle geometrie) o 'Chiudi' (nel caso della particella)
    */
   gestioneClickAnnullaFigura() {

      const content = 'Annullare la figura?';

      this.ebwModalService.openConfirmModal({ title: 'Attenzione', content: content }).result.then((result) => {
         if (result === EBWModalResult.Confirm) {
            this.cancelDraw();
            this.isEdit = false;
         }
      }, (reason) => { });
   }

   /**
    * Elimina la geometria selezionata
    */
   gestioneClickEliminaFigura() {
      this.ebwModalService.openConfirmModal({ title: 'Attenzione', content: 'Eliminare la figura?' }).result.then((result) => {
         if (result === EBWModalResult.Confirm) {
            this.deleteFeature();
            this.isEdit = false;
         }
      }, (reason) => { });
   }


   /**
    * Rimuove la feature dal layer default
    */
   deleteFeature() {

      const feature = this.selectedFeature;
      this.resetPanels();

      /** Riabilito i tools nella toolbar */
      this.disegnoAttivoDisabilitaTool = false;

      /** Termino disegno */
      this.endDraw();

      /** Pulisco layer precisione */
      this.clearPrecisionLayer();

      /** Pulisco le proprietà della feature originale memorizzate durante la selezione */
      this.originalFeature = undefined;
      this.idFeatureSelezionata = undefined;
      this.proprietaFeatureSelezionata = {};
      /** Deselezione della feature selezionata */
      this.deselectFeature.emit();

      /** Riabilito i tools nella toolbar */
      this.disegnoAttivoDisabilitaTool = false;

      /** Reimposto lo stile di default al posto di quello del layer di precisione */
      feature.setStyle(this.servizioMappa.buildFeatureStyle(this.layerDefaultStyleId));
      /** Per aggiornamento storia modifiche */
      this.deletedFeatureHistory.emit({ original: feature });
   }


   /**
    * Pulisce il layer di precisione
    */
   clearPrecisionLayer() {
      ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().forEach((f: Feature) => {
         this.removeTooltip.emit(f.get('tooltip'));
         f.unset('tooltip');
      });


      ((this.precisionLayer as Layer).getSource() as VectorSource).clear();
   }

   /**
    * Reinizializza i pannelli e fa apparire la schermata principale
    */
   resetPanels() {

      /**
       * - Ripristino il pannello coordinate allo stato iniziale
       * - Ripristino la toolbar allo stato iniziale
       * - Abilito la selezione per consentire la modifica delle geometrie inserite
       */

      /** Reimposta i valori iniziali della toolbar e del pannello Coordinate */
      this.initForms();

      /** Abilito la selezione per le feature da poter trasformare */
      this.changedEditMode.emit('select');

   }

   /**
    * SID3 Invoca il tool aggancia punto
    */
   toolAgganciaPunto(precisionCounterPlaceholder= undefined) {
      // NOTE da verificare
      if (typeof(precisionCounterPlaceholder) == 'undefined') {
         precisionCounterPlaceholder = 0;
      }

      /** Disabilito il tool OL per disegnare */
      this.endDraw();
      this.changedEditMode.emit('none');

      this.btnToolAgganciaPunto.emit({toolAgganciaPuntoAttivo: true,
                                       precisionCounterPlaceholder});

   }


   /**
    * Aggiungi punto
    * Per aggiungere un punto dal pannello delle coordinate
    * @param index posizione della feature dopo la quale deve essere aggiunto il nuovo punto
    * @param featureAP Feature restituita dal tool Aggancia Punto
    */
   aggiungiPunto(index, featureAP?) {
      console.log('aggiungiPunto index --> ', index, 'featureAP' , featureAP);
      /** Placeholder del nuovo punto da inserire */
      const newIndex = index + 1;
      /** Variabile di appoggio che contiene le proprietà della feature in esame */
      // let prop;

      let insertPointCoords;

      /** Se è stata restituita una feature dal tool aggancia punto */
      if (!!featureAP) {
         insertPointCoords = featureAP.getGeometry().getCoordinates();
      } else {
         insertPointCoords = this.centroMappa;
      }


      /**
       * Gestione del placeholder per la nuova feature e aggiornamento dei placeholder delle features già inserite
       * il placeholder corrisponde all'indice nell'array
       */
      ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().forEach((f: Feature) => {
         /** Aggiorno tutti i placeholder con valore maggiore o uguale al placeholder della nuova feature */
         if (typeof (f.get('precisionCounterPlaceholder')) !== 'undefined' && f.get('precisionCounterPlaceholder') > index) {
            const p = f.getProperties();
            p.precisionCounterPlaceholder = p.precisionCounterPlaceholder + 1;
            f.setProperties(p);
            // PLACEHOLDER MODIFICATO
         }
      });



      if (this.tipoGeometriaSelezionata == 'Circle') {
         const meters = this.pannelloCoordinateForm.controls.raggio.value;
         const radius = this.callConvertiMetriInRaggioOl(meters);

         /**
          * Se il raggio è già valorizzato (tmpCircleRadius) allora il punto inserito corrisponde al
          * centro del cerchio quindi posso disegnare un Circle nel centro della mappa.
          */
         if (!isNaN(radius)) {
            // const f = this.createCircleFeature(this.centroMappa,
            //                                     parseFloat(radius)); // 3857
            const f = this.createCircleFeature(insertPointCoords, radius); // 3857

            /** Aggiungo lo stile del layer precision alla feature */
            f.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER));

            /** Imposto una proprietà per rendere la feature già confermata */
            f.set('notSetUnconfirmedTag', true);

            const p = f.getProperties();
            p.precisionCounterPlaceholder = newIndex;
            f.setProperties(p);

            /** Aggiungo la feature al layer precision */
            ((this.precisionLayer as Layer).getSource() as VectorSource).addFeature(f);
			f.set('highlight', true);

         } else {
            console.log('*** Raggio non inserito ***');
         }
      } else {
         const f = this.createPointFeature(insertPointCoords); // 3857
         // const f = this.createPointFeature(this.centroMappa); // 3857

         /** Aggiungo lo stile del layer precision alla feature */
         f.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER));

         /** Imposto una proprietà per rendere la feature già confermata */
         f.set('notSetUnconfirmedTag', true);

         const p = f.getProperties();
         p.precisionCounterPlaceholder = newIndex;
         f.setProperties(p);

         /** Aggiungo la feature al layer precision */
         ((this.precisionLayer as Layer).getSource() as VectorSource).addFeature(f);
         console.log("++++ addFeature 5");

         f.set('highlight', true);

         /** Rigenero le tooltips */
         if (this.tipoGeometriaSelezionata !== 'Point') {
            this.refreshPointsTooltip();
         }
      }
   }


   /**
    * Creazione di una feature di tipo Point.
    * @param   coords coordinate per creare il punto
    */
   createPointFeature(coords: number[]) {
      const feature = new Feature({
         geometry: new Point(coords)
      });
      feature.setId(this.generateFeatureId());
      return feature;
   }

   /**
    * Creazione di una feature di tipo Cerchio.
    * @param   coords coordinate per creare il punto
    */
   createCircleFeature(center: number[], radius: number) {
      const feature = new Feature({
         geometry: new Circle(center, radius)
      });

      let featId;

      if (!!this.idFeatureSelezionata) {
         featId = this.idFeatureSelezionata;
      } else {
         featId = this.generateFeatureId();
      }

      feature.setId(featId);

      const p = feature.getProperties();

      /** Salvo il raggio in metri tra le proprietà */

      if (this.pannelloCoordinateForm.controls.raggio.value != 0 && this.pannelloCoordinateForm.controls.raggio.value != '') {
      p.radiusMeters = this.pannelloCoordinateForm.controls.raggio.value;
      } else {
      p.radiusMeters = this.callConvertiRaggioInMetri(radius);
      }

      feature.setProperties(p);

      return feature;
   }


   /** Per rendere visibile o nascondere il tool aggancia punto */
   isAgganciaPuntoVisible(){

      // Sempre disabilitato
      return false;

      // /** Nel caso della ricerca geometrica il tool aggancia punto viene disabilitato */
      // if(this.currentObjType == "ricerca_geometrica"){
      //    return false;
      // }
      // else{
      //    return true;
      // }
   }


   /**
    * Per abilitare/disabilitare il pulsante per agganciare un nuovo punto da Pannello Coordinate
    */
   isAgganciaPuntoDisabled() {
      switch (this.toolbarForm.controls.disegno.value) {

         case 'Point':
         if (((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().length > 0){
            /** Disabilita il pulsante per aggiungere nuovi punti */
            return true;
         }
           break;
         case 'Circle':
         if (((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().length > 0) {
            /** Disabilita il pulsante per aggiungere nuovi punti */
            return true;
         }
           break;

         default:
            return false;
            break;
      }
   }



    /**
    * Per abilitare/disabilitare il pulsante per aggiungere un nuovo punto da Pannello Coordinate
    */
   isAggiungiPuntoDisabled() {
      switch (this.toolbarForm.controls.disegno.value) {

         case 'Point': if (((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().length > 0) {
            /** Disabilita il pulsante per aggiungere nuovi punti */
            return true;
         }
                       break;
         case 'Circle': if (((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().length > 0) {
            /** Disabilita il pulsante per aggiungere nuovi punti */
            return true;
         }
                        break;

         default:
            return false;
            break;
      }
   }

   /**
    * Per abilitare/disabilitare il pannello delle coordinate
    */
   isPannelloCoordDisabled() {

      /** Disabilitato */
      if (this.toolbarForm.controls.disegno.value == '' || this.toolbarForm.controls.disegno.value == null || this.toolbarForm.controls.disegno.value == 'Particella') {
         return true;
      } else {
         return false;
      }
   }

   /**
    * Per abilitare/disabilitare i tools della toolbar
    * true = disabilitati
    * false = abilitati
    */
   isDisegnoAttivoDisabilitaTool() {

      /**
       * Se il pannello coordinate è aperto e sono presenti delle feature nel layer di precisione
       * oppure se sono in modalità di selezione di features multiple nello stesso pixel
       * disabilito i tools nella toolbar
       */
      if ((this.disegnoAttivoDisabilitaTool && ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().length > 0) || this.isFeatureAtPixelTDVisible()) {
         return true;
      /**
      * Altrimenti se non sono né in modifica né in inserimento o se non ci sono features nel layer
      * di precisione i tools devono essere abilitati
      * Posso cambiare tool perché non ci sono features nel layer di precisione, cioè non ho ancora iniziato
      * a disegnare o non ho lasciato disegni in sospeso;
      */
      } else{
        return false;
      }
   }


   /**
    * Controlla se la feature inserita è valida, in base al tipo di inserimento e geometria selezionata
    * @returns true se la feature è valida
    */
   isFeatureValid() {

      const confirmedPoints = this.insertedPrecisionFeatures()
         .filter((f: Feature) => f.get('featureTag') !== this.UNCONFIRMED_PRECISION_FEATURE_TAG);

      switch (this.tipoGeometriaSelezionata) {
         case 'Point':
            return confirmedPoints.length === 1 ? true : false;
         // case 'Particella':
         //    return confirmedPoints.length === 1 ? true : false;
         // break;
         case 'LineString':
            return confirmedPoints.length >= 2 ? true : false;
         // break;
         case 'Polygon':
            return confirmedPoints.length >= 3 ? true : false;
         // break;
         case 'Circle':
            return confirmedPoints.length === 1 ? true : false;
         // break;
      }
   }


   /**
    * Quando viene invocata emette un evento al padre per effettuare l'undo dell'ultima modifica
    */
   invokeUndo() {
      this.undoModify.emit(true);
   }


   /**
    * CONVERTI IN METRI
    * Chiama il servizio della mappa per convertire il raggio in metri
    */
   callConvertiRaggioInMetri(radius: number): number {
      const meters = this.servizioMappa.convertiRaggioInMetri(radius, this.viewMappa);
      return meters;
   }

   /**
    * CONVERTI IN RAGGIO
    * Chiama il servizio della mappa per convertire i metri passati in input nel formato del
    * raggio necessario per costuire il cerchio
    */
   callConvertiMetriInRaggioOl(meters: number): number {
      const radius = this.servizioMappa.convertiMetriInRaggioOl(meters, this.viewMappa);
      return radius;
   }

   /**
    * Gestione della tooltip nel bottone che apre il pannello laterale
    */
   pannelloTooltip() {
      if (typeof (this.tipoGeometriaSelezionata) == 'undefined' || this.tipoGeometriaSelezionata == '') {
         return '';
      } else if (this.tipoGeometriaSelezionata == 'Particella') {
         return 'Pannello Gestione Particella';
      } else {
         return 'Pannello Coordinate';
      }
   }
}
