import { NGXLogger } from "ngx-logger";
import { Injectable } from "@angular/core";
import { ConfigService } from "./config/config.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { GestioneLoginService } from "src/app/servizi/login/gestione-login.service";
import { HttpHeaders } from "@angular/common/http";

/**
 * Servizio contenente funzioni generali condivise dall'intero portale
 */
@Injectable({
  providedIn: "root",
})
export class UtilsService {
  /** Chiave utilizzata nel file JSON che contiene tutte le rotte di una API */
  private static PATHS_KEY: string = "paths";

  /** Indirizzo del server backend */
  private configServerIP: string;

  /** Porta del server backend */
  private configServerPort: any;

  /** Porta del server backend */
  private configServerProtocol: string;

  /** Porta del server backend */
  private configServerRelativePath: string;

  /** costruttore */
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private logger: NGXLogger,
    private loginServ: GestioneLoginService
  ) {
    // inizializzo i parametri di connessione al server
    this.configServerIP = this.configService.getConfigGlobale().api.server.apiIp;
    this.configServerPort = this.configService.getConfigGlobale().api.server.apiPort;
    this.configServerProtocol = this.configService.getConfigGlobale().api.server.apiProtocol;
    this.configServerRelativePath = this.configService.getConfigGlobale().api.server.apiRelativePath;
  }

  /**
   * Utile nelle procedure di filtro. Converte i null in stringa
   * e rende tutto maiuscolo, in modo da unificare i confronti
   * @param {string} str stringa da convertire
   * @returns {string} stringa convertita
   */
  nullToString(str: string): string {
    if (str == null) {
      return "";
    } else {
      return str.toUpperCase();
    }
  }

  /**
   * generatore di URL per raggiungere il backend
   * @param sezione sezione del file di configurazione (campo key del campo routes)
   * @param id identificativo della rotta (campo id del paths)
   * @returns URL completo
   */
  creaURL(sezione: string, id: string) {
    try {
      const path = this.configService
        .getConfigGlobale()
        .api.routes.find((r) => r.key === sezione)
        [UtilsService.PATHS_KEY].find((p) => p.id === id).path;
      return this.creaUrlDaRoute(path);
    } catch {
      this.logger.error(
        `Impossibile Generare il path ${sezione}/${id}. Controllare in ../assets/config/config-globale.dev.json`
      );
      return "";
    }
  }

  /**
   * Metodo per ricavare tutte le rotte di una API identificata dalla sezione
   * @param sezione API di cui si vogliono ottenere le rotte
   * @returns array contenente tutte le rotte di una specifica API
   */
  ottieniTutteRoutes(
    sezione: string
  ): [
    {
      /** identificativo route */
      id: string;
      /** percorso della route */
      path: string;
    }
  ] {
    return this.configService.getConfigGlobale().api.routes.find((r) => r.key === sezione)[
      UtilsService.PATHS_KEY
    ];
  }

  /**
   * Metodo per generare l'url del back avendo già la rotta desiderata
   * @param route rotta da utilizzare per creare l'URL
   */
  creaUrlDaRoute(route: string): string {
    if (this.configServerRelativePath) {
      return this.configServerRelativePath + route;
    } else {
      return this.configServerProtocol + this.configServerIP + ":" + this.configServerPort + route;
    }
  }

  /**
   * Metodo per effettaure una select generica sul DB
   * @param params es: { "corpo": "* FROM pippo" }
   */
  queryView(params: any): any {
    const API_URL = this.creaURL("query", "view");
    // if (!!params.token) {
    //    alert("HO GIA' IL TOKEN" + API_URL)
    // }
    return this.http
      .post(API_URL, params, this.getAuthorizationHeader())
      .pipe(
        map((resp: any) => {
          if (resp.error === 0 && resp.status === 0) {
            return resp.data;
          } else {
            this.logger.error(`Errore getTable`, resp);
            return undefined;
          }
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /**
   * Metodo per effettaure una generica richiesta in post al backend
   * @param url url da chiamare. es: this.creaURL('soggetti', 'save')
   * @param params parametri della richiesta. es: { "soggetto": {...} }
   */
  postRequest(url: string, params: any): Observable<any> {
    // if (!!params.token) {
    //    alert("HO GIA' IL TOKEN" + url)
    // }
    const bodyParams = Object.assign({},params);
    const reqBody = {
      orderBy: [],
      filterBy: [],
      params: bodyParams,
      pagination: {
        reqPage: 1,
        rowsPerPage: Infinity,
      },
      token: this.loginServ.getToken(),
    };
    const reqOptions = {
      observe: "body" as const,
      responseType: "text" as const,
      params: params as HttpParams,
      headers: this.getAuthorizationHeader().headers,
    };
    // console.log("params: ",params);
    return this.http
      .post(url, reqBody,reqOptions)
      .pipe(
        map((resp: any) => {
          // in caso di errore stampo un alert
          if ((resp.error && resp.error !== 0) || (resp.status && resp.status !== 0)) {
            this.logger.error(`Errore postRequest`, resp);
          }

          // in ogni caso ritorno al response dell'API
          return resp;
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getRequest(url: string, params?: any): Observable<any> {
    // if (!!params.token) {
    //    alert("HO GIA' IL TOKEN" + url)
    // }
    return this.http
      .get(url, { params: !!params ? params : "", headers: this.getAuthorizationHeader().headers })
      .pipe(
        map((resp: any) => {
          // in ogni caso ritorno al response dell'API
          return resp;
        })
      )
      .pipe(
        catchError((error) => {
          this.logger.error(`Errore getRequest ${url}`, error);
          return throwError(error);
        })
      );
  }

  private getAuthorizationHeader() {
    return {
      headers: new HttpHeaders({
        Authorization: "bearer " + this.loginServ.getToken(),
      }),
    };
  }

  /**
   * Metodo per effettaure una generica richiesta in post al backend
   * @param url url da chiamare. es: this.creaURL('soggetti', 'save')
   * @param params parametri della richiesta. es: { "soggetto": {...} }
   */
  simplepostRequest1(url: string, params: any): any {
    return this.http.post(url, params);
  }

  /**
   * Utile nei selettori TypeHead di ng-bootstrap per filtrare gli elementi di un array
   * in base al testo digitato dall'utente
   * @param {string} term stringa digitata
   * @param {any[]} array array sorgente da filtrare
   * @param {func()} formatter funzione di prelevamento stringa
   * @param {number} limit numero di elementi da mostrare
   * @returns {any[]} array filtrato, ordinato e limitato
   */
  searchFilterTH(term: string, array: any[], formatter: any, limit: number): string[] {
    return array
      .filter((e) => formatter(e).toLowerCase().includes(term.toLowerCase()))
      .sort(
        (a, b) =>
          formatter(a).toLowerCase().indexOf(term.toLowerCase()) -
          formatter(b).toLowerCase().indexOf(term.toLowerCase())
      )
      .slice(0, limit);
  }

  /**
   * Questo metodo è utile per formattare una data da stringa al tipo richiesto dal
   * componente ngbDatepicker
   * @param val :string valore della data
   */
  _dataFormatter(val) {
    console.log("_dataFormatter", val);
    if (!val || typeof val !== "string") {
      return;
    }

    let [year, month, day] = [null, null, null];

    if (!val) {
      console.log("_dataFormatter BBBB", { year, month, day });
      return { year, month, day };
    }

    const controlloAnno: RegExp = /^[0-9]{4}?/;
    const lang = controlloAnno.test(val) ? "eng" : "ita";
    if (lang === "ita") {
      [day, month, year] = val.split("/");
    } else {
      [year, month, day] = val.split("-");
    }

    const a = {
      year: parseInt(year),
      month: parseInt(month),
      day: parseInt(day),
    };
    console.log("_dataFormatter a ", a);
    return a;
  }

  /**
   *
   * @param name
   */
  getFunctionPageId(name: string): number {
    const func = this.configService.getConfigGlobale().funzionalita.find((x) => x.nome === name);
    return func ? func.id : 0;
  }
}
