import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { faAlignJustify, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { MappaService } from '../../servizi/mappa/mappa.service';
import { GestioneLoginService } from 'src/app/servizi/login/gestione-login.service';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
// import { VerificaAbilitazioneService } from 'src/app/servizi/verifica-abilitazione.service';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { UtenteAttivoService } from 'src/app/servizi/utente-attivo/utente-attivo.service';
import { ConfigService } from 'src/app/servizi/config/config.service';
import { PayloadUtente } from 'src/app/modelli/api.response.model';
import Funzionalita from 'src/app/servizi/EBWIdFunzionalita';

/** Componente per gestire l'Header dell'applicazione */
@Component({
   selector: 'app-header',
   templateUrl: './header.component.html',
   styleUrls: ['./../../app.component.css', './header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

   /** icona FA */
   faAlignJustify = faAlignJustify;
   /** icona FA */
   faUserCircle = faUserCircle;
   /** icona calendario */
   faCalendarAlt = faCalendarAlt;

   /** EventEmitter che notifica il click sul bottone della sidebar  */
   @Output() toggle: EventEmitter<any> = new EventEmitter();

   /** payload dal token */
   userInfo: PayloadUtente;

   /** sottoscrizione al login */
   loginSubs: Subscription;

   Funzionalita = Funzionalita;

   /** Costruttore */
   constructor(
      private loginServ: GestioneLoginService,
      // public abilitazServ: VerificaAbilitazioneService,
      public utenteAttivoService: UtenteAttivoService,
      private configService: ConfigService,
      private mappaService: MappaService,
      private logger: NGXLogger) {
   }

   /** ngOnInit */
   ngOnInit() {

      // inizializzo il pannellino con i dati utente
      this.initPannelloUtente();

      // resto in ascolto, in attesa di una login differente (es:simula)
      this.loginSubs = this.loginServ.ascoltaLogin().subscribe(
         (resp: any) => {
            // aggiorno il pannellino con i dati utente
            this.initPannelloUtente();
         },
         (err: string) => { this.logger.error('activationError: ', err); }
      );
   }

   /** inizializzazione del pannellino con i dati utente */
   initPannelloUtente() {
      // prelevo i dati utente
      const isImpersonificato = this.loginServ.isImpersonificato();
      this.userInfo = this.loginServ.getUser();

      // lancio la verifica di abilitazione "Simula Utente"
      // this.abilitazServ.verificaFunzionalita(115);
   }

   /** Metodo invocato ad ogni click sull bottone di apertura/chiusura sidebar */
   toggleSidebar() {
      this.toggle.emit(null);
      this.mappaService.refreshMappa();
   }

   /** logout utente */
   onLogout() {
      // this.loginServ.setToken(null);
      this.loginServ.logout();
   }

   /** ngOnDestroy */
   ngOnDestroy() {
      this.loginSubs.unsubscribe();
   }
}
