// tslint:disable: class-name

class PRATICHE {
    static IMPORT_EXPORT = 70;
    static DECADENZA_REVOCA = 73;

    static INVIO_FILE = 77;
    static IMPORT_FILE = 78;
    static PRATICHE_NON_GESTITE = 83;
    static GESTIONE_ATTI_AMMINISTRATIVI = 98;
    static PRATICHE_PER_SOGGETTO = 100;
    static CONSULTAZIONE_F24 = 111;
    static VARIAZIONI_AMMINISTRATIVE_MODIFICA_ANNO = 201;
    static VARIAZIONI_AMMINISTRATIVE_MODIFICA_NUMERO = 202;
    static VARIAZIONI_AMMINISTRATIVE_SCEGLI_LEGGE = 203;
    static VARIAZIONI_AMMINISTRATIVE_PROCURATORE = 199;
}

class PASSAGGIO {
    static SUBINGRESSO = 131;
    static CONSEGNA = 132;
    static DOMANDA = 133;
}
class DOMANDA {
    static PROFILO_ECONOMICO = 134;
    static PASSAGGIO_A = 135;
    static PASSAGGIO_CONSEGNA = 136;
    static DINIEGO = 137;
    static RIPRISTINO = 138;
    static ARCHIVIAZIONE_139 = 139;
    static ACQUISIZIONE_ESTREMI = 140;
    static ARCHIVIAZIONE_192 = 192;
}
class ATTO_FORMALE {
    static PROFILO_ECONOMICO = 186;
}
class LICENZA {
    static PROFILO_ECONOMICO = 143;
    static VARIAZIONI_AMMINISTRATIVE = 145;
    static VARIAZIONI_GEOMETRICHE = 146;
    static RIPRISTINO = 147;
    static DECADENZA_REVOCA = 148;
    static ARCHIVIAZIONE = 149;
    static EXPORT = 150;
    static CANCELLAZIONE = 151;
    static CHIUSURA = 152;
}
class INTEGRAZIONE {
    static CANCELLAZIONE = 153;
    static IMPORT = 154;
}
class SUPPLETIVA {
    static ARCHIVIAZIONE = 158;
    static PROFILO_ECONOMICO = 187;
}
class CONSEGNA {
    static RICONSEGNA_TOTALE = 167;
    static RICONSEGNA_PARZIALE = 168;
    static CONSULTAZIONE = 183;
    static VARIAZIONI_GEOMETRICHE = 188;
    static VARIAZIONI_AMMINISTRATIVE = 200;
    static INVIO_FILE = 195;
    static ARCHIVIAZIONE = 196;
}
class INGIUNZIONI {
    static GESTIONE = 130;
    static ACQUISIZIONE = 178;
    static PROFILO_ECONOMICO = 179;
    static MODIFICA = 180;
    static CANCELLAZIONE = 181;
    static ARCHIVIAZIONE = 182;
    static RIPRISTINO = 184;
    static REGOLARIZZAZIONE = 185;
}
class GESTIONE_PRATICHE {
    static PASSAGGIO = PASSAGGIO;
    static DOMANDA = DOMANDA;
    static ATTO_FORMALE = ATTO_FORMALE;
    static LICENZA = LICENZA;
    static INTEGRAZIONE = INTEGRAZIONE;
    static SUPPLETIVA = SUPPLETIVA;
    static CONSEGNA = CONSEGNA;
    static INGIUNZIONI = INGIUNZIONI;

    static CANCELLAZIONE_ANOMALIE = 141;
    static RINNOVO_ARCHIVIAZIONE = 193;
    static DECADUTE_RINNOVATE_CHIUSURA = 194;
}

class RICERCA_PRATICHE {
    static RICERCA_PRATICHE = 124;
    static RICERCA_ESPLOSA = 126;
    static RICERCA_VELOCE = 127;
    static RICERCA_VELOCE_SOGGETTI = 128;
}

class OCCUPAZIONI {
    static ACQUISISCI = 172;
    static MODIFICA = 173;
    static ELIMINA = 174;
    static PASSAGGIO_INGIUNZIONE = 175;
    static ARCHIVIAZIONE = 176;
}

class AMMINISTRAZIONE_SISTEMA {
    static CERTIFICATI = 72;
    static SEGNALAZIONI_AGEA = 75;
    static UTENTI_LIVELLI_ACCESSO = 92;
    static PROCEDURE_MICROSERVIZI = 112;
    static LOG_ATTIVITA = 162;
    static REPORT_ACCESSI = 165;
}

class DOWNLOAD {
    static SITO_MINISTERO = 74;
    static APPLICATIVO_GETRANS = 91;
    static APPLICATIVI_MANUALI = 104;
    static MANUALE_DORI = 109;
    static LOG_ATTIVITA = 113;
}

class DECRETI {
    static DECRETI = 81;
    static NUOVO = 99;
    static MODIFICA = 169;
    static CANCELLAZIONE = 170;
}
class DEMANIO {
    static DECRETI = DECRETI;

    static TERRITORIO_GESTIONE_DISEGNI = 79;
    static CONSULTA_CATASTO = 80;
    static MANUFATTI = 84;
}

class BENE_DEMANIALE {
    static DEMOLISCI = 119;
    static PASSAGGIO_OE_PD = 120;
    static PASSAGGIO_STORICO = 121;
    static REPORT = 122;
}

class TERRITORIO {
    static CALCOLI_MISURE = 88;
    static MISURE_TRASFORMAZIONI_COORDINATE = 94;
    static ALLINEAMENTO_DATI_CATASTALI = 101;
    static GESTIONE_RISERVE = 102;
    static VINCOLI = 110;
}

class PROFILO_ECONOMICO {
    static CANONE = 116;
    static RATA = 205;
    static F24 = 206;
}

class STATISTICHE {
    static VISUALIZZA_PRATICHE_IMPORTATE = 85;
    static IMPOSTA = 87;
    static AVVIA = 89;
    static RESOCONTO = 90;
}

class PORTALE_AMMINISTRATIVO {
    static PORTALE_AMMINISTRATIVO = 159;
    static GESTIONE_NOTIFICHE = 160;
    static GESTIONE_FUNZIONALITA = 161;
    static INTERSCAMBIO = 163;
    static EXPORT_DB = 164;
    static GESTIONE_CATASTO = 166;
}


class FUNZIONALITA {
    static PRATICHE = PRATICHE;
    static GESTIONE_PRATICHE = GESTIONE_PRATICHE;
    static RICERCA_PRATICHE = RICERCA_PRATICHE;
    static OCCUPAZIONI = OCCUPAZIONI;
    static AMMINISTRAZIONE_SISTEMA = AMMINISTRAZIONE_SISTEMA;
    static DOWNLOAD = DOWNLOAD;
    static DEMANIO = DEMANIO;
    static BENE_DEMANIALE = BENE_DEMANIALE;
    static TERRITORIO = TERRITORIO;
    static PROFILO_ECONOMICO = PROFILO_ECONOMICO;
    static STATISTICHE = STATISTICHE;
    static PORTALE_AMMINISTRATIVO = PORTALE_AMMINISTRATIVO;

    static ESENZIONE_INSERIMENTO_DATI = 71;
    static STORAGE = 82;
    static DORI = 86;
    static LOCALIZZA = 93;
    static STATISTICHE_CITTADINO = 103;
    static MIT_MODELLI_DOMANDA_GUIDE = 106;
    static MANUALI = 107;
    static DOCOL = 114;
    static UTENTE_SIMULATO = 115;
    static VARIAZIONI_AMMINISTRATIVE = 118;
    static TUTORIAL = 123;
    static PORTALE_ORDINANZE = 129;
    static REPORT_CATASTALE = 125;
    static VARIAZIONE_SOGGETTO_FISICO = 190;
    static VARIAZIONE_SOGGETTO_GIURIDICO = 191;
    static MODIFICA_RAPPRESENTANTE_LEGALE = 197;
    static MODIFICA_PROCURATORE = 198;
    static LOGIN = 207;
}


export default FUNZIONALITA;
