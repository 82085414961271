import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, NgControl } from '@angular/forms';
import { pairwise, startWith } from 'rxjs/operators';
import { UtenteAttivoService } from '../../../../servizi/utente-attivo/utente-attivo.service';

/**
 * Componente per la scelta rappresentazione
 */
@Component({
   selector: 'ebw-scelta-rappresentazione',
   templateUrl: './scelta-rappresentazione.component.html',
   styleUrls: ['./scelta-rappresentazione.component.scss']
})
export class SceltaRappresentazioneComponent implements OnInit {

   /** Struttura di configurazione del pannello */
   @Input() tabConfiguration: any[] = [];

   /** Struttura dei tab presenti */
   tabVisibili: any[] = [];

   /** Emettitore dell'evento del cambio di stato dei layer */
   @Output() layerAggiornati: EventEmitter<any> = new EventEmitter();

   /** Array che contiene tutti i form nel pannello */
   forms: FormGroup[] = [];

   /** Variabile che contiene i dati memorizzati in sessione */
   session: any[] = [];

   constructor(
      private formBuilder: FormBuilder,
      private utenteAttivo: UtenteAttivoService) { }

   ngOnInit(): void {
      // Scorro la configurazione iniziale e creo form group e form control
      //  in base al tipo di controllo da implementare
      this.tabConfiguration.forEach(tab => {
        // TO DO: controllo visibilita
         if ((tab.visibility.indexOf('cittadino') > -1) || (tab.visibility.indexOf('amministrazione') > -1)) {
            this.tabVisibili.push(tab);
            var obj = {};
            tab.content.forEach(group => {
               if (group.type == "checkbox") {
                  var checked: boolean[] = [];
                  group.values.forEach((layer, index) => {
                     checked.push(group.checked[index])
                  })
                  obj[group.group_name] = this.formBuilder.array(checked);
               } else {
                  obj[group.group_name] = new FormControl(group.checked);
               }
            });
            this.forms.push(this.formBuilder.group(obj))
         }
      });

      // Ad ogni controllo gestisco le azioni del valueChanges in modo da intercettare
      //  il cambiamento di stato e reagire di conseguenza
      this.forms.forEach((form, index) => {
         form.valueChanges.pipe(startWith(form.value), pairwise()).subscribe(([old, changed]) => {

            // Se i due oggetti sono diversi aggiorno gli stati del form e della sessione
            var objCompareResult = this.objCompare(old, changed);
            if (!objCompareResult.res) {
               // Il setTimeout permette di modificare gli oggetti prima di richiamare la funzione
               //  evita quindi che la funzione venga chiamata all'infinito ad ogni aggiornamento
               setTimeout(() => {
                  this.updateStateGroup(form, index);
                  this.updateStateSession(index, objCompareResult.groupName, objCompareResult.oldValue, objCompareResult.newValue);
               }, 5);
            }
         })
      });

      // Leggo la sessione e setto i valori dei vari form control
      this.session = JSON.parse(sessionStorage.getItem('layer_attivi')) == null ? [] : JSON.parse(sessionStorage.getItem('layer_attivi')) ;
      if (this.session != null) {
         console.log(this.session);
         // Scorro il form, per ogni tab recupero i gruppi e ne analizzo i valori
         this.forms.forEach((tab, index) => {
            Object.keys(tab.value).forEach(groupName => {
               var groupValues = tab.value[groupName];
               const isString = (typeof groupValues === 'string');
               const isArray = Array.isArray(groupValues);
               if (isArray) {
                  // Se è un array il gruppo è una serie di checkbox
                  // Recupero i valori dalla configurazione di default
                  var default_layers_name = this.tabVisibili[index].content.find(el => el.group_name == groupName).values;

                  default_layers_name.forEach((element, index) => {
                     // Se nella sessione è impostato un determinato valore lo uso, altrimenti lascio quello esistente
                     var i = this.session.findIndex(el => el.layer == element);
                     if (i >= 0) {
                        groupValues[index] = this.session[i].visibility;
                     }
                  });
                  // Assegno i nuovi valori al form group
                  this.forms[index].controls[groupName].setValue(groupValues);

               } else if (!isArray && isString) {
                  // Se è una stringa il gruppo è una select o un radio
                  // Cerco nella sessione quale valore deve essere impostato a true
                  this.session.forEach(layer => {
                     if (layer.title == groupName && layer.visibility) {
                        // Modifico sia il default content che il form in modo che vengano aggiornati i campi selezionati
                        this.tabVisibili[index].content.find(el => el.group_name == groupName).checked = layer.layer;
                        // this.forms[index].controls[groupName].setValue({value: layer.layer});
                        this.forms[index].controls[groupName].setValue(layer.layer);

                     }
                  });
               }
            });
         });
      }
   }

   /** Confermo la selezione e salvo le scelte in sessione */
   confirm() {
      sessionStorage.setItem('layer_attivi', JSON.stringify(this.session));
      this.layerAggiornati.emit(this.session);
   }

   /** Aggiorno lo stato dei gruppi */
   updateStateGroup(form: FormGroup, index: number) {
      var formValues = form.value;
      // Scorro i gruppi e verifico in base a cosa devono essere abilitati
      this.tabVisibili[index].content.forEach(group => {
         var enabled: boolean[] = [];
         if (group.enabledBy) {
            group.enabledBy.forEach(layer => {
               // Distinguo tra gruppo e layer abilitanti
               var groupName = layer.split("|")[0];
               var layerName = layer.split("|")[1];

               // Analizzo il valore indicato per distinguere tra i tipi di gruppi
               const isObject = (typeof formValues[groupName] === 'object');
               const isArray = Array.isArray(formValues[groupName]);

               if (!isArray && !isObject) {
                  // Se non è un array o un oggetto il gruppo è un radio
                  // Memorizzo se il layer selezionato è quello che cerco o meno
                  enabled.push(formValues[groupName] === layerName);
               } else if (isArray) {
                  // Se è un array il gruppo è una serie di checkbox
                  // Memorizzo se il layer è selezionato o meno
                  enabled.push(formValues[groupName][layerName]);
               } else if (!isArray && isObject) {
                  // Se è un oggetto il gruppo è una select
                  // Memorizzo se il layer selezionato è quello che verco o meno
                  enabled.push(formValues[groupName].value === layerName);
               }
            })

            // Verifico il tipo di operatore da utilizzare
            //  e controllo i valori salvati abilitando o disabilitando i gruppi
            if(group.enableOperator == "OR") {
               if (!enabled.includes(true)) {
                  form.get(group.group_name).disable();
               } else {
                  form.get(group.group_name).enable();
               }
            } else if (group.enableOperator == "AND") {
               if (!enabled.includes(false)) {
                  form.get(group.group_name).disable();
               } else {
                  form.get(group.group_name).enable();
               }
            }
         }
      })
   }

   /** Confronto due oggetti */
   objCompare(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      var result = {
         res: true,
         groupName: "",
         oldValue: -1,
         newValue: -1
      };

      // Se le lunghezze sono diverse gli oggetti sono diversi
      if (keys1.length != keys2.length) {
         result.res = false;
         return result;
      }

      // Scorro le chiavi del primo oggetto e ne confronto i valori con quelli delle stesse chiavi del secondo
      // Se sono diversi mi salvo il nome del gruppo ed i valori prima e dopo la modifica
      keys1.forEach((key1, i) => {
         const val1 = object1[key1];
         const val2 = object2[key1];
         const areObjects = (typeof val1 === 'object' && typeof val2 === 'object');
         const areArray = (Array.isArray(val1) && Array.isArray(val2));

         if (!areObjects && !areArray && val1 != val2) {
            // Se sono due valori li confronto direttamente
            result.res = false;
            result.groupName = key1;
            result.oldValue = val1;
            result.newValue = val2;
         } else if (areArray) {
            // Se sono due array comparo gli array
            var arrayCompareResult = this.arrayCompare(val1, val2);
            if (!arrayCompareResult.result) {
               result.res = false;
               result.groupName = key1;
               result.oldValue = arrayCompareResult.index;
               result.newValue = arrayCompareResult.index;
            }
         } else if (areObjects) {
            // Se sono due oggetti chiamo la funzione ricorsivamente
            var objCompareResult = this.objCompare(val1, val2);
            if (!objCompareResult.res) {
               result.res = false;
               result.groupName = objCompareResult.groupName;
               result.oldValue = objCompareResult.oldValue;
               result.newValue = objCompareResult.newValue;
            }
         }
      });

      return result;
   }

   /** Confronto due array */
   arrayCompare(array1, array2) {
      var result = true;
      var index = -1;
      // Scorro i valori del primo e li confronto con quelli del secondo
      array1.forEach((element, i) => {
         // Quando trovo l'elemento diverso salvo anche l'indice
         if (element !== array2[i]) {
            result = false;
            index = i;
         }
      });

      return {
         result: result,
         index: index
      };
   }

   /** Aggiorno lo stato della sessione */
   updateStateSession(tabIndex, groupName, oldValue, newValue) {
      // Verifico che i valori passatimi siano valorizzati
      if (groupName.length > 0) {
         // Se il valore è un numero allora devo aggiornare un layer di una checkbox
         //  quindi recupero i nomi dei layer dalla configurazione
         if (typeof(oldValue) == "number") {
            this.tabVisibili.forEach(tab => {
               var oldGroupNameIndex = tab.content.findIndex(el => el.group_name == groupName);
               if (oldGroupNameIndex >= 0) {
                  oldValue = tab.content[oldGroupNameIndex].values[oldValue];
                  newValue = tab.content[oldGroupNameIndex].values[newValue];
               }
            });
         }

         // Imposto a false la visibilità del vecchio layer selezionato
         //  Se il layer non è presente in sessione lo aggiungo come ultimo elemento
         if (oldValue != null && oldValue != "null") {
            var oldSessionIndex = this.session.findIndex(el => el.layer == oldValue);
            if (oldSessionIndex >= 0) {
               this.session[oldSessionIndex].visibility = false;
            } else {
               this.session.push({
                  tab: this.tabVisibili[tabIndex].tab_id,
                  title: groupName,
                  layer: oldValue,
                  visibility: false
               });
            }
         }

         // Imposto a true la visibilità del nuovo layer selezionato
         if (newValue != null && newValue != "null") {
            var newSessionIndex = this.session.findIndex(el => el.layer == newValue);
            if (newSessionIndex >= 0) {
               this.session[newSessionIndex].visibility = true;
            } else {
               this.session.push({
                  tab: this.tabVisibili[tabIndex].tab_id,
                  title: groupName,
                  layer: newValue,
                  visibility: true
               });
            }
         }
      }
   }

}
