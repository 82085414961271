import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneraPDFComponent } from './esporta-pdf/genera-pdf.component';
import { GeneraPDFService } from './genera-pdf.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/** Modulo che permette di generare un PDF */
@NgModule({
   declarations: [GeneraPDFComponent],
   imports: [
      CommonModule,
      FormsModule,
      NgbModule,
      FontAwesomeModule,
      ReactiveFormsModule
   ],
   providers: [
      GeneraPDFService
   ],
   entryComponents: [
      GeneraPDFComponent
   ]
})
export class GeneraPDFModule { }
