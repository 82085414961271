import { Component, Input, OnInit } from '@angular/core';
import { Map, View, Feature } from 'ol';
import  BaseLayer  from 'ol/layer/Base';
import { Layer, Vector } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { defaults as defaultControls } from 'ol/control';
import { defaults as defaultInteractions } from 'ol/interaction';
import { fromExtent } from 'ol/geom/Polygon';
import { MapService, PosizioneLayer, TipoLayer } from '../../service/map.service';
import { transform } from 'ol/proj';
import { Style, } from 'ol/style';
import { Coordinate } from 'ol/coordinate';

/** Componente che gestisce la preview */
@Component({
   selector: 'ebw-preview-mappa',
   templateUrl: './preview-mappa.component.html',
   styleUrls: ['./preview-mappa.component.scss']
})
export class PreviewMappaComponent implements OnInit {

   /** Nome del layer temporaneo aggiunto alla preview */
   static TEMPORAL_LAYER_NAME = 'temporalPreview';

   /** Nome della feature che rappresenta l'area di lavoro dell'utente */
   static EXTENT_FEATURE_NAME = 'extent';

   /** Altezza del contenitore globale della preview */
   @Input() altezzaPreview: number;

   /** Larghezza del contenitore globale della preview */
   @Input() larghezzaPreview: number;

   /** ID dello stile da utilizzare per rappresentare l'area di lavoro della mappa utente */
   @Input() set idStilePreview(idStile: string) {
      this.stilePreview = this.servizioMappa.buildFeatureStyle(idStile);
   }
   /** Differenza di zoom tra la mappa utente e la preview */
   @Input() sogliaZoom: number;

   /** Zoom corrente della mappa utente ricevuto da {@link MapComponent} */
   @Input() set zoomCorrente(zoom: number) {
      this._zoomCorrente = zoom - this.sogliaZoom < 0 ? 0 : zoom - this.sogliaZoom;
      if (this.mappaPreviewPronta) {
         this.mappaOpenLayerPreview.getView().setZoom(this._zoomCorrente);
      }
   }

   /** Zoom massimo raggiungibile */
   @Input() zoomMassimo: number;

   /** Coordinate del centro mappa ricevute da {@link MapComponent} */
   @Input() set centroMappa3857(centro: Coordinate) {
      this._centroMappa3857 = centro;
      this._centroMappa4326 = transform(this._centroMappa3857, MapService.EPSG_3857_KEY, MapService.EPSG_4326_KEY);
      if (this.mappaPreviewPronta) {
         this.mappaOpenLayerPreview.getView().setCenter(this._centroMappa3857);
      }
   }

   /** Valore per il controllo della visibilità del layer di Google della preview */
   @Input() mappaGoogleVisibile = false;

   /** Valore per il controllo della tipologia della mappa del layer di Google della preview */
   @Input() mappaGoogleTipologia: string;   // possibili valori  'roadmap'|'hybrid'|'satellite'|'terrain'

   /** Valore per il controllo dell'opacità del layer di Google della preview */
   @Input() mappaGoogleOpacity = 0;

   /** Boundig-box della vista nella mappa principale ricevuto da {@link MapComponent} */
   @Input() set extentCorrente(extend: [number, number, number, number]) {
      this._extentCorrente = extend;
      if (this.mappaPreviewPronta) {
         this.disegnaExtent(this._extentCorrente, this.stilePreview);
      }
   }

   /** Array di layers ricevuto da {@link MapComponent}. Viene filtrato per utilizzare solo i layer BASE */
   @Input() set layers(layers: BaseLayer[]) {
      // TODO verificare caso aggiunta layer BASE runtime
      this._layers = layers.filter(layer => layer.get(MapService.POSIZIONE_LAYER_KEY) === PosizioneLayer.BASE);
   }

   /** Valore zoom della preview */
   _zoomCorrente: number;

   /** Coordinate del centro della mappa in EPSG:4326 */
   _centroMappa4326: Coordinate;

   /** Coordinate del centro della mappa in EPSG:3857 */
   private _centroMappa3857: Coordinate;

   /** stile di default per la preview */
   private stilePreview: Style;

   /** Boundig-box della vista nella mappa principale */
   private _extentCorrente: [number, number, number, number];

   /** Array di layer BASE (già filtrato) */
   private _layers: BaseLayer[];

   /** Layer per i disegni temporanei */
   private tmpLayer: BaseLayer;

   /** Istanza della mappa Openlayer della preview */
   private mappaOpenLayerPreview: Map;

   /** Booleano per lo stato di inizializzazione della mappa della preview */
   private mappaPreviewPronta = false;

   /** Costruttore per il componente preview */
   constructor(private servizioMappa: MapService) { }

   /** ngOnInit */
   ngOnInit(): void {
      if (!this.mappaPreviewPronta) {
         this.inizializzazionePreview();
      }
   }

   /** Inizializzazione della preview */
   private inizializzazionePreview() {
      this.mappaOpenLayerPreview = new Map({
         target: 'ol-map-preview',
         layers: this._layers,
         view: new View({
            center: this._centroMappa3857,
            zoom: this._zoomCorrente,
            maxZoom: this.zoomMassimo,
            constrainResolution: true,
         }),
         controls: defaultControls({
            zoom: false,
         }).getArray(),
         interactions: defaultInteractions({
            altShiftDragRotate: false,
            pinchRotate: false,
            shiftDragZoom: false,
            zoomDuration: 0
         }).getArray(),

         // Migliora la user experience caricando i tiles durante l'animazione
        //  loadTilesWhileAnimating: true, // to do : da verificare se esiste
        //  loadTilesWhileInteracting: true, // to do : da verificare se esiste
      });
      this.mappaPreviewPronta = true;

      this.rimuoviInterazioniControlli();
      this.aggiungiLayerTemporaneo();

      this.disegnaExtent(this._extentCorrente, this.stilePreview);

      // Sistema la dimensione della preview dinamicamente
      setTimeout(() => { this.mappaOpenLayerPreview.updateSize(); }, 0);
   }

   /** Aggiunge il layer temporaneo alla preview */
   private aggiungiLayerTemporaneo() {
      this.tmpLayer = new Vector({
         source: new VectorSource(),
         visible: true,
         zIndex: 999
      });
      this.tmpLayer.set(MapService.TITOLO_LAYER_KEY, PreviewMappaComponent.TEMPORAL_LAYER_NAME);
      this.tmpLayer.set(MapService.TIPO_LAYER_KEY, TipoLayer.TEMP);
      this.tmpLayer.set(MapService.POSIZIONE_LAYER_KEY, PosizioneLayer.OVERLAY);
      this.mappaOpenLayerPreview.addLayer(this.tmpLayer);
   }

   /** Disabilita tutte le interazioni e rimuove tutti i controlli di openlayer */
   private rimuoviInterazioniControlli() {
      this.mappaOpenLayerPreview.getInteractions().forEach(interaction => {
         interaction.setActive(false);
      });

      this.mappaOpenLayerPreview.getControls().forEach(control => {
         this.mappaOpenLayerPreview.removeControl(control);
      });
   }

   /**
    * Disegna il bbox dell'area di lavoro della mappa principale
    * @param   extent extent della vista della mappa principale
    * @param   stile stile del bbox da disegnare
    */
   private disegnaExtent(extent: [number, number, number, number], stile: Style) {
      this.removeTmpFeature(PreviewMappaComponent.EXTENT_FEATURE_NAME);
      const box = new Feature({
         geometry: fromExtent(extent)
      });
      box.setStyle(stile);
      box.set(MapService.FEATURE_TAG_KEY, PreviewMappaComponent.EXTENT_FEATURE_NAME);
      this.aggiungiTmpFeature(box);
   }

   /**
    * Aggiunge una feaure temporanea al layer tmpLayer.
    * @param   feature feature da aggiungere
    */
   private aggiungiTmpFeature(feature: Feature) {
      if (this.mappaPreviewPronta) {
         ((this.tmpLayer as Layer).getSource() as VectorSource).addFeature(feature);
      }
   }

   /**
    * Cerca la feature da eliminare e se presente la elimina
    * @param   featureTag tag della feature da rimuovere
    */
   private removeTmpFeature(featureTag: string) {
      if (this.mappaPreviewPronta) {
         const feature = ((this.tmpLayer as Layer).getSource() as VectorSource).getFeatures().filter((layer) => layer.get(MapService.FEATURE_TAG_KEY) === featureTag);
         if (feature.length > 0) {
            ((this.tmpLayer as Layer).getSource() as VectorSource).removeFeature(feature[0]);
         }
      }
   }

}
