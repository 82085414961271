import { FormattatoreDataDatepickerService } from 'src/app/servizi/datepicker/formattatore-data-datepicker.service';
import { RaffinaDatiService } from 'src/app/servizi/raffina-dati.service';
import { Component } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from './servizi/config/config.service';

/** App Component */
@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.css'],
   providers: [{ provide: NgbDateParserFormatter, useClass: FormattatoreDataDatepickerService }]
})
export class AppComponent {

   /** Titolo della pagina sul browser */
   title = 'InWit';
   loading = false;
   /** costruttore */
   constructor(
      private raffinaDatiService: RaffinaDatiService,
      private configService: ConfigService) {
   }

}
