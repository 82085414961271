import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class PatientDataLoaderService {
  private _loadPatientDetailAlarms$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  get loadPatientDetailAlarms$(): BehaviorSubject<void> {
    return this._loadPatientDetailAlarms$;
  }
  set loadPatientDetailAlarms(val) {
    this._loadPatientDetailAlarms$.next(val);
  }

  private _loadPatientAreasList$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  get loadPatientAreasList$(): BehaviorSubject<void> {
    return this._loadPatientAreasList$;
  }
  set loadPatientAreasList(val) {
    this._loadPatientAreasList$.next(val);
  }

  private _loadPatientSafePathsList$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  get loadPatientSafePathsList$(): BehaviorSubject<void> {
    return this._loadPatientSafePathsList$;
  }
  set loadPatientSafePathsList(val) {
    this._loadPatientSafePathsList$.next(val);
  }

  private _loadPatientBiometricData$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  get loadPatientBiometricData$(): BehaviorSubject<void> {
    return this._loadPatientBiometricData$;
  }
  set loadPatientBiometricData(val) {
    this._loadPatientBiometricData$.next(val);
  }

  private _loadPatientsList$: Subject<boolean> = new Subject<boolean>();
  get loadPatientsList$(): Subject<boolean> {
    return this._loadPatientsList$;
  }
  set loadPatientsList(val: boolean) {
    this._loadPatientsList$.next(val);
  }

  private _loadPatientAlarmsList$: Subject<boolean> = new Subject<boolean>();
  get loadPatientAlarmsList$(): Subject<boolean> {
    return this._loadPatientAlarmsList$;
  }
  set loadPatientAlarmsList(val: boolean) {
    this._loadPatientAlarmsList$.next(val);
  }

  private _loadPatientPaths$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  get loadPatientPaths$(): BehaviorSubject<void> {
    return this._loadPatientPaths$;
  }
  set loadPatientPaths(val) {
    this._loadPatientPaths$.next(val);
  }

  private _loadPatientHeatmap$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  get loadPatientHeatmap$(): BehaviorSubject<void> {
    return this._loadPatientHeatmap$;
  }
  set loadPatientHeatmap(val) {
    this._loadPatientHeatmap$.next(val);
  }

  private _loadPatient$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  get loadPatient$(): BehaviorSubject<void> {
    return this._loadPatient$;
  }
  set loadPatient(val) {
    this._loadPatient$.next(val);
  }
  constructor() {}
}
