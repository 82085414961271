<div [ngStyle]="{'width': larghezzaPreview + 'px', 'height': altezzaPreview + 'px'}"
   class="rounded border border-secondary bg-secondary p-0 shadow">
      <agm-map  *ngIf="mappaGoogleVisibile"
         class="rounded border position-absolute preview_map"
         [ngStyle]="{opacity: mappaGoogleOpacity, width: (larghezzaPreview - 4) + 'px', height: (altezzaPreview - 4) + 'px'}"
         [longitude]="_centroMappa4326[0]"
         [latitude]="_centroMappa4326[1]"
         [zoom]="_zoomCorrente"
         [mapTypeId]="mappaGoogleTipologia"
         [disableDefaultUI]="true"
         [zoomControl]="false"
         [streetViewControl]="false"
         [keyboardShortcuts]="false"
         [clickableIcons]="false"
         [disableDoubleClickZoom]="true"
         [mapTypeControlOptions]="true"
         [mapDraggable]="false"
         [scrollwheel]="false"
         [usePanning]="false"
         [panControl]="false"
         [rotateControl]="false"
         [fullscreenControl]="false">
      </agm-map>
      <div
         [ngStyle]="{width: (larghezzaPreview - 2) + 'px', height: (altezzaPreview - 2) + 'px'}"
         id="ol-map-preview"
         class="rounded border position-absolute">
      </div>
</div>
