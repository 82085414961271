import { NGXLogger } from 'ngx-logger';
import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { CustomValidators } from 'src/app/condivisa/custom-validators';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GestioneUtentiService } from 'src/app/servizi/utenti/gestione-utenti.service';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/servizi/utils.service';
import { Location } from '@angular/common';
import { EbwModalService } from 'src/app/library/ebw-modals-angular/ebw-modal.service';

/**
 * Componente che gestisce la registrazione al portale di un Cittadino
 */
@Component({
   selector: 'app-registrazione',
   templateUrl: './registrazione.component.html',
   styleUrls: ['./registrazione.component.css']
})
export class RegistrazioneComponent implements AfterViewInit {

   /** form di registrazione */
   utenteForm: FormGroup;

   /** icona calendario */
   faCalendarAlt = faCalendarAlt;

   /** array dei comuni mappati con codice catastale */
   comuni: any;

   /** costruttore */
   constructor(
      private utilsServ: UtilsService,
      private elementRef: ElementRef,
      private ebwModalService: EbwModalService,
      private fb: FormBuilder,
      private gestioneUtentiService: GestioneUtentiService,
      private logger: NGXLogger,
      private location: Location) {
      this.utenteForm = this.createSignupForm();
   }

   /** ngAfterViewInit */
   ngAfterViewInit() {
      // cambio lo sfondo in grigio
      this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'grey';
      // prelevo tutti i comuni
      this.utilsServ.queryView({ corpo: 'cod_catastale, denominazione FROM decifra_codici_aree WHERE tipo=\'comune\'' }).subscribe(results => {
         this.logger.debug(results);
         this.comuni = results;
      });
   }

   /** creazione del form */
   createSignupForm(): FormGroup {
      /*
      N.B i nomi dei campi nella form devono corrispondere esattamente a quelli che si aspetta l'API utenti_save,
      in quanto il json da passare insieme alla richiesta a tale API (this.datiUtente) viene generato automaticamente a partire dalla form
      nel metodo salvaUtente()
      vedere file  sid3\backend\default.json API_UTENTI.fields
      */
      return this.fb.group({

         nome: ['', Validators.required],
         cognome: ['', Validators.required],
         data_nascita: [{}, Validators.required],
         codcom_nascita: [undefined, Validators.compose([Validators.required, this.comuneValidator.bind(this)])],
         sesso: ['F', Validators.required],
         codice_fiscale: ['', Validators.compose([Validators.required, Validators.minLength(16), Validators.maxLength(16)])],
         codcom_residenza: [undefined, Validators.compose([Validators.required, this.comuneValidator.bind(this)])],
         indirizzo: [''],
         telefono: [''],
         email: ['', Validators.compose([Validators.required, Validators.email])],
         tipo: ['C', Validators.required],
         numero_iscrizione: [''],
         albo_iscrizione: [''],
         data_iscrizione: [{}],
         username: ['', [Validators.required], this.uniqueUsernameValidator.bind(this)],
         password: ['', Validators.compose([
            // 1. Password Field is Required
            Validators.required,
            // 2. check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, { hasNumber: true }),
            // 3. check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
            // 4. check whether the entered password has a lower-case letter
            CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
            // 5. check whether the entered password has a special character
            CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
            // 6. Has a minimum length of 8 characters
            Validators.minLength(8)])
         ],
         conferma_password: ['', Validators.required],
         attivo: ['1']
      },
         {
            // check whether our password and confirm password match
            validator: CustomValidators.passwordMatchValidator
         });
   }

   /** gestione del popup di scelta comune */
   comuneFormatter = (e: any) => e.denominazione;
   comuneSearch = (text$: Observable<string>) => {
      return text$.pipe(debounceTime(200), distinctUntilChanged(), map(term => term === '' ? [] :
         this.utilsServ.searchFilterTH(term, this.comuni, this.comuneFormatter, 10)));
   }

   /** validazione del comune dal typehead */
   comuneValidator(ctrl: FormControl): { [s: string]: boolean } {
      if (this.comuni) {
         const finded = this.comuni.find((e) => this.comuneFormatter(e) === this.comuneFormatter(ctrl.value));
         if (finded === undefined) { return { comuneError: true }; }
      }
      return null;
   }


   /** validatore dell'univocità dell'username */
   uniqueUsernameValidator(control: FormControl): Promise<any> | Observable<any> {
      const promise = new Promise<any>((resolve, reject) => {
         const jsonData = {
            params: {
               select: 'users',
               orderBy: [
                  { field: 'id', order: 'desc' }
               ],
               pagination: {
                  reqPage: 1, rowsPerPage: 10
               },
               filterBy: [
                  { field: 'username', eq: control.value.trim() }
               ]
            }
         };
         this.gestioneUtentiService.getElencoPost(jsonData)
            .subscribe(
               (response) => {
                  this.logger.debug(response);
                  if (response.results.length > 0) {
                     resolve({ isNotUnique: true });
                  } else {
                     resolve(null);
                  }
                  // resolve({'isNotUnique': true});
               },
               (error) => this.logger.error(error)
            );
      });
      return promise;
   }


   /** click sul bottone "registrati" */
   salvaUtente() {
      // console.log('this.utenteForm.value',this.utenteForm.value)

      // json da passare al backend
      const jsonData: any = {
         utente: {
            nome: this.utenteForm.value.nome,
            cognome: this.utenteForm.value.cognome,
            data_inizio: new Date(),
            data_fine: new Date(new Date().setFullYear(new Date().getFullYear() + 100)),
            tipo: 'C',
            email: this.utenteForm.value.email,
            codice_fiscale: this.utenteForm.value.codice_fiscale,
            sesso: this.utenteForm.value.sesso,
            telefono: this.utenteForm.value.telefono,
            data_nascita: new Date(
               this.utenteForm.value.data_nascita.year,
               this.utenteForm.value.data_nascita.month - 1,
               this.utenteForm.value.data_nascita.day
            ),
            indirizzo: this.utenteForm.value.indirizzo,
            codcom_residenza: this.utenteForm.value.codcom_residenza ?  this.utenteForm.value.codcom_residenza.cod_catastale : '',
            codcom_nascita: this.utenteForm.value.codcom_nascita.cod_catastale,
            attivo: 1,
            auth: {
               gruppi_id: 6,
               amministrazioni_id: 1762,
               username: this.utenteForm.value.username,
               // TODO PASSWORD CRIPTATA!
               password: this.utenteForm.value.password,
               attivo: 1
            }
         }
      };
      // aggiungo i dati relativi al cittadino
      if (this.utenteForm.value.tipo === 'C') {
         jsonData.utente.auth.ruoli_id = 5;
      }
      // aggiungo i dati relativi tecnico
      if (this.utenteForm.value.tipo === 'T') {
         jsonData.utente.auth.ruoli_id = 4;
         jsonData.utente.numero_iscrizione = this.utenteForm.value.numero_iscrizione;
         jsonData.utente.albo_iscrizione = this.utenteForm.value.albo_iscrizione;
         jsonData.utente.data_iscrizione = new Date(
            this.utenteForm.value.data_iscrizione.year,
            this.utenteForm.value.data_iscrizione.month - 1,
            this.utenteForm.value.data_iscrizione.day
         );
      }

      this.gestioneUtentiService.salvaUtente(jsonData).subscribe(
         (results: any) => {
            this.logger.debug('Risposta dal backend: ', results);
            this.ebwModalService.openSuccessModal({ title: 'Nuovo utente', content: 'Registrazione avvenuta con successo!' });
            this.back();
         }
      );
   }

   /** Click sul bottone indietro */
   back() {
      this.location.back();
   }
}
