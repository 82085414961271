// import { ToolParticellaComponent } from './sidebar/tool-particella/tool-particella.component';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, ElementRef, TemplateRef } from '@angular/core';
import { MapService, PosizioneLayer, TipoLayer } from './service/map.service';

import { Map, View, Feature, Collection, Overlay } from 'ol';
import { getCenter as getExtentCenter, Extent } from 'ol/extent';
import  BaseLayer  from 'ol/layer/Base';
import { Layer, Tile, Vector } from 'ol/layer';
import { OSM, TileWMS, Vector as VectorSource, XYZ } from 'ol/source';
import { transform } from 'ol/proj';
import { WKT, GeoJSON } from 'ol/format';
import { Point, Circle, Geometry, Polygon, SimpleGeometry } from 'ol/geom';
import { fromExtent } from 'ol/geom/Polygon';
import { Style, Fill, Stroke, Icon } from 'ol/style';
import { defaults as defaultControls } from 'ol/control';
import { Draw, Select, DragBox, defaults as defaultInteractions, Pointer as PointerInteraction } from 'ol/interaction';
import { LayerWorld, LayerWMS, LayerVector, LayerXYZ } from './model/layer.model';
import { FeatureStyle } from './model/style.model';

import { shiftKeyOnly, singleClick } from 'ol/events/condition';
import { HttpClient } from '@angular/common/http';
import { ToolDisegnoComponent } from './sidebar/tool-disegno/tool-disegno.component';
import { ControlliMappaComponent } from './componenti-aggiuntivi/controlli-mappa/controlli-mappa.component';
import { ToolAgganciaPuntoComponent } from './sidebar/tool-aggancia-punto/tool-aggancia-punto.component';
import { EbwModalService } from './library/ebw-modals-angular/ebw-modal.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
// import ConfigMappaJSON from 'src/assets/config/config-mappa.dev.json';
import { IConfigMappa } from './model/config-mappa.model';
//import { GestioneLoginService } from 'src/app/servizi/login/gestione-login.service';
import { HttpHeaders } from '@angular/common/http';
import { RicercaGeometricaComponent } from './sidebar/ricerca-geometrica/ricerca-geometrica.component';
import { Coordinate } from 'ol/coordinate';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import GeometryType from 'ol/geom/GeometryType';
import { MapUtilsService } from './service/map-utils.service';
import { PayloadUtente } from "./model/api.response.model";
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AgmMap, MapsAPILoader } from '@agm/core';

/** Componente per la visualizzazione della mappa */
@Component({
  selector: "ebw-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          width: "{{width}}",
          transform: "translate3d(100%, 0, 0)",
        }),
        { params: { width: 0 } }
      ),
      state(
        "out",
        style({
          width: "{{width}}",
          transform: "translate3d(0, 0, 0)",
        }),
        { params: { width: 0 } }
      ),
      transition("in => out", animate("300ms ease-in-out")),
      transition("out => in", animate("300ms ease-in-out")),
    ]),
    trigger("slideMap", [
      state(
        "in",
        style({
          "margin-right": "0px",
        })
      ),
      state(
        "out",
        style({
          "margin-right": "{{margin}}",
        }),
        { params: { margin: 0 } }
      ),
      transition("in => out", animate("300ms ease-in-out")),
      transition("out => in", animate("300ms ease-in-out")),
    ]),
  ],
})
export class MapComponent implements OnInit {
  @ViewChild("ricercaGeometrica", { static: false })
  ricercaGeometrica: RicercaGeometricaComponent;
  @ViewChild("toolDisegno", { static: false })
  toolDisegno: ToolDisegnoComponent;
  @ViewChild("toolAgganciaPunto", { static: false })
  toolAgganciaPunto: ToolAgganciaPuntoComponent;
  @ViewChild("controlliMappa", { static: false })
  controlliMappa: ControlliMappaComponent;
  //@ViewChildren(ToolParticellaComponent) viewParticella: QueryList<ToolParticellaComponent>;
  @ViewChild("olmapdiv", { static: false }) olmapdiv;
  @ViewChild("popup", { static: false }) popup: NgbPopover;
  @ViewChild("streetViewContainer", { static: false })
  streetViewContainer: ElementRef<HTMLElement>;
  @ViewChild("mapContainer", { static: false })
  mapContainer: ElementRef<HTMLElement>;
  @ViewChild("agmMap", { static: false }) agmMap: AgmMap;
  private _mapContainer: HTMLElement;
  private _streetViewContainer: HTMLElement;
  @ViewChild("popupContent") popupContent: TemplateRef<HTMLElement>;
  popupHTML: string;
  menuState: string = "in";

  /** Variabile locale per memorizzare l'identificativo dello stile della selezione */
  _idStileSelezione: string;

  /** Variabile locale per memorizzare l'identificativo dello stile del disegno */
  _idStileDisegno: string;
  /**
   * Variabile per memorizzare lo stile della selezione creato tramite il servizio
   */
  stileSelezione: Style;
  /**
   * Variabile per memorizzare lo stile del disegno creato tramite il servizio
   */
  stileDisegno: Style;
  /**
   * Percorso per l'icona dell'indirizzo selezionato
   */
  _iconaIndirizzoSelezionato: string;
  /**
   * Stato della sidebar
   */
  sidebarOpened = false;
  /**
   * Stringa utilizzata per definire la dimensione della sidebar
   */
  _larghezzaSidebar: string = "300px";

  /**
   * Per visualizzare o meno il bottone 'Elimina' nel tool di disegno
   */
  visibilitaBtnEliminaToolDisegno: boolean = true;

  /**
   * Per visualizzare o meno il bottone 'Annulla' nel tool di disegno
   */
  visibilitaBtnAnnullaToolDisegno: boolean = false;

  /**
   * Per preselezionare un tool dalla toolbar
   */
  toolbarDefaultSelectedTool: string = "";

  /**
   * Per far si che i tasti 'Annulla' e 'X' dell pannello coordinate del tool di disegno, chiudano anche la toolbar
   */
  toolDisegnoCloseAll: boolean;

  /** Dati extra da passare al tool di disegno */
  objectDataToolDisegno: any;

  private popupOverlay: Overlay;

  /** Booleano per controllare se provengo dalla login oppure no. Viene passato da MappaComponent */
  @Input() login: boolean;

  /**
   * Utilizzato per prendere in input il valore della dimensione della sidebar
   */
  @Input() set larghezzaSidebar(value: number) {
    this._larghezzaSidebar = value + "px";
  }
  /**
   * Definizione della configurazione degli stili da caricare nel sevizio
   */
  @Input() set stiliConfigurazione(stili: FeatureStyle[]) {
    this.servizioMappa.impostaStili(stili);
  }
  /**
   * Stile dell'extent da passare alla preview
   * @returns [description]
   */
  @Input() idStilePreview: string;
  /**
   * Variabile per la definizione della larghezza della preview
   */
  @Input() altezzaPreview: number;
  /**
   * Variabile per la definizione dell'altezza della preview
   */
  @Input() larghezzaPreview: number;

  /**
   * Funzione utilizzata per ricevere l'identificativo dello stile per il disegno che viene poi costruito tramite
   * il metodo messo a disposizione dal servizio
   */
  @Input() set idStileSelezione(idStile: string) {
    // TODO verificare se gli stili nella mappa sono già popolati
    this._idStileSelezione = idStile;
    this.stileSelezione = this.servizioMappa.buildFeatureStyle(
      this._idStileSelezione
    );
    // console.log('STILE SELEZIONE', this.stileSelezione);
  }

  /**
   * Funzione utilizzata per ricevere l'identificativo dello stile per il disegno che viene poi costruito tramite
   * il metodo messo a disposizione dal servizio
   */
  @Input() set idStileDisegno(idStile: string) {
    // TODO verificare se gli stili nella mappa sono già popolati
    this._idStileDisegno = idStile;
    this.stileDisegno = this.servizioMappa.buildFeatureStyle(
      this._idStileDisegno
    );
    // console.log('STILE DISEGNO', this.stileDisegno);
  }
  /**
   * Variabile che contiene la tipologia della chiave per la mappa di Google (apiKey o clientId)
   */
  @Input() googleKeyType: string;
  /**
   * Variabile che contiene la chiave per la mappa di Google
   */
  @Input() googleKey: string;
  /**
   * Valore per l'arrotondamento dei valori mostrati nel componente inforMappa
   */
  @Input() arrotondamentoInfoVista: number;
  /**
   * Valore della differenza di zoom tra la mappa principale e la preview
   */
  @Input() sogliaZoomPreview: number;
  /**
   * Variabile utilizzata per il passaggio dei layer World (OSM, GOOGLE, BING, ...)
   * @returns [description]
   */
  @Input() layersWorld: LayerWorld[];
  /**
   * Variabile utilizzate per il passaggio dei layer WMS
   */
  @Input() layersWMS: LayerWMS[];

  /**
   * Variabile utilizzate per il passaggio dei layer WMS
   */
  @Input() layersXYZ: LayerXYZ[];

  /**
   * Variabile utilizzata per il passggio dei layer vettoriali
   * @returns [description]
   */
  @Input() layersVector: LayerVector[];

  /**
   * Coordinate del centro della mappa in EPSG:4326
   */
  @Input() centroMappa4326: Coordinate;

  /**
   * Valore dello zoom corrente
   */
  @Input() zoom: number;
  /**
   * Limite minimo dello zoom
   */
  @Input() zoomMinimo: number;
  /**
   * Limite massimo dello zoom
   */
  @Input() zoomMassimo: number;
  /**
   * Gestore per la visualizzazione del componente infoMappa
   */
  @Input() infoMappaAttivo = false;
  /**
   * Gestore per la visualizzazione del componente previewMappa
   */
  @Input() previewMappaAttiva = false;
  /**
   * Gestore per la visualizzazione del componente controlliMappa
   */
  @Input() controlliMappaAttivi = false;
  /**
   * Gestore per la visualizzazione del compoenente layerSfondo
   */
  @Input() layerSfondoMappaAttivo = false;
  /**
   * Gestore per la visualizzazioen del componente selezioneOggetti
   */
  @Input() selezioneOggettiAttiva = false;

  @Input() set iconaIndirizzoSelezionato(path: string) {
    this._iconaIndirizzoSelezionato = path;
  }
  /**
   * Indirizzo URL del backend (API)
   */
  @Input() urlBackendAPI = "";

  /**
   * SR di default per tutto il pacchetto
   */
  @Input() defaultSR: any;

  /**
   * Array di SR ottenuti dalla configurazione
   */
  @Input() arraySR: any[] = [];

  /**
   * Configurazione base del pannello della scelta rappresentazione
   */
  @Input() contentSceltaRappresentazione: any[] = [];

  @Input() set token(token) {
    this.mapUtilsService.setToken(token);
  }
  /** Variabile che conterrà le configurazioni della mappa */
  @Input() configMappa: IConfigMappa = undefined;

  @Input() set payLoad(payLoad: PayloadUtente) {
    this.mapUtilsService.setPayload(payLoad);
  }
  /**
   * Booleano che indica lo stato di inizializzazione della mappa OpenLayer
   */
  _mappaPronta: boolean = false;
  /**
   * Booleano che indica lo stato di inizializzazione della mappa Google
   */
  _mappaGooglePronta: boolean = false;
  /**
   * Indicatore della visibilità della mappa di Google
   */
  mappaGoogleVisibile: boolean = false;
  /**
   * Stringa che indica la tipologia della mappa di Google
   */
  mappaGoogleTipologia: string; // possibili valori  'roadmap'|'hybrid'|'satellite'|'terrain'
  /**
   * Indicatore dell'opacità della mappa di Google
   */
  mappaGoogleOpacity: number = 0;
  /**
   * Coordinate del centro della mappa in EPSG:3857
   */
  centroMappa3857: Coordinate = [0, 0];

  /**
   * View della mappa
   */
  viewMappa: View;
  /**
   * Coordinate del mouse in EPSG:4326
   */
  coordinateMouse4326: Coordinate = [0, 0];

  /**
   * Risoluzione della vista corrente
   */
  risoluzioneCorrente: number;
  /**
   * Boundig-box della vista corrente
   */
  extentCorrente: [number, number, number, number];
  /**
   * Array di oggetti selezionati sulla mappa
   */
  oggettiSelezionati: Feature[] = [];
  /**
   * Array dei layers presenti nella mappa
   */
  layers: BaseLayer[] = [];
  /**
   * Istanza del layer base attivo
   */
  layerBaseAttivo: BaseLayer = undefined;
  /**
   * Istanza del layer temporaneo
   */
  tmpLayer: BaseLayer;
  /**
   * Istanza del layer per la selezione
   */
  layerSelezione: BaseLayer;
  /**
   * Istanza del layer per il disegno
   */
  layerDisegno: BaseLayer;

  /** Istanza del layer per l'evidenziazione delle particelle durante una riconsegna */
  layerParticelle: BaseLayer;

  /**
   * Istanza dell'oggetto OpenLayers della mappa
   */
  mappaOpenLayer: Map;
  mapGoogle: google.maps.Map;
  /*******************SELEZIONE**********************/
  /**
   * Interazione selezione ad area
   */
  interazioneSelezioneArea: DragBox;
  /**
   * Interazione selezione puntuale
   */
  interazioneSelezionePunto: Select;
  /**
   * Coordiante punto iniziale selezione area
   */
  coordinateInizioBox: [number, number];
  /**
   * Coordiante punto finale selezione area
   */
  coordinateFineBox: [number, number];
  /**
   * Coordiante selezione puntuale
   */
  coordinatePunto: [number, number];
  /********************DISEGNO********************/
  /**
   * Tipologie di geometrie disponibili
   */
  AVAILABLE_GEOMETRIES = [
    { k: "Point", v: "Punto" },
    { k: "LineString", v: "Linea" },
    { k: "Polygon", v: "Poligono" },
    { k: "Circle", v: "Cerchio" },
  ]; // TODO vedere se metterle in configurazione
  /**
   * Interazione disegno
   */
  interazioneDisegno: Draw;
  /**
   * Booleano che indica lo stato dell'interazione disegno
   */
  interazioneDisegnoAttiva = false;
  /**
   * Tipologia delle geometria che si sta disegnando
   */
  tipoGeometriaDisegno: GeometryType;

  /**
   * Collezione di feature selezionate
   */
  collectionFeatureSelezionate = new Collection();

  pannelliSidebar = {
    trasforma_coord: false,
    disegno: false,
    particella: false,
    aggancia_punto: false,
    legenda: false,
    streetView: false,
    scelta_rappresentazione: false,
    ricerca_geometrica: false,
  };

  /** Di default la toolbar è visualizzata */
  toolbarVisible: boolean = true;

  /** Dati dell'amministrazione che ha fatto il login */
  amministrazione;

  /**
   * Posizione in cui andrà inserito (nel tool di disegno | pannello coordinate)
   * il punto recuperato con il tool aggancia punto, viene usato nell'integrazione di tool aggancia
   * punto con il tool di disegno
   */
  precisionCounterPlaceholder: number;

  /** Variabile usata per nascondere il pannello coordinate senza 'distruggere' il componente */
  displayPcMap = false;

  /** Oggetto da passare al tool di disegno */
  // toolDisegnoCurrentObj;

  /** Feature che deve essere aggiunta dopo che il tool di disegno ha inizializzato il layer precisionLayer */
  featureToAddAfterToolDisegnoInit;

  /** Oggetto passato al tool di disegno dopo che è stato inizializzato il componente */
  objectToAddAfterToolDisegnoInit;

  /**
   * Tipo dell'oggetto passato al tool di disegno, ad esempio occupazioni, oggetto, ingiunzioni, ecc...
   */
  objectTypeToAddAfterToolDisegnoInit;

  /** Contiene l'elemento confermato tramite il tool di disegno */
  evtElementoConfermato;
  pointFeatureStreetView: Feature;
  streetViewService: google.maps.StreetViewService;
  panorama: google.maps.StreetViewPanorama;
  noPanorama: boolean = false;

  positionChangedPanoramaListener: google.maps.MapsEventListener;
  povChangedPanoramaListener: google.maps.MapsEventListener;

  /**
   * Emettitore dell'evento del cambio di stato della mappa
   */
  @Output() mappaPronta: EventEmitter<boolean> = new EventEmitter();
  /**
   * Emettitore dell'evento di click sul bottone di chiusura di un oggetto selezionato
   */
  @Output()
  chiusuraOggettoSelezionatoEmitter: EventEmitter<any> = new EventEmitter();
  /**
   * Emettitore dell'evento di click sul bottone di modifica di un oggetto selezionato
   */
  @Output()
  modificaOggettoSelezionatoEmitter: EventEmitter<any> = new EventEmitter();
  /**
   * Emettitore dell'evento di click sul bottone di info di un oggetto selezionato
   */
  @Output()
  visualizzaInfoOggettoSelezionatoEmitter: EventEmitter<any> = new EventEmitter();

  /** Emette l'evento che serve per visualizzare gli oggetti sulla mappa dopo aver effettuato la modifica di
   * uno di tali oggetti (Es, nelle Variazioni Geometriche)
   */
  @Output()
  caricaOggettiDopoEditToolDisegnoEmitter: EventEmitter<any> = new EventEmitter();

  /** Emette esternamente l'elemento/oggetto restituito dal tool di disegno */
  @Output()
  toolDisegnoElementoConfermatoMapEmitter: EventEmitter<any> = new EventEmitter();

  /** Emette esternamente l'evento che indica che il tool di disegno deve essere chiuso, nelle ordinanze e nei vincoli serve per chiudere la modale map-modal */
  @Output() chiudiToolDisegnoMapEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * Costruttore del componente mappa
   * @param servizioMappa servizio per la gestione della mappa
   * @param http servizio per la gestione di chiamate HTTP
   */
  constructor(
    private servizioMappa: MapService,
    private http: HttpClient,
    private mapUtilsService: MapUtilsService,
    //	private loginService: GestioneLoginService,
    private ebwModalService: EbwModalService,
    private cdRef: ChangeDetectorRef, // TO DO : verify
    private _loaderAGM: MapsAPILoader
  ) {
    // this.configMappa = ConfigMappaJSON as IConfigMappa;
  }

  /**
   * Funzione OnInit di Angular. Avviene la creazione della mappa e di tutte le componenti associate.
   */
  ngOnInit() {
    /** Solo se vengo dalla login devo centrare la mappa nella zona di competenza */
    if (this.login) {
      this.mapUtilsService
        .postRequest(this.mapUtilsService.creaURL("amministrazioni", "view"), {
          where: {
            attivo: 1,
            id: this.mapUtilsService.getPayload().amministrazioni_id,
          },
        })
        .subscribe((res) => {
          if (res.error == 0 && res.status == 0) {
            this.amministrazione = res.data[0];
            if (this.amministrazione.amministrazioni_geom) {
              const amministrazione_geom = this.amministrazione.amministrazioni_geom.find(
                (el) => (el.tipo = "T")
              );
              const geom = new GeoJSON().readGeometry(
                amministrazione_geom.geom,
                {
                  dataProjection: MapService.EPSG_3857_KEY,
                  featureProjection: MapService.EPSG_3857_KEY,
                }
              );
              this.viewMappa.fit(geom as SimpleGeometry);
              // this.viewMappa.setZoom(15);
            }
          }
        });
    }

    if (
      this.servizioMappa.coordinateCentroMappa &&
      this.servizioMappa.coordinateCentroMappa.length > 0
    ) {
      this.centroMappa3857 = transform(
        this.servizioMappa.coordinateCentroMappa,
        MapService.EPSG_4326_KEY,
        MapService.EPSG_3857_KEY
      );
    } else {
      this.centroMappa3857 = transform(
        this.centroMappa4326,
        MapService.EPSG_4326_KEY,
        MapService.EPSG_3857_KEY
      );
    }

    if (this.servizioMappa.zoom) {
      this.zoom = this.servizioMappa.zoom;
    }

    this.layersWorld
      .filter((layer) => layer.posizione === PosizioneLayer.BASE)
      .forEach((layer) => this.creaLayerWorld(layer));
    this.layersWMS
      .filter((layer) => layer.posizione === PosizioneLayer.BASE)
      .forEach((layer) => this.creaLayerWMS(layer));
    this.layersXYZ
      .filter((layer) => layer.posizione === PosizioneLayer.BASE)
      .forEach((layer) => this.creaLayerXYZ(layer));
    this.layersVector
      .filter((layer) => layer.posizione === PosizioneLayer.BASE)
      .forEach((layer) => this.createLayerVector(layer));

    this.mappaOpenLayer = new Map({
      target: "ol-map",
      layers: this.layers,
      view: new View({
        constrainResolution: true,
        center: this.centroMappa3857,
        zoom: this.zoom,
        maxZoom: this.zoomMassimo,
      }),
      controls: defaultControls({
        zoom: false,
      }).getArray(),
      interactions: defaultInteractions({
        altShiftDragRotate: false,
        pinchRotate: false,
        shiftDragZoom: false,
        zoomDuration: 0,
      }).getArray(),

      // Migliora la user experience caricando i tiles durante l'animazione
      //  loadTilesWhileAnimating: true, // to do : da verificare se existe
      //  loadTilesWhileInteracting: true,
    });

    this.viewMappa = this.mappaOpenLayer.getView();

    this.extentCorrente = this.mappaOpenLayer.getView().calculateExtent();
    this.risoluzioneCorrente = this.mappaOpenLayer.getView().getResolution();

    if (this.servizioMappa.risoluzione) {
      this.risoluzioneCorrente = this.servizioMappa.risoluzione;
    }

    this.mappaOpenLayer.getView().on(
      "change:center",
      function (event) {
        this.eventoCambiatoCentro(event);
      }.bind(this)
    );

    this.mappaOpenLayer.getView().on(
      "change:resolution",
      function (event) {
        this.eventoCambiataRisoluzione(event);
      }.bind(this)
    );

    this.mappaOpenLayer.on(
      "pointermove",
      function (event) {
        this.eventoMovimentoMouse(event);
      }.bind(this)
    );

    this.mappaOpenLayer.on(
      "rendercomplete",
      function (event) {
        if (!this.mappaGoogleVisibile && !this._mappaPronta) {
          this._mappaPronta = true;
          this.mappaPronta.emit(this._mappaPronta);
        }
      }.bind(this)
    );

    this.aggiungiLayerTemporaneo();

    this.istanziaSelezione();

    this.istanziaDisegno();

    // inizializza le interazioni del disegno
    this.setEditModes();

    this.servizioMappa.impostaURLBackend(this.urlBackendAPI);
  }

  ngAfterViewInit() {
    this.popupOverlay = new Overlay({
      element: document.getElementById("popup"),
      autoPan: true,
    });
    this.insertOverlay(this.popupOverlay);
    this._mapContainer = this.mapContainer.nativeElement;
    this._streetViewContainer = this.streetViewContainer.nativeElement;

    this.pointFeatureStreetView = new Feature({
      geometry: new Point([0, 0]),
    });

    // this._loaderAGM.load().then(()=>{

    // });
    // setTimeout(()=>{
    //   this.handleStreetView(true);
    // },5000);
  }

  getModificheAssociazione(evt) {
    this.pannelliSidebar.particella = false;
    // this.modificaParticella.emit(evt);
  }

  resetToolbar(event) {
    if (!!this.toolDisegno) {
      this.toolDisegno.cancelDraw();
    }
  }

  //   fnDisegnoAttivoDisabilitaTool(event) {
  //      if (!!this.toolDisegno) {
  //         this.toolDisegno.disegnoAttivoDisabilitaTool = event;
  //      }
  //   }

  /**
   * Handler per l'evento di modifica del centro mappa
   * @param   event evento lanciato da Angular
   */
  eventoCambiatoCentro(event: Event) {
    this.centroMappa3857 = this.mappaOpenLayer.getView().getCenter();
    this.centroMappa4326 = transform(
      this.centroMappa3857,
      MapService.EPSG_3857_KEY,
      MapService.EPSG_4326_KEY
    );

    this.extentCorrente = this.mappaOpenLayer.getView().calculateExtent();
    this.servizioMappa.coordinateCentroMappa = this.centroMappa4326;
  }
  /**
   * Handler per l'evento di zoom
   * @param   event evento lanciato da Angular
   */
  eventoCambiataRisoluzione(event: Event) {
    this.mappaGoogleOpacity = 0;
    this.zoom = this.mappaOpenLayer.getView().getZoom();
    this.risoluzioneCorrente = this.mappaOpenLayer.getView().getResolution();
    this.extentCorrente = this.mappaOpenLayer.getView().calculateExtent();
    this.servizioMappa.zoom = this.zoom;
    this.servizioMappa.risoluzione = this.risoluzioneCorrente;
  }
  /**
   * Handler per l'evento move del mouse
   * @param   event evento lanciato da Angular
   */
  eventoMovimentoMouse(event) {
    this.coordinateMouse4326 = transform(
      event.coordinate,
      MapService.EPSG_3857_KEY,
      MapService.EPSG_4326_KEY
    );
  }
  /**
   * Aggiunge un layer alla mapppa
   * @param   layer oggetto da aggiungere alla mappa
   */
  aggiungiLayerAllaMappa(layer: BaseLayer) {
    this.mappaOpenLayer.addLayer(layer);
  }
  /**
   * Crea layer base da aggiungere alla mappa (OSM, GOOGLE, BING, ...)
   * @param   jsonLayer configurazione del layer
   */
  creaLayerWorld(jsonLayer: LayerWorld): BaseLayer {
    const layer: Tile = new Tile({
      visible: jsonLayer.visibilita,
      opacity: jsonLayer.opacita,
    });

    // controlli per attivazione mappe Google
    const tipologiaGoogle: string = jsonLayer.tipologiaMappaGoogle;
    if (tipologiaGoogle === "") {
      layer.setSource(new OSM());
    }
    layer.set(MapService.TITOLO_LAYER_KEY, jsonLayer.titolo);
    layer.set(MapService.TIPO_LAYER_KEY, jsonLayer.tipo);
    layer.set(MapService.POSIZIONE_LAYER_KEY, jsonLayer.posizione);
    layer.set(
      MapService.TIPOLOGIA_GOOGLE_LAYER_KEY,
      jsonLayer.tipologiaMappaGoogle
    );
    this.layers.push(layer);

    if (jsonLayer.visibilita) {
      this.layerBaseAttivo = layer;
      if (tipologiaGoogle !== undefined && tipologiaGoogle !== "") {
        this.mappaGoogleVisibile = true;
        this.mappaGoogleTipologia = tipologiaGoogle;
      }
    }
    return layer;
  }
  /**
   * Crea layer WMS da aggiungere alla mappa
   * @param   jsonLayer configurazione del layer
   */
  creaLayerWMS(jsonLayer: LayerWMS): BaseLayer {
    let layer: Tile;
    if (!!jsonLayer.auth) {
      layer = new Tile({
        visible: jsonLayer.visibilita,
        opacity: jsonLayer.opacita,
        source: new TileWMS({
          url: jsonLayer.url,
          params: jsonLayer.params,
          transition: 0, // Durata dell'effetto di opacità durante la transizione
          tileLoadFunction: function (tile, src) {
            const client = new XMLHttpRequest();
            client.open("GET", src);

            if (jsonLayer.auth.type === "Basic") {
              client.setRequestHeader(
                "Authorization",
                "Basic " + btoa(this.jsonLayer.auth.key)
              );
            }

            client.responseType = "blob";
            client.addEventListener("loadend", function (evt) {
              const data = this.response;
              if (data !== undefined) {
                tile.getImage().src = URL.createObjectURL(data);
              } else {
                tile.setState(3);
                // ol/TileState
                // IDLE number 0
                // LOADING number 1
                // LOADED number 2
                // ERROR number 3
                // EMPTY 4
              }
            });
            client.send();
          }.bind({ jsonLayer }),
        }),
      });
    } else {
      layer = new Tile({
        visible: jsonLayer.visibilita,
        opacity: jsonLayer.opacita,
        source: new TileWMS({
          url: jsonLayer.url,
          params: jsonLayer.params,
          transition: 0, // Durata dell'effetto di opacità durante la transizione
        }),
      });
    }

    layer.set(MapService.TITOLO_LAYER_KEY, jsonLayer.titolo);
    layer.set(MapService.TIPO_LAYER_KEY, jsonLayer.tipo);
    layer.set(MapService.POSIZIONE_LAYER_KEY, jsonLayer.posizione);
    layer.set(MapService.DATA_URL_LAYER_KEY, jsonLayer.dataUrl);
    this.layers.push(layer);
    return layer;
  }

  /**
   * Crea layer XYZ da aggiungere alla mappa
   * @param   jsonLayer configurazione del layer
   */
  creaLayerXYZ(jsonLayer: LayerXYZ): BaseLayer {
    let layer: Tile;
    if (!!jsonLayer.auth) {
      layer = new Tile({
        visible: jsonLayer.visibilita,
        opacity: jsonLayer.opacita,
        source: new XYZ({
          url: jsonLayer.url,
          transition: 0, // Durata dell'effetto di opacità durante la transizione
          tileLoadFunction: function (tile, src) {
            const client = new XMLHttpRequest();
            client.open("GET", src);

            if (jsonLayer.auth.type === "Basic") {
              client.setRequestHeader(
                "Authorization",
                "Basic " + btoa(this.jsonLayer.auth.key)
              );
            }

            client.responseType = "blob";
            client.addEventListener("loadend", function (evt) {
              const data = this.response;
              if (data !== undefined) {
                tile.getImage().src = URL.createObjectURL(data);
              } else {
                tile.setState(3);
                // ol/TileState
                // IDLE number 0
                // LOADING number 1
                // LOADED number 2
                // ERROR number 3
                // EMPTY 4
              }
            });
            client.send();
          }.bind({ jsonLayer }),
        }),
      });
    } else {
      layer = new Tile({
        visible: jsonLayer.visibilita,
        opacity: jsonLayer.opacita,
        source: new XYZ({
          url: jsonLayer.url,
          transition: 0, // Durata dell'effetto di opacità durante la transizione
        }),
      });
    }

    layer.set(MapService.TITOLO_LAYER_KEY, jsonLayer.titolo);
    layer.set(MapService.TIPO_LAYER_KEY, jsonLayer.tipo);
    layer.set(MapService.POSIZIONE_LAYER_KEY, jsonLayer.posizione);
    layer.set(MapService.DATA_URL_LAYER_KEY, jsonLayer.dataUrl);
    this.layers.push(layer);
    return layer;
  }

  /**
   * Crea layer vettoriale e ci associa gli stili.
   * @param jsonLayer configurazione del layer.
   * @returns layer vettoriale.
   */
  createLayerVector(jsonLayer: LayerVector): BaseLayer {
    let styles = undefined;
    if (jsonLayer.layerStyles && jsonLayer.layerStyles.length > 0) {
      const stylesKey = jsonLayer.layerStyles;
      styles = [];
      stylesKey.forEach((id) => {
        styles.push(this.servizioMappa.buildFeatureStyle(id));
      });
    }

    if (jsonLayer.customStyles && jsonLayer.customStyles.length > 0) {
      // console.log("---customStyles: ",jsonLayer.customStyles);
      styles = function (feature: Feature, resolution: number) {
        const properties = feature.getProperties();
        // console.log("--MapComponent createLayerVector prop: ",properties);
        // console.log("--MapComponent createLayerVector length: ",this.customStyles.length);
        const featureStyle = [];

        Object.keys(properties).forEach((propKey) => {
          // get the value
          let propValue = properties[propKey];
          // console.log("--------propValue: ", propValue);

          // loop styles
          let confStyles = this.customStyles.filter((style) => {
            // check condition for the style
            // console.log("--------style: ", style);
            let checkIfStylePresent = style.condition.find((cond) => {
              // console.log("--------key: ",propKey);
              // console.log("--------value: ",propValue);
              if (cond.field == propKey) {
                if (!!cond.eq) {
                  return propValue == cond.eq;
                } else if (!!cond.gt) {
                  return propValue >= cond.gt;
                } else if (!!cond.lt) {
                  return propValue <= cond.lt;
                } // add others compare types
              }
              return false;
            });

            // if check passed
            if (!!checkIfStylePresent) {
              // console.log("-------checkIfStylePresent",checkIfStylePresent);
              return true;
            }
            return false;
          });
          // console.log("--------confStyles: ", confStyles);
          confStyles.forEach((el) => {
            el.style.forEach((styleKey) => {
              featureStyle.push(this.mapService.buildFeatureStyle(styleKey));
            });
          });
          feature.setStyle(featureStyle);
          // console.log("----- createLayerVector feature style : ", feature);
          // console.log("----- createLayerVector feature style : ", feature);
          // this.map.getView().fit(feature.getGeometry());
        });
      }.bind({
        customStyles: jsonLayer.customStyles,
        mapService: this.servizioMappa,
        map: this.mappaOpenLayer,
      });
    }

    let format;
    switch (jsonLayer.format.type) {
      case "geojson":
        format = new GeoJSON({
          dataProjection: jsonLayer.format.dataProjection,
          featureProjection: jsonLayer.format.featureProjection,
        });
        break;
    }
    let url = "";

    // if(!!jsonLayer.url){
    //   url = jsonLayer.url;
    // } else {
    //   url =()=>{}
    // }
    const layer: Vector = new Vector({
      visible: jsonLayer.visibilita,
      opacity: jsonLayer.opacita,
      style: styles, // styleFn
      source: new VectorSource({
        url: jsonLayer.url,
        format: format,
      }),
    });

    layer.set(MapService.TITOLO_LAYER_KEY, jsonLayer.titolo);
    layer.set(MapService.TIPO_LAYER_KEY, jsonLayer.tipo);
    layer.set(MapService.POSIZIONE_LAYER_KEY, jsonLayer.posizione);
    layer.set(MapService.DATA_URL_LAYER_KEY, jsonLayer.dataUrl);

    // 
    if(jsonLayer.popupTemplate){
      layer.set(MapService.POPUP_TEMPLATE_KEY, jsonLayer.popupTemplate);
    }

    this.layers.push(layer);
    return layer;
  }
  /**
   * Aggiunge il layer temporaneo alla mappa
   */
  aggiungiLayerTemporaneo() {
    this.tmpLayer = new Vector({
      source: new VectorSource(),
      visible: true,
      zIndex: 995,
    });
    this.tmpLayer.set(MapService.TITOLO_LAYER_KEY, "temporal");
    this.tmpLayer.set(MapService.TIPO_LAYER_KEY, TipoLayer.TEMP);
    this.tmpLayer.set(MapService.POSIZIONE_LAYER_KEY, PosizioneLayer.OVERLAY);
    this.mappaOpenLayer.addLayer(this.tmpLayer);
  }
  /**
   * Aumenta lo zoom della mappa di una unità (controlla il livello di zoom massimo)
   */
  aumentaZoom() {
    if (this.zoom < this.zoomMassimo) {
      this.mappaOpenLayer.getView().setZoom(this.zoom + 1);
    }
  }
  /**
   * Diminuisce lo zoom della mappa di una unità (controlla il livello di zoom minimo)
   */
  diminuisciZoom() {
    if (this.zoom > this.zoomMinimo) {
      this.mappaOpenLayer.getView().setZoom(this.zoom - 1);
    }
  }
  /**
   * Crea l'extent dall'array di coordinate passate e la disegna sulla mappa
   * @param   obj   oggetto composto da extent (array con le quattro coordinate per la costruzione del box) e stile (oggetto per la silizzazione della feature creata)
   */
  disegnaExtent(obj: { extent: Extent; stile: Style }) {
    this.rimuoviFeatureFromTag(this.tmpLayer, "extent");
    if (!!!obj.extent) {
      return;
    }
    const box = new Feature({
      geometry: fromExtent(obj.extent),
    });
    box.setStyle(obj.stile);

    box.set(MapService.FEATURE_TAG_KEY, "extent");
    this.aggiungiFeature(this.tmpLayer, box);
  }
  /**
   * Aggiunge o rimuove sulla mappa le informazioni relative alla localizzazione.
   */
  localizza(position) {
    this.rimuoviFeatureFromTag(this.tmpLayer, "localize");
    this.rimuoviFeatureFromTag(this.tmpLayer, "accuracy");
    if (position !== null) {
      const coordinate = transform(
        [position.coords.longitude, position.coords.latitude],
        MapService.EPSG_4326_KEY,
        MapService.EPSG_3857_KEY
      );

      const posizione = new Feature({
        geometry: new Point(coordinate),
      });
      posizione.setStyle(
        new Style({
          image: new Icon({
            anchor: [0.5, 1],
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.FRACTION,
            src: this.configMappa.centraMappa.icon,
          }),
        })
      );
      posizione.set(MapService.FEATURE_TAG_KEY, "localize");
      this.aggiungiFeature(this.tmpLayer, posizione);

      const accuratezza = new Feature({
        geometry: new Circle(coordinate, position.coords.accuracy),
      });
      accuratezza.setStyle(
        new Style({
          fill: new Fill({
            color: [255, 0, 0, 0.5],
          }),
          stroke: new Stroke({
            color: [255, 0, 0, 1],
            width: 5,
          }),
        })
      );
      accuratezza.set(MapService.FEATURE_TAG_KEY, "accuracy");
      this.aggiungiFeature(this.tmpLayer, accuratezza);
      this.mappaOpenLayer.getView().setCenter(coordinate);
    }
  }
  /**
   * Aggiunge l'interazione alla mappa
   * @param   interazione interazione da aggiungere
   */
  aggiungiInterazione(interazione) {
    this.mappaOpenLayer.addInteraction(interazione);
  }
  /**
   * Rimuove l'interazione dalla mappa
   * @param   interazione interazione da rimuovere
   */
  rimuoviInterazione(interazione) {
    this.mappaOpenLayer.removeInteraction(interazione);
  }
  /**
   * Aggiunge una feaure temporanea al layer tmpLayer.
   * @param   layer layer nel quale aggiungere la feature
   * @param   feature feature da aggiungere
   */
  aggiungiFeature(layer: BaseLayer, feature: Feature) {
    // console.log("aggiungiFeature---> ", feature);
    ((layer as Layer).getSource() as VectorSource).addFeature(feature);
  }
  /**
   * Cerca la feature da eliminare dal layer e se presente la elimina
   * @param   layer layer dal quale rimuovere la feature
   * @param   featureTag tag della feature da rimuovere
   */
  rimuoviFeatureFromTag(layer: BaseLayer, featureTag: string) {
    const feature = ((layer as Layer).getSource() as VectorSource)
      .getFeatures()
      .filter((f: Feature) => f.get(MapService.FEATURE_TAG_KEY) === featureTag);
    if (feature.length > 0) {
      ((layer as Layer).getSource() as VectorSource).removeFeature(feature[0]);
    }
  }

  /**
   * Rimuove la feature tmpLayer se presente
   * @param   layer layer dal quale rimuovere la feature
   * @param   feature oggetto da rimuovere
   */
  rimuoviFeatureFromObj(layer: BaseLayer, feature: Feature) {
    ((layer as Layer).getSource() as VectorSource).removeFeature(feature);
  }
  /**
   * Cambia la visibilità di un layer in base al parametro passato.
   * @param   idGruppo  stringa del gruppo di cui modificare la visibilità
   * @param   visible   booleano da settare per la visibilità.
   */
  cambiaVisibilitaGruppoLayers(idGruppo: string, visible: boolean) {
    this.mappaOpenLayer
      .getLayers()
      .getArray()
      .filter(
        (layer: BaseLayer) => layer[MapService.GRUPPO_LAYER_KEY] === idGruppo
      )
      .forEach((l: BaseLayer) => l.setVisible(visible));
  }
  /**
   * Cambia la visibilità di un layer in base al parametro passato.
   * @param   layer   layer di cui modificare la visibilità.
   * @param   visible booleano da settare per la visibilità.
   */
  cambiaVisibilitaLayer(layer: BaseLayer, visible: boolean) {
    layer.setVisible(visible);
  }
  /**
   * Invocata quando la mappa di google ha completato l'inizializzazione
   */
  mappaGooglePronta(map: any) {
    if (
      this.mappaGoogleVisibile &&
      !this._mappaGooglePronta &&
      !this._mappaPronta
    ) {
      this.mapGoogle = map;
      this._mappaGooglePronta = true;
      this._mappaPronta = true;
      this.mappaPronta.emit(this._mappaPronta);
    }
    // Elimina la modalità 3D
    // this.initializeStreetView();
    map.setTilt(0);
  }
  /**
   *  Invocata quando la mappa di google è inattiva DOPO il pan o lo zoom
   */
  mappaGoogleInattiva() {
    this.mappaGoogleOpacity = 1;
  }
  /**
   *  Invocata ad un cambiamento di zoom della mappa di google
   */
  mappaGoogleZoomCambiato(event: number) {}
  /**
   * Gestisce il cambio di layer BASE
   * @param layer layer da attivare
   */
  cambiaLayerBase(layer: BaseLayer) {
    this.layers
      .filter(this.servizioMappa.isLayerBase)
      .forEach((item) => item.setVisible(false));
    this.layers
      .filter(
        (element) =>
          element.get(MapService.TITOLO_LAYER_KEY) ===
          layer.get(MapService.TITOLO_LAYER_KEY)
      )
      .forEach((item) => item.setVisible(true));

    // Controlli per attivazione mappe Google
    const tipologiaGoogle: string = layer.get(
      MapService.TIPOLOGIA_GOOGLE_LAYER_KEY
    );
    if (tipologiaGoogle !== undefined && tipologiaGoogle.length > 0) {
      this.mappaGoogleVisibile = true;
      this.mappaGoogleTipologia = tipologiaGoogle;
    } else {
      this.mappaGoogleVisibile = false;
    }
    this.layerBaseAttivo = layer;
  }
  /**
   * Instanzia le interazioni e i subscribe per la selezione
   */
  istanziaSelezione() {
    // Layer selezione
    this.layerSelezione = new Vector({
      source: new VectorSource(),
      style: this.stileSelezione,
      visible: true,
      zIndex: 997,
    });

    this.layerSelezione.set(MapService.TITOLO_LAYER_KEY, "temporal_select");
    this.layerSelezione.set(MapService.TIPO_LAYER_KEY, TipoLayer.TEMP);
    this.layerSelezione.set(
      MapService.POSIZIONE_LAYER_KEY,
      PosizioneLayer.OVERLAY
    );

    this.aggiungiLayerAllaMappa(this.layerSelezione);

    // Selezione area
    this.interazioneSelezioneArea = new DragBox({
      condition: shiftKeyOnly,
    });

    this.interazioneSelezioneArea.on(
      "boxstart",
      function (event) {
        this.coordinateInizioBox = event.coordinate;
      }.bind(this)
    );

    this.interazioneSelezioneArea.on(
      "boxdrag",
      function (event) {
        this.disegnaExtent({
          extent: this.coordinateInizioBox.concat(event.coordinate),
          stile: this.stileSelezione,
        });
      }.bind(this)
    );

    this.interazioneSelezioneArea.on(
      "boxend",
      function (event) {
        this.disegnaExtent({ extent: [], stile: this.stileSelezione }); // emetto un array vuoto per cancellare l'ultimo bounding box
        this.coordinateFineBox = event.coordinate;
        this.servizioMappa.coordinateSelezionate$.next([
          this.coordinateInizioBox,
          this.coordinateFineBox,
        ]);

        this.oggettiSelezionati = [];
        this.layerSelezione.getSource().clear();

        this.servizioMappa.ricercaOggetti$.next({
          location: this.layers
            .filter((layer) => layer.getVisible())
            .map((layer) => layer.get(MapService.DATA_URL_LAYER_KEY))
            .filter((layer) => layer !== undefined),
          geometry: fromExtent(
            this.coordinateInizioBox.concat(this.coordinateFineBox)
          ),
        });
      }.bind(this)
    );

    // Selezione puntuale
    this.interazioneSelezionePunto = new Select({
      condition: singleClick,
      layers: [],
      toggleCondition: function (event) {
        this.coordinatePunto = event.coordinate;
        this.popup.isOpen() ? this.popup.close() : "";
        this.servizioMappa.coordinateSelezionate$.next([this.coordinatePunto]);

        this.oggettiSelezionati = [];
        this.layerSelezione.getSource().clear();
        // console.log("-----point coords",new Point(this.coordinatePunto))
        this.servizioMappa.ricercaOggetti$.next({
          location: this.layers
            .filter((layer) => layer.getVisible())
            .map((layer) => layer.get(MapService.DATA_URL_LAYER_KEY))
            .filter((layer) => layer !== undefined),
          geometry: new Circle(this.coordinatePunto,50),
        });
      }.bind(this),
    });

    this.aggiungiInterazione(this.interazioneSelezionePunto);
    this.aggiungiInterazione(this.interazioneSelezioneArea);

    this.servizioMappa.ricercaOggetti$.subscribe((ricerca) => {
      // console.log("MapComponent ricercaOggetti.subscribe: ", ricerca);
      // console.log("MapComponent ricercaOggetti.subscribe: ", typeof ricerca.location[0]);
      if (ricerca.location.length > 0) {
        // controllo se la ricerca deve essere fatta sui layer o su una stringa
        if (typeof ricerca.location[0] === "string" && ricerca.location[0].length >0) {
          this.ricercaOggettiInUrl(ricerca);
        } else {
          this.ricercaOggettiInLayersLocali({
            layers: ricerca.location,
            geometry: ricerca.geometry,
          });
        }
      }
    });

    this.servizioMappa.interruttoreInterazioneSelezione$.subscribe((flag) => {
      this.interazioneSelezioneArea.setActive(flag);
      this.interazioneSelezionePunto.setActive(flag);
    });
  }

  private showPopup(coordinate: Coordinate, title?: string, content?: string) {
    // console.log("_______DENTRO SHOWPOPUP");
    this.popup.isOpen() ? this.popup.close() : "";
    // const hdms = toStringHDMS(toLonLat(coordinate));
    this.popup.popoverTitle = title;
    setTimeout(() => {
      this.popup.ngbPopover = content;
      this.popupOverlay.setPosition(coordinate);
      this.popup.isOpen() ? "" : this.popup.open();
    }, 200);
  }

  public handleStreetView(isStreetViewVisible: boolean) {
    const btnGroup = document.getElementById("map-btn-goup");
    if (this._streetViewContainer && this._mapContainer) {
      if (
        isStreetViewVisible &&
        this._mapContainer.classList.contains("h-100")
      ) {
        // console.log("MapComponent showStreetView show");
        this._streetViewContainer.classList.add("h-50");
        this._mapContainer.classList.replace("h-100", "h-50");
        btnGroup.classList.replace("btn-group-vertical","btn-group");
        this.streetViewOpen();
      } else if (
        !isStreetViewVisible &&
        this._mapContainer.classList.contains("h-50")
        ) {
          // console.log("MapComponent showStreetView hide");
          this.noPanorama = false;
          this._streetViewContainer.classList.remove("h-50");
          this._mapContainer.classList.replace("h-50", "h-100");
          btnGroup.classList.replace("btn-group","btn-group-vertical");
        this.rimuoviFeatureFromObj(this.tmpLayer, this.pointFeatureStreetView);
        // google.maps.event.removeListener(this.positionChangedPanoramaListener);
        // google.maps.event.removeListener(this.povChangedPanoramaListener);
        this.mappaOpenLayer.un("moveend", this.adjust_stretview_position);
      }
    }
  }

  private initializeStreetView() {
    this.streetViewService = new google.maps.StreetViewService();
    let center = { lat: this.centroMappa4326[1], lng: this.centroMappa4326[0] };

    this.panorama = new google.maps.StreetViewPanorama(
      document.getElementById("pano") as HTMLElement,
      {
        position: center,
        pov: {
          heading: 34,
          pitch: 10,
        },
        fullscreenControl: false,
        panControl: false,
        enableCloseButton: false,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_CENTER,
        },
        addressControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_LEFT,
        },
      }
    );
    //Listener per il cambio di posizione dentro SV (vado avanti/indietro)
    this.positionChangedPanoramaListener = google.maps.event.addListener(
      this.panorama,
      "position_changed",
      () => {
        //Sposto il centro della mappa --> per cui, per la funzione registrata, aggiorno il segnaposto
        var newLonLat = transform(
          [
            this.panorama.getPosition().lng(),
            this.panorama.getPosition().lat(),
          ],
          "EPSG:4326",
          "EPSG:3857"
        );
        this.mappaOpenLayer.un("moveend", this.adjust_stretview_position);
        this.mappaOpenLayer.getView().setCenter(newLonLat);
        this.mappaOpenLayer.on("moveend", this.adjust_stretview_position);

        this.rimuoviFeatureFromObj(this.tmpLayer, this.pointFeatureStreetView);

        this.pointFeatureStreetView.setGeometry(new Point(newLonLat));
        this.pointFeatureStreetView.setStyle(
          new Style({
            image: new Icon({
              src: "assets/icons/arrow.png",
              rotation:
                ((180 + this.panorama.getPov().heading) % 360) *
                (Math.PI / 180), //180 * (Math.PI/180),
              scale: 0.05,
            }),
          })
        );

        this.aggiungiFeature(this.tmpLayer, this.pointFeatureStreetView);
      }
    );

    //Listener per il cambio di orientamento dentro SV (mi giro destra/sinistra)
    this.povChangedPanoramaListener = google.maps.event.addListener(
      this.panorama,
      "pov_changed",
      () => {
        // console.log("addListener pov_changed");
        //Ruoto il marker sulla mappa
        if (this.pointFeatureStreetView != null) {
          (this.pointFeatureStreetView.getStyle() as Style)
            .getImage()
            .setRotation(
              ((180 + this.panorama.getPov().heading) % 360) * (Math.PI / 180)
            );
          this.tmpLayer.changed();
        }
      }
    );
    this.mapGoogle.setStreetView(this.panorama);
  }

  adjust_stretview_position = () => {
    //Questa funzione è registrata per ogni spostamento della mappa,
    //che può avvenire sia se manuale che dentro container SV
    let center_3857 = this.mappaOpenLayer.getView().getCenter();
    let center_4326 = transform(center_3857, "EPSG:3857", "EPSG:4326");
    let radius = 20;

    if (this.streetViewService == undefined) return;

    this.streetViewService.getPanorama(
      {
        location: new google.maps.LatLng(center_4326[1], center_4326[0]),
        radius: radius,
      },
      this.processSVData
    );
  };

  processSVData = (data, status) => {
    if (status == google.maps.StreetViewStatus.OK) {
      //Ho dei dati validi
      this.panorama.setVisible(true);
      this.panorama.setPano(data.location.pano);
      this.panorama.setPov({
        heading: this.panorama.getPov().heading, //mantengo precedente orientazione
        pitch: 0,
      });

      var lat = data.location.latLng.lat();
      var lng = data.location.latLng.lng();

      if (this.pointFeatureStreetView != null) {
        var streetViewPosition = transform(
          [lng, lat],
          "EPSG:4326",
          "EPSG:3857"
        );
        this.pointFeatureStreetView.setGeometry(new Point(streetViewPosition));
        this.tmpLayer.changed();
      }
      // if ($('.error-container').data('type') == 'streetview') {
      //         JSMain.hideError();
      //     }
      this.noPanorama = false;
    } else {
      //NON ho dei dati validi
      // conferma
      //
      this.panorama.setVisible(false);
      console.log("------------> fuori strada");
      this.noPanorama = true;
      // JSMain.showError('streetview', messaggi["no_SV_data"]);
    }
  };

  streetViewOpen() {
    this.initializeStreetView();
    // aggiunta per la versione OL4
    // in OL4 servono due div (gmap4, map4) impostati in posizione fixed (0,0)
    // quando si aggiunge lo streetview bisogna spostare il div map4 nella posizione corretta

    this.pointFeatureStreetView.setGeometry(
      new Point(this.mappaOpenLayer.getView().getCenter())
    );
    this.pointFeatureStreetView.setStyle(
      new Style({
        image: new Icon({
          src: "assets/icons/arrow.png",
          rotation: 180 * (Math.PI / 180),
          scale: 0.05,
        }),
      })
    );
    this.aggiungiFeature(this.tmpLayer, this.pointFeatureStreetView);
    console.log("***POINT FEATURE ADDED");

    // per centramento mappa con i ristoranti (POI)
    this.mappaOpenLayer
      .getView()
      .setCenter(this.mappaOpenLayer.getView().getCenter());
    this.mappaOpenLayer
      .getView()
      .setZoom(this.mappaOpenLayer.getView().getZoom());

    this.mappaOpenLayer.on(
      "moveend",
      this.adjust_stretview_position.bind(this)
    );
    this.adjust_stretview_position();
  }

  /**
   * Ricerca gli elementi nelle url richieste e li aggiunge agli oggetti selezionati
   * @param   ricerca oggetto formato da un array di url da invocare e una geometria all'interno della quale devono risiedere gli oggetti da selezionare
   */
  ricercaOggettiInUrl(ricerca) {
    //   console.log("MapComponent ricercaOggettiInUrl: ", ricerca);
    const wktString = new WKT().writeGeometry(ricerca.geometry);

    var http_options = {
      headers: new HttpHeaders({
        Authorization: "bearer " + this.mapUtilsService.getToken(),
      }),
    };
    // TODO filtrare anche url !== undefined
    // TO DO : use map-utils.service
    ricerca.location
      .filter((url) => url.length > 0)
      .forEach((url) => {
        this.http.post(url, { geometry: wktString }, http_options).subscribe(
          (data: any) => {
            // TODO gestire geometrie complesse
            new GeoJSON()
              .readFeatures(data, {
                dataProjection: MapService.EPSG_3857_KEY,
                featureProjection: MapService.EPSG_3857_KEY,
              })
              .forEach((feature) => {
                // TODO gestire stile personalizzato
                this.aggiungiOggettoSelezionato(feature);
                this.aggiungiFeature(this.layerSelezione, feature);
              });
          },
          (err: any) => {
            console.log(err);
          },
          () => {
            console.log("HTTP Observable unsuscribed");
          }
        );
      });
  }
  /**
   * Ricerca gli oggetti tra i layers locali
   * @param   ricerca oggetto composto da layers (layers locali su cui cercare) e geometry (geometria sula quale effettuare l'intersect di selezione)
   */
  ricercaOggettiInLayersLocali(ricerca) {
    //  console.log("MapComponent ricercaOggettiInLayersLocali: ", ricerca);
    // console.log("_______DENTRO ricercaOggettiInLayersLocali");
    const selectionExtent = ricerca.geometry.getExtent();
    this.layers
      .filter((layer) => layer.get(MapService.TIPO_LAYER_KEY) === "VECTOR")
      .filter((layer)=>layer.getVisible())
      .forEach((layer) => {
        ((layer as Layer).getSource() as VectorSource).forEachFeatureInExtent(
          selectionExtent,
          function (feature) {
            const popupTemplate = layer.get(MapService.POPUP_TEMPLATE_KEY);
            if(!!popupTemplate){
              const coord = feature.getGeometry().getCoordinates();
              const prop = feature.getProperties();
              let header= popupTemplate.header.value;
              let body= popupTemplate.body.value;
              popupTemplate.header.keys.forEach(key =>{
                const regex = new RegExp(`${key}`);
                header= header.replace(regex, prop[key]);
              });
              popupTemplate.body.keys.forEach(key =>{
                const regex = new RegExp(`${key}`);
                body= body.replace(regex, prop[key]);
              });
              this.popupHTML = body;
              
              this.showPopup(coord,header,this.popupContent);

            } else {
              this.aggiungiOggettoSelezionato(feature);
              this.aggiungiFeature(this.layerSelezione, feature);
            }
          }.bind(this)
        );
      });
  }
  /**
   * Oggetto da aggiungere a quelli selezionati
   * @param   oggetto feature da rimuovere
   */
  aggiungiOggettoSelezionato(oggetto: Feature) {
    this.oggettiSelezionati.push(oggetto);
  }
  /**
   * Rimuove un oggetto da quelli selezionati
   * @param   oggetto feature da rimuovere
   */
  rimuoviOggettoSelezionato(oggetto: Feature) {
    this.oggettiSelezionati.splice(this.oggettiSelezionati.indexOf(oggetto), 1);
  }
  /**
   * Istanzia le interazioni e i subscribe per il disegno e le sue trasformazioni
   */
  istanziaDisegno() {
    this.layerDisegno = new Vector({
      source: new VectorSource(),
      visible: true,
      zIndex: 999,
    });
    this.layerDisegno.set(MapService.TITOLO_LAYER_KEY, "temporal_draw");
    this.layerDisegno.set(MapService.TIPO_LAYER_KEY, TipoLayer.TEMP);
    this.layerDisegno.set(
      MapService.POSIZIONE_LAYER_KEY,
      PosizioneLayer.OVERLAY
    );

    this.aggiungiLayerAllaMappa(this.layerDisegno);

    this.collectionFeatureSelezionate.on(
      "add",
      function (feature) {
        // TODO rivedere con la gestione di annulla e undo
        this.selectedFeature = feature.element;
        this.backupFeature = feature.element.clone();
        this.backupFeature.setId(this.selectedFeature.getId());
      }.bind(this)
    );

    this.servizioMappa.interruttoreInterazioneDisegno$.subscribe((element) => {
      element.style =
        element.style !== undefined && element.style !== ""
          ? element.style
          : this.stileDisegno;
      element.layer =
        element.layer !== undefined && element.layer !== ""
          ? element.layer
          : this.layerDisegno;
      if (this.interazioneDisegnoAttiva && element.flag) {
        // Posso cambiare tool perché non ci sono features nel layer di precisione, cioè non ho ancora iniziato a disegnare o non ho lasciato disegni in sospeso
        return;
      } else {
        if (element.flag) {
          this.servizioMappa.interruttoreInterazioneSelezione$.next(false);
          this.interazioneDisegnoAttiva = true;
          this.tipoGeometriaDisegno = element.geometry;
          if (
            !this.AVAILABLE_GEOMETRIES.some(
              (item) => item.k === this.tipoGeometriaDisegno
            )
          ) {
            alert("Tipologia geometria non disponibile");
          } else {
            this.interazioneDisegno = new Draw({
              type: this.tipoGeometriaDisegno,
            });

            this.aggiungiInterazione(this.interazioneDisegno);

            this.interazioneDisegno.on(
              "drawend",
              function (drawendEvent) {
                const feature = drawendEvent.feature;
                // var style = new Style({
                //    image: new RegularShape({
                //       fill: new Fill({
                //          color: [0,255,0,1]
                //       }),
                //       stroke: new Stroke({
                //          color: [255,0,0,1],
                //          width: 5
                //       }),
                //       radius: 5,
                //       points: 80
                //    }),
                //    fill: new Fill({
                //       color: [0,255,0,1]
                //    }),
                //    stroke: new Stroke({
                //       color: [255,0,0,1],
                //       width: 2
                //    }),
                // })
                feature.setStyle(
                  this.servizioMappa.buildFeatureStyle(element.style)
                );
                this.aggiungiFeature(element.layer, feature);
              }.bind(this)
            );
          }
        } else {
          this.interazioneDisegnoAttiva = false;
          this.servizioMappa.interruttoreInterazioneSelezione$.next(true);
          this.rimuoviInterazione(this.interazioneDisegno);
        }
      }
    });

    this.servizioMappa.inserimentoFeaturesDaGeojson$.subscribe((obj) => {
      this.inserisciFeaureDaGeojson(obj.geojson, obj.layer);
    });
  }

  /**
   * Inserisce le feature lette dal geojson sul layer specificato
   */
  inserisciFeaureDaGeojson(geojson: {}, layer: BaseLayer) {
    // TODO gestire geometrie complesse
    new GeoJSON()
      .readFeatures(geojson, {
        dataProjection: MapService.EPSG_3857_KEY,
        featureProjection: MapService.EPSG_3857_KEY,
      })
      .forEach((feature) => {
        if (feature.getProperties().styleId) {
          const st = this.servizioMappa.buildFeatureStyle(
            feature.getProperties().styleId
          );
          feature.setStyle(st);
        }
        // TODO gestire stile personalizzato
        this.aggiungiFeature(layer, feature);
      });
  }
  /**
   * Evento chiusura oggetto selezionato
   * @param   obj Oggetto selezionato
   */
  closeSelectedObject(obj) {
    // console.log('mappa --> close', obj);
    this.chiusuraOggettoSelezionatoEmitter.emit(obj);
  }
  /**
   * Evento modifica oggetto selezionato
   * @param   obj Oggetto selezionato
   */
  modifySelectedObject(obj) {
    // console.log('mappa --> modify', obj);
    this.modificaOggettoSelezionatoEmitter.emit(obj);
  }
  /**
   * Evento info oggetto selezionato
   * @param   obj Oggetto selezionato
   */
  viewInfoSelectedObject(obj) {
    // console.log('mappa --> info', obj);
    this.visualizzaInfoOggettoSelezionatoEmitter.emit(obj);
  }
  /**
   * Gestione rappresentazione marker indirizzo selezionato
   * @param   event Oggetto contenente informazioni indirizzo, localizzazione e extent
   */
  gestioneSelezioneRicercaGoogle(event) {
    this.rimuoviFeatureFromTag(this.tmpLayer, "marker");
    if (this._iconaIndirizzoSelezionato !== undefined) {
      const marker = new Feature({
        geometry: new Point(event.location),
      });

      marker.setStyle(
        new Style({
          image: new Icon({
            anchor: [0.5, 1],
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.FRACTION,
            src: this._iconaIndirizzoSelezionato,
          }),
        })
      );

      marker.set(MapService.FEATURE_TAG_KEY, "marker");
      this.aggiungiFeature(this.tmpLayer, marker);

      this.mappaOpenLayer.getView().fit(event.extent);
    } else {
      console.log(
        "Nessun percorso per l'icona per la selezione dell'indirizzo"
      );
    }
  }
  /**
   * Toggle per la gestione dello stato della sidebar
   */
  toggleSidebar() {
    this.menuState = this.menuState === "out" ? "in" : "out";
    this.sidebarOpened = !this.sidebarOpened;
  }

  centraMappaAllaCoordinata(coord: number[]) {
    // console.log('Centro mappa alla coordinata', coord);

    const posizione = new Feature({
      geometry: new Point(coord),
    });
    posizione.setStyle(
      new Style({
        image: new Icon({
          anchor: [0.5, 1],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          src: this.configMappa.centraMappa.icon,
        }),
      })
    );
    posizione.set(MapService.FEATURE_TAG_KEY, "localize");
    this.aggiungiFeature(this.tmpLayer, posizione);

    this.mappaOpenLayer.getView().setCenter(coord);
    this.mappaOpenLayer.getView().setZoom(this.configMappa.centraMappa.zoom);
    setTimeout(() => {
      this.rimuoviFeatureFromTag(this.tmpLayer, "localize");
      this.rimuoviFeatureFromTag(this.tmpLayer, "accuracy");
    }, this.configMappa.centraMappa.timeout);
  }

  gestisciPannelloTrasformazioneCoordinate(event) {
    this.toggleSidebar();
    this.pannelliSidebar.trasforma_coord = event;
    // console.log('disegno', this.pannelliSidebar.disegno, 'trasforma', this.pannelliSidebar.trasforma_coord);
  }

  gestisciPannelloRicercaGeometrica(event) {
    this.toggleSidebar();
    this.pannelliSidebar.ricerca_geometrica = event;

    /** Chiusura pannello ricerca geometrica */
    /** Pulisco il layer disegno quando chiudo il tool ricerca geometrica */
    if (!this.pannelliSidebar.ricerca_geometrica) {
      // if(!!evt && evt.type == "ricerca_geometrica"){
      // PULISCI LAYER
      // this.layerDisegno.getSource().clear();
      //}

      this.pulisciLayer("layerDisegno");

      /** Pulisco i riferimenti alla geometria eventualmente inserita */
      this.featureToAddAfterToolDisegnoInit = undefined;
      this.objectToAddAfterToolDisegnoInit = undefined;
      this.objectTypeToAddAfterToolDisegnoInit = undefined;

      /** Chiusura tool di disegno */
      if (this.pannelliSidebar.disegno) {
        this.pannelliSidebar.disegno = false;
      }
    }
  }

  /** Apertura tool aggancia punto
   * event può essere in uno dei seguenti formati
   * { toolAgganciaPuntoAttivo: true|false|undefined }
   *
   * { toolAgganciaPuntoAttivo: true|false|undefined
   *   precisionCounterPlaceholder: number }
   */
  gestisciToolAgganciaPunto(event) {
    /** undefined */
    if (
      typeof event === "undefined" ||
      typeof event.aggancia_punto === "undefined"
    ) {
      this.pannelliSidebar.aggancia_punto = event;
    } else if (!event.aggancia_punto) {
      this.pannelliSidebar.aggancia_punto = false;

      /** Quando chiudo il pannello aggancia punto da tasto 'Chiudi' o X devo mettere a false
       * la proprietà this.statoToolAgganciaPunto di controlli-mappa.component
       */
      if (!!this.controlliMappa) {
        this.controlliMappa.statoToolAgganciaPunto = false;
      }
    } else if (event.aggancia_punto) {
      this.pannelliSidebar.aggancia_punto = true;
    }

    if (this.pannelliSidebar.aggancia_punto) {
      this.servizioMappa.interruttoreInterazioneSelezione$.next(false);
      this.displayPcMap = true;
    } else {
      this.servizioMappa.interruttoreInterazioneSelezione$.next(true);
      this.displayPcMap = false;
    }

    // Memorizzo il placeholder nella mappa, posizione in cui andrà inserita la feature restituita in gestisciPuntoAgganciato
    if (!!event) {
      this.precisionCounterPlaceholder = event.precisionCounterPlaceholder;
    }
  }

  /** Gestione del punto agganciato al vertice */
  gestisciPuntoAgganciato(featureAP) {
    /** Reimpostare visualizzazione pannello coordinate */
    this.displayPcMap = false;

    /** Disabilitare disegno agganciaPunto (Già fatto nella chiusura del tool??? ) SI */

    /** Abilitare disegno toolDisegno */
    this.toolDisegno.riabilitaDopoAgganciaPunto(
      this.precisionCounterPlaceholder,
      featureAP
    );

    /** Reimposto il placeholder ad undefined */
    this.precisionCounterPlaceholder = undefined;
  }

  /** Gestione dei riferimenti catastali della particella */
  gestisciRiferimentiCatastali(obj) {
    console.log(
      "MAP - TOOL PARTICELLA - Riferimenti catastali particella",
      obj
    );
  }

  /**
   * Se il tool di disegno è stato aperto tramite la selezione di un elemento della lista
   * (es. oggetti delle variazioni geometriche, occupazioni, ecc..), la feature associata a tale
   * elemento deve essere aggiunta al layer, solo quando il tool di disegno ha eseguito l'inizializzazione
   * del layer stesso (precisionLayer)
   */

  gestisciInserimentoFeaturePrecaricata(event: Event) {
    /**
     * In generale, quando il tool di disegno è stato inizializzato,
     * Se presente abilito il tool di default
     */

    if (!!this.toolbarDefaultSelectedTool) {
      this.toolDisegno.toolbarForm.controls.disegno.setValue(
        this.toolbarDefaultSelectedTool
      );
    }

    /**
     * Nel caso della ricerca geometrica devo disabilitare alcuni tools
     * dalla toolbar del tool di disegno
     */

    if (this.objectTypeToAddAfterToolDisegnoInit == "ricerca_geometrica") {
      this.toolDisegno.enabledTools = {
        point: true,
        line: false,
        polygon: true,
        circle: true,
        particella: false,
      };
    }

    if (!!this.toolDisegnoCloseAll) {
      this.toolDisegno.closeAll = this.toolDisegnoCloseAll;
    }

    /** Se presenti imposto i dati extra che devono essere passati */
    if (!!this.objectDataToolDisegno) {
      this.toolDisegno.currentData = this.objectDataToolDisegno;
    }

    //GB
    // console.log("featureToAddAfterToolDisegnoInit", this.featureToAddAfterToolDisegnoInit)
    // console.log("objectToAddAfterToolDisegnoInit", this.objectToAddAfterToolDisegnoInit)
    // console.log("objectTypeToAddAfterToolDisegnoInit", this.objectTypeToAddAfterToolDisegnoInit)

    /** Geometria oggetto presente nel DB e precaricata nel layer */
    if (
      !!this.featureToAddAfterToolDisegnoInit &&
      !!this.objectToAddAfterToolDisegnoInit
    ) {
      this.toolDisegno.selectedFeature = this.featureToAddAfterToolDisegnoInit;
      this.toolDisegno.currentObj = this.objectToAddAfterToolDisegnoInit;
      this.toolDisegno.currentObjType = this.objectTypeToAddAfterToolDisegnoInit;

      this.toolDisegno.visibilitaBtnElimina = false;
      this.toolDisegno.visibilitaBtnAnnulla = true;

      this.toolDisegno.editFeaturePrecision();
    } else if (!!this.objectToAddAfterToolDisegnoInit) {
      this.toolDisegno.currentObj = this.objectToAddAfterToolDisegnoInit;
      this.toolDisegno.currentObjType = this.objectTypeToAddAfterToolDisegnoInit;
      this.toolDisegno.visibilitaBtnElimina = false;
      this.toolDisegno.visibilitaBtnAnnulla = true;

      this.toolDisegno.editFeaturePrecision();
    } else {
      this.toolDisegno.currentObjType = this.objectTypeToAddAfterToolDisegnoInit;
      this.toolDisegno.visibilitaBtnElimina = false;
      this.toolDisegno.visibilitaBtnAnnulla = true;
    }
  }

  /** Cliccato bottone Reset in ricerca geometrica */
  resetEmitted() {
    this.featureToAddAfterToolDisegnoInit = undefined;
    this.objectToAddAfterToolDisegnoInit = undefined;
    this.objectTypeToAddAfterToolDisegnoInit = undefined;

    ((this.layerDisegno as Layer).getSource() as VectorSource).clear();
  }

  /** Apertura tool di disegno dal pannello di ricerca geometrica */
  apriToolDisegno(obj) {
    this.gestisciToolDisegno(obj, "ricerca_geometrica", undefined, true);
  }

  /** Apertura Legenda */
  gestisciLegenda(event) {
    this.toggleSidebar();
    this.pannelliSidebar.legenda = event;
    // console.log('legenda', this.pannelliSidebar.legenda);
  }

  /**
   * Apertura del tool di disegno
   * @param obj Oggetto da gestire (ingiunzione, occupazione, vincoli, ecc..) o evento (booleano) di apertura/chiusura del pannello (controlli mappa)
   * @param type Identifica il tipo di oggetto passato, ad esempio: Occupazione, Ingiunzione, Oggetto, ecc..
   * @param defaultTool Parametro opzionale che indica se il tool di disegno deve essere aperto con un strumento preselezionato
   * @param closeAll Parametro opzionale che indica se i pulsanti 'Annulla' e 'X' devono chiudere sia il pannello delle coordinate che la toolbar
   * @param data
   */
  gestisciToolDisegno(obj, type = "", defaultTool?, closeAll?, data?) {
    /**
     * Se è stato passato un oggetto (Ad esempio nel caso della modifica di un oggetto nella funzionalità Variazioni Geometriche delle pratiche),
     * il tool di disegno va aperto passandogli tale oggetto
     */
    if (!!obj && typeof obj === "object" && !!type) {
      switch (type) {
        // Ricerca geometrica
        case "ricerca_geometrica":
          if (!!obj.geom) {
            var feature;

            if (obj.geom.type == "Circle") {
              feature = new Feature({
                geometry: new Circle(obj.geom.coordinates, obj.geom.radius),
              });
            } else {
              feature = new Feature({
                geometry: new GeoJSON().readGeometry(obj.geom, {
                  dataProjection: MapService.EPSG_3857_KEY,
                  featureProjection: MapService.EPSG_3857_KEY,
                }),
              });
            }

            // AGGIUNGI FEATURE
            // Nel caso della ricerca geometrica le feature non sono caricate preventivamente, quindi bisogna farlo qui
            this.featureToAddAfterToolDisegnoInit = feature;

            this.featureToAddAfterToolDisegnoInit.setStyle(
              this.servizioMappa.buildFeatureStyle("BlueFill")
            );

            var id = Math.random().toString(36).substr(2, 9);
            this.featureToAddAfterToolDisegnoInit.setId(id);

            this.aggiungiFeature(
              this.layerDisegno,
              this.featureToAddAfterToolDisegnoInit
            );
          } else {
            this.featureToAddAfterToolDisegnoInit = undefined;
          }

          this.objectToAddAfterToolDisegnoInit = obj;
          this.objectTypeToAddAfterToolDisegnoInit = type;

          // console.log("featureToAddAfterToolDisegnoInit", this.featureToAddAfterToolDisegnoInit)
          // console.log("objectToAddAfterToolDisegnoInit", this.objectToAddAfterToolDisegnoInit)
          // console.log("objectTypeToAddAfterToolDisegnoInit", this.objectTypeToAddAfterToolDisegnoInit)
          break;
      }

      this.pannelliSidebar.disegno = true;
    }

    if (!!data && !this.featureToAddAfterToolDisegnoInit) {
      this.objectDataToolDisegno = data;

      /** Per inserire il nome della geometria nel caso della riconsegna */
      let tmp;
      if (type == "consegna") {
        tmp = this.objectToAddAfterToolDisegnoInit;
      }

      this.featureToAddAfterToolDisegnoInit = ((this
        .layerDisegno as Layer).getSource() as VectorSource)
        .getFeatures()
        .find((el) => el.getId() === data.atto_id);
      this.objectToAddAfterToolDisegnoInit = data;
      this.objectTypeToAddAfterToolDisegnoInit = type;

      /** Per inserire il nome della geometria nel caso della riconsegna */
      if (type == "consegna" && !!tmp) {
        this.objectToAddAfterToolDisegnoInit.numero = tmp.numero;
        this.objectToAddAfterToolDisegnoInit.anno = tmp.anno;
      }
    }

    if (!!type) {
      this.objectTypeToAddAfterToolDisegnoInit = type;
    }

    // Per preselezionare un tool di disegno
    if (!!defaultTool) {
      this.toolbarDefaultSelectedTool = defaultTool;
    }

    // Per far si che i pulsanti 'Annulla' e 'X' chiudano sia il pannello delle coordinate che la toolbar del tool di disegno
    if (!!closeAll) {
      this.toolDisegnoCloseAll = closeAll;
    }

    /** Anche se l'oggetto è undefined devo consentire l'apertura del tool di disegno per fare un nuovo inserimento */
    if (!!!obj && !!type) {
      this.pannelliSidebar.disegno = true;
    }

    if (this.pannelliSidebar.disegno) {
      this.servizioMappa.interruttoreInterazioneSelezione$.next(false);
    } else {
      this.servizioMappa.interruttoreInterazioneSelezione$.next(true);
    }

    this.cdRef.detectChanges();
  }

  /** Metodo usato per calcolare la superficie di una geometria */
  calcolaSuperficie(geom) {
    const tmp = new GeoJSON().readGeometry(geom, {
      dataProjection: MapService.EPSG_3857_KEY,
      featureProjection: MapService.EPSG_3857_KEY,
    });

    const feat = new Feature({ geometry: tmp });

    const sup = (feat.getGeometry() as Polygon).getArea();

    return sup;
  }

  /** Per evidenziare la geometria delle particelle sulla mappa (riconsegna totale e parziale) */
  tematizzaArea(geoms) {
    /** Pulisco il layer */
    this.pulisciLayer("layerParticelle");

    /** Aggiungo le geometrie al layer */
    geoms.forEach((geom) => {
      const olGeom = new GeoJSON().readGeometry(geom.geom, {
        dataProjection: MapService.EPSG_3857_KEY,
        featureProjection: MapService.EPSG_3857_KEY,
      });

      const feat = new Feature({
        geometry: olGeom,
      });
      feat.setId(geom.id);
      feat.setStyle(this.servizioMappa.buildFeatureStyle("OrangeFill")); // Colore che non c'è, guarda se possono commentare + sposta

      this.aggiungiFeature(this.layerParticelle, feat);
    });
  }

  /** Per ripulire un layer */
  pulisciLayer(layerName, reload = false) {
    switch (layerName) {
      case "layerParticelle":
        ((this.layerParticelle as Layer).getSource() as VectorSource).clear();
        break;
      case "layerDisegno":
        ((this.layerDisegno as Layer).getSource() as VectorSource).clear();
        break;
      default:
        const layer = this.layers.find(
          (l) => l.get(MapService.TITOLO_LAYER_KEY) === layerName
        );
        if (!!layer && layer.get(MapService.TIPO_LAYER_KEY) === "VECTOR") {
          if (reload) {
            ((layer as Layer).getSource() as VectorSource).clear();
          } else {
            const layerFeatures = ((layer as Layer).getSource() as VectorSource).getFeatures();
            layerFeatures.forEach((f) => {
              ((layer as Layer).getSource() as VectorSource).removeFeature(f);
            });
          }
        }
        break;
    }
  }

  /** Inizializzazione del layer particelle */
  istanziaLayerParticelle() {
    this.layerParticelle = new Vector({
      source: new VectorSource(),
      visible: true,
      zIndex: 998,
    });

    this.layerParticelle.set(
      MapService.TITOLO_LAYER_KEY,
      "evidenzia_particelle"
    );
    this.layerParticelle.set(MapService.TIPO_LAYER_KEY, TipoLayer.TEMP);
    this.layerParticelle.set(
      MapService.POSIZIONE_LAYER_KEY,
      PosizioneLayer.OVERLAY
    );

    this.aggiungiLayerAllaMappa(this.layerParticelle);
    this.cambiaVisibilitaLayer(this.layerParticelle, true);
  }

  /** Funzione che emette l'elemento che è stato inserito e confermato con il tool di disegno  */
  emettiElementoConfermato(evt) {
    /** Devo gestire l'elemento confermato dal tool di disegno nel componente RicercaGeometricaComponent */
    if (evt.type == "ricerca_geometrica") {
      this.ricercaGeometrica.elementoConfermatoObj = evt;
      // PULISCI LAYER
      // GB this.layerDisegno.getSource().clear();

      // Disabilito la selezione delle geometrie
      this.editModes["select"].deactivate();
    } else {
      /** Emetto l'elemento confermato al componente esterno MappaComponent */
      this.toolDisegnoElementoConfermatoMapEmitter.emit(evt);
    }
  }

  /** Disegna le geometrie caricate da CSV in ricerca-geometrica.component */
  disegnaGeometrie(features) {
    // console.log("disegnaGeometrie --->", features)

    features.forEach((obj) => {
      let f;

      if (obj.geom.type == "Circle") {
        f = new Feature({
          geometry: new Circle(obj.geom.coordinates, obj.geom.radius),
        });
      } else {
        f = new Feature({
          geometry: new GeoJSON().readGeometry(obj.geom, {
            dataProjection: MapService.EPSG_3857_KEY,
            featureProjection: MapService.EPSG_3857_KEY,
          }),
        });
      }

      f.setStyle(this.servizioMappa.buildFeatureStyle("BlueFill"));

      var id = Math.random().toString(36).substr(2, 9);
      f.setId(id);

      this.aggiungiFeature(this.layerDisegno, f);
    });

    //console.log("ADDED FEATURES --> ", this.layerDisegno.getSource().getFeatures());

    // Disabilito la selezione delle geometrie
    this.editModes["select"].deactivate();
  }
  /**
   * Metodo eseguito dopo che è stata inserita / modificata una geometria con il tool di disegno
   * Richiama la funzionalità per chiudere il tool di disegno ed emette l'evento per visualizzare gli
   * oggetti sulla mappa
   * @param evt {type: this.currentObjType}
   */
  gestisciChiudiToolDisegno(evt) {
    this.pannelliSidebar.disegno = false;

    // if(!!evt && evt.type == "ricerca_geometrica"){
    // PULISCI LAYER
    // this.layerDisegno.getSource().clear();
    //}
    this.chiudiToolDisegnoMapEmitter.emit(evt);
  }

  addPrecisionLayer(layer: BaseLayer) {
    if (!this.mappaOpenLayer.getLayers().getArray().includes(layer)) {
      this.aggiungiLayerAllaMappa(layer);
    }
  }

  removePrecisionLayer(layer: BaseLayer) {
    this.mappaOpenLayer.removeLayer(layer);
  }

  insertOverlay(overlay: Overlay) {
    this.mappaOpenLayer.addOverlay(overlay);
  }

  deleteOverlay(overlay: Overlay) {
    // console.log("------> DELETE TOOLTIP")
    this.mappaOpenLayer.removeOverlay(overlay);
  }

  /**********Gestione interazioni disegno**********/

  editModes: {};
  changesHistory: HistoryList;
  selectionDrillDownCounter = 0;
  selectionDrillDownCoordinate: number[];
  selectedFeatureForTransformation: Feature;
  triggerSelectedFeature: number = 0;

  /** TD = Tool Disegno */
  featuresAtPixelTD: Feature[];
  triggerfeaturesAtPixelTD: number = 0;

  /**
   * Richiamata all'inizializzazione del componente per istanziare le interazioni
   */
  setEditModes() {
    this.changesHistory = new HistoryList(this.layerDisegno);
    this.editModes = {
      select: new SelectMode(this.mappaOpenLayer, this.layerDisegno, this),
      translate: new TranslateMode(
        this.mappaOpenLayer,
        this.layerDisegno,
        this
      ),
      rotate: new RotateMode(this.mappaOpenLayer, this.layerDisegno, this),
      scale: new ScaleMode(this.mappaOpenLayer, this.layerDisegno, this),
    };
  }

  pulisciTutto() {
    this.featuresAtPixelTD = [];
    this.changesHistory = new HistoryList(this.layerDisegno);
  }
  /**
   * Aggiorna la modalità di modifica.
   * @param   selectedEditModeKey modalità da attivare
   */
  changeEditMode(selectedEditModeKey: string) {
    // se esiste la modalità passata
    if (!!this.editModes[selectedEditModeKey]) {
      this.closeEditMode();

      // poi attivo solo quella selezionata
      this.editModes[selectedEditModeKey].activate();
    }

    if (selectedEditModeKey === "none") {
      this.closeEditMode();
    }
  }

  /**
   * Disattiva tutte le modalità
   */
  closeEditMode() {
    // disattivo tutte le modalità
    for (const modeKey in this.editModes) {
      if (this.editModes.hasOwnProperty(modeKey)) {
        this.editModes[modeKey].deactivate();
      }
    }
  }

  /**
   * Aggiunge un'elemento "feature modificata" alla storia delle modifiche.
   * @param   activeFeature   feature modificata
   * @param   originalFeature feature originale
   */
  setAlteredInHistory(activeFeature: Feature, originalFeature: Feature) {
    // console.log('MAP : setAlteredInHistory');
    this.changesHistory.setAltered(activeFeature, originalFeature);
  }

  /**
   * Metodo richiamato dal pannello che wrappa la chiamata a 'setAltered'
   * @param   obj     oggetto contenente la feature originale e quella modificata
   */
  setAlteredFromPanel(obj: { original: Feature; altered: Feature }) {
    // console.log('MAP : setAlteredFromPanel');
    this.setAlteredInHistory(obj.altered, obj.original);
  }

  /**
   * Aggiunge un'elemento "feature inserita" alla storia delle modifiche
   * @param   insertedFeature feature aggiunta
   */
  setInsertedInHistory(insertedFeature: Feature) {
    // console.log('MAP : setInsertedInHistory');
    this.changesHistory.setInserted(insertedFeature);
  }

  /**
   * Metodo richiamato dal pannello che wrappa la chiamata a 'setAltered'
   * @param   obj oggetto contenente la feature aggiunta
   */
  setInsertedFromPanel(obj: { altered: Feature }) {
    // console.log('MAP : setInsertedFromPanel');
    this.setInsertedInHistory(obj.altered);
  }

  setDeletedInHistory(deletedFeature: Feature) {
    // console.log('MAP : setDeletedInHistory');
    this.changesHistory.setDeleted(deletedFeature);
  }

  setDeletedFromPanel(obj: { original: Feature }) {
    // console.log('MAP : setDeletedFromPanel');
    this.setDeletedInHistory(obj.original);
  }

  selectedFeatureForTransformationFromPanel(feature: Feature) {
    if (!!this.selectedFeatureForTransformation) {
      // ripristino il colore della feature che era selezionata
      this.selectedFeatureForTransformation.setStyle(this.stileDisegno);
    }

    feature.setStyle(this.stileSelezione);
    this.selectedFeatureForTransformation = feature;
  }

  /**
   * Compara le coordinate dei pixel per verificare se due punti coincidono
   * @param   p1 coordinate pixel
   * @param   p2 coordinate pixel
   * @returns   true se le due coordinate coincidono
   */
  comparePixelsForDrillDown(p1: number[], p2: number[]) {
    return p1[0] === p2[0] && p1[1] === p2[1];
  }

  /**
   * Wrapper che richiama la funzione undo nella storia delle modifiche
   */
  handleUndo(evt) {
    // resetto lo stile di default
    // if (this.selectedFeatureForTransformation != null) {
    //    this.selectedFeatureForTransformation.setStyle(this.stileDisegno);
    // }

    // recupero la feature da ripristinare e se valida setto lo stile di selezione
    const restoredFeature = this.changesHistory.undo();

    // this.selectedFeatureForTransformation = restoredFeature;

    if (!!restoredFeature) {
      /**
       * Dopo aver fatto l'undo, viene ripristinata la geometria ma con il nuovo tool
       * di disegno non voglio che resti selezionata
       */
      restoredFeature.setStyle(this.stileDisegno);
    }
  }

  /**
   * Deselezione della feature selezionata
   */
  handleDeselectFeature(evt) {
    this.selectedFeatureForTransformation = null;
  }

  /**
   * Wrapper che richiama la funzione cancel per pulire la storia delle modifiche
   */
  handleCancel(evt) {
    this.changesHistory.cancel();
    this.selectedFeatureForTransformation = null;
  }

  /**********Gestione interazioni disegno**********/

  gestisciPannelloSceltaRappresentazione(event) {
    this.toggleSidebar();
    this.pannelliSidebar.scelta_rappresentazione = event;
  }

  /* Aggiorna la visibilità dei layer in base alle scelte impostate nel pannello scelta rappresentazione */
  layerAggiornati(event) {
    event.forEach((sessionLayer) => {
      this.mappaOpenLayer
        .getLayers()
        .getArray()
        .filter(
          (layer: BaseLayer) =>
            layer.getProperties()[MapService.TITOLO_LAYER_KEY] ===
            sessionLayer.layer
        )
        .forEach((baseLayer: BaseLayer) =>
          this.cambiaVisibilitaLayer(baseLayer, sessionLayer.visibility)
        );
    });
    this.servizioMappa.layers = event;
  }
}

class DrawMode {

   map: Map;
   layer: BaseLayer;
   component: MapComponent;

   pointerInteraction: PointerInteraction;

   constructor(map: Map, layer: BaseLayer, component: MapComponent) {
      this.map = map;
      this.layer = layer;
      this.component = component;
      const control = this;
      this.pointerInteraction = new PointerInteraction({
         handleDownEvent(evt) { return control.handleDown(evt); },
         handleUpEvent(evt) { return control.handleUp(evt); },
         handleDragEvent(evt) { return control.handleDrag(evt); },
         handleMoveEvent(evt) { return control.handleMove(evt); }
      });
   }

   handleDown(evt) { return false }

   handleUp(evt) { return false }

   handleDrag(evt) { }

   handleMove(evt) { }

   activate() {
      this.map.addInteraction(this.pointerInteraction);
      this.pointerInteraction.setActive(true);
      return true;
   }

   deactivate() {
      this.pointerInteraction.setActive(false);
      this.map.removeInteraction(this.pointerInteraction);
   }
}

class EditMode extends DrawMode {

   // activeFeature: Feature;

   originalFeature: Feature;

   lastPos: number[];

   lastPixel: number[];

   constructor(map: Map, layer: BaseLayer, component: MapComponent) {
      super(map, layer, component);
      // this.activeFeature = null;
      this.lastPos = null;
      this.originalFeature = null;
   }

   handleDown(evt) {
      // se c'è una feature selezionata
      if (!!this.component.selectedFeatureForTransformation) {
         this.originalFeature = this.component.selectedFeatureForTransformation.clone();
         this.originalFeature.setStyle(this.component.stileDisegno); // reimposto lo stile di default (altrimenti ha quello di selezione)
         this.lastPos = evt.coordinate;
         this.lastPixel = evt.pixel;
         return true;
      }
      return false;
   }

   handleUp(evt) {
      // se la posizione del pixel down è diversa dal pixel up, allora vuol dire che c'è stato un cambiamento
      if (!this.component.comparePixelsForDrillDown(this.lastPixel, evt.pixel)) {
         this.handleActiveFeatureDrop();
         if (!!this.component.selectedFeatureForTransformation) {
            this.component.setAlteredInHistory(this.component.selectedFeatureForTransformation, this.originalFeature);
         }
      }

      // this.activeFeature = null;
      this.lastPos = null;
      return false;
   }

   handleActiveFeatureDrop() { }
}

class SelectMode extends EditMode {

   featuresAtPixel: Feature[];

   constructor(map: Map, layer: BaseLayer, component: MapComponent) {
      super(map, layer, component);
   }

   /** Gestione Click Giù */
   handleDown(evt) {
      // resetto lo stile della feature selezionata (se presente)
      if (!!this.component.selectedFeatureForTransformation) {
         this.component.selectedFeatureForTransformation.setStyle(this.component.stileDisegno);
      }
      this.component.selectedFeatureForTransformation = null;

      // se l'evento Down è in un altro punto rispetto al precedente Up, azzero il drill counter
      if (!!this.lastPixel && !this.component.comparePixelsForDrillDown(this.lastPixel, evt.pixel)) {
         this.component.selectionDrillDownCounter = 0;
      }

      this.component.selectionDrillDownCoordinate = evt.pixel;

      this.featuresAtPixel = [];

      this.map.forEachFeatureAtPixel(evt.pixel, (f: Feature, l: BaseLayer) => {
         if (l.get(MapService.TITOLO_LAYER_KEY) === this.layer.get(MapService.TITOLO_LAYER_KEY)) {
            this.featuresAtPixel.push(f);
         }
      });

      // ATTENZIONE: se il return viene fatto sempre (fuori dall'if) blocca il pan della mappa
      if (this.featuresAtPixel.length > 0) {
         return true;
      }

      return false;

   }

   /** Gestione click su */
   handleUp(evt) {
      this.lastPixel = evt.pixel;

      /** Confronto il pixel di click giù con il pixel di click su */
      if (this.component.comparePixelsForDrillDown(evt.pixel, this.component.selectionDrillDownCoordinate)) {

         const counter = 0;

         /** Se ci sono più features nel pixel selezionato */
         if (this.featuresAtPixel.length > 1) {
            /** Valorizzo l'array del tool di disegno con le features presenti nel pixel */
            this.component.featuresAtPixelTD = this.featuresAtPixel;

            /** Per indicare al tool di disegno che sono presenti più features nel pixel selezionato */
            this.component.triggerfeaturesAtPixelTD++;

            // for (var i = 0; i <= this.featuresAtPixel.length - 1; i++) {
            //    let f = this.featuresAtPixel[i];
            //    // se il counter corrisponde conrollo il layer
            //    if (counter == this.component.selectionDrillDownCounter) {
            //       f.setStyle(this.component.stileSelezione)
            //       this.component.selectedFeatureForTransformation = f;
            //       /** Per indicare al tool di disegno che è stata selezionata una geometria */
            //       this.component.triggerSelectedFeature++;
            //    }
            //    counter++;
            // }
         } else {
            for (let i = 0; i <= this.featuresAtPixel.length - 1; i++) {
               const f = this.featuresAtPixel[0];

               f.setStyle(this.component.stileSelezione);
               this.component.selectedFeatureForTransformation = f;
               /** Per indicare al tool di disegno che è stata selezionata una geometria */
               this.component.triggerSelectedFeature++;
            }

            // stesso pixel
            this.component.selectionDrillDownCounter++;
         }
      } else {
         this.component.selectionDrillDownCounter = 0;
      }
      return false;
   }

}

class TranslateMode extends EditMode {
   handleDrag(evt) {
      if (!!this.component.selectedFeatureForTransformation) {
         const diffLon = evt.coordinate[0] - this.lastPos[0];
         const diffLat = evt.coordinate[1] - this.lastPos[1];

         const geom = this.component.selectedFeatureForTransformation.getGeometry();
         geom.translate(diffLon, diffLat);

         this.lastPos = evt.coordinate;
      }
   }
}

class RotateMode extends EditMode {

   activeExtent: Extent;
   activeCenter: number[];
   srcGeom: Geometry;
   baseAngle: number;

   constructor(map: Map, layer: BaseLayer, component: MapComponent) {
      super(map, layer, component);
      this.activeExtent = null;
      this.activeCenter = null;
      this.srcGeom = null;
      this.baseAngle = null;
   }

   handleDown(evt) {
      const returnable = super.handleDown(evt);
      if (!!this.component.selectedFeatureForTransformation) {
         this.srcGeom = this.component.selectedFeatureForTransformation.getGeometry().clone();
         this.activeExtent = this.srcGeom.getExtent();
         this.activeCenter = getExtentCenter(this.activeExtent);
         this.baseAngle = this.getActiveAngle(evt.coordinate);
      }
      return returnable;
   }

   getActiveAngle(coords: number[]) {
      const distX = coords[0] - this.activeCenter[0];
      const distY = coords[1] - this.activeCenter[1];

      return Math.atan2(distY, distX);
   }

   handleDrag(evt) {
      if (!!this.component.selectedFeatureForTransformation) {
         const anglePointer = this.getActiveAngle(evt.coordinate);
         const angleDiff = anglePointer - this.baseAngle;
         const geom = this.srcGeom.clone();
         geom.rotate(angleDiff, this.activeCenter);
         this.component.selectedFeatureForTransformation.setGeometry(geom);
      }
   }

   handleActiveFeatureDrop() {
      this.activeExtent = null;
      this.activeCenter = null;
      this.srcGeom = null;
      this.baseAngle = null;
   }
}

class ScaleMode extends EditMode {

   activeExtent: Extent;
   activeCenter: number[];
   srcGeom: Geometry;

   constructor(map: Map, layer: BaseLayer, component: MapComponent) {
      super(map, layer, component);
      this.activeExtent = null;
      this.activeCenter = null;
      this.srcGeom = null;
   }

   handleDown(evt) {
      const returnable = super.handleDown(evt);
      if (!!this.component.selectedFeatureForTransformation) {
         this.srcGeom = this.component.selectedFeatureForTransformation.getGeometry().clone();
         this.activeExtent = this.srcGeom.getExtent();
         this.activeCenter = getExtentCenter(this.activeExtent);
      }
      return returnable;
   }

   getActiveCenterDistance(coords: number[]) {
      return Math.sqrt(Math.pow(coords[0] - this.activeCenter[0], 2) + Math.pow(coords[1] - this.activeCenter[1], 2));
   }

   handleDrag(evt) {
      if (!!this.component.selectedFeatureForTransformation) {
         const distPointer = this.getActiveCenterDistance(evt.coordinate);
         const borderpoint = this.srcGeom.getClosestPoint(evt.coordinate);
         const distBorder = this.getActiveCenterDistance(borderpoint);

         const geom = this.srcGeom.clone();
         geom.scale(distPointer / distBorder);
         this.component.selectedFeatureForTransformation.setGeometry(geom);
      }
   }

   handleActiveFeatureDrop() {
      this.activeExtent = null;
      this.activeCenter = null;
      this.srcGeom = null;
   }
}

class HistoryList {

   history: EditOperation[];

   layer: BaseLayer;

   lastOperation: EditOperation;

   constructor(layer: BaseLayer) {
      this.history = [];
      this.layer = layer;
      this.lastOperation = null;
   }

   doStepBack() {
      this.history.pop(); // rimuovo dall'array della storia delle modifiche l'oggetto appena rimosso
      if (this.history.length > 0) {
         this.lastOperation = this.history[this.history.length - 1]; // aggiorno il puntamento di lastOperation
      } else {
         this.lastOperation = null;
      }
   }

   /**
    * Torna all'inizio della storia
    */
   cancel() {
      // per ogni modifica presente nell'array history eseguo undo
      for (let i = this.history.length - 1; i >= 0; i--) {
         this.undo();
      }
   }

   /**
    * Torna un passo indietro
    */
   undo() {

      if (this.lastOperation == null) {
         return;
      }
      switch (this.lastOperation.op) {
         case 'altered':
            // Recupero ultima feature
            const fidAltered = this.lastOperation.originalFeature.getId();
            let currentAltered: Feature;
            if (!!fidAltered) {
               currentAltered = ((this.layer as Layer).getSource() as VectorSource).getFeatureById(fidAltered);
            } else {
               alert('UNDO: Manca identificativo nella feature originale!!!');
               return;
            }
            // Rimuovo ultima feature
            ((this.layer as Layer).getSource() as VectorSource).removeFeature(currentAltered);

            // Recupero feature precedente
            const restoredFeature = this.lastOperation.originalFeature;

            // Aggiungo feature precedente
            ((this.layer as Layer).getSource() as VectorSource).addFeature(restoredFeature);

            this.doStepBack();

            return restoredFeature;
            break;
         case 'inserted':
            const fidInserted = this.lastOperation.alteredFeature.getId();
            let currentInserted: Feature;
            if (!!fidInserted) {
               currentInserted = ((this.layer as Layer).getSource() as VectorSource).getFeatureById(fidInserted);
            } else {
               alert('UNDO: Manca identificativo nella feature inserita!!!');
               return;
            }
            ((this.layer as Layer).getSource() as VectorSource).removeFeature(currentInserted);

            this.doStepBack();
            break;
         case 'deleted':

            const currentDeleted = this.lastOperation.originalFeature;
            ((this.layer as Layer).getSource() as VectorSource).addFeature(currentDeleted);

            this.doStepBack();
            break;
      }
   }

   clearHistory() {
      this.history = [];
      this.lastOperation = null;
   }

   historyChangesSize() {
      return this.history.length;
   }

   setAltered(alteredFeature: Feature, originalFeature: Feature) {
      const fid = alteredFeature.getId();

      if (!!fid) {
         originalFeature.setId(fid);
      } else {
         alert('setAltered: Manca identificativo nella feature modificata!!!');
         return;
      }

      const editOperation = {
         op: 'altered',
         originalFeature,
         alteredFeature
      };

      this.lastOperation = editOperation;
      this.history.push(editOperation);
   }

   setInserted(insertedFeature: Feature) {
      const insertOperation = {
         op: 'inserted',
         originalFeature: undefined,
         alteredFeature: insertedFeature
      };

      this.lastOperation = insertOperation;
      this.history.push(insertOperation);
   }

   setDeleted(deletedFeature: Feature) {
      const insertOperation = {
         op: 'deleted',
         originalFeature: deletedFeature,
         alteredFeature: undefined
      };

      this.lastOperation = insertOperation;
      this.history.push(insertOperation);
   }

   closeSidebar() {
	// TO DO: verify if needs removal
   }


}

interface EditOperation {
   'op': string;
   'originalFeature': Feature;
   'alteredFeature': Feature;
}
