import { IConfigVincolo } from './../../modelli/config-vincolo.model';
import { Injectable } from '@angular/core';

import { IConfigGlobale } from '../../modelli/config-globale.model';
import { IConfigAttiAmm } from '../../modelli/config-atti-amm.model';
import { IConfigMappa } from '../../modelli/config-mappa.model';
import { IConfigLogAttivita } from '../../modelli/config-log-attivita.model';
import { IConfigManufatti } from '../../modelli/config-manufatti.model';
import { IConfigCatasto } from '../../modelli/config-catasto.model';
import { IConfigRiserve } from '../../modelli/config-riserve.model';

import configGlobale from 'src/assets/config/config-globale.dev.json';
import configAttiAmm from 'src/assets/config/config-atti-amm.dev.json';
import configMappa from 'src/assets/config/config-mappa.dev.json';
import configBeniDem from 'src/assets/config/config-beni-dem.dev.json';
import configLogAttivita from 'src/assets/config/config-log-attivita.dev.json';
import configManufatti from 'src/assets/config/config-manufatti.dev.json';
import configCatasto from 'src/assets/config/config-catasto.dev.json';
import configRiserve from 'src/assets/config/config-riserve.dev.json';
import configVincolo from 'src/assets/config/config-vincoli.dev.json';

/** Servizio che carica e mette a disposizione i file di configurazione dentro la cartella assets/config */
@Injectable({
   providedIn: 'root'
})
export class ConfigService {

   /** json di configurazioni globali */
   public getConfigGlobale(): IConfigGlobale {
      return configGlobale as IConfigGlobale;
   }

   /** json di configurazioni degli atti amministrativi */
   public getConfigAttiAmm(): IConfigAttiAmm {
      return configAttiAmm as IConfigAttiAmm;
   }

   /** json di configurazioni della mappa */
   public getConfigMappa(): IConfigMappa {
      return configMappa as IConfigMappa;
   }

   // NOTE per ora non vengono usati
   /** json di configurazione dei beni demaniali */
   public getConfigBeniDem(): any { // TODO sistemare interfaccia typescript
      return configBeniDem;
   }

   /** json di configurazioni del log attivita */
   public getConfigLogAttivita(): IConfigLogAttivita {
      return configLogAttivita as IConfigLogAttivita;
   }

   /** json di configurazione dei manufatti */
   public getConfigManufatti(): IConfigManufatti {
      return configManufatti as IConfigManufatti;
   }

   /** json di configurazione del catasto */
   public getConfigCatasto(): IConfigCatasto {
      return configCatasto as IConfigCatasto;
   }

   /** json di configurazione delle riserve */
   public getConfigRiserve(): IConfigRiserve {
      return configRiserve as IConfigRiserve;
   }

   /** json di configurazione del catasto */
   public getConfigVincolo(): IConfigVincolo {
      return configVincolo as IConfigVincolo;
   }

   /** costruttore */
   constructor() { }

}
