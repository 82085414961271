<div>
  <div class="d-flex mb-10">
    <div class="mr-auto">
      <!-- <div *ngIf="mappedResults.length==rowsPerPage"> -->
      <span for="rows_per_page">Righe per pagina </span>
      <select id="rows_per_page"
              name="select"
              [ngModel]="rowsPerPage"
              class="rows_per_page"
              (change)="updateListByEvent($event)">
        <option class="custom-option"
                *ngFor="let opt of _rowsPerPageOptions"
                [value]="opt">
          {{ opt }}
        </option>
      </select>
    </div>

    <div class="btn-export"
         [ngStyle]="{ display: showCSVExport() ? '' : 'none' }">
      <button class="btn bg-blu txt-white btn-sm"
              id="exportCSV"
              (click)="exportCSV(false)">
        Esporta Csv
      </button>
    </div>
    <div class="btn-export"
         [ngStyle]="{ display: showXMLExport() ? '' : 'none' }">
      <button class="btn bg-blu txt-white btn-sm"
              id="exportXML"
              (click)="exportXML()">
        Esporta Xml
      </button>
    </div>
    <div class="btn-export"
         [ngStyle]="{ display: showPDFExport() ? '' : 'none' }">
      <button class="btn bg-blu txt-white btn-sm"
              id="exportPDF"
              (click)="exportPDF()">
        Esporta Pdf
      </button>
    </div>
  </div>

  <table class="table table-bordered table-hover table-sm">
    <thead>
      <tr>
        <th *ngFor="let item of listFields; let i = index"
            [ngStyle]="{ width: item.width + '%', 'font-size': '14px' }"
            scope="col"
            [ngClass]="{ 'pr-20': listFields.length - 1 == i && alignItem }">
          <div *ngIf="!isUndefined(item.checkbox);else text;"
               class="text-center">
            <input type="checkbox"
                   [value]="item.header"
                   [id]="item.header"
                   #inputHeaderCheckbox
                   (click)="onCheckboxHeaderClick(inputHeaderCheckbox.checked, item.checkbox.id)">
          </div>
          <ng-template #text>
            <div>
              {{ item.header }}
              <fa-icon *ngIf="item.sortable == 'y'"
                       [ngStyle]="{ float: 'right' }"
                       (click)="updateListByEvent($event)"
                       aria-hidden="true"
                       [id]="'order_' + item.dbField"
                       class="order_columns"
                       [title]="'Ordina ' + item.header"
                       [icon]="getOrderIcons(item)"></fa-icon>
            </div>
          </ng-template>
        </th>
      </tr>
    </thead>
    <tbody class="height-custom">
      {{
      listType
      }}
      <tr *ngIf="!loading && _listType === 'empty'">
        <td [attr.colspan]="listFields ? listFields.length : 0">
          Avvia ricerca
        </td>
      </tr>
      <tr *ngIf="!loading && _listType !== 'empty' && results.length === 0">
        <td [attr.colspan]="listFields ? listFields.length : 0">
          Nessun risultato
        </td>
      </tr>
      <tr *ngFor="let row of mappedResults; let i = index"
          [id]="'row_' + i">
        <td *ngFor="let item of row"
            [ngStyle]="{ width: item.width + '%' }">
          <img *ngIf="!isUndefined(item.img)"
               (click)="item?.clickable ? onCellClick(item.record,i,'image') : ''"
               [src]="item.img"
               [ngStyle]="{ width: item.imageWidth, height: item.imageHeight, cursor: item?.clickable ? 'pointer' : 'normal' }"
               [ngbTooltip]="item.title | translateDbField" />
          <div *ngIf="!isUndefined(item.mainButtons) || !isUndefined(item.subButtons)">
            <div class="btn-group">
              <button *ngFor="let button of item.mainButtons"
                      type="button"
                      class="btn btn-sm btn-outline-secondary actionButtons"
                      [ngbTooltip]="button.title"
                      container="body"
                      placement="top"
                      (click)="onActionClick($event, button.action, button.record_id)">
                <fa-icon aria-hidden="true"
                         [id]="'action_' + button.title"
                         class="actionButtons"
                         [title]="button.title"
                         [icon]="button.faImage"></fa-icon>
              </button>

              <div ngbDropdown
                   class="btn-group"
                   role="group"
                   [container]="'body'"
                   *ngIf="
                  !isUndefined(item.subButtons) &&
                  item.subButtons &&
                  item.subButtons.length > 0">
                <button class="btn btn-sm btn-outline-secondary"
                        ngbDropdownToggle
                        [id]="'subActions_' + i">
                  <fa-icon aria-hidden="true"
                           [id]="'action_sub_' + i"
                           [icon]="_faCog"></fa-icon>
                </button>
                <div ngbDropdownMenu
                     aria-labelledby="subActions">
                  <button class="dropdown-item btn-sm actionButtons"
                          *ngFor="let button of item.subButtons"
                          (click)="onActionClick($event, button.action, button.record_id)">
                    <fa-icon aria-hidden="true"
                             [id]="'action_' + button.title"
                             [title]="button.title"
                             [icon]="button.faImage"
                             [ngStyle]="{ color: button.color }"></fa-icon>
                    {{ button.title }}{{ button.style }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isUndefined(item.txt)"
               style="cursor: pointer;"
               (click)="onCellClick(item.record,i,'txt')">{{ item.txt | translateDbField}}</div>
          <div *ngIf="!isUndefined(item.checkbox)"
               class="text-center">
            <input *ngIf="item.show"
                   type="checkbox"
                   [value]="item.id"
                   [id]="item.id"
                   #inputCheckbox
                   (click)="onCheckboxClick(i, inputCheckbox.checked, item.record)">
          </div>
        </td>
      </tr>
      <!-- <tr *ngIf="loading">
            <td [attr.colspan]="listFields ? listFields.length : 0" class="loader">
               <div class="spinner-border loading" role="status">
                  <span class="sr-only">Loading...</span>
               </div>
            </td>
         </tr> -->
    </tbody>
    <tfoot>
      <tr>
        <td *ngFor="let item of listFields"
            [ngStyle]="{ width: item.width + '%' }">
          <input *ngIf="item.filterComparison == 'like'"
                 class="input_filter"
                 type="text"
                 [id]="'filter_' + item.dbField"
                 [placeholder]="item.header"
                 [title]="'Filtra ' + item.header"
                 (keyup)="updateListByEvent($event)" />
          <select *ngIf="
              item.filterComparison == 'eq' &&
              item.filter &&
              item.filter.length > 0
            "
                  class="select_filter"
                  [id]="'filter_' + item.dbField"
                  [title]="'Filtra ' + item.header"
                  (change)="updateListByEvent($event)">
            <option *ngFor="let opt of item.filter"
                    [value]="opt">
              {{ opt }}
            </option>
          </select>
        </td>
      </tr>
      <tr [ngStyle]="{ display: showPagination() ? '' : 'none' }">
        <td [colSpan]="!!listFields ? listFields.length : 0">
          <ngb-pagination id="paginator"
                          class="d-flex justify-content-center"
                          [(collectionSize)]="totPages * rowsPerPage"
                          [(pageSize)]="rowsPerPage"
                          [(page)]="currPage"
                          [maxSize]="5"
                          [rotate]="true"
                          [ellipses]="true"
                          [boundaryLinks]="true"
                          size="sm"
                          (pageChange)="updateListByEvent($event)"></ngb-pagination>

          <!-- aria-label="Default pagination" -->
          <!-- [collectionSize]="totPages*10" ............. numero di pagine totali (aggiungere * 10)-->
          <!-- [(page)]="currPage" ........................ pagina corrente-->
          <!-- [maxSize]="5" .............................. numero elementi visibili -->
          <!-- [rotate]="false" ........................... mantiene il numero al centro se non siamo vicino agli estremi-->
          <!-- [ellipses]="false" ......................... per creare celle con puntini se siamo a metà della collection -->
          <!-- [boundaryLinks]="true" ..................... per scorrere all'inizio o alla fine in un click -->
          <!-- size="sm" .................................. dimensione del pagination -->
        </td>
      </tr>
    </tfoot>
  </table>
</div>