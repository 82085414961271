import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "translateDbField" })
export class TranslateDbFieldsPipe implements PipeTransform {
  fields = {
    dangerarea: "In zona di pericolo",
    heartratebelowmin: "Frequenza cardiaca inferiore a minimo",
    heartrateovermax: "Frequenza cardiaca superiore a massimo",
    fall: "Paziente caduto",
    lowbattery: "Batteria scarica",
    moving: "In movimento",
    safearea: "Area sicura",
    safepath: "Percorso sicuro",
    safe: "Sicuro",
    ok: "OK",
    created: "Nuovo allarme",
    ignored: "Ignorato",
    danger: "Pericolo",
    emergencycall: "Chiamata d'emergenza",
    patientcall: "Chiamato il paziente",
    familycall: "Chiamata la famiglia",
    nodata: "Segnale assente",
    alarm: "Allarme",
    archive: "Archivio",
    archived: "Archiviato",
    patient: "Paziente"
  };
  transform(value: string): string {
    const val = value.toLowerCase();
    return !!this.fields[val] ? this.fields[val] : value;
  }
}
