import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IPatientAlarm } from "src/app/mappa/sidebar/patients/patient.model";
import { concatMap, filter, map, switchMap, tap } from "rxjs/operators";

import { GestioneLoginService } from "../login/gestione-login.service";
import { UtilsService } from "../utils.service";
import { io, Socket } from "socket.io-client";
import { Observable, of, Subject } from "rxjs";
import { Coordinate } from "ol/coordinate";

@Injectable({ providedIn: "root" })
export class PatientDataService {
  public patientsUrl = this.gestioneLoginService.creaURL("fdg", "users");
  public patientsFilteredUrl = this.gestioneLoginService.creaURL("fdg", "usersFiltered");
  public patientsMovingUrl = this.gestioneLoginService.creaURL("fdg", "usersMoving");
  public patientsWithAlarmsUrl = this.gestioneLoginService.creaURL("fdg", "usersWithAlarms");
  private patientUrl = this.gestioneLoginService.creaURL("fdg", "user");
  public patientHeatmapUrl = this.gestioneLoginService.creaURL("fdg", "heatmap");
  private patientDeletePathsUrl = this.gestioneLoginService.creaURL("fdg", "deletePaths");
  private patientAlarmsUrl = this.gestioneLoginService.creaURL("alarms", "read");
  private patientUpdateAlarmUrl = this.gestioneLoginService.creaURL("alarms", "update");
  public patientDeleteAlarmsUrl = this.gestioneLoginService.creaURL("alarms", "delete");
  public patientDeleteAlarmUrl = this.gestioneLoginService.creaURL("alarms", "deleteAlarm");
  public patientBiometricDataUrl = this.gestioneLoginService.creaURL("fdg", "data");
  public patientPathsUrl = this.gestioneLoginService.creaURL("features", "paths");
  public patientAddGeometryUrl = this.gestioneLoginService.creaURL("features", "add");
  public patientDeleteGeometryUrl = this.gestioneLoginService.creaURL("features", "delete");
  public patientSafePathsUrl = this.gestioneLoginService.creaURL("features", "linestrings");
  public patientAreasUrl = this.gestioneLoginService.creaURL("features", "areas");
  public postUsersUrl = this.gestioneLoginService.creaURL("fdg", "postUsers");
  private reverseGeocodingGetUrl = this.gestioneLoginService.creaURL("location", "reversegeocodingGET");
  private reverseGeocodingPostUrl = this.gestioneLoginService.creaURL("location", "reversegeocodingPOST");
  private patientsLastPositionUrl = this.gestioneLoginService.creaURL("fdg", "lastpositions");
  private patientsWebsocketUrl = "https://fdg.ebwdatacenter.it";
  patientsParam = new HttpParams();

  patients$ = this.utilsService
    .postRequest(this.patientsUrl, this.patientsParam)
    .pipe(map((data) => data.results.filter((res) => res.type === "patient")));

  patientsLastPositions$ = this.utilsService.getRequest(this.patientsLastPositionUrl);

  patientSocketIO: Socket = io(this.patientsWebsocketUrl);
  patientAlarmsWS$: Subject<IPatientAlarm> = new Subject<IPatientAlarm>();
  patientPositionWS$: Subject<any> = new Subject<any>();

  constructor(
    private gestioneLoginService: GestioneLoginService,
    private utilsService: UtilsService,
    private http: HttpClient
  ) {}

  ngOnInit() {}

  connectAlarmWS() {
    console.log("PacientDataService connectAlarmWS");
    this.reconnectWS();
    this.patientSocketIO.on("alarm", (data: IPatientAlarm) => {
      // console.log("---socket IO alarm---Received message from Websocket, id: ", data);
      if (data) {
        this.patientAlarmsWS$.next(data);
      }
    });
  }

  connectPointWS() {
    console.log("----PacientDataService connectPointWS");
    this.reconnectWS();
    this.patientSocketIO.on("point", (data) => {
      // console.log("---socket IO points---Received message from Websocket, heartRate", data);
      // const positionStatus = ["dangerArea", "noData", "unknown", "safePoint"];
      // const pos = Math.floor(Math.random() * (positionStatus.length - 0) + 0);
      // data.positionStatus = positionStatus[pos];
      if (data) this.patientPositionWS$.next(data);
    });
  }

  reconnectWS() {
    if (this.patientSocketIO.disconnected) {
      console.log("------reconnectWS");
      this.patientSocketIO.open();
    }
  }

  disconnectWS() {
    if (this.patientSocketIO.connected) {
      console.log("------disconnectWS");
      this.patientSocketIO.disconnect();
    }
  }

  getPatient(id: string): Observable<any> {
    return of(id).pipe(
      filter((id) => !!id),
      map((id) => id),
      switchMap((id) => this.utilsService.getRequest(this.patientUrl + id))
    );
  }
  getPatientAlarms(idPatient: string): Observable<any> {
    return of(idPatient).pipe(
      filter((id) => !!id),
      map((id) => this.patientAlarmsUrl.replace("{id}", id)),
      switchMap((alarmsUrl) => this.utilsService.postRequest(alarmsUrl, null))
    );
  }
  deletePatientAlarms(idPatient: string): Observable<any> {
    return of(idPatient).pipe(
      filter((id) => !!id),
      map((id) => this.patientAlarmsUrl.replace("{id}", id)),
      concatMap((alarmsUrl) => this.http.delete(alarmsUrl))
    );
  }
  deletePatientAlarm(idAlarm: string): Observable<any> {
    return of(idAlarm).pipe(
      filter((id) => !!id),
      map((id) => this.patientDeleteAlarmUrl.replace("{id}", id)),
      concatMap((deleteAlarmUrl) => this.http.delete(deleteAlarmUrl))
    );
  }

  deletePatientPaths(userid: string, body: string[]): Observable<any> {
    return of([userid, body]).pipe(
      filter(([userid, body]) => !!userid),
      map(([userid, body]) => {
        const options: any = {
          body: { pathIdArray: body },
        };
        return [this.patientDeletePathsUrl.replace("{id}", userid as string), options];
      }),
      concatMap(([deletePathsUrl, options]) => this.http.delete(deletePathsUrl, options))
    );
  }

  deletePatientGeometry(idFeature: string): Observable<any> {
    return of(idFeature).pipe(
      filter((id) => !!id),
      map((id) => this.patientDeleteGeometryUrl.replace("{id}", id)),
      concatMap((areasUrl) => this.http.delete(areasUrl))
    );
  }
  updatePatientAlarm(idAlarm: string, body): Observable<any> {
    return of(idAlarm).pipe(
      filter((id) => !!id),
      map((id) => this.patientUpdateAlarmUrl.concat(id)),
      concatMap((alarmsUrl) => this.http.put(alarmsUrl, body))
    );
  }
  getPatientPaths(idPatient: string, beginDateParam = null, endDateParam = null): Observable<any> {
    let params: HttpParams;
    if (beginDateParam instanceof Date && endDateParam instanceof Date) {
      params = new HttpParams()
        .set("begin", beginDateParam.toISOString())
        .set("end", endDateParam.toISOString());
    } else {
      params = new HttpParams();
    }
    return of(idPatient).pipe(
      filter((id) => !!id),
      map((id) => this.patientPathsUrl.replace("{id}", id)),
      switchMap((pathsUrl) => this.utilsService.getRequest(pathsUrl, params))
    );
  }

  getPatientHeatmap(idPatient: string, beginDateParam = null, endDateParam = null): Observable<any> {
    let params: HttpParams;
    if (beginDateParam instanceof Date && endDateParam instanceof Date) {
      params = new HttpParams()
        .set("begin", beginDateParam.toISOString())
        .set("end", endDateParam.toISOString());
    } else {
      params = new HttpParams();
    }
    return of(idPatient).pipe(
      filter((id) => !!id),
      map((id) => this.patientHeatmapUrl.replace("{id}", id)),
      switchMap((pathsUrl) => this.utilsService.getRequest(pathsUrl, params))
    );
  }

  getPatientBiometricData(idPatient: string, begin = null, end = null): Observable<any> {
    let params: HttpParams;
    if (begin instanceof Date && end instanceof Date) {
      params = new HttpParams().set("begin", begin.toISOString()).set("end", end.toISOString());
    } else {
      params = new HttpParams();
    }
    return of(idPatient).pipe(
      filter((id) => !!id),
      map((id) => this.patientBiometricDataUrl.replace("{id}", id)),
      switchMap((pathsUrl) => this.utilsService.postRequest(pathsUrl, params))
    );
  }
  getPatientSafePaths(idPatient: string): Observable<any> {
    return of(idPatient).pipe(
      filter((id) => !!id),
      map((id) => this.patientSafePathsUrl.replace("{id}", id)),
      switchMap((pathsUrl) => this.utilsService.getRequest(pathsUrl))
    );
  }
  getPatientAreas(idPatient: string): Observable<any> {
    return of(idPatient).pipe(
      filter((id) => !!id),
      map((id) => this.patientAreasUrl.replace("{id}", id)),
      switchMap((pathsUrl) => this.utilsService.getRequest(pathsUrl))
    );
  }
  addPatientGeometry(idPatient: string, body: any): Observable<any> {
    return of(idPatient).pipe(
      filter((id) => !!id),
      map((id) => this.patientAddGeometryUrl.replace("{id}", id)),
      concatMap((addGeometryUrl) => this.http.put(addGeometryUrl, body))
    );
  }
  getReverseGeocoding(coord: Coordinate) {
    return of(coord).pipe(
      filter((coord) => !!coord),
      map((coord) => new HttpParams().set("lat", coord[1].toString()).set("lon", coord[0].toString())),
      switchMap((params) => this.utilsService.getRequest(this.reverseGeocodingGetUrl, params))
    );
  }
  getPatientsLastPositions() {
    return this.utilsService.getRequest(this.patientsLastPositionUrl);
  }
  postReverseGeocoding(coords: Coordinate[]) {
    return of(coords).pipe(
      filter((coords) => coords.length > 0),
      // tap({next: res=> console.log("postReverseGeocoding coords: ", res)}),
      switchMap((coords) => this.utilsService.postRequest(this.reverseGeocodingPostUrl, coords))
    );
  }
}
