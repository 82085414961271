<div id="map-btn-goup"
     class="btn-group-vertical"
     role="group"
     aria-label="Controlli generici">
  <button type="button"
          class="btn bg-blu txt-white btn-sm"
          placement="left"
          ngbTooltip="Incrementa Zoom"
          (click)="incrementaZoom()">
    <fa-icon [icon]="faPlus"></fa-icon>
  </button>
  <button type="button"
          class="btn bg-blu txt-white"
          placement="left"
          ngbTooltip="Diminuisci Zoom"
          (click)="diminuisciZoom()">
    <fa-icon [icon]="faMinus"></fa-icon>
  </button>
  <button type="button"
          class="btn bg-blu txt-white"
          placement="left"
          ngbTooltip="Schermo intero"
          (click)="schermoIntero()">
    <fa-icon [icon]="faArrowsAlt"></fa-icon>
  </button>
  <button type="button"
          class="btn bg-blu txt-white"
          placement="left"
          ngbTooltip="Localizzami"
          (click)="localizza()"
          [ngClass]="{ active: localizzatoreId !== undefined }">
    <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
  </button>
  <button type="button"
          class="btn bg-blu txt-white"
          placement="left"
          ngbTooltip="Street View"
          (click)="onHandleStreetView()"
          [ngClass]="{ active: localizzatoreId !== undefined }">
    <fa-icon [icon]="faStreetView"></fa-icon>
  </button>

</div>
<!-- I bottoni qui sotto sono da rimuovere insieme ai metodi della parte ts. Sono messi solo a scopo di test. -->
<!-- <div class="ebw-text-right">
  <div style="margin-top: 50px">
    <button type="button"
            class="btn bg-blu txt-white btnControl"
            placement="left"
            ngbTooltip="Trasforma Coordinate"
            (click)="trasformaCoordinate()"
            style="display: block">
      <fa-icon [icon]="faGlobe"></fa-icon>
    </button>
  </div>
  <div class="mt-3">
    <button type="button"
            class="btn bg-blu txt-white btnControl"
            placement="left"
            ngbTooltip="Tool di Disegno"
            (click)="toolDisegno()"
            style="display: block">
      <fa-icon [icon]="faDraftingCompass"></fa-icon>
    </button>
  </div>

  <div class="mt-3">
    <button type="button"
            class="btn bg-blu txt-white btnControl"
            placement="left"
            ngbTooltip="Tool Ricerca Geometrica"
            (click)="toolRicercaGeometrica()"
            style="display: block">
      <fa-icon [icon]="faDrawPolygon"></fa-icon>
    </button>
  </div>

   <div class="mt-3">
     <button type="button" class="btn bg-blu txt-white btnControl" placement="left" ngbTooltip="Tool Particella"
        (click)=toolParticella() style="display:block">
        <fa-icon [icon]="faParking"></fa-icon>
     </button>
  </div>

  <div class="mt-3">
    <button type="button"
            class="btn bg-blu txt-white btnControl"
            placement="left"
            ngbTooltip="Scelta Rappresentazione"
            (click)="sceltaRappresentazione()"
            style="display: block">
      <fa-icon [icon]="faLayerGroup"></fa-icon>
    </button>
  </div>

  <div class="mt-3">
    <button type="button"
            class="btn bg-blu txt-white btnControl"
            placement="left"
            ngbTooltip="Legenda"
            (click)="legenda()"
            style="display: block">
      <fa-icon [icon]="faMap"></fa-icon>
    </button>
  </div>

</div> -->