import { PayloadUtente } from "src/app/modelli/api.response.model";
import { NGXLogger } from "ngx-logger";
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError, Subject, of } from "rxjs";
import { map, catchError, shareReplay, filter, switchMap, tap } from "rxjs/operators";
import { ConfigService } from "../config/config.service";

/** Servizio per la gestione del login */
@Injectable({
  providedIn: "root",
})
export class GestioneLoginService {
  /** Chiave utilizzata nel file JSON che contiene tutte le rotte di una API */
  private PATHS_KEY: string = "paths";

  /** variabile che conterrà il token generato */

  private token: string; // GB DA DECOMMENTARE

  // GB Da togliere
  // private token: string = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Im5vbWUiOiJDUCIsImNvZ25vbWUiOiJDb21hbmRhbnRlIiwidXRlbnRpX2lkIjoxNzAxLCJydW9saV9pZCI6MywicnVvbGlfbm9tZSI6IkFtbWluaXN0cmF0b3JlIiwiZ3J1cHBpX2lkIjoyLCJncnVwcGlfbm9tZSI6IkNhcGl0YW5lcmlhIGRpIHBvcnRvIiwiYW1taW5pc3RyYXppb25pX2lkIjoxMDI3LCJkZW5vbWluYXppb25lIjoiQ2FwaXRhbmVyaWEgZGkgcG9ydG8gZGkgUGVzYXJvIiwidXNlcm5hbWUiOiJzaWRwcyIsImNvbXByZXNhX2luIjoiQU5DT05BIiwidGlwbyI6Ik8iLCJib29sX2NvbXBldGVuemFfdGVycml0b3JpYWxlIjp0cnVlLCJib29sX2lzX3NpY2lsaWEiOmZhbHNlfSwiaWF0IjoxNTgxOTQ3ODE1LCJleHAiOjE1ODIwMzQyMTV9.Nt79ujG7GQlVq5fuJIbREVYRN_rqqmOM7klxqO9eeKAC_mc59kiZILxdMgtkdGzkpBJpA_o0whs0HyAzg6W3CbD9TK1AareUT6JuLs0Z6nIRndB27d49HNbb6JO4uqXjMPbZN0LzDktNO-3VDKjg3gMMdCtk2JObdOLu9x0q1Xk';

  /** variabile che conterrà il payload con le informazioni utente */
  private payload: PayloadUtente; // GB DA DECOMMENTARE

  // GB da togliere
  /*private payload: any = {
       "nome": "CP",
       "cognome": "Comandante",
       "utenti_id": 1701,
       "ruoli_id": 3,
       "ruoli_nome": "Amministratore",
       "gruppi_id": 2,
       "gruppi_nome": "Capitaneria di porto",
       "amministrazioni_id": 1027,
       "denominazione": "Capitaneria di porto di Pesaro",
       "username": "sidps",
       "compresa_in": "ANCONA",
       "tipo": "O",
       "bool_competenza_territoriale": true,
       "bool_is_sicilia": false
    };*/

  /** subject per comunicare all'esterno il login */
  private loginSubject = new Subject<any>();

  /** costruttore */
  constructor(private http: HttpClient, private configService: ConfigService, private logger: NGXLogger) {}

  /** getter del token */
  public getToken(): string {
    return this.token || localStorage.getItem("token");
  }
  /** setter del token */
  public setToken(token: string): void {
    this.token = token;
    //  if (token) {
    //    localStorage.setItem("jwt", btoa(token));
    //    // localStorage.setItem('id_token', authResult.idToken);
    //    // localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
    //  } else {
    //    localStorage.removeItem("jwt");
    //    localStorage.removeItem("payload");
    //  }
  }

  private toDateTime(secs) {
    const t = new Date();
    t.setSeconds(secs);
    console.log(t);
    return t;
  }

  setSession(authResult, user) {
    const expiresAt = this.toDateTime(authResult.expires_in);
    localStorage.setItem("token", authResult.access_token);
    localStorage.setItem("user", user);
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
  }

  private getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);
    return expiresAt;
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("user");
    localStorage.removeItem("payload");
    this.setToken(null);
  }

  public isLoggedIn() {
    console.log("isLoggedIn: ", this.getExpiration() - new Date().getTime() > 0);
    return this.getExpiration() - new Date().getTime() > 0;
  }

  isOnMobile() {
    return window.screen.width < 700;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getUser(): PayloadUtente {
    const user = localStorage.getItem("user");
    return {
      nome: user,
      cognome: "",
      /** Identificativo utente */
      utenti_id: 0,
      /** Identificativo ruolo utente */
      ruoli_id: 0,
      /** Definizione ruolo utente */
      ruoli_nome: "",
      /** Identificativo gruppo amministrazione dell'utente */
      gruppi_id: 0,
      /** Definizione gruppo amministrazione dell'utente */
      gruppi_nome: "",
      /** Identificativo dell'amministrazione dell'utente */
      amministrazioni_id: 0,
      /** Username dell'utente */
      username: user,
      /** Tipologia di utente */
      tipo: "",
      /** booleano competenza territoriale */
      bool_competenza_territoriale: false,
      /** booleano sicilia */
      bool_is_sicilia: false,
      /** booleano cittadino */
      bool_cittadino: false,
      /** Diritti */
      rights: [],
      /** Denominazione amministrazione */
      denominazione: "",
      /** Direzione marittima */
      compresa_in: "",
      /** Utente che sta impersonificando questo utente */
      impersonificato_da: undefined,
    };
  }

  /** getter del payload */
  public getPayload(isHeader: boolean = false): PayloadUtente {
    return {
      nome: "",

      cognome: "",
      /** Identificativo utente */
      utenti_id: 0,
      /** Identificativo ruolo utente */
      ruoli_id: 0,
      /** Definizione ruolo utente */
      ruoli_nome: "",
      /** Identificativo gruppo amministrazione dell'utente */
      gruppi_id: 0,
      /** Definizione gruppo amministrazione dell'utente */
      gruppi_nome: "",
      /** Identificativo dell'amministrazione dell'utente */
      amministrazioni_id: 0,
      /** Username dell'utente */
      username: "",
      /** Tipologia di utente */
      tipo: "",
      /** booleano competenza territoriale */
      bool_competenza_territoriale: false,
      /** booleano sicilia */
      bool_is_sicilia: false,
      /** booleano cittadino */
      bool_cittadino: false,
      /** Diritti */
      rights: [],
      /** Denominazione amministrazione */
      denominazione: "",
      /** Direzione marittima */
      compresa_in: "",
      /** Utente che sta impersonificando questo utente */
      impersonificato_da: undefined,
    };
    if (!!this.payload.impersonificato_da && !isHeader) {
      return this.payload.impersonificato_da;
    } else {
      return this.payload;
    }
  }
  /** setter del payload */
  public setPayload(payload: PayloadUtente): void {
    console.log("setPayload", payload);
    this.payload = payload;
    // localStorage.setItem("payload", btoa(JSON.stringify(payload)));
  }

  public isImpersonificato(): boolean {
    return !!this.payload && !!this.payload.impersonificato_da;
  }

  private getAuthorizationHeader() {
    return {
      headers: new HttpHeaders({
        Authorization: "bearer " + this.getToken(),
      }),
    };
  }

  /**
   * Chiama l'API che, dati in input username e password, fornisce il token JWT
   * @param username username dell'utente
   * @param password password dell'utente
   * @param simula {boolean} modalità simulazione utente
   */
  getJWT(username: string, password: string, simula: boolean = false): Observable<any> {
    const API_URL = this.creaURL("auth", "login");
    const body = {
      username: username,
      password: password,
      grant_type: "password",
      client_id: "mobile",
      client_secret: "secret",
    };
    const toUrlEncoded = (obj) =>
      Object.keys(obj)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]))
        .join("&");
    const formData = toUrlEncoded(body);
    // const params = new HttpParams().set("username", username).set("password", password).set("grant_type", "password").set("client_id", "mobile").set("client_secret","secret")
    const httpHeader = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
    return this.http
      .post(API_URL, formData, { headers: httpHeader })
      .pipe(
        map((resp: any) => {
          // console.log("getJWT resp", resp);
          return resp;
        }),
        shareReplay()
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  verifyUserExists(user) {
    return of(user).pipe(
      filter((id) => !!id),
      map((id) => [this.creaURL("auth", "exists"), new HttpParams().set("username", id.toString())]),
      // tap(e=> console.log(e)),
      switchMap(([url, params]: any) => this.http.get(url, { params }))
    );
    // const url = this.creaURL("auth", "exists");
    // const params = new HttpParams().set("username", user.toString())
    // return this.http.get(url, { params })
  }

  /** Chiama l'API che, dato in input il token JWT, ne verifica la validità */
  verifyJWT(): any {
    const API_URL = this.creaURL("login", "verify_token");
    return this.http
      .post(API_URL, {}, this.getAuthorizationHeader())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /**
   * Chiama l'API che, dato in input il token JWT, ne verifica la validità, e permette di eseguire una funzione se esso è valido
   */
  verifyJWTTest(): Observable<any> {
    const API_URL = this.creaURL("login", "verify_token_test");

    return this.http
      .post(API_URL, {}, this.getAuthorizationHeader())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /** Chiama l'API che, dato in input il token JWT, restituisce payload e header */
  decodeJWT(): Observable<any> {
    const API_URL = this.creaURL("login", "decode_token");
    const PARAMS = {
      token: this.getToken(),
    };
    return this.http
      .post(API_URL, PARAMS, this.getAuthorizationHeader())
      .pipe(
        map((resp: any) => {
          return resp;
          // const text = 'Decodifica Token: Header --> ' + JSON.stringify(x.decoded.header) + '  Payload --> ' + JSON.stringify(x.decoded.payload);
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /**
   * Chiama l'API che, dato in input il token JWT, ne verifica la validità, e restituisce payload e header
   */
  verifyDecodeJWT(): Observable<any> {
    const API_URL = this.creaURL("login", "verify_decode_token");
    return this.http
      .post(API_URL, {}, this.getAuthorizationHeader())
      .pipe(
        map((resp: any) => {
          return resp;
        })
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /**
   * Chiama l'API che, ... TODO???
   */
  recuperaPassword(datiUtente: object): Observable<any> {
    const API_URL = this.creaURL("login", "recupera_password");
    return this.http.post(
      API_URL,
      datiUtente // ,
      //   { responseType: 'text' as 'json' }
    );
  }

  /**
   * metodo per comunicare il login
   * @param payload oggetto con dati utente salvati nel token
   */
  segnalaLogin(payload: any): void {
    // comunico all'esterno il login (per chi avesse bisogno di ascoltralo)
    this.loginSubject.next(payload);
    // salvo sul db la data di accesso
    const utentiId = !!payload.impersonificato_da ? payload.impersonificato_da.utenti_id : payload.utenti_id;
    this.salvaDataAccesso(utentiId);
  }

  /**
   * metodo per salvare nel db la data di primo/ultimo accesso al portale quando l'utente fa un login
   * @param utenti_id identificativo dell'utente
   */
  salvaDataAccesso(utentiId: any): void {
    // prelevo i dati dell'utente
    const viewUrl = this.creaURL("utenti", "view");
    const viewParam = { where: { id: utentiId } };
    this.http.post<any>(viewUrl, viewParam, this.getAuthorizationHeader()).subscribe((resp) => {
      if (resp.error === 0 && resp.status === 0 && resp.data.length === 1) {
        const user = resp.data[0];
        // aggiorno le date
        const saveUrl = this.creaURL("utenti", "save");
        const saveParam = {
          utente: {
            id: user.id,
            auth: {
              id: user.auth.id,
              utenti_id: user.auth.utenti_id,
              data_ultimo_accesso: new Date(),
              data_primo_accesso: null,
            },
          },
        };
        // se è il primo accesso aggiorno anche data_primo_accesso
        if (user.auth.data_primo_accesso === undefined) {
          saveParam.utente.auth.data_primo_accesso = new Date();
        }

        this.http.post<any>(saveUrl, saveParam, this.getAuthorizationHeader()).subscribe((resp2) => {
          if (resp2.error === 0 && resp2.status === 0) {
            this.logger.debug("Salvataggio data login nel DB");
          }
        });
      }
    });
  }

  /** metodo per ascoltare il login */
  ascoltaLogin(): Observable<any> {
    return this.loginSubject.asObservable();
  }

  /**
   * generatore di URL per raggiungere il backend
   * @param sezione sezione del file di configurazione (campo key del campo routes)
   * @param id identificativo della rotta (campo id del paths)
   * @returns URL completo
   */
  creaURL(sezione: string, id: string) {
    try {
      const path = this.configService
        .getConfigGlobale()
        .api.routes.find((r) => r.key === sezione)
        [this.PATHS_KEY].find((p) => p.id === id).path;
      return this.creaUrlDaRoute(path);
    } catch {
      this.logger.error(
        `Impossibile Generare il path ${sezione}/${id}. Controllare in ../assets/config/config-globale.dev.json`
      );
    }
  }

  /**
   * Metodo per generare l'url del back avendo già la rotta desiderata
   * @param route rotta da utilizzare per creare l'URL
   */
  creaUrlDaRoute(route: string): string {
    if (this.configService.getConfigGlobale().api.server.apiRelativePath) {
      return this.configService.getConfigGlobale().api.server.apiRelativePath + route;
    } else {
      return (
        this.configService.getConfigGlobale().api.server.apiProtocol +
        this.configService.getConfigGlobale().api.server.apiIp +
        ":" +
        this.configService.getConfigGlobale().api.server.apiPort +
        route
      );
    }
  }
}
