import { Component, Input } from "@angular/core";
import { faMousePointer } from "@fortawesome/free-solid-svg-icons";

/** Componente che mostra le informazioni generiche della mappa */
@Component({
  selector: "ebw-info-vista",
  templateUrl: "./info-vista.component.html",
  styleUrls: ["./info-vista.component.scss"],
})
export class InfoVistaComponent {
  /** Icona puntatore mouse */
  faMousePointer = faMousePointer;

  /** Array di coordinate correnti puntate dal mouse (già arrotondate) */
  _coordinateCorrenti: [number, number];

  /** Risoluzione corrente della mappa (già troncata) */
  _risoluzioneCorrente: number;

  /** Valore di arrotondamento dei decimali della posizione corrente del mouse */
  @Input() arrotondamento: number;

  /** Array di coordinate correnti puntate dal mouse (da arrotondare) */
  @Input() set coordinateCorrenti(coords: [number, number]) {
    this._coordinateCorrenti = [
      parseFloat(coords[0].toFixed(this.arrotondamento)),
      parseFloat(coords[1].toFixed(this.arrotondamento)),
    ];
  }

  /** Input della risoluzione corrente della mappa (da troncare) */
  @Input() set risoluzioneCorrente(risoluzione: number) {
    this._risoluzioneCorrente = Math.trunc(risoluzione);
  }
}
