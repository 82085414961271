import { NgModule, ModuleWithProviders } from '@angular/core';
import { MapComponent } from './map.component';
import { MapService } from './service/map.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { ControlliMappaComponent } from './componenti-aggiuntivi/controlli-mappa/controlli-mappa.component';
import { PreviewMappaComponent } from './componenti-aggiuntivi/preview-mappa/preview-mappa.component';
import { ControlliLayerSfondoComponent } from './componenti-aggiuntivi/controlli-layer-sfondo/controlli-layer-sfondo.component';
import { SelezioneOggettiComponent } from './componenti-aggiuntivi/selezione-oggetti/selezione-oggetti.component';
import { InfoVistaComponent } from './componenti-aggiuntivi/info-vista/info-vista.component';
import { RicercaGoogleComponent } from './componenti-aggiuntivi/ricerca-google/ricerca-google.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TrasformaCoordinateComponent } from './sidebar/trasforma-coordinate/trasforma-coordinate.component';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger/';
import { ToolDisegnoComponent } from './sidebar/tool-disegno/tool-disegno.component';
import { EbwModalModule } from './library/ebw-modals-angular/ebw-modal.module';
// import { ToolParticellaComponent } from './sidebar/tool-particella/tool-particella.component';
// import { ShrComponentsModule } from './../../condivisa/shr-components.module';
import { ToolAgganciaPuntoComponent } from './sidebar/tool-aggancia-punto/tool-aggancia-punto.component';
import { LegendaComponent } from './sidebar/legenda/legenda.component';
import { SceltaRappresentazioneComponent } from './sidebar/scelta-rappresentazione/scelta-rappresentazione.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { RicercaGeometricaComponent } from './sidebar/ricerca-geometrica/ricerca-geometrica.component';
import { MapUtilsService } from './service/map-utils.service';

/** Map Module */
@NgModule({
   declarations: [
      MapComponent,
      ControlliMappaComponent,
      PreviewMappaComponent,
      ControlliLayerSfondoComponent,
      SelezioneOggettiComponent,
      InfoVistaComponent,
      RicercaGoogleComponent,
      TrasformaCoordinateComponent,
      ToolDisegnoComponent,
      ToolAgganciaPuntoComponent,
      LegendaComponent,
      SceltaRappresentazioneComponent,
      RicercaGeometricaComponent
   ],
   imports: [
      BrowserModule,
      NgbModule,
      HttpClientModule,
      FontAwesomeModule,
      FormsModule,
      EbwModalModule,
      ReactiveFormsModule,
      AgmCoreModule.forRoot(),
      LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
      AngularDraggableModule
   ],
   exports: [MapComponent],
   providers: [
      MapService,
      HttpClient,
      MapUtilsService
   ],
})
export class MapModule {

   /**
    * Utilizzare per impostare la GoogleKey
    * @param configuration JSON di configurazioni
    */
   public static forRoot(configuration: {
      /** APIKey Google. Se non presente mettere stringa vuota ('') */
      apiKey?: string,

      /** ClientID Google. Se non presente mettere stinga vuota */
      clientId?: string,

      /** array di stringhe delle librerie da caricare. Se non presente mettere array vuoto ([]) */
      libraries?: string[]
   }): ModuleWithProviders<MapModule> {
      return {
         ngModule: MapModule,
         providers: [
            {
               provide: LAZY_MAPS_API_CONFIG,
               useValue: configuration
            }
         ],
      };
   }
}
