<div class="form-group" ngbDropdown #calendarPanel="ngbDropdown">
   <div class="input-group">
      <input type="numeric" [ngClass]="{'form-control':true, 'has-error': has_error}" id="data" name="data"
         [(ngModel)]="dataTxt" [placeholder]="mask" #item (input)="change(item.value)" [ngbTooltip]="current_tip">
      <div class="input-group-append">

         <button class="btn btn-outline-secondary dropdown-toggle-split" ngbDropdownToggle>
            <div class="calendar" aria-hidden="true"></div>
         </button>
      </div>
   </div>
   <div ngbDropdownMenu class="dropdown-menu">
      <div class="row">
         <button class="btn btn-link col-4" (click)="addYear($event,-1);">
            <span class="ngb-dp-navigation-arrow left"></span>
         </button>
         <button class="btn col-4">{{incr+1}}-{{incr+10}}</button>
         <button class="btn btn-link col-4" (click)="addYear($event,+1);">
            <span class="ngb-dp-navigation-arrow right"></span>
         </button>
         <div *ngFor="let a of cells; let i = index; let first = first; let last = last" class="col-4"
            (click)="selectYearMonth($event,i)">
            <div *ngIf="data"
               [ngClass]="{'select': !isyear?(i+1)==data.month:(i+incr)==data.year,'outrange':isyear && (first || last)}"
               class="card-body">
               <span>{{i+incr}}</span>
            </div>
         </div>
      </div>
   </div>
</div>