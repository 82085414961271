import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { DecodificheResponse} from 'src/app/modelli/decodifiche-response';
import * as FileSaver from 'file-saver';
import { ConfigService } from './config/config.service';
import { IFiltriAttributi } from '../modelli/config-beni-dem.model';
import { GestioneLoginService } from './login/gestione-login.service';
import { SolrResponse } from '../modelli/solr-response.sevice';
import { SolrFilters } from 'src/app/modelli/solr-filters';
import { NGXLogger } from 'ngx-logger';
import { UtilsService } from 'src/app/servizi/utils.service';
import { map, catchError } from 'rxjs/operators';




@Injectable({
   providedIn: 'root'
})
export class ControllerFormService implements OnDestroy {

   currentTipoAtto$ = new Subject();
   currentSelectedValues$ = new Subject();
   cmdListener$ = new Subject();
   emptyForm$ = new Subject();
   tabChange$ = new Subject();
   systemTabChange$ = new Subject();
   filterHandlerService = new Subscription();

   API_URL: {
      "key": string,
      "paths": {
         "id": string,
         "path": string
      }[]
   }[];
   solrApiUrl: string;

   // salvo i valori dei componenti di tipo list-choice-template o le date che non sono direttamente inserite nel Form
   currentSelectedValues: {};

   constructor(
            private http: HttpClient,
            private gestioneLoginService: GestioneLoginService,
            private logger: NGXLogger,
            private configService: ConfigService,
            private loginService: GestioneLoginService,
            private utils: UtilsService) {
      this.currentSelectedValues = {};
      // recupero i valori delle url da chamare dal file di configurazione config-atti-amm-dev.json
      this.API_URL = this.configService.getConfigGlobale().api.routes;

      this.solrApiUrl = this.utils.creaURL('autocomplete', 'solr')
      // this.solrApiUrl = this.API_URL.filter((aa) => aa.key === 'autocomplete')[0].paths.filter((aa) => aa.id === 'solr')[0];
   }

   /**
    * Metodo chiamato dai component di autocomplete con richieste sul digit a Solr
    */
   search(term: string, filters?: SolrFilters): Observable<SolrResponse> {
      console.log(filters);
      filters = filters ? filters : new SolrFilters();
      filters.token = this.gestioneLoginService.getToken();
      filters.term = term;
      return (term === '') ? new Observable<SolrResponse>()
         : this.utils.postRequest(this.solrApiUrl, filters);
   }
	/**
    * Metodo chiamato dai component di autocomplete con richieste sul digit a Solr
    */
   searchCodCatastale(codice_catastale: string, filters?: SolrFilters): Observable<SolrResponse> {
      console.log(filters);
      filters = filters ? filters : new SolrFilters();
      filters.token = this.gestioneLoginService.getToken();
      filters.codice_catastale = codice_catastale;
      console.log("-------" , this.solrApiUrl, " ++++ ", filters)
      return (codice_catastale === '') ? new Observable<SolrResponse>()
         : this.utils.postRequest(this.solrApiUrl, filters);
   }

   /**
    * Chiamata alle rest api delle decodifiche per popolare i valori options dei form
    */
   initOptions(url_id: string, key?: string, term?: string) {
      const params = (Array.isArray(url_id) && url_id.length === 2) ? url_id[1] : '';
      url_id = (Array.isArray(url_id)) ? url_id[0] : url_id;

      if (key === 'decreti' || key === 'manufatti' || key === 'catasto') {
         key = 'decodifiche';
      }

      key = key ? key : 'decodifiche';

      const a = this.API_URL.filter((aa) => aa.key === key);
      const b = a[0].paths.filter((aa) => aa.id === url_id);


      const data = { 'params': params, 'tipo': url_id, 'token': this.gestioneLoginService.getToken() };
      if(term && term !='') data['term'] = term;
      return this.utils.postRequest(this.utils.creaUrlDaRoute(b[0].path), data);
   }

   /**
    * Gestisce le chiamate generiche alle api.
    * E' collegata al file di configurazionecoinfig-globale che è strutturato così;
    *  "routes": [
         {
            "key": "login",
            "paths": [
               {
                  "id": "get_token",
                  "path": "/login/getToken"
               },
               {
    * @param api nome dell'api
    * @param params body della richiesta
    * @returns observables a cui sottoscriversi
    */
   /**
    * Gestisce le chiamate generiche alle api.
    * E' collegata al file di configurazione config-globale che è strutturato così;
    *  "routes": [
         {
            "key": "login",
            "paths": [
               {
                  "id": "get_token",
                  "path": "/login/getToken"
               }.....
    * @param routes_key : "key" di config-globale
    * @param path_id : "id" di config-globale
    * @param params : paramentri della richiesta
    */
   apiRequest(routes_key : string, path_id : string, params?: any) {


      if(!params) params = {};
      params.token = this.loginService.getToken();
      const url = this.utils.creaURL(routes_key, path_id);
      // console.log('apiRequest-------', routes_key, ' -- ', path_id);
      //console.log(JSON.stringify(params));
      // console.log('apiRequest---------------------------------');
      return this.utils.postRequest(url, params)

   }

   /**
    * Questo servizio viene chiamato prima di inviare i dati al servizio che restituisce l'elenco dei risultati per ottenere il nome dei campi da applicare ed altre info
    */
   filterHandler(url_id: string, filters: any) {
      const tipo = url_id;
      const data = { 'data': filters };
      const filtersApiUrl = this.API_URL.filter((aa) => aa.key === 'decodifiche')[0].paths.filter((aa) => aa.id === 'filters')[0];
      console.log('filtersApiUrl ' + filtersApiUrl);

      return this.utils.postRequest(this.utils.creaUrlDaRoute(filtersApiUrl.path), data);

   }

   /**
    * @param name nome del filtro
    * @param value valore del filtro
    * @param current_page form corrente gestito, è una chiave in quanto questo component
    * gestisce multiple form
    * Questo metodo viene chiamato ogni volta che un filtro dle form
    * cambia valore
    * Fire Event: currentSelectedValues$
    */
   setCurrentSelectedValue(name: string, value: string, current_page?: string) {
      current_page = current_page ? current_page : 'gestione';
      if (typeof (this.currentSelectedValues[current_page]) === 'undefined') {
         this.currentSelectedValues[current_page] = {};
      }
      this.currentSelectedValues[current_page][name] = value;
      // fire event: form value changed
      this.currentSelectedValues$.next(this.currentSelectedValues);
   }
   getCurrentSelectedValues(current_page: string) {
      return (typeof (this.currentSelectedValues[current_page]) === 'undefined')
         ? []
         : this.currentSelectedValues[current_page];
   }
   /**
    *  restituisce il valore della label leggendo il file di configurazione config-atti-amm.dev.json
    *     key: nome del campo
    *     choice: caso da applicare
    *     Es di configurazione completa
    *     "anno": {
    *        "label": "Anno",
    *        "labels_choices": {
    *           "gestione": { "I": "Anno Concessione", "II": "Anno", "III": "Anno",  "IV": "Anno" },
    *           "import": { "I": "Anno Concessione", "II": "Anno", "III": "Anno",  "IV": "Anno" },
    *           "soggetti": { "I": "Anno Concessione", "II": "Anno", "III": "Anno",  "IV": "Anno" },
    *           "f24": { "I": "Anno Concessione", "II": "Anno", "III": "Anno",  "IV": "Anno" }
    *        }
    *     },
    */
   getLabel(current: IFiltriAttributi, current_page: string, choice?: string) {
      if (typeof (current.label) === 'undefined' && typeof (current.labels_choices) === 'undefined') {
         return '';
      }
      // caso labels_choices non previste
      if (typeof (current.labels_choices) === 'undefined') {
         return current.label;
      }
      // non prevista la pagina
      if (typeof (current.labels_choices[current_page]) === 'undefined') {
         return current.label;
      }

      // non prevista il caso
      if (typeof (current.labels_choices[current_page][choice]) === 'undefined') {
         return typeof (current.labels_choices[current_page].default !== 'undefined')
            ? current.labels_choices[current_page].default
            : current.label;
      }
      return current.labels_choices[current_page][choice];
   }

   initSearch(current_page: string, selectedTab?: string) {
      /* Metodo chiamato per avviare a ricerca Avvia la ricerca */
      console.log('cerco!!!!!!!!!!!!!!!!');

      let data = {};
      data['cmd'] = 'search';
      data['entityConfiguration'] = 'confAtti';
      data['current_page'] = current_page;
      data['configType'] = 'licenza';
      if (current_page === 'soggetti') {
         data['configType'] = 'soggetti';

      }
      data = this._allowedFilters(data, current_page, selectedTab);

      /** chiamo il servizio per lavorare i filtri da inviare all'elenco **/
      this.filterHandlerService = this.filterHandler('filters', data).subscribe(
         (results: any) => {
            console.log('chiedo l\'elenco ' + results);
            //      return this.cmdListener$.next(results);
         }
      );



   }

   _allowedFilters(data: { [x: string]: { [x: string]: string; }; }, current_page: string, selectedTab?: string) {
      selectedTab = selectedTab ? selectedTab : '';

      data['filters'] = this.currentSelectedValues[current_page];
      /* if ( current_page === 'soggetti' && typeof(data['filters'] ['tipo_soggetto']) !== 'undefined') {
           data['filters'] ['tipo_soggetto'] = selectedTab;
      }*/

      // gestire filtri ammessi nel caso per es in cui non sia presente il selected ta
      // Controllo sui filtri ammessi
      const filtri_ammessi = {
         'soggetti': {
            'F': ['nome', 'cognome', 'codice_fiscale_f', 'tipo_soggetto'],
            'G': ['denominazione', 'codice_fiscale_g', 'tipo_soggetto'],
            'A': ['autocomplete_soggetti']
         }
      };
      let ammessi = Array<string>();

      if (typeof (filtri_ammessi[current_page]) !== 'undefined') {
         ammessi = selectedTab === '' ? filtri_ammessi[current_page] : filtri_ammessi[current_page][selectedTab];
      }
      if (ammessi.length > 0) {
         for (let [key, value] of Object.entries(data['filters'])) {
            console.log(key + ':' + value);
            if (!ammessi.includes(key)) {
               data['filters'][key] = '';
            }
         }
      }
      return data;
   }

   exportFile(content: string, type: { type: string }, fileName: string) {
      // esempio
      // type =  {type: "text/plain;charset=utf-8"};
      // fileName = 'file_export_' + new  Date().getTime() + '.csv'
      const data: Blob = new Blob([content], type);
      FileSaver.saveAs(data, fileName);
   }
   ngOnDestroy(): void {
      this.filterHandlerService.unsubscribe()
   }
}
