import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

/**
 * Servizio per formattare il campo input del NGB Datepicker
 */
@Injectable()
export class FormattatoreDataDatepickerService extends NgbDateParserFormatter {

   /**
    * Converte la data impostata (stringa) nel modello utilizzato dal Datepicker
    * -- from your model -> internal model --
    * @param value stringa ricevuta dall'INPUT dell'utente
    */
   parse(value: string): NgbDateStruct {
      if (value) {
         const carattereDivisore = (value.includes('-') ? '-' : value.includes('/') ? '/' : '');

         const dateParts: string[] = value.trim().split(carattereDivisore);
         if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
            return { day: this.toInteger(dateParts[0]), month: null, year: null };
         } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
            return { day: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), year: null };
         } else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
            return { day: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), year: this.toInteger(dateParts[2]) };
         }
      }
      return null;
   }

   /**
    * Converte la data, ricevuta dal DatePicker,nella stringa GG-MM-AAAA
    * -- from internal model -> your model --
    * @param date data passata dal datepicker
    * @returns stringa della data oppure null
    */
   format(date: NgbDateStruct): string {
      if (date !== null) {
         return this.padNumber(date.day) + '/' + this.padNumber(date.month) + '/' + date.year;
      }
      return null;
   }
   /**
    * Funzione che controlla se il valore passato è un numero
    * @param value stringa da controllare
    * @returns true se la stringa rappresenta un numero, altrimenti false
    */
   isNumber(value: string): boolean {
      return !isNaN(parseInt(`${value}`, 10));
   }

   /**
    * Funzione che converte il valore in numero a base 10 (decimale)
    * @param value stringa da convertire
    * @returns numero
    */
   toInteger(value: string): number {
      return parseInt(`${value}`, 10);
   }

   /**
    * Funzione che aggiunge il carattere 0 per quei mesi/giorni prima del 10
    * @param value valore da sistemare
    * @returns valore distemato oppure ''
    */
   padNumber(value): string {
      if (this.isNumber(value)) {
         return `0${value}`.slice(-2);
      } else {
         return '';
      }
   }

}
