import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class ControllerMessaggiService {

   /** Evento chiamato dalle azioni eseguite sulla modal dei messaggi (click su close, click su conferma, etc) */
   eventHandler$ = new Subject();

   actionHandler$ = new Subject();

   constructor() { }
}
