import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'currencyIta'
})
export class CurrencyItaPipe implements PipeTransform {


   /**  Metodo per trasformare un numero in una stringa formatta come segue:
    * 1. due decimali
    * 2. virgola come separatore dei decimali
    * 3. torna stringa vuota se valori = 0 o null
    * @param value : numero o stringa da trasformare
    * @param default_value : valore di default se la stringa è vuota
    */
   transform(value: any, default_value: any = ''): string {

      if (value === null || value === 0 || value === '' || value === 'NaN') {
         value = default_value;
      }


      value = Number(value).toFixed(2);
      value = value.toString().replace('.', ',');
      return value;
   }

}
