import { Injectable } from "@angular/core";
import { Feature } from "ol";
import { Layer, Heatmap as HeatmapLayer, Tile as TileLayer } from "ol/layer";
import Vector from "ol/layer/Vector";
import VectorSource, { VectorSourceEvent } from "ol/source/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";
import { Subject } from "rxjs";
import { MapService, PosizioneLayer, TipoLayer } from "src/app/library/ebw-map-angular/service/map.service";
import { MappaService } from "../mappa/mappa.service";
import { GeoJSON, KML } from "ol/format";
import { Geometry, Point, LineString } from "ol/geom";
import { ReadOptions } from "ol/format/Feature";
import { Coordinate } from "ol/coordinate";
import { StyleLike } from "ol/style/Style";
// import LineString from 'ol/geom/LineString';

@Injectable({ providedIn: "root" })
export class PatientMapShowGeometryService {
  handleLayer$ = new Subject<IHandleLayer>();

  private patientsListValuesSource$ = new Subject();
  private _patientListValues$ = this.patientsListValuesSource$.asObservable();
  get patientListValues$() {
    return this._patientListValues$;
  }
  set patientListValues(values: any) {
    !!values ? this.patientsListValuesSource$.next(values) : this.patientsListValuesSource$.error(values);
  }

  private patientPathFeatureSource$ = new Subject();
  private _patientPathFeature$ = this.patientPathFeatureSource$.asObservable();
  get patientPathFeature$() {
    return this._patientPathFeature$;
  }
  set patientPathFeature(value: any) {
    this.patientPathFeatureSource$.next(value);
  }

  private patientDataSource$ = new Subject();
  private _patientData$ = this.patientDataSource$.asObservable();
  get patientData$() {
    return this._patientData$;
  }
  set patientData(value: any) {
    this.patientDataSource$.next(value);
  }

  private patientPathsSource$ = new Subject();
  private _patientPaths$ = this.patientPathsSource$.asObservable();
  get patientPathsFeatureColl$() {
    return this._patientPaths$;
  }
  set patientPathsFeatureColl(value: any) {
    this.patientPathsSource$.next(value);
  }

  private patientHeatmapSource$ = new Subject();
  private _patientHeatmap$ = this.patientHeatmapSource$.asObservable();
  get patientHeatmapFeatureColl$() {
    return this._patientHeatmap$;
  }
  set patientHeatmapFeatureColl(value: any) {
    this.patientHeatmapSource$.next(value);
  }

  private _trackPatient: boolean = false;
  set trackPatient(status: boolean) {
    this._trackPatient = status;
  }
  get trackPatient() {
    return this._trackPatient;
  }
  color = {
    darkGreen: [0, 255, 0, 0.8],
    lightGreen: [0, 255, 0, 0.3],
    lightRed: [255, 0, 0, 0.3],
    darkRed: [255, 0, 0, 0.3],
    darkishBlue: [0, 0, 255, 0.8],
    blue: [0, 0, 255, 1],
    gray: [19, 51, 55, 1],
    darkStefanoGreen: [51, 204, 255, 0.8],
    lightStefanoGreen: [51, 204, 255, 0.3],
    lightGray: [19, 51, 55, 0.3],
  };

  constructor(private mappaService: MappaService, private mapService: MapService) {}

  showPatientPositions() {
    // show Patient positions
    this.mappaService.clearMapLayer.next({
      id: "PatientPositions",
      reload: true,
    });
  }

  removePatientPositions() {
    // clear map
    // console.trace("----removePatientPositions");
    this.mappaService.clearMapLayer.next({
      id: "PatientPositions",
      reload: false,
    });
  }

  createGeoJsonGeometryFromFeature(
    feature,
    dataProjection = MapService.EPSG_4326_KEY,
    featureProjection = MapService.EPSG_3857_KEY
  ): Geometry {
    const geometryReadOptiones: ReadOptions = {
      dataProjection: dataProjection,
      featureProjection: featureProjection,
    };
    const geometry: Geometry = new GeoJSON().readGeometry(feature.geometry, geometryReadOptiones);
    return geometry;
  }

  insertPatientPositionFeatureInMapLayer(feature: any) {
    this.mappaService.insertFeatureMapLayer.next({
      layer: "PatientPositions",
      feature: feature,
    });
  }

  createVectorLayerFromUrl(featureUrl: string, title: string, type = TipoLayer.TEMP): Vector {
    const vectorOptions = {
      visible: true,
      opacity: 1,
      style: featureUrl.includes("linestring") ? this.styleSafePathFeature : this.styleFeature,
      source: this.createVectorSource(featureUrl),
    };
    const layer: Vector = new Vector(vectorOptions);
    layer.set(MapService.TITOLO_LAYER_KEY, title);
    layer.set(MapService.TIPO_LAYER_KEY, type);
    layer.set(MapService.POSIZIONE_LAYER_KEY, PosizioneLayer.OVERLAY);
    return layer;
  }
  createVectorLayerFromGeoJson(geoJson, title: string, type = TipoLayer.TEMP): Vector {
    const vectorOptions = {
      visible: true,
      opacity: 1,
      style: this.styleFeature,
      source: this.createVectorSourceFromGeoJson(geoJson),
    };
    const layer: Vector = new Vector(vectorOptions);
    layer.set(MapService.TITOLO_LAYER_KEY, title);
    layer.set(MapService.TIPO_LAYER_KEY, type);
    layer.set(MapService.POSIZIONE_LAYER_KEY, PosizioneLayer.OVERLAY);
    return layer;
  }

  createHeatmapVectorLayerFromUrl(featureUrl: string, title: string, type = TipoLayer.TEMP): HeatmapLayer {
    const heatmapOptions = {
      source: this.createVectorSource(featureUrl),
      radius: 20,
      blur: 15,
      weight: function (feature) {
        const value = feature.get("value");
        return value;
      },
    };

    const heatmapVectorLayer = new HeatmapLayer(heatmapOptions);
    // const defaultStyleFn = heatmapVectorLayer.getStyleFunction();
    // heatmapVectorLayer.setStyle((feature, resolution) => {
    //   const style = defaultStyleFn(feature, resolution);
    //   const geom = feature.getGeometry();
    //   if (geom.getType() === "LineString") {
    //     const coords: Coordinate[] = (geom as LineString).getCoordinates();
    //     console.log("coords: ", coords.length);
    //     if (coords.length > 0) {
    //       for (let i = 0; i < coords.length; i++) {
    //         style[0].setGeometry(new Point(coords[i]));
    //       }
    //     }
    //     // style[0].setGeometry(new Point((geom as LineString).getFirstCoordinate()));
    //     // style[0].setGeometry(new Point((geom as LineString).getCoordinateAt(0.5)));
    //     // style[0].setGeometry(new Point((geom as LineString).getLastCoordinate()));
    //   }
    //   return style;
    // });
    // heatmapVectorLayer.getSource().on("addfeature", (event: VectorSourceEvent<Geometry>) => {
    //   event.feature.set("weight", 4);
    // });
    heatmapVectorLayer.set(MapService.TITOLO_LAYER_KEY, title);
    heatmapVectorLayer.set(MapService.TIPO_LAYER_KEY, type);
    heatmapVectorLayer.set(MapService.POSIZIONE_LAYER_KEY, PosizioneLayer.OVERLAY);
    return heatmapVectorLayer;
  }

  createHeatmapVectorLayerFromGeoJson(geoJson, title: string, type = TipoLayer.TEMP): HeatmapLayer {
    const heatmapOptions = {
      source: this.createVectorSourceFromGeoJson(geoJson),
      radius: 20,
      blur: 15,
      weight: function (feature) {
        const properties = feature.getProperties();
        return properties.value;
      },
    };
    const heatmapVectorLayer = new HeatmapLayer(heatmapOptions);
    // const defaultStyleFn = heatmapVectorLayer.getStyleFunction();
    // heatmapVectorLayer.setStyle((feature, resolution) => {
    //   const style = defaultStyleFn(feature, resolution);
    //   const geom = feature.getGeometry();
    //   if (geom.getType() === "LineString") {
    //     const coords: Coordinate[] = (geom as LineString).getCoordinates();
    //     console.log("coords: ", coords.length);
    //     if (coords.length > 0) {
    //       for (let i = 0; i < coords.length; i++) {
    //         style[0].setGeometry(new Point(coords[i]));
    //       }
    //     }
    //   }
    //   return style;
    // });
    // heatmapVectorLayer.getSource().on("addfeature", (event: VectorSourceEvent<Geometry>) => {
    //   event.feature.set("weight", 4);
    // });
    heatmapVectorLayer.set(MapService.TITOLO_LAYER_KEY, title);
    heatmapVectorLayer.set(MapService.TIPO_LAYER_KEY, type);
    heatmapVectorLayer.set(MapService.POSIZIONE_LAYER_KEY, PosizioneLayer.OVERLAY);
    return heatmapVectorLayer;
  }

  createVectorSource(featureUrl: string): VectorSource<Geometry> {
    const vectorSourceOptions = {
      url: featureUrl,
      format: this.createVectorSourceFormat(),
    };
    const vectorSource = new VectorSource(vectorSourceOptions);
    return vectorSource;
  }

  createVectorSourceFromGeoJson(
    geoJsonObject,
    dataProjection = "EPSG:4326",
    featureProjection = "EPSG:3857"
  ): VectorSource<Geometry> {
    const formatOptions = {
      dataProjection: dataProjection,
      featureProjection: featureProjection,
    };
    const features = new GeoJSON().readFeatures(geoJsonObject, formatOptions);
    const vectorSource = new VectorSource({
      features: features,
    });
    return vectorSource;
  }

  createVectorSourceFormat(dataProjection = "EPSG:4326", featureProjection = "EPSG:3857"): GeoJSON {
    const formatOptions = {
      dataProjection: dataProjection,
      featureProjection: featureProjection,
    };
    const vectorSourceFormat = new GeoJSON(formatOptions);
    return vectorSourceFormat;
  }
  private randomProperty(obj) {
    const keys = Object.keys(obj);
    return obj[keys[(keys.length * Math.random()) << 0]];
  }

  styleSafePathFeature(feature: Feature, resolution): Style {
    const color = {
      lightGreen: [0, 255, 0, 0.3],
      lightStefanoGreen: [51, 204, 255, 0.3],
    };
    const styleOptions = {
      stroke: new Stroke({
        width: 25,
        color: color.lightStefanoGreen,
      }),
    };
    const style = new Style(styleOptions);
    feature.setStyle(style);
    return style;
  }
  styleFeature(feature: Feature, resolution): Style {
    const featureProperties = feature.getProperties();
    const color = {
      darkGreen: [0, 255, 0, 0.8],
      lightGreen: [0, 255, 0, 0.3],
      lightRed: [255, 0, 0, 0.3],
      darkRed: [255, 0, 0, 0.3],
      blue: [0, 0, 255, 0.8],
      gray: [19, 51, 55, 1],
      lightGray: [19, 51, 55, 0.3],
      darkStefanoGreen: [51, 204, 255, 0.8],
      lightStefanoGreen: [51, 204, 255, 0.3],
      white: [255, 255, 255],
    };
    // let randomColor = color[Object.keys(color)[(Object.keys(color).length * Math.random()) << 0]];
    // console.log("dentro STYLE feature, color is: ", randomColor);
    if (feature.getGeometry().getType() == "LineString") {
      // to do check prop and set color ...
      const styleOptions = {
        stroke: new Stroke({
          width: 3,
          color: color.lightGray,
        }),
      };
      const style = new Style(styleOptions);
      feature.setStyle(style);
    } else if (feature.getGeometry().getType() == "Polygon") {
      // console.log("feature prop", featureProperties);
      let darkColor =
        featureProperties.type === "danger"
          ? color.darkRed
          : featureProperties.type === "safe"
          ? color.darkStefanoGreen
          : color.blue;
      let lightColor =
        featureProperties.type === "danger"
          ? color.lightRed
          : featureProperties.type === "safe"
          ? color.lightStefanoGreen
          : color.blue;
      const styleOptions = {
        stroke: new Stroke({
          width: 3,
          color: darkColor,
        }),
        fill: new Fill({
          color: lightColor,
        }),
        text: new Text({
          text: featureProperties.name,
          stroke: new Stroke({ color: color.white, width: 3 }),
          fill: new Fill({ color: darkColor }),
          font: "bold 14px serif",
        }),
      };
      const style = new Style(styleOptions);
      return style;
    }
  }

  public styleSelectedPath(mapFeature: Feature<Geometry>) {
    const styleOptions = {
      zIndex: Infinity,
      stroke: new Stroke({
        width: 3,
        color: this.color.blue,
      }),
    };
    const style = new Style(styleOptions);
    // console.log("style feature: ", res.action);
    mapFeature.setStyle(style);
  }

  public styleUnselectedPath(mapFeature: Feature<Geometry>) {
    const styleOptions = {
      zIndex: 0,
      stroke: new Stroke({
        width: 3,
        color: this.color.lightGray,
      }),
    };
    const style = new Style(styleOptions);
    // console.log("style feature: ", res.action);
    mapFeature.setStyle(style);
  }
}

export interface IHandleLayer {
  action: ACTION;
  layer: Layer;
}
export interface IHandleFeature {
  action: ACTION;
  feature: any;
}

export enum ACTION {
  ADD = "add",
  REMOVE = "remove",
}
