import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
} from "@angular/core";
import {
  faDrawPolygon,
  faMap,
  faPlus,
  faMinus,
  faArrowsAlt,
  faMapMarkerAlt,
  faPencilRuler,
  faEdit,
  faPeopleCarry,
  faGlobe,
  faDraftingCompass,
  faParking,
  faLink,
  faLayerGroup,
  faStreetView
} from "@fortawesome/free-solid-svg-icons";
import { EbwModalService } from "../../library/ebw-modals-angular/ebw-modal.service";

/** Componente per i controlli della mappa zoom, localizzazione e schermo intero */
@Component({
  selector: "ebw-controlli-mappa",
  templateUrl: "./controlli-mappa.component.html",
  styleUrls: ["./controlli-mappa.component.scss"],
})
export class ControlliMappaComponent implements OnDestroy {
  /** Emettitore evento zoom in */
  @Output() zoomIn: EventEmitter<any> = new EventEmitter();

  /** Emettitore evento zoom out */
  @Output() zoomOut: EventEmitter<any> = new EventEmitter();

  /** Emettitore evento schermo intero */
  @Output() fullScreen: EventEmitter<any> = new EventEmitter();

  /** Emettitore evento localizzazione */
  @Output() localize: EventEmitter<any> = new EventEmitter();

  /** Emettitore evento per pannello trasformazione coordinate */
  @Output() btnTransformCoordinate: EventEmitter<boolean> = new EventEmitter();

  /** Emettitore evento per tool di disegno */
  @Output() btnToolDisegno: EventEmitter<boolean> = new EventEmitter();

  /** Emettitore evento per tool aggancia */
  @Output() btnToolAgganciaPunto: EventEmitter<boolean> = new EventEmitter();

  /** Emettitore evento per legenda */
  @Output() btnLegenda: EventEmitter<boolean> = new EventEmitter();
  /** Emit boolean event streetView */
  @Output() btnStreetView: EventEmitter<boolean> = new EventEmitter();

  /** Emettitore evento per tool scelta rappresentazione */
  @Output()
  btnSceltaRappresentazione: EventEmitter<boolean> = new EventEmitter();

  /** Emettitore evento per tool ricerca geometrica */
  @Output() btnRicercaGeometrica: EventEmitter<boolean> = new EventEmitter();

  /** Icona fontawesome per il segno più */
  faPlus = faPlus;

  /** Icona fontawesome per il segno meno */
  faMinus = faMinus;

  /** Icona fontawesome per lo schermo intero */
  faArrowsAlt = faArrowsAlt;

  /** Icona per la trasformazione delle coordinate */
  faGlobe = faGlobe;

  /** Icona per il pannello disegno */
  faDraftingCompass = faDraftingCompass;

  /** Icona per la localizzazione */
  faMapMarkerAlt = faMapMarkerAlt;

  /** Street view icon */
  faStreetView = faStreetView;

  faParking = faParking;

  faLink = faLink;

  /** Icona per la legenda */
  faMap = faMap;

  /** Icona per la scelta rappresentazione */
  faLayerGroup = faLayerGroup;

  faDrawPolygon = faDrawPolygon;

  // TODO DA ELIMINARE
  // ----------------------------------
  faPencilRuler = faPencilRuler;
  faEdit = faEdit;
  faPeopleCarry = faPeopleCarry;
  disegnoAttivo = false;
  modificaAttiva = false;
  spostamentoAttivo = false;
  // ----------------------------------

  /** Identificativo localizzatore genera il navigator watch position */
  localizzatoreId: number = undefined;

  @Input() statoPannelliSidebar: {
    trasforma_coord: boolean;
    disegno: boolean;
    particella: boolean;
    aggancia_punto: boolean;
    streetView: boolean;
    legenda: boolean;
    scelta_rappresentazione: boolean;
    ricerca_geometrica: boolean;
  };

  /** Variabile che indica lo stato del pannello di trasformazione delle coordinate */
  statoPannelloTrasformazioneCoordinate: boolean = false;

  /** Variabile che indica lo stato del tool di disegno */
  statoToolDisegno: boolean = false;

  /** Variabile che indica lo stato del tool aggancia punto */
  statoToolAgganciaPunto: boolean = false;

  /** Variabile che indica lo stato della legenda */
  statoLegenda: boolean = false;

  /** Variabile che indica lo stato del tool scelta rappresentazione */
  statoPannelloSceltaRappresentazione: boolean = false;

  /** Costruttore componente controlli */
  constructor(private ebwModalService: EbwModalService) {}

  /** ngOnDestroy */
  ngOnDestroy() {
    if (this.localizzatoreId !== undefined) {
      navigator.geolocation.clearWatch(this.localizzatoreId);
    }
  }

  /** Funzione emette l'evento di zoom IN */
  incrementaZoom() {
    this.zoomIn.emit("zoomin");
  }

  /** Funzione emette l'evento di zoom OUT */
  diminuisciZoom() {
    this.zoomOut.emit("zoomout");
  }

  /** Funzione che invoca l'emettitore per lo schermo intero */
  schermoIntero() {
    this.fullScreen.emit("fullScreen");
    const elem = document.documentElement;
    const methodToBeInvoked =
      elem.requestFullscreen ||
      elem["webkitRequestFullScreen"] ||
      elem["mozRequestFullScreen"] ||
      elem["msRequestFullscreen"];
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
  }

  /** Funzione attiva il tracciamento dell'utente ed emette la posizione rilevata */
  localizza() {
    if (this.localizzatoreId === undefined) {
      if (navigator.geolocation) {
        // controllo se Geolocation è supportato dal broswer
        this.localizzatoreId = navigator.geolocation.watchPosition(
          (position) => {
            this.localize.emit(position);
          }
        );
      } else {
        this.ebwModalService.openErrorModal({
          title: "Errore geolocalizzazione",
          content:
            "Geolocalizzazione non riuscita oppure non supportata da questo browser.",
        });
      }
    } else {
      navigator.geolocation.clearWatch(this.localizzatoreId);
      this.localizzatoreId = undefined;
      this.localize.emit(null);
    }
  }

  /** Funzione che apre il pannello per la trasformazione delle coordinate */
  trasformaCoordinate() {
    this.statoPannelliSidebar.trasforma_coord = !this.statoPannelliSidebar
      .trasforma_coord;

    /** Se ci sono altri tool abilitati li disabilito e attivo il tool trasforma coordinate */
    if (
      this.statoPannelliSidebar.trasforma_coord &&
      this.statoPannelliSidebar.disegno
    ) {
      console.log("-- Disattivo Il tool di disegno");
      //this.statoPannelliSidebar.disegno = false;
      this.statoPannelliSidebar.disegno = false;
      this.btnToolDisegno.emit(this.statoPannelliSidebar.disegno);
    } else if (
      this.statoPannelliSidebar.trasforma_coord &&
      this.statoPannelliSidebar.aggancia_punto
    ) {
      console.log("-- Disattivo tool aggancia punto");
      this.statoPannelliSidebar.aggancia_punto = false;
      this.btnToolAgganciaPunto.emit(this.statoPannelliSidebar.aggancia_punto);
    } else if (
      this.statoPannelliSidebar.trasforma_coord &&
      this.statoPannelliSidebar.legenda
    ) {
      console.log("-- Disattivo tool legenda");
      this.statoPannelliSidebar.legenda = false;
      this.btnLegenda.emit(this.statoPannelliSidebar.legenda);
    } else if (
      this.statoPannelliSidebar.trasforma_coord &&
      this.statoPannelliSidebar.scelta_rappresentazione
    ) {
      console.log("-- Disattivo pannello scelta rappresentazione");
      this.statoPannelliSidebar.scelta_rappresentazione = false;
      this.btnSceltaRappresentazione.emit(
        this.statoPannelliSidebar.scelta_rappresentazione
      );
    } else if (
      this.statoPannelliSidebar.trasforma_coord &&
      this.statoPannelliSidebar.ricerca_geometrica
    ) {
      console.log("-- Disattivo pannello ricerca geometrica");
      this.statoPannelliSidebar.ricerca_geometrica = false;
      this.btnRicercaGeometrica.emit(
        this.statoPannelliSidebar.ricerca_geometrica
      );
    }

    this.btnTransformCoordinate.emit(this.statoPannelliSidebar.trasforma_coord);
    console.log(
      "-- TOOL COORDINATE",
      this.statoPannelliSidebar.trasforma_coord
    );
  }

  /** Funzione che apre il tool di disegno */
  toolDisegno() {
    this.statoPannelliSidebar.disegno = !this.statoPannelliSidebar.disegno;

    /** Se ci sono altri tool abilitati li disabilito e attivo il tool di disegno */
    if (
      this.statoPannelliSidebar.disegno &&
      this.statoPannelliSidebar.trasforma_coord
    ) {
      console.log("-- Disattivo pannello coordinate");
      //this.statoPannelliSidebar.disegno = false;
      this.statoPannelliSidebar.trasforma_coord = false;
      this.btnTransformCoordinate.emit(
        this.statoPannelliSidebar.trasforma_coord
      );
    } else if (
      this.statoPannelliSidebar.disegno &&
      this.statoPannelliSidebar.aggancia_punto
    ) {
      console.log("-- Disattivo tool aggancia punto");
      this.statoPannelliSidebar.aggancia_punto = false;
      this.btnToolAgganciaPunto.emit(this.statoPannelliSidebar.aggancia_punto);
    } else if (
      this.statoPannelliSidebar.disegno &&
      this.statoPannelliSidebar.legenda
    ) {
      console.log("-- Disattivo tool legenda");
      this.statoPannelliSidebar.legenda = false;
      this.btnLegenda.emit(this.statoPannelliSidebar.legenda);
    } else if (
      this.statoPannelliSidebar.disegno &&
      this.statoPannelliSidebar.scelta_rappresentazione
    ) {
      console.log("-- Disattivo pannello scelta rappresentazione");
      this.statoPannelliSidebar.scelta_rappresentazione = false;
      this.btnSceltaRappresentazione.emit(
        this.statoPannelliSidebar.scelta_rappresentazione
      );
    } else if (
      this.statoPannelliSidebar.disegno &&
      this.statoPannelliSidebar.ricerca_geometrica
    ) {
      console.log("-- Disattivo pannello ricerca geometrica");
      this.statoPannelliSidebar.ricerca_geometrica = false;
      this.btnRicercaGeometrica.emit(
        this.statoPannelliSidebar.ricerca_geometrica
      );

      /**
       * Se ho chiuso il pannello di ricerca geometrica perché volevo aprire il tool di disegno (tramite controlli-mappa)
       * allora riabilito il tool di disegno, altrimenti in automatico quando chiudo la ricerca geometrica viene chiuso anche il
       * tool di disegno
       */
      this.statoPannelliSidebar.disegno = true;
    }

    console.log("-- TOOL DI DISEGNO", this.statoPannelliSidebar.disegno);
    this.btnToolDisegno.emit(this.statoPannelliSidebar.disegno);
  }

  /** Funzione che apre il pannello per la ricerca geometrica (Funzionalità INWIT) */
  toolRicercaGeometrica() {
    this.statoPannelliSidebar.ricerca_geometrica = !this.statoPannelliSidebar
      .ricerca_geometrica;

    /** Se ci sono altri tool abilitati li disabilito e attivo il tool trasforma coordinate */
    if (
      this.statoPannelliSidebar.ricerca_geometrica &&
      this.statoPannelliSidebar.disegno
    ) {
      console.log("-- Disattivo Il tool di disegno");
      //this.statoPannelliSidebar.disegno = false;
      this.statoPannelliSidebar.disegno = false;
      this.btnToolDisegno.emit(this.statoPannelliSidebar.disegno);
    } else if (
      this.statoPannelliSidebar.ricerca_geometrica &&
      this.statoPannelliSidebar.trasforma_coord
    ) {
      console.log("-- Disattivo pannello coordinate");
      //this.statoPannelliSidebar.disegno = false;
      this.statoPannelliSidebar.trasforma_coord = false;
      this.btnTransformCoordinate.emit(
        this.statoPannelliSidebar.trasforma_coord
      );
    } else if (
      this.statoPannelliSidebar.ricerca_geometrica &&
      this.statoPannelliSidebar.aggancia_punto
    ) {
      console.log("-- Disattivo tool aggancia punto");
      this.statoPannelliSidebar.aggancia_punto = false;
      this.btnToolAgganciaPunto.emit(this.statoPannelliSidebar.aggancia_punto);
    } else if (
      this.statoPannelliSidebar.ricerca_geometrica &&
      this.statoPannelliSidebar.legenda
    ) {
      console.log("-- Disattivo tool legenda");
      this.statoPannelliSidebar.legenda = false;
      this.btnLegenda.emit(this.statoPannelliSidebar.legenda);
    } else if (
      this.statoPannelliSidebar.ricerca_geometrica &&
      this.statoPannelliSidebar.scelta_rappresentazione
    ) {
      console.log("-- Disattivo pannello scelta rappresentazione");
      this.statoPannelliSidebar.scelta_rappresentazione = false;
      this.btnSceltaRappresentazione.emit(
        this.statoPannelliSidebar.scelta_rappresentazione
      );
    }

    this.btnRicercaGeometrica.emit(
      this.statoPannelliSidebar.ricerca_geometrica
    );
    console.log(
      "-- TOOL RICERCA GEOMETRICA",
      this.statoPannelliSidebar.ricerca_geometrica
    );
  }

  /** Funzione che apre il tool aggancia punto */
  toolAgganciaPunto() {
    this.statoPannelliSidebar.aggancia_punto = !this.statoPannelliSidebar
      .aggancia_punto;
    // this.btnToolAgganciaPunto.emit(this.statoPannelliSidebar.aggancia_punto);

    /** Se ci sono altri tool abilitati li disabilito e attivo il tool aggancia punto */
    if (
      this.statoPannelliSidebar.aggancia_punto &&
      this.statoPannelliSidebar.trasforma_coord
    ) {
      console.log("-- Disattivo pannello coordinate");
      //this.statoPannelliSidebar.disegno = false;
      this.statoPannelliSidebar.trasforma_coord = false;
      this.btnTransformCoordinate.emit(
        this.statoPannelliSidebar.trasforma_coord
      );
    } else if (
      this.statoPannelliSidebar.aggancia_punto &&
      this.statoPannelliSidebar.disegno
    ) {
      console.log("-- Disattivo tool di disegno");
      this.statoPannelliSidebar.disegno = false;
      this.btnToolDisegno.emit(this.statoPannelliSidebar.disegno);
    } else if (
      this.statoPannelliSidebar.aggancia_punto &&
      this.statoPannelliSidebar.legenda
    ) {
      console.log("-- Disattivo tool legenda");
      this.statoPannelliSidebar.legenda = false;
      this.btnLegenda.emit(this.statoPannelliSidebar.legenda);
    } else if (
      this.statoPannelliSidebar.aggancia_punto &&
      this.statoPannelliSidebar.scelta_rappresentazione
    ) {
      console.log("-- Disattivo pannello scelta rappresentazione");
      this.statoPannelliSidebar.scelta_rappresentazione = false;
      this.btnSceltaRappresentazione.emit(
        this.statoPannelliSidebar.scelta_rappresentazione
      );
    }

    this.btnToolAgganciaPunto.emit(this.statoPannelliSidebar.aggancia_punto);
    console.log(
      "-- TOOL AGGANCIA PUNTO",
      this.statoPannelliSidebar.aggancia_punto
    );
  }

  /** Funzione che apre la legenda*/
  legenda() {
    this.statoPannelliSidebar.legenda = !this.statoPannelliSidebar.legenda;

    /** Se ci sono altri tool abilitati li disabilito e attivo il tool legenda */
    if (
      this.statoPannelliSidebar.legenda &&
      this.statoPannelliSidebar.trasforma_coord
    ) {
      console.log("-- Disattivo pannello coordinate");
      this.statoPannelliSidebar.trasforma_coord = false;
      this.btnTransformCoordinate.emit(
        this.statoPannelliSidebar.trasforma_coord
      );
    } else if (
      this.statoPannelliSidebar.legenda &&
      this.statoPannelliSidebar.disegno
    ) {
      console.log("-- Disattivo tool di disegno");
      this.statoPannelliSidebar.disegno = false;
      this.btnToolDisegno.emit(this.statoPannelliSidebar.disegno);
    } else if (
      this.statoPannelliSidebar.legenda &&
      this.statoPannelliSidebar.aggancia_punto
    ) {
      console.log("-- Disattivo tool aggancia punto");
      this.statoPannelliSidebar.aggancia_punto = false;
      this.btnToolAgganciaPunto.emit(this.statoPannelliSidebar.aggancia_punto);
    } else if (
      this.statoPannelliSidebar.legenda &&
      this.statoPannelliSidebar.scelta_rappresentazione
    ) {
      console.log("-- Disattivo pannello scelta rappresentazione");
      this.statoPannelliSidebar.scelta_rappresentazione = false;
      this.btnSceltaRappresentazione.emit(
        this.statoPannelliSidebar.scelta_rappresentazione
      );
    } else if (
      this.statoPannelliSidebar.legenda &&
      this.statoPannelliSidebar.ricerca_geometrica
    ) {
      console.log("-- Disattivo pannello ricerca geometrica");
      this.statoPannelliSidebar.ricerca_geometrica = false;
      this.btnRicercaGeometrica.emit(
        this.statoPannelliSidebar.ricerca_geometrica
      );
    }

    this.btnLegenda.emit(this.statoPannelliSidebar.legenda);
    console.log("-- LEGENDA", this.statoPannelliSidebar.legenda);
  }

  /** Funzione che apre il tool scelta rappresentazione */
  sceltaRappresentazione() {
    console.log(
      "PRIMA this.statoPannelliSidebar.scelta_rappresentazione: ",
      this.statoPannelliSidebar.scelta_rappresentazione
    );
    this.statoPannelliSidebar.scelta_rappresentazione = !this
      .statoPannelliSidebar.scelta_rappresentazione;
    console.log(
      "DOPO this.statoPannelliSidebar.scelta_rappresentazione: ",
      this.statoPannelliSidebar.scelta_rappresentazione
    );

    /** Se ci sono altri tool abilitati li disabilito e attivo il tool scelta rappresentazione */
    if (
      this.statoPannelliSidebar.scelta_rappresentazione &&
      this.statoPannelliSidebar.trasforma_coord
    ) {
      console.log("-- Disattivo pannello coordinate");
      this.statoPannelliSidebar.trasforma_coord = false;
      this.btnTransformCoordinate.emit(
        this.statoPannelliSidebar.trasforma_coord
      );
    } else if (
      this.statoPannelliSidebar.scelta_rappresentazione &&
      this.statoPannelliSidebar.disegno
    ) {
      console.log("-- Disattivo tool di disegno");
      this.statoPannelliSidebar.disegno = false;
      this.btnToolDisegno.emit(this.statoPannelliSidebar.disegno);
    } else if (
      this.statoPannelliSidebar.scelta_rappresentazione &&
      this.statoPannelliSidebar.aggancia_punto
    ) {
      console.log("-- Disattivo tool aggancia punto");
      this.statoPannelliSidebar.aggancia_punto = false;
      this.btnToolAgganciaPunto.emit(this.statoPannelliSidebar.aggancia_punto);
    } else if (
      this.statoPannelliSidebar.scelta_rappresentazione &&
      this.statoPannelliSidebar.legenda
    ) {
      console.log("-- Disattivo tool legenda");
      this.statoPannelliSidebar.legenda = false;
      this.btnLegenda.emit(this.statoPannelliSidebar.legenda);
    } else if (
      this.statoPannelliSidebar.scelta_rappresentazione &&
      this.statoPannelliSidebar.ricerca_geometrica
    ) {
      console.log("-- Disattivo pannello ricerca geometrica");
      this.statoPannelliSidebar.ricerca_geometrica = false;
      this.btnRicercaGeometrica.emit(
        this.statoPannelliSidebar.ricerca_geometrica
      );
    }

    this.btnSceltaRappresentazione.emit(
      this.statoPannelliSidebar.scelta_rappresentazione
    );
    console.log(
      "-- TOOL SCELTA RAPPRESENTAZIONE",
      this.statoPannelliSidebar.scelta_rappresentazione
    );
  }

  public onHandleStreetView(){
    console.log("handleStreetView");
    this.statoPannelliSidebar.streetView = !this.statoPannelliSidebar.streetView;
    this.btnStreetView.emit(this.statoPannelliSidebar.streetView);
  }
}
