import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { GestioneAttiAmmService } from '../../servizi/atti-amministrativi/gestione-atti-amm.service';


interface Idata {
   year: number, dataTxt?: string, month?: string;
}

@Component({
   // tslint:disable-next-line:component-selector
   selector: 'year-date-picker',
   templateUrl: './year-date-picker.component.html',
   styleUrls: ['./year-date-picker.component.css'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => YearDatePickerComponent),
         multi: true
      }
   ]
})
export class YearDatePickerComponent implements ControlValueAccessor {
   NAMERE: RegExp = /^[0-9]{4}?$/;

   data: Idata;
   dataTxt: string;
   separator: string;
   monthFirst: boolean;
   place: number;

   isyear = true;
   incr = 0;


   cells: Array<string> = new Array(12);
   // Allow the input to be disabled, and when it is make it somewhat transparent.
   @Input() disabled = false;
   @Input() mask = 'aaaa';
   @Input() name = 'test';
   @Input() current_tip = 'Anno';
   @Input() has_error = false;


   @ViewChild('calendarPanel', { static: false }) calendar: NgbDropdown;

   constructor(private service$: GestioneAttiAmmService) {
   }

   change(value: string) {
      //console.log('change year ' + value);
      // tslint:disable-next-line:radix

      if (!this.NAMERE.test(value)) {
         this.data = { year: -1, dataTxt: this.dataTxt };
         // console.log('non valid? '+this.NAMERE.test(value)+' -- -- '+this.data.year)
         this.writeValue(this.data);
         return;
      }

      let iyear = parseInt(value);
      this.data = { year: iyear, dataTxt: this.dataTxt };
      this.incr = this.getIncr(this.data.year);

      this.writeValue(this.data);
   }

   selectYearMonth($event, index: number) {
      $event.stopPropagation();
      this.data.year = index + this.incr;
      this.dataTxt = this.formatData(this.data);
      this.data.dataTxt = this.dataTxt;
      this.incr = this.getIncr(this.data.year);
      //this._selectedItem( this.data.year.toString() );
      this.calendar.close();
   }

   addYear($event: any, incr: number) {
      $event.stopPropagation();
      const year = this.isyear ? this.data.year + 10 * incr : this.data.year + incr;
      this.data.year = year;
      this.incr = this.getIncr(year);
      this.dataTxt = this.formatData(this.data);

   }
   onChange = (data: Idata) => {
      this.data = data;

      // non spostare perchè altrimenti si reimposta il dataTxt
      if (this.formatData(this.data) === '') {
         this.data = { year: -1, dataTxt: this.dataTxt };
         this.data.year = (new Date()).getFullYear();
         this.dataTxt = '';
      } else {
         this.dataTxt = data.year.toString();
      }
      this.incr = this.getIncr(this.data.year);
   }

   getIncr(year: number): number {
      return (year - year % 10) - 1;
   }

   formatData(data: Idata): string {
      if (!data || data === undefined) { return ''; }
      const a = (data.year === null) ? '' : data.year.toString();
      return a.toString();
   }
   // Function to call when the input is touched (when a star is clicked).
   onTouched = () => { };

   writeValue(data: Idata): void {
      this.data = data;
      this.onChange(this.data);
   }

   // Allows Angular to register a function to call when the model (rating) changes.\
   // Save the function as a property to call later here.
   registerOnChange(fn: (data: Idata) => void): void {
      this.onChange = fn;
   }

   // Allows Angular to register a function to call when the input has been touched.
   // Save the function as a property to call later here.
   registerOnTouched(fn: () => void): void {
      this.onTouched = fn;
   }

   // Allows Angular to disable the input.
   setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
   }


   selectItem(data: Idata) {
      console.log('selectItem ' + data);
      this.data = data;

      // non spostare perchè altrimenti si reimposta il dataTxt
      if (this.formatData(this.data) === '') {
         this.data.year = (new Date()).getFullYear();
         this.dataTxt = '';
      } else {
         this.dataTxt = data.year.toString();
      }
      this.incr = this.getIncr(this.data.year);
   }

   resetValue() {
      /* sbianca i valori e fa partire i trigger legati al selectedItem in questo caso vuoto) */
      // sbianco il campo
      const data: Idata = { year: null };
      // selezione valore vuoto
      this.writeValue(data);
      return;

   }

   isValid(year) {
      console.log(year);
      console.log(year);

      if (!year || year == '') return true;
      return this.NAMERE.test(year);

   }
}
