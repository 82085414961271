import { Component, Input } from '@angular/core';

@Component({
   // tslint:disable-next-line:component-selector
   selector: 'shr-title',
   templateUrl: './shr-title.component.html',
   styleUrls: ['./shr-title.component.css']
})
export class ShrTitleComponent{

   @Input() current_title: string;

   constructor() {}

}
