<div ngDraggable *ngIf="formReady && toolbarVisible" id="toolDisegnoContainer" class="position-absolute">
   <div class="container-fluid border border-secondary rounded bg-white p-1">

      <!-- <p>pannelloParticellaAbilitato : {{pannelloParticellaAbilitato}}</p>
      <p>tipoGeometriaSelezionata : {{tipoGeometriaSelezionata}}</p> -->
      <!-- <div>
      historyChangesSize :
      {{historyChangesSize}}
   </div>
   <div>
      isEdit :
      {{isEdit}}
   </div>
   <div>
      idFeatureSelezionata:
      {{idFeatureSelezionata}}
   </div>
   <div>
      disegnoAttivoDisabilitaTool:
      {{disegnoAttivoDisabilitaTool}}
   </div> -->

      <form [formGroup]="toolbarForm">

         <!---------------------------------------------------------------------------------------------------------------------->
         <!--                                                    TOOLBAR                                                       -->
         <!---------------------------------------------------------------------------------------------------------------------->
         <div class="d-inline btn-group btn-group-toggle" ngbRadioGroup name="disegno" formControlName="disegno">

            <!-- Punto  -->
            <label *ngIf="enabledTools.point" ngbButtonLabel ngbTooltip="Punto" container="body" placement="bottom"
               [ngClass]=" (toolbarForm.controls['disegno'].value === 'Point') ? 'toolButtonActive' : 'toolButton'">
               <input ngbButton name="disegno" type="radio" value="Point"
                  ng-checked="toolbarDefaultSelectedTool =='Point'"
                  [disabled]="isDisegnoAttivoDisabilitaTool() && toolbarForm.controls['disegno'].value!='Point'">
               <fa-icon [icon]="faCircle"></fa-icon>
            </label>

            <!-- Linea -->
            <label *ngIf="enabledTools.line" ngbButtonLabel ngbTooltip="Linea" container="body" placement="bottom"
               [ngClass]=" (toolbarForm.controls['disegno'].value === 'LineString') ? 'toolButtonActive' : 'toolButton'">
               <input ngbButton name="disegno" type="radio" value="LineString"
                  ng-checked="toolbarDefaultSelectedTool =='LineString'"
                  [disabled]="isDisegnoAttivoDisabilitaTool() && toolbarForm.controls['disegno'].value!='LineString'">
               <fa-icon [icon]="faMinus"></fa-icon>
            </label>

            <!-- Poligono -->
            <label *ngIf="enabledTools.polygon" ngbButtonLabel ngbTooltip="Poligono" container="body" placement="bottom"
               [ngClass]=" (toolbarForm.controls['disegno'].value === 'Polygon') ? 'toolButtonActive' : 'toolButton'">
               <input ngbButton name="disegno" type="radio" value="Polygon"
                  ng-checked="toolbarDefaultSelectedTool =='Polygon'"
                  [disabled]="isDisegnoAttivoDisabilitaTool() && toolbarForm.controls['disegno'].value!='Polygon'">
               <fa-icon [icon]="faDrawPolygon"></fa-icon>
            </label>

            <!-- Cerchio -->
            <label *ngIf="enabledTools.circle" ngbButtonLabel ngbTooltip="Cerchio" container="body" placement="bottom" class="border toolButton"
               [ngClass]=" (toolbarForm.controls['disegno'].value === 'Circle') ? 'toolButtonActive' : 'toolButton'">
               <input ngbButton name="disegno" type="radio" value="Circle"
                  ng-checked="toolbarDefaultSelectedTool =='Circle'"
                  [disabled]="isDisegnoAttivoDisabilitaTool() && toolbarForm.controls['disegno'].value!='Circle'">
               <fa-icon [icon]="faDotCircle"></fa-icon>
            </label>

            <!-- SID3 TOOL PARTICELLA -->
            <label *ngIf="enabledTools.particella" ngbButtonLabel ngbTooltip="Tool Particella" container="body" placement="bottom"
               class="border toolButton"
               [ngClass]=" (toolbarForm.controls['disegno'].value === 'Particella') ? 'toolButtonActive' : 'toolButton'">
               <input ngbButton name="disegno" type="radio" value="Particella"
                  ng-checked="toolbarDefaultSelectedTool =='Particella'"
                  [disabled]="isDisegnoAttivoDisabilitaTool() && toolbarForm.controls['disegno'].value!='Particella'">
               <fa-icon [icon]="faParking"></fa-icon>
            </label>
            <!-- SID3 TOOL PARTICELLA -->

         </div>

         <!-- FUNZIONALITA' -->
         <div class="d-inline align-middle ml-1">

            <!-- Undo -->
            <button type="button" class="btn btn-bg-outline" placement="bottom" ngbTooltip="Undo modifica"
               container="body"
               [disabled]="historyChangesSize == 0 || !isPannelloCoordDisabled() || isFeatureAtPixelTDVisible()"
               (click)="invokeUndo()">
               <fa-icon [icon]="faUndo"></fa-icon>
            </button>

            <!-- Pannello Coordinate -->
            <button type="button" class="btn bg-blu txt-white ml-1" placement="bottom" [ngbTooltip]="pannelloTooltip()"
               container="body" (click)="gestisciPannelloCoordinate()" [disabled]="isPannelloCoordDisabled()">
               <fa-icon [icon]="faListAlt"></fa-icon>
            </button>

         </div>
      </form>
   </div>
</div>


<!---------------------------------------------------------------------------------------------------------------------->
<!--                                           PANNELLO SELEZIONE MULTIPLA                                            -->
<!-- Pannello di gestione della selezione di feature multiple (Più features nello stesso pixel)                       -->
<!-- Se ci sono più features nel pixel selezionato mostro un pannellino per la scelta                                 -->
<!---------------------------------------------------------------------------------------------------------------------->
<div ngDraggable *ngIf="isFeatureAtPixelTDVisible()" id="selectedFeaturesAtPixelContainer"
   class="position-absolute w-25 container-fluid border border-secondary rounded bg-white p-1">
   <div class="row no-gutters">
      <div class="col-10 text-left">
         <h5 class="m-0">Seleziona geometria</h5>
      </div>
      <div class="col-2 text-right">
         <button type="button" class="btn btn-sm btn-bg-outline" ngbTooltip="Chiudi il pannello di selezione"
            container="body" (click)="chiudiPannelloSelezioneMultipla()">
            <fa-icon [icon]="faTimes"></fa-icon>
         </button>
      </div>
   </div>
   <hr class="my-1">
   <ul class="container-fluid list-group list-group-flush pr-0">
      <li *ngFor="let feature of featuresAtPixelTD; let i = index" class="list-group-item p-1 myYellowLi"
         (mouseout)="spegniFeature(feature)" (mouseover)="illuminaFeature(feature)"
         (click)="gestisciFeatureSelezioneMultipla(feature)">
         <div class="row no-gutters">
            <div class="col-1 text-center my-auto">
               <span class="badge badge-pill badge-info badge-reposition">{{i+1}}</span>
            </div>
            <span class="col-1 text-center">
               <fa-icon ngbTooltip="{{GEOM_TYPE_LABEL[feature.getGeometry().getType()]}}"
                  [icon]="GEOM_TYPE_ICON[feature.getGeometry().getType()]">
               </fa-icon>
            </span>
            <span class="col-4 text-left">{{GEOM_TYPE_LABEL[feature.getGeometry().getType()]}}</span>
            <span class="col-6 text-left">{{feature.getProperties().nome}}</span>
         </div>
      </li>
   </ul>
</div>


<!---------------------------------------------------------------------------------------------------------------------->
<!--                                                PANNELLO COORDINATE                                               -->
<!---------------------------------------------------------------------------------------------------------------------->
<div ngDraggable *ngIf="pannelloCoordinateAbilitato" id="pannelloCoordinateContainer"
   [ngStyle]="{'display': displayPc ? 'none' : 'block'}"
   class="position-absolute p-2 container-fluid bg-white rounded border border-secondary" style="width: 30%!important;">
   <div class="row no-gutters">
      <div class="col-10 text-left">
         <h5 class="m-0 txt-bg-blu font-weight-bold">Pannello Coordinate</h5>
      </div>
      <div class="col-2 text-right">
         <button type="button" class="btn btn-sm btn-bg-outline" ngbTooltip="Chiudi il pannello di selezione"
            (click)="gestioneClickAnnullaFigura()">
            <fa-icon [icon]="faTimes"></fa-icon>
         </button>
      </div>
   </div>

   <hr class="my-1">

   <!-- Pannello coordinate form -->
   <form [formGroup]="pannelloCoordinateForm">
      <!-- Nome geometria -->
      <div class="row no-gutters">
         <div class="col mb-2">
            <label for="nome">Nome Geometria</label>
            <input class="form-control" type="text" id="nome" formControlName="nome" placement="top"
               ngbTooltip="Nome Geometria" placeholder="Nome Geometria">
         </div>
      </div>
      <!-- EPSG -->
      <div class="row no-gutters">
         <div class="col mb-2">
            <label>Sistema di Riferimento</label>
            <div class="input-group">
               <div class="input-group-prepend">
                  <div class="input-group-text">EPSG</div>
               </div>
               <select class="custom-select form-control" id="epsgSelect" formControlName="epsg" placement="top"
                  ngbTooltip="Seleziona EPSG">
                  <option *ngFor="let epsg of EPSG_LIST" [ngValue]="epsg.value"> {{ epsg.label }}</option>
               </select>
            </div>
         </div>
      </div>

      <hr class="my-1">

      <div class="row no-gutters">
         <div class="col">
            <h6
               *ngIf="tipoGeometriaSelezionata == 'Point' || tipoGeometriaSelezionata == 'LineString' || tipoGeometriaSelezionata == 'Polygon'">
               Lista Coordinate</h6>
            <h6 *ngIf="tipoGeometriaSelezionata == 'Circle'">Cerchio</h6>
         </div>
      </div>
      <div *ngIf="(toolbarForm.controls['disegno'].value !== ''); else elseBlock" class="container-fluid p-1 rounded">

         <!-- Raggio Cerchio -->
         <div *ngIf="tipoGeometriaSelezionata == 'Circle'" class="row no-gutters">
            <div class="col">
               <label for="raggio">Raggio (m)</label>
               <input class="form-control" type="text" id="raggio" formControlName="raggio" (blur)="updateCircle()"
                  placement="top" ngbTooltip="Raggio (Metri)" placeholder="Metri">
            </div>
         </div>

         <div *ngIf="tipoGeometriaSelezionata == 'Circle'" class="row no-gutters">
            <div class="col">
               <label>Centro</label>
            </div>
         </div>

         <!-- Se non c'è ancora nessun punto inserito -->
         <div class="row no-gutters my-1" *ngIf="insertedPrecisionFeatures().length == 0">
            <div class="col-9 text-left"><span class="align-middle">Nessun punto inserito</span></div>
            <div class="col-3 text-right">
               <button type="button" class="btn btn-bg-outline btn-sm" placement="top" ngbTooltip="Aggiungi Punto"
                  container="body" (click)="aggiungiPunto(-1)">
                  <fa-icon [icon]="faPlus"></fa-icon>
               </button>
               <button *ngIf="isAgganciaPuntoVisible()" type="button" class="btn btn-bg-outline-info btn-sm" placement="left" ngbTooltip="Aggancia Punto"
                  container="body" [disabled]="isAgganciaPuntoDisabled()" (click)="toolAgganciaPunto()">
                  <fa-icon [icon]="faLink"></fa-icon>
               </button>
            </div>
         </div>

         <!-- Lista coordinate -->
         <div class="row no-gutters">
            <div class="col">
               <!-- Se sono già stati inseriti dei punti -->
               <ul *ngIf="insertedPrecisionFeatures().length > 0"
                  class="list-group container-fluid coordsContainer list-group-flush pr-0" #coordsListContainer>
                  <li *ngFor="let feature of insertedPrecisionFeatures(); let i = index"
                     [ngClass]="{'highlight':feature.get('highlight')}" class="list-group-item m-0 px-0 py-1"
                     #coordItem>
                     <div class="row no-gutters">
                        <div class="col-1 text-center my-auto">
                           <span class="badge badge-pill badge-info badge-reposition">{{i+1}}</span>
                        </div>
                        <div class="col text-center my-auto ml-1">
                           <input type="text" class=" d-inline form-control" name="coord-x" placement="right"
                              container="body" [ngbTooltip]="ttCoordX" (blur)="updateCoords($event,feature)"
                              [value]="(!!feature.get('transformedCoords') ? feature.get('transformedCoords')[0] : '-')">
                        </div>

                        <div class="col text-center my-auto ml-1 mr-1">
                           <input type="text" class="d-inline form-control" name="coord-y" placement="left"
                              container="body" [ngbTooltip]="ttCoordY" (blur)="updateCoords($event,feature)"
                              [value]="(!!feature.get('transformedCoords') ? feature.get('transformedCoords')[1] : '-')">
                        </div>

                        <div class="col-3 text-center my-auto">
                           <div class="btn-group" role="group" aria-label="Gestione punto">
                              <button type="button" class=" btn btn-bg-outline btn-sm" placement="left"
                                 ngbTooltip="Elimina Punto" container="body"
                                 [disabled]="existsUnconfirmedFeature() && !isUnconfirmedFeature(feature)"
                                 (click)="deletePoint(feature)">
                                 <fa-icon [icon]="faTrash"></fa-icon>
                              </button>
                              <button type="button" class="btn btn-bg-outline btn-sm" placement="left"
                                 ngbTooltip="Aggiungi Punto" container="body" [disabled]="isAggiungiPuntoDisabled()"
                                 (click)="aggiungiPunto(feature.get('precisionCounterPlaceholder'))">
                                 <fa-icon [icon]="faPlus"></fa-icon>
                              </button>
                              <button 
                                 *ngIf="isAgganciaPuntoVisible()"
                                 type="button" class="btn btn-bg-outline-info btn-sm" 
                                 placement="left"
                                 ngbTooltip="Aggancia Punto" 
                                 container="body" 
                                 [disabled]="isAgganciaPuntoDisabled()"
                                 (click)="toolAgganciaPunto(feature.get('precisionCounterPlaceholder'))">
                                 <fa-icon [icon]="faLink"></fa-icon>
                              </button>

                           </div>
                        </div>
                     </div>
                  </li>
               </ul>
            </div>
         </div>
     
      </div>



      <ng-template #elseBlock>
         <div class="container-fluid p-1 rounded">
            <div class="row no-gutters">
               <div class="col">
                  <h6 class="text-primary">Selezionare uno strumento dalla Toolbar</h6>
               </div>
            </div>
         </div>
      </ng-template>

      <div class="row no-gutters mt-1">
         <div class="col text-center">
            <button type="button" class="btn btn-dark btn-sm" ngbTooltip="Elimina geometria"
               *ngIf="visibilitaBtnElimina" (click)="gestioneClickEliminaFigura()" [disabled]="!isEdit">
               <fa-icon [icon]="faTrash"></fa-icon> Elimina
            </button>

            <button type="button" class="btn btn-bg-outline btn-sm" ngbTooltip="Annulla modifiche"
               *ngIf="visibilitaBtnAnnulla" (click)="gestioneClickAnnullaFigura()">
               <fa-icon [icon]="faTrash"></fa-icon> Annulla
            </button>


         </div>
         <div class="col text-center">
            <button type="button" class="btn bg-blu txt-white btn-sm" ngbTooltip="Conferma geometria e termina"
               [disabled]="!isFeatureValid()" (click)="gestioneClickConfermaFigura()">
               <fa-icon [icon]="faCheck"></fa-icon> Conferma
            </button>

         </div>
      </div>
   </form>
</div>
