<link rel="stylesheet" href="https://openlayers.org/en/v5.3.0/css/ol.css" type="text/css">
<div class="container-fluid" [class.sb-open]="rightSBarOpened">
   <div class="row">
      <div class="col p-0">

         <div class="torna-pagina" [hidden]="!showBackButton">
            <button type="button" class="btn bg-blu txt-white" placement="bottom"
               ngbTooltip="Torna alla pagina di ricerca" (click)="onBackButtonClick();">
               Torna alla pagina di ricerca
            </button>
         </div>

         <div class="position-relative map-dim" [ngStyle]="altezza" [class.sb-open-content]="rightSBarOpened">
            <ebw-map [infoMappaAttivo]="true" [previewMappaAttiva]="true" [controlliMappaAttivi]="controlliMappaAttivi"
               [layerSfondoMappaAttivo]="true" [selezioneOggettiAttiva]="true" [zoom]="configMappa.mapSetup.zoom" [login]="login"
               [zoomMassimo]="configMappa.mapSetup.zoomMassimo" [zoomMinimo]="configMappa.mapSetup.zoomMinimo"
               [centroMappa4326]="configMappa.mapSetup.coordinate"
               [arrotondamentoInfoVista]="configMappa.infoViewSetup.round"
               [sogliaZoomPreview]="configMappa.mapPreviewSetup.sogliaZoom"
               [altezzaPreview]="configMappa.mapPreviewSetup.altezza"
               [larghezzaPreview]="configMappa.mapPreviewSetup.larghezza"
               [idStilePreview]="configMappa.mapPreviewSetup.stile"
               [idStileSelezione]="configMappa.selezioneSetup.stile" [idStileDisegno]="configMappa.disegnoSetup.stile"
               [layersWorld]="configMappa.worldLayers" [layersWMS]="configMappa.wmsLayers"
               [layersXYZ]="configMappa.xyzLayers" [layersVector]="configMappa.vectorLayers"
               [stiliConfigurazione]="configMappa.styles" [iconaIndirizzoSelezionato]="'assets/resources/marker50.png'"
               [larghezzaSidebar]="500" [urlBackendAPI]="utils.creaUrlDaRoute('')" [defaultSR]="configMappa.defaultSR"
               [arraySR]="configMappa.arraySR" [contentSceltaRappresentazione]="configMappa.sceltaRappresentazione"
               [token]="loginService.getToken()"
               [payLoad]="loginService.getPayload()"
               (mappaPronta)="leggiConfigurazioniSpecifiche()" (chiusuraOggettoSelezionatoEmitter)="chiudiObj($event)"
               (modificaOggettoSelezionatoEmitter)="modificaObj($event)"
               (visualizzaInfoOggettoSelezionatoEmitter)="visualizzaObj($event)"
               (toolDisegnoElementoConfermatoMapEmitter)="gestisciElementoConfermato($event)"
               (chiudiToolDisegnoMapEmitter)="gestisciChiusuraToolDisegno($event)"
               #mappa>
            </ebw-map>
         </div>
      </div>
   </div>
</div>

<div class="right-map-sidebar" [class.sb-close]="!rightSBarOpened"
   [ngStyle]="{'margin-top': loadIngiunzione ? '0' : '67px'}">

   <!-- <associa-particelle #associaParticella *ngIf="loadParticella" [mapInputData]="mapInputData"
      [newParticella]="newParticella" (gestioneParticella)="gestisciParticella($event)"
      (chiudiParticella)="gestisciSidebarParticella($event)">
   </associa-particelle> -->
   <router-outlet></router-outlet>
</div>
