import { Injectable } from '@angular/core';
import { ToastCustomModel, ToastBaseModel, ToastExtendModel } from './ebw-toast.model';

/** Servizio per gestire i toast nell'intera applicazione */
@Injectable({
   providedIn: 'root'
})
export class EbwToastService {

   /** lista dei toast di tutta l'applicazione */
   toasts: ToastCustomModel[] = [];

   /** timeout defaut */
   delay: number = 5000;

   /** costruttore */
   constructor() { }

   /**
    * Funzione per creare un toast di INFO
    * @param toastModel modello del toast
    */
   showInfoToast(toastModel: ToastExtendModel | ToastBaseModel) {
      const model = this.generateDefaultToast(toastModel);
      model.classname = 'bg-info text-white';
      this.toasts.push(model);
   }

   /**
    * Funzione per creare un toast di SUCCESS
    * @param toastModel modello del toast
    */
   showSuccessToast(toastModel: ToastExtendModel | ToastBaseModel) {
      const model = this.generateDefaultToast(toastModel);
      model.classname = 'bg-success text-white toast-custom';
      this.toasts.push(model);
   }

   /**
    * Funzione per creare un toast di WARNING
    * @param toastModel modello del toast
    */
   showWarningToast(toastModel: ToastExtendModel | ToastBaseModel) {
      const model = this.generateDefaultToast(toastModel);
      model.classname = 'bg-warning text-dark';
      this.toasts.push(model);
   }

   /**
    * Funzione per creare un toast di ERROR
    * @param toastModel modello del toast
    */
   showErrorToast(toastModel: ToastExtendModel | ToastBaseModel) {
      const model = this.generateDefaultToast(toastModel);
      model.classname = 'bg-danger text-white';
      this.toasts.push(model);
   }

   /** Funzione per creare un toast totalmente costum
    *  @param toastModel modello del toast
    */
   showCustomToast(toastModel: ToastCustomModel) {
      this.toasts.push(toastModel);
   }

   /**
    * Funzione utilizzata dal toast (metodo hide) per rimuoversi dall'array
    * @param toast toast da rimovere
    */
   remove(toast: ToastCustomModel) {
      this.toasts = this.toasts.filter(t => t !== toast);
   }

   /**
    * Funzione che genera il modello adatto per istanziare il toast
    * @param toastModel modello del toast da sistemare
    */
   generateDefaultToast(toastModel: ToastExtendModel | ToastBaseModel): ToastCustomModel {
      const model: ToastCustomModel = JSON.parse(JSON.stringify(toastModel));

      if (!('delay' in toastModel)) {
         model.header = '';
         model.autohide = true;
         model.delay = this.delay;
      }
      return model;
   }
}
