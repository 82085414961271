import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'nullToZero'
})
export class NullToZeroPipe implements PipeTransform {

   /**
    * Converte i valori null o stringa vuota in '0.00', con il numero di decimali specificato
    * @param value valore da convertire
    * @param decimali (opzionale, default=2) numero di cifre dopo la virgolo
    */
   transform(value: any, decimali: number = 2): string {
      if (value == null || value === '')
         value = 0;

      if (typeof value == "string")
         value = parseInt(value)

      return value.toFixed(decimali);
   }

}
