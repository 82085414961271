<div class="position-absolute h-auto w-25 m-auto center-div selezionaOggetti">
  <div class="overflow-box">
    <div
      class="bg-dark text-white p-2"
      *ngFor="let oggettoSelezionato of oggettiSelezionati"
    >
      <label>{{ oggettoSelezionato.getProperties().name }}</label>
      <div class="float-right">
        <fa-icon
          class="px-1"
          placement="top"
          ngbTooltip="Info"
          (click)="viewInfoSelectedObject(oggettoSelezionato)"
          [icon]="faInfoCircle"
        >
        </fa-icon>
        <fa-icon
          class="px-1"
          placement="top"
          ngbTooltip="Modifica"
          (click)="modifySelectedObject(oggettoSelezionato)"
          [icon]="faEdit"
        >
        </fa-icon>
        <fa-icon
          class="px-1"
          placement="top"
          ngbTooltip="Chiudi"
          (click)="closeSelectedObject(oggettoSelezionato)"
          [icon]="faTimesCircle"
        >
        </fa-icon>
      </div>
    </div>
  </div>
</div>

<!-- TODO da eliminare -->
<!-- <div *ngIf="infoFeature !== undefined" class="position-absolute bg-white pl-1 h-25 w-75" style="overflow-y:scroll; left:5px;">
   <p *ngFor="let key of infoFeatureKeys" class="text-dark">{{key}}: <b>{{getJSONString(infoFeature.getProperties().info_catasto[0][key])}}</b></p>
</div> -->
