import { AbstractControl, ValidationErrors, ValidatorFn, FormGroup } from '@angular/forms';

/** Classe che contiene validator generici condivisibili per tutta l'applicazione */
export class CustomValidators {

   /**
    * Pattern Validator
    * So, our custom validator will accept the validation RegExp expression,
    * and validation error object to return if it encounters an error
    * ES. patternValidator(/\d/, { hasNumber: true }),
    */
   static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } => {
         if (!control.value) {
            // if control is empty return no error
            return null;
         }

         // test the value of the control against the regexp supplied
         const valid = regex.test(control.value);

         // if true, return no error (no error), else return error passed in the second parameter
         // console.log('patternValidator --> ', error);
         return valid ? null : error;
      };
   }

   /**
    * Password Match Validator
    * CustomValidator to check whether our password and confirm password are a match.
    * Add a second customValidator, called passwordMatchValidator and add the following code
    * @param control
    */
   static passwordMatchValidator(control: AbstractControl) {
      const password: string = control.get('password').value; // get password from our password form control
      const confermaPassword: string = control.get('conferma_password').value; // get password from our confirmPassword form control
      // compare is the password math
      if (password !== confermaPassword) {
         // if they don't match, set an error in our confirmPassword form control
         control.get('conferma_password').setErrors({ NoPasswordMatch: true });
      }
   }

   /** Custom validator per controllare che ci sia almeno un valore inserito (Da impostare alla form globale) */
   static atLeastOneValidator(controlGroup: FormGroup) {
      const controls = controlGroup.controls;
      if (controls) {
         const theOne = Object.keys(controls).find(key => controls[key].value !== null);
         if (!theOne) {
            return { almeno_uno: { messaggio: 'Ci deve essere almeno un valore popolato' } };
         }
      }
      return null;
   }

   /** Custom validator per controllare che ci sia almeno un valore inserito (Da impostare alla form globale) */
   static atLeastOneValidatorCheckbox(controlGroup: FormGroup) {
      const controls = controlGroup.controls;
      if (controls) {
         const theOne = Object.keys(controls).find(key => controls[key].value !== false);
         if (!theOne) {
            return { almeno_uno: { messaggio: 'Ci deve essere almeno un valore selezionato' } };
         }
      }
      return null;
   }

   /*
    * Password Match Validator
    * CustomValidator to check whether our password and confirm password are a match.
    *  Add a second customValidator, called passwordMatchValidator and add the following code
    */
   /*
       static uniqueUsernameValidator(gestioneUtentiService: GestioneUtentiService): AsyncValidatorFn {

          return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
             var jsonData = {
                              "params" : {
                                 "select": "users",
                                 "orderBy" : [
                                             {"field":"id","order":"desc"}
                                          ],
                                 "pagination": {
                                             "reqPage": 1, "rowsPerPage": 10
                                          },
                                    "filterBy": [
                                                {"field":"username", "eq": control.value.trim()}
                                             ]
                              }
                           }

                gestioneUtentiService.selezionaUtentiValidator(jsonData).subscribe(
                     (results: any) => {

                        console.log("RESULTS --> ", results);
                });
             //return aa;
             return gestioneUtentiService.validationResult;
          }
       }
   */



   /*
      static uniqueUsernameValidator_old(gestioneUtentiService: GestioneUtentiService, error: ValidationErrors):  ValidatorFn {
          return (control: AbstractControl) : { [key: string]: any } => {
                     var jsonData = {
                                      "params" : {
                                         "select": "users",
                                         "orderBy" : [
                                                     {"field":"id","order":"desc"}
                                                  ],
                                         "pagination": {
                                                     "reqPage": 1, "rowsPerPage": 10
                                                  },
                                            "filterBy": [
                                                        {"field":"username", "eq": control.value.trim()}
                                                     ]
                                      }
                                   }
                                  // return error;
                     return gestioneUtentiService.selezionaUtenti(jsonData).subscribe(
                         (results: any) => {
                            console.log('RISULTATI TROVATI --> ' , results['results'].length);
                            return (results.results.length) ? { 'isNotUnique': true } : null;

                          //   if (results['results'].length > 0){
                          //      console.log("Username NON Valido --> ", error);
                          //      return error;
                          //   }
                          //
                          //  else{
                          //    console.log("Username Valido");
                          //    return null;
                          // }
                     });
            };
      }*/
}
