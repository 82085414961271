<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [header]="toast.header"
  [autohide]="true"
  [delay]="toast.delay"
  (hide)="toastService.remove(toast)"
>
  {{ toast.message }}
</ngb-toast>
