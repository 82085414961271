
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

// login component
import { LoginComponent } from './login/login.component';
import { RegistrazioneComponent } from './login/registrazione/registrazione.component';
import { SimulazioneUtenteComponent } from './login/simulazione-utente/simulazione-utente.component';
import { RecuperoPasswordComponent } from './login/recupero-password/recupero-password.component';

// home component
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './home/menu/menu.component';
import { HeaderComponent } from './home/header/header.component';

// loader


import { MappaComponent } from './mappa/mappa.component';
import { AuthGuardService } from './servizi/auth-guard.service';
import { DoNotShowSidebarOnRefreshGuard } from './servizi/patient/do-not-show-sidebar-on-refresh.guard';
import { PrivacyComponent } from './login/privacy/privacy.component';

const routes: Routes = [
   {
      path: '',
      redirectTo: '/mappa',
      pathMatch: 'full',
      runGuardsAndResolvers: 'always'
   },
   {
      path: 'login',
      component: LoginComponent
   },
   {
      path: 'registrazione',
      component: RegistrazioneComponent
   },
   {
      path: 'informativa',
      component: PrivacyComponent
   },
   {
      path: '',
      component: HomeComponent,
      runGuardsAndResolvers: 'always',
      // canActivate: [AuthGuardService], // GB da decommentare
      children: [
        //  {
        //     path: 'variazione-soggetto-test',
        //     component: VariazioneSoggettoTestComponent
        //  },
         {
            path: 'mappa',
            component: MappaComponent,
            data: {},
            canActivate: [AuthGuardService],
            children: [
              {path: 'pazienti', loadChildren: () => import('src/app/mappa/sidebar/sidebar.module').then(m => m.SidebarModule), canActivate:[DoNotShowSidebarOnRefreshGuard]}
            ]
         },
         {
            path: 'simulazione-utente',
            component: SimulazioneUtenteComponent,
            canActivate: [AuthGuardService]
         },
         // TODO:  component: DisegniComponent
         // {
         //    path: 'disegni',
         //
         // }
      ]
   },
];

/** NgModule */
@NgModule({
   imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' , preloadingStrategy: PreloadAllModules})],
   exports: [RouterModule]
})
export class AppRoutingModule { }
