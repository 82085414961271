import { MappaBaseService } from './mappa-base.service';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';

import { Layer, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import { OSM, TileWMS, Vector as VectorSource, XYZ } from 'ol/source';
import Feature from 'ol/Feature';
import { Circle, Point } from 'ol/geom';
import GeoJSON from 'ol/format/GeoJSON';
import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style';

import { Draw, Modify, Snap } from 'ol/interaction';
import { transform } from 'ol/proj';

import { ConfigService } from '../config/config.service';
import { HttpClient } from '@angular/common/http';
import { reject } from 'q';
import { BehaviorSubject, Subject } from 'rxjs';
import GeometryType from 'ol/geom/GeometryType';
import { ImageTile } from 'ol';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';

@Injectable({
   providedIn: 'root'
})
export class MappaService extends MappaBaseService implements OnDestroy {

   NUOVA_POSIZIONE_KEY = 'nuovaPosizione';
   AGGIUNGI_LAYER_KEY = 'aggiungiLayer';
   ELIMINA_LAYER_KEY = 'eliminaLayer';
   AGGIUNGI_INTERAZIONE_KEY = 'aggiungiInterazione';
   ELIMINA_INTERAZIONE_KEY = 'eliminaInterazione';
   SELEZIONE_FEATURES_KEY = 'attivaSelezione';
   OGGETTI_SELEZIONATI_KEY = 'oggettiSelezionati';
   CAMBIATO_BASE_LAYER_KEY = 'cambiatoBaseLayer';
   NASCONDI_LAYER = 'nascondiLayer';
   MOSTRA_LAYER = 'mostraLayer';
   CAMBIATA_RAPPRESENTAZIONE = 'cambiataRappresentazione';
   REFRESH_MAPPA = 'refreshMappa';

   selezioneFeatures: Boolean = Boolean(false);
   selezioneFeauresLayer: VectorLayer;

   disegnoLibero = false;
   disegnoLiberoLayer: VectorLayer;
   disegnoLiberoSource!: VectorSource;
   disegnoLiberoModify!: Modify;
   disegnoLiberoDraw!: Draw;
   disegnoLiberoSnap!: Snap;

   localizzazioneAttiva = false;
   localizzatoreId!: number;
   posizioneFeature: Feature;
   accuratezzaFeature: Feature;
   localizzazioneLayer: VectorLayer;

   layerBaseAttivo!: string;
   rappresentazioneAttiva: BaseLayer;
   tipologiaUtenteCorrente = 'operatore';

   eventoGenerico: EventEmitter<{ chiave: string, valore: object }> = new EventEmitter();

   clearMapLayer: Subject<{id:string, reload: boolean}> = new Subject();
   insertFeatureMapLayer: Subject<{layer:string, feature: any}> = new Subject();

   // Questo subject viene utilizzato dal router per poter passare i dati alla mappa quando ci si trova già in quella rotta
   updateDataWithSameRoute: BehaviorSubject<any> = new BehaviorSubject<any>('');

   constructor(private http: HttpClient, private configService: ConfigService) {
      super();

      this.posizioneFeature = new Feature();
      const style = new Style({
         image: new Icon({
            anchor: [0.5, 1],
            anchorXUnits: IconAnchorUnits.FRACTION,
            anchorYUnits: IconAnchorUnits.FRACTION,
            src: 'assets/icons/position.png'
         })
      });
      this.posizioneFeature.setStyle(style);

      this.accuratezzaFeature = new Feature({
         style: new Style({
            image: new CircleStyle({
               radius: 5,
               fill: new Fill({
                  color: 'rgba(255, 255, 255, 0.5)'
               }),
               stroke: new Stroke({
                  color: '#3399CC',
                  width: 2
               })
            })
         })
      });

      this.localizzazioneLayer = new VectorLayer({
         source: new VectorSource(),
         visible: false
      });
      this.localizzazioneLayer.set('generic_type', 'TEMP');
      this.localizzazioneLayer.set('user_type', 'cittadino_operatore');
      this.aggiungiNuovoLayerGenerico('posizione_utente', this.localizzazioneLayer);

      this.selezioneFeauresLayer = new VectorLayer({
         visible: false
      });
      this.selezioneFeauresLayer.set('generic_type', 'TEMP');
      this.selezioneFeauresLayer.set('user_type', 'cittadino_operatore');
      this.aggiungiNuovoLayerGenerico('selezione_features', this.selezioneFeauresLayer);

      this.disegnoLiberoLayer = new VectorLayer({
         visible: false
      });
      this.disegnoLiberoLayer.set('generic_type', 'TEMP');
      this.disegnoLiberoLayer.set('user_type', 'cittadino_operatore');
      this.aggiungiNuovoLayerGenerico('disegno_libero', this.disegnoLiberoLayer);
   }

   inizializzaMappa() {
      this.configService.getConfigMappa().wmsLayers.forEach((item) => {
         this.aggiungiNuovoWMSLayerDaConfig(item);
      });
   }

   /**
   * UTILIZZATA NEL VECCHIO SID PER GENERARE FALSE RICHIESTE WMS
   */
   private static parseQueryString(str: string) {
      const nvpair = {};
      const qs = str.substring(str.indexOf('?') + 1);
      const pairs = qs.split('&');
      pairs.forEach(function(value, index) {
         const pair = value.split('=');
         nvpair[pair[0]] = pair[1];
      });
      return nvpair;
   }

   private static gss(src: any, layers: string, styles: string) {
      // console.log(src);
      const params = this.parseQueryString(src);
      const bb = params['BBOX'];
      // var myurl = "http://"+host+":"+port+
      const root_url = 'https://www.sid.mit.gov.it';
      const my_user = 'ammcon';
      // TODO controllare TOKEN (non va bene quello corrente)
      const my_token = 'ebw_session';
      const myurl2 = 'http://localhost/ministero?request=mappaOpenLayer&wmtver=1.3.0&format=image/png&transparent=TRUE&width=256&height=256&crs=sw:world_popular_visualisation_spherical_m&layers=' + layers + '&styles=' + styles + '&my_user=' + my_user + '&my_token=' + my_token;
      return myurl2 + '&bbox=' + bb;
   }

   ngOnDestroy(): void {
      if (this.localizzazioneAttiva) {
         navigator.geolocation.clearWatch(this.localizzatoreId);
         this.localizzazioneAttiva = false;
         this.localizzazioneLayer.setVisible(false);
         this.localizzazioneLayer.getSource().clear();
      }
   }

   /**
    * Gestisce l'esito del controllo della tipologia utente loggato
    */
   /*private gestisciEsito(response): void {
      if (response.status === 'ok') {
         // Verify Token OK
         if (!!response.message) {
            console.log('Messaggio: ' + response.message);
         } else if (!!response.decoded) { // Decode Token OK
            console.log('Decodifica Token: Header --> ' + JSON.stringify(response.decoded.header) + 'Payload --> ' + JSON.stringify(response.decoded.payload));
            const user_type = response.decoded.payload.user.tipo;
            if (user_type === 'O') {
               this.tipologiaUtenteCorrente = 'operatore';
            } else if (user_type === 'C') {
               this.tipologiaUtenteCorrente = 'cittadino';
            }
         }
      } else if (response.status === 'failed') {
         console.log('verifica + decodifica token fallita!!');
      }
   }*/

   controllaTipologiaUtente(): boolean {
      // console.log('controllaTipologiaUtente: ' + this.tipologiaUtenteCorrente);
      return this.tipologiaUtenteCorrente === 'operatore';
   }

   aggiungiNuovoBaseLayerDaConfig(JSONlayer) {
      const layer: TileLayer = new TileLayer();
      let source;
      switch (JSONlayer.title) {
         case 'Open Street Map':
            layer.setSource(new OSM());
            break;
         case 'AGEA':
         case 'Riprese Aerofotogrammetriche':
            source = new TileWMS({
               url: JSONlayer.url,
               params: JSONlayer.params,
               transition: 0 // Durata dell'effetto di opacità durante la transizione
            });
            layer.setSource(source);
            break;
         case 'Immagini satellitari':
            source = new XYZ({
               url: JSONlayer.url
            });
            layer.setSource(source);
            break;
         default:
            break;
      }
      layer.setOpacity(JSONlayer.opacity);
      layer.setVisible(JSONlayer.visible);
      layer.set('map_type', JSONlayer.map_type);
      layer.set('generic_type', 'BASE');
      layer.set('user_type', JSONlayer.user_type);
      if (JSONlayer.visible) {
         this.layerBaseAttivo = JSONlayer.title;
      }

      this.aggiungiNuovoLayerGenerico(JSONlayer.title, layer);
   }

   aggiungiNuovoWMSLayerDaConfig(JSONlayer) {
      let source;

      if (JSONlayer.title.includes('GSS')) {

         source = new TileWMS({
            tileLoadFunction: function(imageTile, src) {
               ((imageTile as ImageTile).getImage() as HTMLImageElement).src = MappaService.gss(src, JSONlayer.params.LAYERS, JSONlayer.params.STYLES);
            },
            url: JSONlayer.url,
            params: {},
            transition: 0,
            cacheSize: 0
         });
      } else {
         source = new TileWMS({
            url: JSONlayer.url,
            params: JSONlayer.params,
            // serverType: JSONlayer.serverType,
            transition: 0, // Durata dell'effetto di opacità durante la transizione
            cacheSize: 0
         });
      }
      const layer = new TileLayer({
         source: source,
         opacity: JSONlayer.opacity,
         visible: JSONlayer.visible,
         maxResolution: JSONlayer.maxResolution
         // extent: JSONlayer.extent
      });
      layer.set('generic_type', 'WMS');
      layer.set('group_id', JSONlayer.group_id);
      layer.set('tab', JSONlayer.tab);
      layer.set('user_type', JSONlayer.user_type);

      if (JSONlayer.visible && JSONlayer.tab === 'Base') {
         this.rappresentazioneAttiva = layer;
      }

      this.aggiungiNuovoLayerGenerico(JSONlayer.title, layer);
   }

   private aggiungiNuovoLayerGenerico(nome: string, layer: BaseLayer) {
      layer.set('title', nome);
      this.layers.push(layer);
      this.eventoGenerico.emit({ chiave: this.AGGIUNGI_LAYER_KEY, valore: layer });
   }

   accendiSpegniTuttiLayerWMS(visibile: boolean) {
      this.layers.filter(this.isLayerWMS)
         .forEach(function(element) { element.setVisible(visibile); });
   }

   refreshMappa() {
      this.eventoGenerico.emit({ chiave: this.REFRESH_MAPPA, valore: null });
   }

   localizzami() {
      if (!this.localizzazioneAttiva) {
         if (navigator.geolocation) { // controllo se Geolocation è supportato dal broswer
            this.localizzatoreId = navigator.geolocation.watchPosition((position) => {

               if (!this.localizzazioneLayer.getVisible()) {

                  const coordinate = transform([position.coords.longitude, position.coords.latitude], this.EPSG_4326_KEY, this.EPSG_3857_KEY);

                  this.posizioneFeature.setGeometry(new Point(coordinate));
                  this.accuratezzaFeature.setGeometry(new Circle(coordinate, position.coords.accuracy));
                  this.localizzazioneLayer.setVisible(true);
                  this.localizzazioneLayer.getSource().addFeature(this.accuratezzaFeature);
                  this.localizzazioneLayer.getSource().addFeature(this.posizioneFeature);
                  this.eventoGenerico.emit({ chiave: this.NUOVA_POSIZIONE_KEY, valore: position });
               }
            });
         } else {
            console.log('Geolocalizzazione non riuscita oppure non supportata da questo browser.');
         }
         this.localizzazioneAttiva = true;
      } else {
         this.localizzazioneLayer.setVisible(false);
         this.localizzazioneLayer.getSource().clear(); // Remove all features from the source.

         // fermo la localizzazione
         navigator.geolocation.clearWatch(this.localizzatoreId);
         this.localizzazioneAttiva = false;
      }
   }

   disegnaLiberamente() {
      if (!this.disegnoLibero) {

         this.disegnoLiberoSource = new VectorSource();
         this.disegnoLiberoLayer.setSource(this.disegnoLiberoSource);
         this.disegnoLiberoLayer.setStyle(new Style({
            fill: new Fill({
               color: 'rgba(255, 255, 255, 0.2)'
            }),
            stroke: new Stroke({
               color: '#ffcc33',
               width: 2
            }),
            image: new CircleStyle({
               radius: 7,
               fill: new Fill({
                  color: '#ffcc33'
               })
            })
         }));

         this.disegnoLiberoModify = new Modify({ source: this.disegnoLiberoSource });
         this.disegnoLiberoSnap = new Snap({ source: this.disegnoLiberoSource });
         this.disegnoLiberoDraw = new Draw({
            source: this.disegnoLiberoSource,
            type: GeometryType.POINT
         });

         this.eventoGenerico.emit({ chiave: this.AGGIUNGI_INTERAZIONE_KEY, valore: this.disegnoLiberoModify });
         this.eventoGenerico.emit({ chiave: this.AGGIUNGI_INTERAZIONE_KEY, valore: this.disegnoLiberoDraw });
         this.eventoGenerico.emit({ chiave: this.AGGIUNGI_INTERAZIONE_KEY, valore: this.disegnoLiberoSnap });
         this.disegnoLibero = true;
         this.disegnoLiberoLayer.setVisible(true);

      } else {

         console.log('Punti disegnati ', this.disegnoLiberoLayer.getSource().getFeatures().length);
         this.disegnoLiberoLayer.getSource().clear();
         this.disegnoLibero = false;
         this.disegnoLiberoLayer.setVisible(false);

         this.eventoGenerico.emit({ chiave: this.ELIMINA_INTERAZIONE_KEY, valore: this.disegnoLiberoSnap });
         this.eventoGenerico.emit({ chiave: this.ELIMINA_INTERAZIONE_KEY, valore: this.disegnoLiberoDraw });
         this.eventoGenerico.emit({ chiave: this.ELIMINA_INTERAZIONE_KEY, valore: this.disegnoLiberoModify });
      }
   }

   disegnaGeoJSONObject(GeoJSONObject) {

      this.selezioneFeauresLayer.setSource(new VectorSource({
         features: (new GeoJSON()).readFeatures(JSON.parse(GeoJSONObject.toString()))
      })
      );
      this.eventoGenerico.emit({ chiave: this.OGGETTI_SELEZIONATI_KEY, valore: this.selezioneFeauresLayer });
   }

   attivaSelezione() {
      this.selezioneFeatures = Boolean(!this.selezioneFeatures.valueOf());

      if (this.selezioneFeauresLayer.getSource() !== undefined) {
         this.selezioneFeauresLayer.getSource().clear();
      }

      this.selezioneFeauresLayer.setVisible(this.selezioneFeatures.valueOf());
      this.eventoGenerico.emit({ chiave: this.SELEZIONE_FEATURES_KEY, valore: this.selezioneFeatures });
   }

   cambiaLayerBase(layer: BaseLayer) {
      if (layer.get('title') !== this.layerBaseAttivo) {
         this.layers.filter(function(item) {
            return item.get('generic_type') === 'BASE';
         })
            .forEach(function(element) {
               if (element.get('title') === layer.get('title')) {
                  // console.log(element);
                  element.setVisible(!element.getVisible());
               } else {
                  element.setVisible(false);
               }
            });
         this.eventoGenerico.emit({ chiave: this.CAMBIATO_BASE_LAYER_KEY, valore: layer });
         this.layerBaseAttivo = layer.get('title');
      }
   }

   cambiaRappresentazione(nuovoLayer: BaseLayer) {

      console.log('Cambia Rappresentazione', nuovoLayer);

      // Nascondo tutti i layer WMS del tab Base e quelli aggiuntivi e opzionali
      this.layers.filter(this.isLayerWMS).filter(this.isBaseTab).forEach(function(item) {
         item.setVisible(false);
      });

      // Nascondo tutti i layer WMS supporto
      this.layers.filter(this.isLayerWMS).filter(this.isLayerSupporto).forEach(function(item) {
         item.setVisible(false);
      });

      // Nascondo tutti i layer WMS opzionali
      this.layers.filter(this.isLayerWMS).filter(this.isLayerOzionale).forEach(function(item) {
         item.setVisible(false);
      });

      // Nascondo tutti i layer WMS vari che non fanno parte della rappresentazione corrente
      this.layers.filter(this.isLayerWMS).filter(this.isLayerVari).forEach(function(item) {
         if (!item.get('group_id').includes(nuovoLayer.get('group_id'))) {
            item.setVisible(false);
         }
      });

      // Rendo visibile la rappresentazione selezionata + i layer di supporto in base alla tipologia di utente corrente
      nuovoLayer.setVisible(true);
      this.layers.filter(this.isLayerWMS).filter(this.isLayerSupporto).filter(this.filtroTipologiaUtente())
         .forEach(function(item) {
            if (item.get('group_id').includes(nuovoLayer.get('group_id'))) {
               item.setVisible(true);
            }
         });

      this.rappresentazioneAttiva = nuovoLayer;
      // emetto un evento di cambio rappresentazione
      this.eventoGenerico.emit({ chiave: this.CAMBIATA_RAPPRESENTAZIONE, valore: nuovoLayer });
   }

   eliminaRappresentazione() {
      const layer = new VectorLayer({});
      layer.set('group_id', 'Vuoto');
      layer.set('generic_type', 'Temp');
      layer.set('tab', 'Base');
      this.cambiaRappresentazione(layer);
   }

   cambiaLayerReti(nuovoLayer: BaseLayer) {
      this.layers.filter(this.isSidReti()).forEach(function(item) {
         item.setVisible(false);
      });
      nuovoLayer.setVisible(true);
   }

   cambiaLayerVari(nuovoLayer: BaseLayer) {
      this.layers.filter(this.isSidVari()).forEach(function(item) {
         item.setVisible(false);
      });
      nuovoLayer.setVisible(true);
   }

   ottieniFeatures(coordinate) {

      // valori da passare:
      // - coordinate

      // console.log('richiedi dati: ', coordinate);

      this.http.get('assets/objects.geojson', { observe: 'body', responseType: 'text' }).subscribe(
         (response) => {
            // console.log(response);

            // deve essere un GeoJSON con coordinate in EPSG:3857
            this.disegnaGeoJSONObject(response);
         },
         (err: string) => {
            reject(`Could not load file 'assets/config/objects.geojson': ${JSON.stringify(err)}`);
         }, () => console.log('Completata richiesta HTTP'));
   }

   /**
   * Conversione DEG -> DEC  di una singola coordinata -> TESTATA
   */
   DMStoDD(degrees, minutes, seconds) {
      const deg = parseInt(degrees, 10),
         min = (parseInt(minutes, 10) / 60.0),
         sec = (parseFloat(seconds) / 3600.0),
         decimals = deg + min + sec;
      console.log('DD :-> ', decimals);
      return decimals;
   }

   // Conversione DEC -> DEG di una singola coordinata -> TESTATA
   DDtoDMS(coordindate) {
      const degree = parseInt(coordindate, 10); // prende il decimale in base 10 (radix)
      const minuteDecimal = Math.abs(coordindate - degree) * 60.0;
      const minute = Math.floor(minuteDecimal);
      const secondsDecimal = (minuteDecimal - minute) * 60.0;
      console.log('DMS:-> ', [degree, minute, secondsDecimal]);
      return [degree, minute, secondsDecimal];
   }

   // FILTRI
   filtroTipologiaUtente() {
      return (currentLayer) => {
         return currentLayer.get('user_type').includes(this.tipologiaUtenteCorrente);
      };
   }

   isLayerWMS(currentLayer: BaseLayer) {
      return currentLayer.get('generic_type') === 'WMS';
   }

   isLayerBase(currentLayer: BaseLayer) {
      return currentLayer.get('generic_type') === 'BASE';
   }

   isBaseTab(currentLayer: BaseLayer) {
      return currentLayer.get('tab') === 'Base';
   }

   isAreeTab(currentLayer: BaseLayer) {
      return currentLayer.get('tab') === 'Aree';
   }

   isLayerSupporto(currentLayer: BaseLayer) {
      return currentLayer.get('group_id').includes('supporto');
   }

   isLayerOzionale(currentLayer: BaseLayer) {
      return currentLayer.get('group_id').includes('opzionale');
   }

   isLayerVari(currentLayer: BaseLayer) {
      return currentLayer.get('group_id').includes('vari');
   }

   isSidReti() {
      return (currentLayer) => {
         return currentLayer.get('generic_type') === 'WMS' && currentLayer.get('tab').includes('Sid') && currentLayer.get('group_id').includes(this.rappresentazioneAttiva.get('group_id')) && currentLayer.get('group_id').includes('reti');
      };
   }

   isSidOccupazione() {
      return (currentLayer) => {
         return currentLayer.get('generic_type') === 'WMS' && currentLayer.get('tab').includes('Sid') && currentLayer.get('group_id').includes(this.rappresentazioneAttiva.get('group_id')) && currentLayer.get('group_id').includes('occupazioni');
      };
   }

   isSidVari() {
      return (currentLayer) => {
         return currentLayer.get('generic_type') === 'WMS' && currentLayer.get('tab').includes('Sid') && currentLayer.get('group_id').includes(this.rappresentazioneAttiva.get('group_id')) && currentLayer.get('group_id').includes('vari');
      };
   }

   isSidIndipendenti() {
      return (currentLayer) => {
         return currentLayer.get('generic_type') === 'WMS' && currentLayer.get('tab').includes('Sid') && currentLayer.get('group_id').includes('Indipendente');
      };
   }
}
