import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GestioneLoginService } from "src/app/servizi/login/gestione-login.service";

@Component({
  selector: "app-privacy",
  templateUrl: "privacy.component.html",
  styleUrls: ["privacy.component.scss"],
})
export class PrivacyComponent implements OnInit {
  mobile: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private gestioneLoginService: GestioneLoginService
  ) {}

  ngOnInit() {
    if (this.gestioneLoginService.isOnMobile()) this.mobile = true;
  }

  onRejectPrivacy() {
    this.router.navigate(["login"]);
    this.gestioneLoginService.logout();
  }

  onAcceptPrivacy() {
    if (!this.gestioneLoginService.isOnMobile())
      this.router.navigate(["../", "mappa"], { relativeTo: this.route });
  }
}
