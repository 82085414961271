<!-- Street View -->
<div id="streetView"
     #streetViewContainer
     [@slideMap]="{ value: menuState, params: { margin: _larghezzaSidebar } }"
     class="position-relative">
     <div id="noPanorama" class="position-absolute" *ngIf="noPanorama" style="top: 45%; left: 45%; z-index:1;">Fuori strada</div>
     <div id="pano"></div>
</div>
<div class="position-relative h-100"
     #mapContainer
     [@slideMap]="{ value: menuState, params: { margin: _larghezzaSidebar } }">
  <agm-map #agmMap *ngIf="mappaGoogleVisibile"
           class="position-absolute w-100 h-100"
           [ngStyle]="{ opacity: mappaGoogleOpacity }"
           [longitude]="centroMappa4326[0]"
           [latitude]="centroMappa4326[1]"
           [zoom]="zoom"
           [mapTypeId]="mappaGoogleTipologia"
           [disableDefaultUI]="true"
           [zoomControl]="false"
           [streetViewControl]="false"
           [keyboardShortcuts]="false"
           [clickableIcons]="false"
           [disableDoubleClickZoom]="true"
           [mapTypeControlOptions]="true"
           [mapDraggable]="false"
           [scrollwheel]="false"
           [usePanning]="false"
           [panControl]="false"
           [rotateControl]="false"
           [fullscreenControl]="false"
           (zoomChange)="mappaGoogleZoomCambiato($event)"
           (mapReady)="mappaGooglePronta($event)"
           (idle)="mappaGoogleInattiva()">
  </agm-map>

  <div id="ol-map"
       class="position-absolute w-100 h-100"
       #olmapdiv></div>

  <ebw-info-vista *ngIf="infoMappaAttivo"
                  class="position-absolute infoVista"
                  [arrotondamento]="arrotondamentoInfoVista"
                  [coordinateCorrenti]="coordinateMouse4326"
                  [risoluzioneCorrente]="risoluzioneCorrente">
  </ebw-info-vista>

  <ebw-selezione-oggetti *ngIf="selezioneOggettiAttiva"
                         [oggettiSelezionati]="oggettiSelezionati"
                         (rimuoviOggettoSelezionato)="rimuoviOggettoSelezionato($event)"
                         (chiusuraOggettoSelezionato)="closeSelectedObject($event)"
                         (modificaOggettoSelezionato)="modifySelectedObject($event)"
                         (visualizzaInfoOggettoSelezionato)="viewInfoSelectedObject($event)">
  </ebw-selezione-oggetti>

  <ebw-controlli-layer-sfondo *ngIf="layerSfondoMappaAttivo"
                              class="position-absolute baseLayerMenuMappa"
                              [layerSfondo]="layers"
                              [layerSfondoAttivo]="layerBaseAttivo"
                              (cambiatoLayerSfondo)="cambiaLayerBase($event)">
  </ebw-controlli-layer-sfondo>

  <ebw-controlli-mappa *ngIf="controlliMappaAttivi"
                       class="position-absolute controlliMappa"
                       [statoPannelliSidebar]="pannelliSidebar"
                       (zoomIn)="aumentaZoom()"
                       (zoomOut)="diminuisciZoom()"
                       (localize)="localizza($event)"
                       (btnTransformCoordinate)="gestisciPannelloTrasformazioneCoordinate($event)"
                       (btnToolDisegno)="gestisciToolDisegno($event)"
                       (btnToolAgganciaPunto)="gestisciToolAgganciaPunto($event)"
                       (btnLegenda)="gestisciLegenda($event)"
                       (btnStreetView)="handleStreetView($event)"
                       (btnSceltaRappresentazione)="gestisciPannelloSceltaRappresentazione($event)"
                       (btnRicercaGeometrica)="gestisciPannelloRicercaGeometrica($event)"
                       #controlliMappa>
  </ebw-controlli-mappa>

  <ebw-preview-mappa *ngIf="previewMappaAttiva"
                     class="position-absolute previewMappa"
                     [layers]="layers"
                     [altezzaPreview]="altezzaPreview"
                     [larghezzaPreview]="larghezzaPreview"
                     [zoomCorrente]="zoom"
                     [zoomMassimo]="zoomMassimo"
                     [sogliaZoom]="sogliaZoomPreview"
                     [idStilePreview]="idStilePreview"
                     [centroMappa3857]="centroMappa3857"
                     [mappaGoogleVisibile]="mappaGoogleVisibile"
                     [mappaGoogleTipologia]="mappaGoogleTipologia"
                     [mappaGoogleOpacity]="mappaGoogleOpacity"
                     [extentCorrente]="extentCorrente">
  </ebw-preview-mappa>

  <ebw-ricerca-google class="position-absolute ricerca"
                      (selezionatoRisultato)="gestioneSelezioneRicercaGoogle($event)"
                      (rimuoviMarkerRicerca)="rimuoviFeatureFromTag(tmpLayer, 'marker')">
  </ebw-ricerca-google>

  <ebw-tool-disegno *ngIf="pannelliSidebar.disegno"
                    class="position-absolute toolDisegno"
                    [defaultSR]="defaultSR"
                    [layerDefaultStyleId]="_idStileDisegno"
                    [layerDefault]="layerDisegno"
                    [historyChangesSize]="changesHistory.historyChangesSize()"
                    [selectedFeature]="selectedFeatureForTransformation"
                    [centroMappa]="centroMappa3857"
                    [viewMappa]="viewMappa"
                    [triggerSelectedFeature]="triggerSelectedFeature"
                    [triggerfeaturesAtPixelTD]="triggerfeaturesAtPixelTD"
                    [featuresAtPixelTD]="featuresAtPixelTD"
                    [toolbarVisible]="toolbarVisible"
                    [displayPc]="displayPcMap"
                    [currentObj]="objectToAddAfterToolDisegnoInit"
                    [currentObjType]="objectTypeToAddAfterToolDisegnoInit"
                    [visibilitaBtnElimina]="visibilitaBtnEliminaToolDisegno"
                    [visibilitaBtnAnnulla]="visibilitaBtnAnnullaToolDisegno"
                    (pulisciTutto)="pulisciTutto()"
                    (featureToDelete)="rimuoviFeatureFromObj(layerDisegno, $event)"
                    (addPrecisionLayer)="addPrecisionLayer($event)"
                    (removeTooltip)="deleteOverlay($event)"
                    (addTooltip)="insertOverlay($event)"
                    (changedEditMode)="changeEditMode($event)"
                    (undoModify)="handleUndo($event)"
                    (deselectFeature)="handleDeselectFeature($event)"
                    (alteredFeatureHistory)="setAlteredFromPanel($event)"
                    (insertedFeatureHistory)="setInsertedFromPanel($event)"
                    (deletedFeatureHistory)="setDeletedFromPanel($event)"
                    (btnToolAgganciaPunto)="gestisciToolAgganciaPunto($event)"
                    (toolDisegnoInitialized)="gestisciInserimentoFeaturePrecaricata($event)"
                    (chiudiToolDisegnoEmitter)="gestisciChiudiToolDisegno($event)"
                    (toolDisegnoElementoConfermatoEmitter)="emettiElementoConfermato($event)"
                    #toolDisegno>
  </ebw-tool-disegno>

  <ebw-tool-aggancia-punto *ngIf="pannelliSidebar.aggancia_punto"
                           class="position-absolute toolAgganciaPunto"
                           [defaultSR]="defaultSR"
                           [layerDefaultStyleId]="_idStileDisegno"
                           [layerDefault]="layerDisegno"
                           [selectedFeature]="selectedFeatureForTransformation"
                           [centroMappa]="centroMappa3857"
                           [viewMappa]="viewMappa"
                           (featureToDelete)="rimuoviFeatureFromObj(layerDisegno, $event)"
                           (addPrecisionLayer)="addPrecisionLayer($event)"
                           (removeTooltip)="deleteOverlay($event)"
                           (addTooltip)="insertOverlay($event)"
                           (changedEditMode)="changeEditMode($event)"
                           (undoModify)="handleUndo($event)"
                           (deselectFeature)="handleDeselectFeature($event)"
                           (alteredFeatureHistory)="setAlteredFromPanel($event)"
                           (insertedFeatureHistory)="setInsertedFromPanel($event)"
                           (deletedFeatureHistory)="setDeletedFromPanel($event)"
                           (chiudiPannelloAgganciaPunto)="gestisciToolAgganciaPunto($event)"
                           (puntoAgganciato)="gestisciPuntoAgganciato($event)"
                           #toolAgganciaPunto>
  </ebw-tool-aggancia-punto>
</div>
<!-- SIDEBAR -->
<div id="map_sidebar"
     class="position-absolute h-100"
     [@slideInOut]="{ value: menuState, params: { width: _larghezzaSidebar } }">
  <ebw-trasforma-coordinate *ngIf="sidebarOpened && pannelliSidebar.trasforma_coord"
                            (centraMappaAllaCoordinata)="centraMappaAllaCoordinata($event)"
                            [centroMappa3857]="centroMappa3857"
                            [defaultSR]="defaultSR"
                            [arraySR]="arraySR">
  </ebw-trasforma-coordinate>

  <ebw-legenda *ngIf="sidebarOpened && pannelliSidebar.legenda"
               (chiudiSidebar)="gestisciLegenda($event)">
  </ebw-legenda>

  <ebw-scelta-rappresentazione *ngIf="sidebarOpened && pannelliSidebar.scelta_rappresentazione"
                               [tabConfiguration]="contentSceltaRappresentazione"
                               (layerAggiornati)="layerAggiornati($event)"
                               (chiudiSidebar)="gestisciPannelloSceltaRappresentazione($event)">
  </ebw-scelta-rappresentazione>

  <ebw-ricerca-geometrica *ngIf="sidebarOpened && pannelliSidebar.ricerca_geometrica"
                          (apriToolDisegno)="apriToolDisegno($event)"
                          (resetEmitter)="resetEmitted()"
                          (disegnaGeometrie)="disegnaGeometrie($event)"
                          [toolDisegnoOpen]="pannelliSidebar.disegno"
                          #ricercaGeometrica>
  </ebw-ricerca-geometrica>

</div>
<!-- Popup -->
<div #popup="ngbPopover"
     class="position-absolute rounded-circle border border-warning"
     id="popup"
     container="body"
     ngbPopover>
</div>
<!-- Popup content-->
<ng-template #popupContent>
     <div [innerHtml]="popupHTML"></div>
</ng-template>
