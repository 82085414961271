import { Observable, Subscription } from 'rxjs';
import { GeneraPDFComponent } from './esporta-pdf/genera-pdf.component';
import { Injectable, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { EBWApiResponse } from '../modelli/api.response.model';
import { UtilsService } from '../servizi/utils.service';
import { GeneraPDFSezione } from './genera-pdf.model';
import { EbwToastService } from '../library/ebw-toast-angular/ebw-toast.service';
import { EbwModalService } from '../library/ebw-modals-angular/ebw-modal.service';

/** Servizio per gestire la generazione di un REPORT in PDF */
@Injectable({
   providedIn: 'root',
})
export class GeneraPDFService implements OnDestroy {

   /** Riferimento alla modale aperta */
   private modalRef: NgbModalRef = null;

   /** Sottoscrizione all'API di esportazione del PDF */
   private generaPDF$: Subscription = null;

   /** Riferimento alla modale di generazione */
   private loadingModalRef: NgbModalRef = null;

   /** Elemento HTML per separare il contenuto in più pagine */
   pageBreakerElement: string = '<div style="page-break-after:always;"></div>';

   constructor(
      private modalService: NgbModal,
      private httpClient: HttpClient,
      private utilsService: UtilsService,
      private ebwToastService: EbwToastService,
      private ebwModalService: EbwModalService,
   ) { }

   /**
    * Funzione che apre una modale di selezione delle parti da inserire nel report
    * @param sezioni array di sezioni selezionabili dall'utente
    * @param selezionaTutto booleano per selezionare automaticamente tutte le parti
    * @returns ritorna il riferimento alla modale istanziata
    */
   apriModaleEsportaPDF(sezioni: GeneraPDFSezione[], selezionaTutto: boolean): NgbModalRef {
      this.modalRef = this.modalService.open(GeneraPDFComponent, { centered: true });
      this.modalRef.componentInstance.selezionaTutto = selezionaTutto;
      this.modalRef.componentInstance.sezioni = sezioni;
      return this.modalRef;
   }

   /**
    * Funzione che richiama la API di generazione del PDF. Le sezioni sono automaticamente separate (una pagina A4 a sezione)
    * @param filename nome del file
    * @param sezioni sezioni da esportare
    * @returns Observable
    */
   esportaPDF(filename: string, sezioni: string[]): Observable<EBWApiResponse> {
      if (this.loadingModalRef === null) {
         this.loadingModalRef = this.ebwModalService.openLoadingModal({ content: 'Generazione PDF in corso...' });
         this.loadingModalRef.result.finally(() => this.loadingModalRef = null);
      }

      let html: string = '';
      sezioni.forEach(element => {
         html += element;
      });

      const generaPDF = this.utilsService.postRequest(this.utilsService.creaURL('funzionalita', 'genera_pdf'), { filename, html });
      this.generaPDF$ = generaPDF.subscribe((response: EBWApiResponse) => {
         this.loadingModalRef.close();
         if (response.status === 0 && response.error === 0) {
            this.ebwToastService.showSuccessToast({ message: response.message });
         } else {
            this.ebwModalService.openErrorModal({ title: 'Errore', content: response.message });
            this.ebwToastService.showErrorToast({ message: response.message });
         }
      });
      return generaPDF;
   }

   /** ngOnDestroy */
   ngOnDestroy(): void {
      if (this.modalRef !== null) {
         this.modalRef.close('Close');
      }

      if (this.generaPDF$ !== null) {
         this.generaPDF$.unsubscribe();
         this.generaPDF$ = null;
      }

      if (this.loadingModalRef !== null) {
         this.loadingModalRef.close();
      }

   }
}
