<div class="container-fluid">
   <div class="row">
      <div class="col p-5">
         <h4 class="mb-4 mt-0 text-blusid"> Rigenera password </h4>
         <form [formGroup]="recuperoPasswordForm">
            <!-- USERNAME -->
            <div class="form-group">
               <!-- <label style = "font-weight:bold"><span> Username </span> </label> -->
               <input type="text" formControlName="username" class="form-control" ngbTooltip="Username"
                  placeholder="Username">
               <small class="text-danger"
                  *ngIf="recuperoPasswordForm.get('username').invalid && recuperoPasswordForm.get('username').touched">
                  Inserire il campo Username.
               </small>
            </div>
            <button type="button" (click)="recuperaPassword()" class="btn bg-blu txt-white center">Invia nuova
               password</button>
         </form>
      </div>
   </div>
</div>
