import { Component } from '@angular/core';
import { EbwToastService } from '../ebw-toast.service';

/** Componente che istanzia i toast */
@Component({
   selector: 'ebw-toast-container',
   templateUrl: './ebw-toast-container.component.html',
   styleUrls: ['./ebw-toast-container.component.scss']
})
export class EbwToastContainerComponent {

   constructor(public toastService: EbwToastService) { }

}
