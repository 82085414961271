import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EbwModalService } from './ebw-modal.service';
import { EbwModalComponent } from './modal/ebw-modal.component';
import { BrowserModule } from '@angular/platform-browser';

/** Modulo che permette la gestione delle modali */
@NgModule({
   declarations: [EbwModalComponent],
   imports: [BrowserModule, NgbModule],
   providers: [EbwModalService],
   entryComponents: [EbwModalComponent],
   exports: []
})
export class EbwModalModule { }
