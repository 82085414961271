import { Component, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { GestioneLoginService } from "./../servizi/login/gestione-login.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RecuperoPasswordComponent } from "./recupero-password/recupero-password.component";
// import { UtenteAttivoService } from "src/app/servizi/utente-attivo/utente-attivo.service";
import { EbwModalService } from "../library/ebw-modals-angular/ebw-modal.service";
import { UtilsService } from "../servizi/utils.service";
import { DomSanitizer } from "@angular/platform-browser";
import { NGXLogger } from "ngx-logger";
// import { AccessLogService } from "../servizi/access-log.service";
import { RouterService } from "../servizi/router.service";
// import { RaffinaDatiService } from "src/app/servizi/raffina-dati.service";
import { EMPTY, fromEvent } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import  config   from '../../assets/config/config-globale.dev.json';

/** Componente che gestisce il login al portale */
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements AfterViewInit {
  /** form di login */
  @ViewChild("f", { static: false }) signupForm: NgForm;
  @ViewChild("username") username: ElementRef<HTMLInputElement>;
  /** messagio di autenticazione fallita */
  loginError: string;

  /** json delle notifiche */
  notifiche: any;

  private readonly pageComponentName: string = "LOGIN";
  public downloadAPKurl = "https://fdg.ebwdatacenter.it/apk/fdgapp.apk";
  public emailString =
    "mailto:register.fdg@ebw.it?subject=Richiesta%20attivazione%20utenza%20Fondazione%20Don%20Gnocchi&body=Con%20questa%20mail%20puoi%20richiedere%20l'accesso%20al%20sistema%20di%20test.%0D%0ARispondi%20compilando%20questi%20dati%3A%0D%0A%0D%0ANome%3A%20_________________%0D%0ACognome%3A%20_________________%0D%0Ae-mail%3A%20_________________%0D%0A%0D%0AUna%20volta%20ottenuto%20il%20codice%20utente%2C%20potrai%20accedere%20al%20sistema.%0D%0A%0D%0AA%20presto%0D%0AFGD.Team";
  public showButton: boolean = false;
  /** costruttore */
  constructor(
    private routerSrv: RouterService,
    // private utenteAttivoService: UtenteAttivoService, // TODO servizio da inglobare a --> GestioneLoginService
    // private elementRef: ElementRef,
    private loginServ: GestioneLoginService,
    private modal: NgbModal,
    private ebwModalService: EbwModalService,
    private utilsServ: UtilsService,
    private sanitizer: DomSanitizer,
    private logger: NGXLogger // private accessLogService: AccessLogService, // private raffinaDatiService: RaffinaDatiService
  ) {
    console.warn("config: ", JSON.stringify(config.api.server.apiRelativePath)); 
  }

  /** ngAfterViewInit */
  ngAfterViewInit() {
    // cambio il colore dello sfondo
    // this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'grey';
    this.onKeyPress(this.username.nativeElement);
  }

  onKeyPress(element: HTMLInputElement) {
    // console.log("asd asoud alsjdasld j", element);
    fromEvent(element, "keyup")
      .pipe(
        debounceTime(500),
        map((e: any) => e.target.value),
        distinctUntilChanged(),
        // tap(e=> console.log(e)),
        switchMap((e) => this.loginServ.verifyUserExists(e))
      )
      .pipe(
        catchError(() => {
          return EMPTY;
        })
      )
      .subscribe({
        next: (res: any) => {
          this.loginError = null;
          //  console.log("be re: ", res)
          if (!res.error) {
            if (res.message.exists) {
              this.showButton = true;
              if (this.username.nativeElement.classList.contains("invalid-user")) {
                this.username.nativeElement.classList.replace("invalid-user", "valid-user");
              } else {
                if (!this.username.nativeElement.classList.contains("valid-user"))
                  this.username.nativeElement.classList.add("valid-user");
              }
            } else {
              this.showButton = false;
              if (this.username.nativeElement.classList.contains("valid-user")) {
                this.username.nativeElement.classList.replace("valid-user", "invalid-user");
              } else {
                if (!this.username.nativeElement.classList.contains("invalid-user"))
                  this.username.nativeElement.classList.add("invalid-user");
              }
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          this.loginError = "Errore: " + err.status.toString();
        },
      });
  }

  /** click sul bottone di login */
  onLogin(): void {
    // prelevo utente e password inseriti
    const username = this.signupForm.value.username;
    const password = this.signupForm.value.password ? this.signupForm.value.password : "nMyVD<*|t_O%L0^C)";
    // this.routerSrv.navigateTo('informativa');
    // provo ad ottenere un token per utente e password inseriti
    this.loginServ.getJWT(username, password).subscribe({
      next: (resp) => {
        this.loginError = null;
        // console.log("response: ", resp);
        if (!!resp.access_token && resp.expires_in > 10) {
          // console.warn('TOKEN GENERATO', resp);
          // salvo il token nel servizio
          this.loginServ.setToken(resp.access_token);
          this.loginServ.setSession(resp, username);
          // TO DO check privacy, then decide the route
          this.routerSrv.navigateTo("informativa");
          // this.raffinaDatiService.initAreeEDecodifiche();

          // decodifico il token generato
          // this.loginServ.decodeJWT()
          //    .subscribe(resp2 => {
          //       //  this.utenteAttivoService.initUtenteAttivo();
          //       if (resp2.error === 0 && resp2.status === 0) {
          //          // prelevo il payload
          //          console.warn('PAYLOAD', resp2.data.payload.user);
          // const payload = resp2.data.payload.user;
          //          // salvo il payload nel servizio
          // this.loginServ.setPayload(payload);
          //          // segnalo l'avvenuto login
          //          this.loginServ.segnalaLogin(payload);
          //          this.accessLogService.trackThis(this.pageComponentName);
          //          // redirect sulla home
          //          this.routerSrv.navigateToChild('mappa', { login: true });
          //       } else {
          //          // errore nel recuperare il payload
          //          console.warn(resp);
          //          this.loginError = 'ERRORE DI SISTEMA!';
          //       }
          //    });
        } else {
          // autenticazione fallita
          console.warn(resp);
          this.loginError = "Autenticazione fallita";
        }
      },
      error: (error) => {
        console.warn(error);
        this.loginError = "Autenticazione fallita";
      },
    });
  }

  onKeyPressed(event) {
    // se premuto invio richiamo il login
    if (event.code === "Enter" && this.showButton) {
      this.onLogin();
    }
  }

  /** click sul recupera password */
  apriRecuperaPassword(): void {
    const report = this.modal.open(RecuperoPasswordComponent, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      windowClass: "modal",
    });
    // Per passare i parametri
    // report.componentInstance.attoid = parseInt(input.value);
  }

  openModal(categoria) {
    // verifico le notifiche da leggere relative all'utente
    const visUrl = this.utilsServ.creaURL("notifiche", "view");
    const visPar = {
      // user_id: this.loginServ.getPayload().utenti_id,
      // user_gruppo: this.loginServ.getPayload().gruppi_id
      categoria,
    };
    this.utilsServ.postRequest(visUrl, visPar).subscribe(
      (resp: { error: number; status: number; data: any }) => {
        let content: string;
        let title: any;

        if (resp.error === 0 && resp.status === 0) {
          const notifiche = resp.data;

          /** le 5 notifiche configurate per i diversi bottoni del menù di login sono univoche.
           *  Ovvero, ad ogni bottone del menù di login è associata una modale (con relativo titolo)
           *  che mostra un unico record (titolo modale + testo) della tabella notifiche
           */
          notifiche.forEach((notifica: { testo: string; titolo: any }) => {
            // sanifico il testo (altrimenti lo style viene escluso per motivi di sicurezza)
            // tslint:disable-next-line: no-string-literal
            content = this.sanitizer.bypassSecurityTrustHtml(notifica.testo)[
              "changingThisBreaksApplicationSecurity"
            ];

            title = notifica.titolo;
          });

          if (!!!content || content === "") {
            content = "Contenuto non disponibile";
          }

          this.ebwModalService.openInfoModal(
            { title, content },
            {
              ariaLabelledBy: "modal-basic-title",
              centered: true,
              scrollable: true,
              windowClass: "modalFull95",
            }
          );
        }
      },
      (error) => this.logger.error(error)
    );
  }
}
