import { Component, EventEmitter, Output, Input } from "@angular/core";
import { PosizioneLayer, MapService } from "../../service/map.service";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import BaseLayer from "ol/layer/Base";

/**
 * Componente che mostra i layer di sfondo (Base) e permette il loro cambiamento
 */
@Component({
  selector: "ebw-controlli-layer-sfondo",
  templateUrl: "./controlli-layer-sfondo.component.html",
  styleUrls: ["./controlli-layer-sfondo.component.scss"],
})
export class ControlliLayerSfondoComponent {
  /**
   * Chiave per il titolo aggiunto ai layer importato da {@link MapService}
   */
  titoloLayerKey = MapService.TITOLO_LAYER_KEY;

  /**
   * Icona fontawesome per l'icona mappa
   */
  faMap = faMap;

  /**
   * Array di layer di sfondo (già filtrati)
   */
  _layerSfondo: BaseLayer[];

  /**
   * Titolo del layer di sfondo attivo
   */
  _titoloLayerAttivo: string;

  /**
   * Array di layer sfondo (da filtrare). Vengono presi in input dal {@link MapComponent}
   */
  @Input() set layerSfondo(layers: BaseLayer[]) {
    this._layerSfondo = layers.filter(
      (layer) =>
        layer.get(MapService.POSIZIONE_LAYER_KEY) === PosizioneLayer.BASE
    );
  }

  /**
   * Layer sfondo attivo nella mappa generica presa in input da {@link MapComponent}
   */
  @Input() set layerSfondoAttivo(layer: BaseLayer) {
    layer.get(this.titoloLayerKey) === undefined
      ? (this._titoloLayerAttivo = "")
      : (this._titoloLayerAttivo = layer.get(this.titoloLayerKey));
  }

  /**
   * EventEmitter utilizzato per notificare la volontà di cambiare layer di sfondo
   */
  @Output() cambiatoLayerSfondo: EventEmitter<BaseLayer> = new EventEmitter();

  /**
   * Emette l'evento di cambiamento del layer di sfondo
   * @param layer che deve essere attivato
   */
  cambiaLayerSfondo(layer: BaseLayer) {
    this.cambiatoLayerSfondo.emit(layer);
  }
}
