import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ShrTitleComponent } from './shr-title/shr-title.component';
import { ShrBreadcrumbComponent } from './shr-breadcrumb/shr-breadcrumb.component';
import { ShrAutocompleteChoiceComponent } from './shr-autocomplete-choice/shr-autocomplete-choice.component';
import { YearDatePickerComponent } from './year-date-picker/year-date-picker.component';
import { CurrencyItaPipe } from './currency-ita.pipe';
import { DisplayMandatoryDirective } from '../directives/display-mandatory.directive';

import { ListModule } from 'src/app/library/ebw-list-angular/list.module';

import { ShrRicercaCatastaleComponent } from './shr-ricerca-catastale/shr-ricerca-catastale.component';
// import { VariazioneSoggettoFisicoComponent } from '../soggetti/gestione/variazione-soggetto-fisico/variazione-soggetto-fisico.component';
// import { VariazioneSoggettoGiuridicoComponent } from '../soggetti/gestione/variazione-soggetto-giuridico/variazione-soggetto-giuridico.component';

@NgModule({
   declarations: [
      ShrTitleComponent,
      ShrBreadcrumbComponent,
      ShrAutocompleteChoiceComponent,
      YearDatePickerComponent,
      DisplayMandatoryDirective,
      CurrencyItaPipe,
      ShrRicercaCatastaleComponent
      // VariazioneSoggettoFisicoComponent,
      // VariazioneSoggettoGiuridicoComponent
   ],
   imports: [
      CommonModule,
      BrowserModule,
      FormsModule,
      NgbModule,
      FontAwesomeModule,
      ReactiveFormsModule
   ],
   exports: [
      ShrTitleComponent,
      ShrBreadcrumbComponent,
      ShrAutocompleteChoiceComponent,
      YearDatePickerComponent,
      ShrRicercaCatastaleComponent,
      ListModule,
      NgbModule,
      DisplayMandatoryDirective,
      CurrencyItaPipe
      // VariazioneSoggettoFisicoComponent,
      // VariazioneSoggettoGiuridicoComponent
   ],
   providers: [
      CurrencyItaPipe
   ]
})
export class ShrComponentsModule { }
