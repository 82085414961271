/** Interfaccia per generare un breve messaggio da passare alla modale */
export interface EBWModalShortMessage {

   /** Contenuto della modale */
   content: string;

}

/** Interfaccia per generare un messaggio completo da passare alla modale */
export interface EBWModalMessage extends EBWModalShortMessage {

   /** Titolo dela modale */
   title: string;

}

/** Interfaccia per genearare una modale custom */
export interface EBWModalMessageCustom extends EBWModalMessage {

   /** Tipologia di modale */
   type: EBWModalType;

}

export enum EBWModalType {
   Info = 'info',
   Warning = 'warning',
   Error = 'error',
   Confirm = 'confirm',
   Success = 'success',
   Loading = 'loading'
}

export enum EBWModalResult {
   Cross = 'cross',
   Close = 'close',
   Cancel = 'cancel',
   Confirm = 'confirm',
}
