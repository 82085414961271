import { Component } from '@angular/core';

@Component({
   selector: 'shr-breadcrumb',
   templateUrl: './shr-breadcrumb.component.html',
   styleUrls: ['./shr-breadcrumb.component.css']
})
export class ShrBreadcrumbComponent {

   constructor() { }

}
