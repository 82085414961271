import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import ConfigMappaJSON from 'src/assets/config/config-mappa.dev.json';
import { IConfigMappa } from '../../model/config-mappa.model';

@Component({
  selector: 'ebw-legenda',
  templateUrl: './legenda.component.html',
  styleUrls: ['./legenda.component.scss']
})
export class LegendaComponent implements OnInit {

   // @ViewChild('content', {static:false}) content: ElementRef;

   /** Variabile che conterrà le configurazioni della mappa */
   configMappa: IConfigMappa = undefined;

   tabs = [];

   sessionData = [];


   constructor() {
      this.configMappa = ConfigMappaJSON as IConfigMappa;
   }


   ngOnInit() {

      /** Recupera la configurazione dei layer accesi/spenti dalla sessione */
      this.sessionData = JSON.parse(sessionStorage.getItem("layer_attivi"));

      //console.log("+++ sessionData +++", this.sessionData);

   }

   /**
    * Se ci sono layers attivi per quel tab
    */
   activeLayers(tab){

      // Find if the array contains an object by comparing the property value
      if(this.sessionData && this.sessionData.some(obj =>{
            if(obj.tab.toUpperCase() === tab.toUpperCase() &&
               obj.visibility === true)
               return true;
            else
               return false;
            })){
         return true;
      } else{

         return false;
      }
   }

   /**
    * Visibilità del layer
    */
   isLayerVisible(tab, gruppo, layer){

      // Find if the array contains an object by comparing the property value
      if(this.sessionData.some(obj =>{
                                       if(obj.tab.toUpperCase() === tab.toUpperCase() &&
                                       obj.title.toUpperCase() === gruppo.toUpperCase() &&
                                       obj.layer.toUpperCase() === layer.toUpperCase() &&
                                       obj.visibility === true)
                                          return true;
                                       else
                                          return false;})){
         return true;
      } else{
         return false;
      }
   }

   /**
    * Visibilità del gruppo
    */
   isGroupVisible(tab, gruppo){

      // Find if the array contains an object by comparing the property value
      if(this.sessionData && this.sessionData.some(obj =>{
                                       if(obj.tab.toUpperCase() === tab.toUpperCase() &&
                                       obj.title.toUpperCase() === gruppo.toUpperCase() &&
                                       obj.visibility === true)
                                          return true;
                                       else
                                          return false;})){

         return true;
      } else{
         return false;
      }
   }


   /**
    *  Visibilità del tab
    *  Il Tab deve essere mostrato o nascosto a seconda delle scelte dell’utente.
    *  Il Tab relativo al SID è sempre abilitato. Es se l’utente sta visualizzando il
    *  SID e un livello dell’ente CGCCP saranno attivi due tab, quello del sid e quello del CGCCP.
    */
   isTabVisible(tab){
      // Find if the array contains an object by comparing the property value
      if(tab.toUpperCase() === "SID" ||
      (this.sessionData &&
        this.sessionData.some(obj =>{
            if(obj.tab.toUpperCase() === tab.toUpperCase() && obj.visibility === true) {
              return true;
            } else {
              return false;
            }
        }))) {

        return true;
      } else{

         return false;
      }
   }

   /**
    * Visibilità della legenda del gruppo
    */
   isLegendaGroupVisible(tab, gruppo){

      /**
       * Se c'è almeno un layer nel gruppo senza legenda, allora visualizzo la legenda del gruppo
       */

      // Ciclo i tab
      if(this.configMappa.legenda.some(t => {


         if(t.nome_tab.toUpperCase() === tab.toUpperCase() &&  // C'è un tab con lo stesso nome di quello passato in input
               t.content.some(g =>{                            // Ciclo i gruppi

                  if(g.title.toUpperCase() === gruppo.toUpperCase() &&  // C'è un gruppo con lo stesso nome di quello passato in input
                     g.layers.some(l => {                               // Ciclo i layers

                        // Se c'è un layer nel gruppo che non ha la legenda, abilito la legenda del gruppo
                        if(l.legenda.length <= 0){
                           // console.log("ABILITA LEGENDA GRUPPO")
                           return true;
                        }
                        else{
                           // Tutti i layers nel gruppo hanno la legenda
                           // console.log("DISABILITA LEGENDA GRUPPO")
                           return false;
                        }

                     })){

                        return true;

                  }
                  else{
                     // Non c'è un gruppo con lo stesso nome di quello passato in input
                     return false;
                  }
            })){
                  // C'è un tab con lo stesso nome di quello passato in input
               return true;

         }else{
            // Non c'è un tab con lo stesso nome di quello passato in input
            return false;
         }

      })){
         return true;
      }
      else{
         // Non c'è un tab con lo stesso nome di quello passato in input
         return false;
      }

   }

}
