// moduli
import { NgModule } from '@angular/core';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// servizi
import { HttpClient } from '@angular/common/http';
// componenti
import { ListComponent } from './list.component';
import { GeneraPDFModule } from './servizi/genera-pdf/genera-pdf.module';
// import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
   declarations: [ListComponent],
   imports: [SharedModule, HttpClientModule, GeneraPDFModule],
   providers: [HttpClient],
   exports: [ListComponent]
})
export class ListModule { }
