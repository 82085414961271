<nav class="navbar navbar-expand-sm navbar-dark bg-white border-nav fixed-top">
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar">
      <span class="navbar-toggler-icon"></span>
   </button>

   <span class="navbar-brand d-flex flex-fill p-0">
      <!-- <button class="btn bg-blu txt-white" (click)="toggleSidebar()">
         <fa-icon aria-hidden="true" [icon]="faAlignJustify"></fa-icon>
      </button> -->
   </span>

   <div class="navbar-collapse collapse">
      <ul class="navbar-nav justify-content-center d-flex flex-fill">
         <!-- <li class="nav-item">
            <a class="nav-link" routerLink="">
               <img src="./../../../assets/img/logo-sid.png" height="40" width="110px"
                  class="rounded-pill d-inline-block align-top ml-3" alt="Sid">
            </a>
         </li>
         <li class="nav-item">
            <a class="nav-link" routerLink="" style="padding-top: 18px;">
               <span class="txt-bg-blu text-dark text-uppercase small font-weight-bold">
                  Portale integrato per la pianificazione del demanio e dello spazio marittimo
               </span>
            </a>
         </li> -->

         <li class="nav-item">
            <a class="nav-link p-0">
               <img src="./../../../assets/img/logo_fdg_278_50.png" height="100%" alt="Fondazione Don Carlo Gnocchi">
            </a>
         </li>
      </ul>
   </div>

   <div class="d-flex flex-fill">
      <!--spacer-->
   </div>


   <div *ngIf="userInfo">
      <div class="btn-group mr-1">
         <button type="button" class="btn bg-blu txt-white" data-toggle="dropdown">
            <fa-icon class="text-white mr-2" aria-hidden="true" [icon]="faUserCircle"></fa-icon>
            <span class="font-weight-bold">{{ userInfo.nome }} {{ userInfo.cognome }}
               {{!!userInfo.impersonificato_da ? '(' + userInfo.impersonificato_da.nome + ' ' + userInfo.impersonificato_da.cognome + ')' : ''}}</span>
         </button>
         <button type="button" class="btn bg-blu txt-white dropdown-toggle dropdown-toggle-split" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <span class="sr-only">Toggle Dropdown</span>
         </button>
         <div class="dropdown-menu custom-dropdowm">
            <span class="dropdown-item-text userRole">
               {{ userInfo.ruoli_nome }} - {{ userInfo.gruppi_nome }}
            </span>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)"> Profilo </a>
            <a class="dropdown-item" routerLink="/login" (click)="onLogout()"> Logout </a>
         </div>
      </div>
   </div>


   <!-- <app-notifiche></app-notifiche> -->

</nav>
