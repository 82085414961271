import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilsService } from 'src/app/servizi/utils.service';
import {
   faUser, faBell, faThumbtack, faCog, faExclamationTriangle,
   faEnvelope, faPaperclip, faPhone, faChartPie, faInfo,
   faWrench, faBan, faHome, faTrash, faPaperPlane, faCube,
   faCamera, faStar, faLock, faCoffee, faCalendarAlt, faPlusCircle, faComments
} from '@fortawesome/free-solid-svg-icons';
import { GestioneLoginService } from 'src/app/servizi/login/gestione-login.service';
import { Subscription } from 'rxjs';

/** componente per la visualizzazione delle notifiche da parte del client */
@Component({
   selector: 'app-notifiche',
   templateUrl: './notifiche.component.html',
   styleUrls: ['./notifiche.component.scss']
})
export class NotificheComponent implements OnInit, OnDestroy {

   /** sottoscrizione al login */
   loginSubs: Subscription;

   /** icona principale */
   faComments = faComments;

   /** json delle notifiche */
   notifiche: any;

   /** notifiche da leggere */
   daLeggere: number = 0;

   /** visualizzazione del pannello notifiche */
   panelOn: boolean = false;

   /** json delle opzioni */
   opzioni: any;

   /** elenco icone associabili a una notifica */
   faIcons: any = {
      faComments,
      faPlusCircle,
      faCalendarAlt,
      faInfo,
      faUser,
      faCog,
      faBell,
      faThumbtack,
      faExclamationTriangle,
      faEnvelope,
      faPaperclip,
      faPhone,
      faChartPie,
      faStar,
      faWrench,
      faBan,
      faLock,
      faCoffee,
      faCamera,
      faCube,
      faPaperPlane,
      faTrash,
      faHome,
   };

   /**intervallo di tempo (5m) per aggiornare le notifiche*/
   private timeInterval = 120000;

   notificationInterval;

   /** costruttore */
   constructor(
      private loginServ: GestioneLoginService,
      private utilsServ: UtilsService,
      private sanitizer: DomSanitizer,
      private logger: NGXLogger) { }

   /** OnInit */
   ngOnInit() {


      // aggiorno le notifiche
      this.aggiornaNotifiche();

      this.notificationInterval = setInterval(this.aggiornaNotifiche.bind(this), this.timeInterval);

      // resto in ascolto, in attesa di una login differente (es:simula)
      this.loginSubs = this.loginServ.ascoltaLogin().subscribe(
         (resp: any) => {
            // aggiorno le notifiche
            this.aggiornaNotifiche();
         },
         (err: string) => { this.logger.error('activationError: ', err); }
      );

      // prelevo le icone associate ai tipi
      const icUrl = this.utilsServ.creaURL('notifiche', 'opzioni_view');
      this.utilsServ.postRequest(icUrl, {}).subscribe(
         (resp) => {
            if (resp.error === 0 && resp.status === 0) {
               this.opzioni = resp.data;
               // console.warn('opzioni', this.opzioni)
            }
         },
         (error) => this.logger.error(error)
      );
   }

   ngOnDestroy() {
      if (!!this.notificationInterval) {
         clearInterval(this.notificationInterval)
      }
   }


   /** aggiorna le notifiche relative all'utente */
   aggiornaNotifiche() {
      // verifico le notifiche da leggere relative all'utente
      const visUrl = this.utilsServ.creaURL('notifiche', 'letture_view');
      const visPar = {
         user_id: this.loginServ.getPayload().utenti_id,
         user_gruppo: this.loginServ.getPayload().gruppi_id
      };
      this.utilsServ.postRequest(visUrl, visPar).subscribe(
         (resp) => {
            if (resp.error === 0 && resp.status === 0) {
               this.notifiche = resp.data;
               console.warn('notifiche', this.notifiche)
               this.daLeggere = this.notifiche.filter((n) => n.lettura == 0).length;
               // sanifico il testo (altrimenti lo style viene escluso per motivi di sicurezza)
               this.notifiche.forEach(notifica => {
                  notifica.testo = this.sanitizer.bypassSecurityTrustHtml(notifica.testo);
               });
            }
         },
         (error) => this.logger.error(error)
      );
   }

   /** toggle pannello notifiche */
   onClick() {
      this.panelOn = !this.panelOn;
   }

   /**
    * dichiarazione di una lettura da parte dell'utente
    * @param idNotifica identificativo della notifica
    */
   salvaLettura(idNotifica) {
      // prelevo la notifica cliccata
      const notLetta = this.notifiche.find((n) => n.id === idNotifica);

      // se non è già stata letta
      if (notLetta.lettura == 0) {
         // dichiaro la lettura (graficamente)
         notLetta.lettura = 1;
         // decremento il contatore notifiche
         this.daLeggere--;

         // dichiaro la lettura (sul db)
         const letUrl = this.utilsServ.creaURL('notifiche', 'letture_insert');
         const letPar = {
            user_id: this.loginServ.getPayload().utenti_id,
            notifiche_id: idNotifica
         };
         this.utilsServ.postRequest(letUrl, letPar).subscribe(
            () => this.logger.debug('lettura notifica: ', idNotifica),
            (error) => this.logger.error(error)
         );
      }
   }

   /**
    * ritorna l'icona associata a un 'tipo'
    * @param tipo valore del 'tipo'
    * @returns icona del tipo
    */
   getTipoIcon(tipo: string) {
      const myTipo = this.opzioni.find(o => (o.opzione === 'tipo' && o.valore === tipo));
      return this.faIcons[myTipo.proprieta];
   }


   /**
    * ritorna la classe css da associare a una priorità
    * @param priorita valore della 'priorità'
    * @returns classe della priorità
    */
   getColor(priorita: string) {
      const myPriority = this.opzioni.find(o => (o.opzione === 'priorita' && o.valore === priorita));
      return myPriority.proprieta;
   }
}
