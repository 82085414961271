import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MappaService } from '../servizi/mappa/mappa.service';

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.scss'],
   animations: [
      trigger('slideInOut', [
         state('in', style({
            transform: 'translate3d(-100%, 0, 0)',

         })),
         state('out', style({
            transform: 'translate3d(0, 0, 0)',
         })),
         transition('in => out', animate('300ms ease-in-out')),
         transition('out => in', animate('300ms ease-in-out'))
      ]),
      trigger('slideMap', [
         state('in', style({
            'margin-left': '0px'

         })),
         state('out', style({
            'margin-left': '115px'
         })),
         transition('in => out', animate('300ms ease-in-out')),
         transition('out => in', animate('300ms ease-in-out'))
      ]),
   ],
   encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements AfterViewInit {

   constructor(
      private elementRef: ElementRef,
      private servizioSidWrapperMappa: MappaService
   ) { }

   /** variabile che gestisce apertura/chiusura del menù */
   opened = true;
   menuState: string = 'in';
   /** AfterViewInit */
   ngAfterViewInit() {
      this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'white';
   }

   /** Apre o chiude il menù (metodo invocato dal bottone nel HeaderComponent) */
   setSidebarVisibility() {
      this.menuState = this.menuState === 'out' ? 'in' : 'out';
      this.opened = !this.opened;
   }
}
