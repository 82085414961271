import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EBWModalMessageCustom, EBWModalResult } from '../model/ebw-modal.model';

/** Componente che detiene la struttura delle modali */
@Component({
   selector: 'ebw-modal',
   templateUrl: './ebw-modal.component.html',
   styleUrls: ['./ebw-modal.component.scss']
})
export class EbwModalComponent {

   /** Tipi utilizzati per */
   resultTypes = EBWModalResult;

   /** messaggio da visualizzare */
   @Input() public message: EBWModalMessageCustom;

   /**
    * Costruttore per il componente
    * @param activeModal servizio ngBootstrap per attivare la modale
    */
   constructor(public activeModal: NgbActiveModal) { }
}
