import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { faTimes, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';


import { Feature, Overlay } from 'ol';
import BaseLayer from 'ol/layer/Base';
import { Point, SimpleGeometry } from 'ol/geom';
import { Layer, Vector } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

import { MapService, TipoLayer, PosizioneLayer } from '../../service/map.service';

/**
* Modali
*/
import { EbwModalService } from '../../library/ebw-modals-angular/ebw-modal.service';
import { EBWModalResult } from '../../library/ebw-modals-angular/model/ebw-modal.model';
import { GeoUtilsData } from '../../model/api.model';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import GeometryType from 'ol/geom/GeometryType';
import OverlayPositioning from 'ol/OverlayPositioning';


@Component({
  selector: 'ebw-tool-aggancia-punto',
  templateUrl: './tool-aggancia-punto.component.html',
  styleUrls: ['./tool-aggancia-punto.component.scss']
})
export class ToolAgganciaPuntoComponent implements OnInit, OnDestroy {

   /** Evento per abilitare una nuova modalità es. selezione */
   @Output() changedEditMode: EventEmitter<string> = new EventEmitter();

   /** Evento per l'inserimento del layer di precisione sulla mappa */
   @Output() addPrecisionLayer: EventEmitter<BaseLayer> = new EventEmitter();

   /** Evento per aggiungere una tooltip */
   @Output() addTooltip: EventEmitter<Overlay> = new EventEmitter();

   /** Evento per la rimozione di una tooltip */
   @Output() removeTooltip: EventEmitter<Overlay> = new EventEmitter();

   /** Evento per l'emissione del punto agganciato */
   @Output() puntoAgganciato: EventEmitter<Feature> = new EventEmitter();

    /** Per chiudere il pannello aggancia punto */
   @Output() chiudiPannelloAgganciaPunto: EventEmitter<any> = new EventEmitter();

   /** SR di default utilizzato in tutto il pacchetto */
   @Input() defaultSR: any;

   /** Identificativo stile del layer di default */
   @Input() layerDefaultStyleId: string;

   /** Oggetto layer di riferimento */
   @Input() layerDefault: BaseLayer;

   /** Feature selezionata per le transformazioni */
   @Input() selectedFeature: Feature;

   /** Centro della mappa sempre aggiornato
   * [0] --> longitudine
   * [1] --> latitudine
   */
   @Input() centroMappa;
   @Input() viewMappa;

   /** Per gestire la visualizzazione del pannello di gestione della particella */
   @Input() set triggerPannelloAgganciaPunto(b: boolean){
      this.pannelloAgganciaPuntoAbilitato = b;
   }


   /** COSTANTI */
   // Numero massimo di vertici vicini mostrati
   MAX_VERTICI_VICINI = 2;
   EPSG_MAP = 3857;
   EPSG_API_VERTICI_VICINI = 4326;
   EPSG_LIST = [3857, 4326];
   STYLE_ID_PRECISION_LAYER = 'GreenPoint';
   STYLE_ID_PRECISION_LAYER_VERTICI_VICINI_BLUE = 'BlueCross';
   STYLE_ID_PRECISION_LAYER_VERTICI_VICINI_YELLOW = 'YellowCross';
   UNCONFIRMED_PRECISION_FEATURE_TAG = 'unconfirmedFeature';



   /** Riferimento al pannello Aggancia Punto */
   public pannelloAgganciaPuntoForm: FormGroup;
   pannelloAgganciaPuntoAbilitato:boolean = true;

   /** Per la gestione del risultato del servizio che recupera i vertici vicini */
   nessunRisultatoShow = false;

   /** Punto da agganciare, di cui cercare i vertici vicini */
   puntoInserito;

   /** Vertici vicini al punto inserito  */
   verticiVicini: any = [];

   /** Per la gestione della visualizzazione del pannello contenente l'elenco dei vertici vicini */
   pannelloVerticiViciniShow: boolean=false;

   /** Proprietà della feature selezionata */
   proprietaFeatureSelezionata = {};

   /** ID della feature selezionata */
   idFeatureSelezionata: string;

   /** Sistema di rimerimento */
   selectedSRID: number;

   /** Layer per disegni di precisione */
   precisionLayer: BaseLayer;

   /** ICONE */
   faTimes = faTimes;
   faTrash = faTrash;
   faCheck = faCheck;

   /**
   * Tooltip che consentono di distinguere le coordinate,
   * variano in base all'EPSG
   * es. EPSG 3857: X/Y, EPSG 4326: LAT/LONG
   */
   ttCoordX = 'X';
   ttCoordY = 'Y';



   constructor(private servizioMappa: MapService,
               private ebwModalService: EbwModalService,
               private formBuilder: FormBuilder) { }

   ngOnDestroy(){

      console.log("-- CHIUSURA TOOL AGGANCIA PUNTO --")

      /** Disabilito la modalità disegno */
      //this.endDraw();

      /** Pulisco i layer di default */
      //this.layerDefault.getSource().clear();

      /** Pulisco il Layer di precisione */
      this.clearPrecisionLayer();

   }


   ngOnInit() {

      /** Inizializzazione della form */
      this.initForms();

      /**
      * Inizializzazione del layer di precisione
      */
      this.selectedSRID = this.defaultSR.epsg;

      /** Inizializzazione della sorgente sulla quale verrà inserito un layer vettoriale */
      const vectorSource = new VectorSource({});

      /** Creazione layer per disegno di precisione */
      this.precisionLayer = new Vector({
         source: vectorSource,
         visible: true,
         zIndex: 1000
      });

      /** Impostazioni del nuovo layer : precisionLayer */
      /** Titolo */
      this.precisionLayer.set(MapService.TITOLO_LAYER_KEY, 'precision_draw');
      /** Tipo */
      this.precisionLayer.set(MapService.TIPO_LAYER_KEY, TipoLayer.TEMP);
      /** Posizione */
      this.precisionLayer.set(MapService.POSIZIONE_LAYER_KEY, PosizioneLayer.OVERLAY);

      /** Emissione dell'evento per l'aggiunta del layer alla mappa */
      this.addPrecisionLayer.emit(this.precisionLayer);

      /**
      * PRECISION LAYER - ADD FEATURE
      *
      * Gestione evento ADDFEATURE della VectorSource associata al precisionLayer
      * Evento generato ogni volta che viene inserita una nuova feature nel layer di precisione
      * (Layer temporaneo di disegno)
      */
      vectorSource.on('addfeature', (sourceEvent) => {

         /** Recupera dalla form il nome che l'utente ha dato alla della feature inserita */
         //  const featureName = this.pannelloCoordinateForm.controls['nome'].value;

         /*
         * PUNTO
         * Se è stato inserito un punto (In modalità precisione)
         */
         if (sourceEvent.feature.getGeometry().getType() === 'Point') {

            /** La feature è sempre confermata chiamando -> this.confirmPoint(sourceEvent.feature); */
            if (!sourceEvent.feature.get('notSetUnconfirmedTag')) {
               /** Imposto la nuova feature come non confermata */
               sourceEvent.feature.set('featureTag', this.UNCONFIRMED_PRECISION_FEATURE_TAG);

               /** Generazione di un ID univoco per la feature inserita */
               sourceEvent.feature.setId(this.generateFeatureId());

            }

            /** Confermo sempre il punto */
            this.confirmPoint(sourceEvent.feature);

            this.transformSinglePrecisionFeatureForVisualization(this.selectedSRID, sourceEvent.feature);
         }
      }); // addfeature


      /**
      * LAYER DEFAULT - ADD FEATURE
      * Ogni volta che viene aggiunta una feature nella modalità standard oppure viene confermata una
      * figura in modalità precisione passa qui
      * sourceEvent è un obj fatto così --> {feature: Feature, target: VectorSource}
      */
      ((this.layerDefault as Layer).getSource() as VectorSource).on('addfeature', (sourceEvent) => {


         /** Se ho salvato delle proprietà per la feature selezionata le risetto */
         if (!!this.proprietaFeatureSelezionata && Object.keys(this.proprietaFeatureSelezionata).length > 0) {
            const properties = this.concatProperties(sourceEvent.feature.getProperties(), this.proprietaFeatureSelezionata);
            sourceEvent.feature.setProperties(properties);
         }

         /** Ho già id, non faccio niente */
         if (sourceEvent.feature.getId() !== undefined) {
         } else if (!!this.idFeatureSelezionata) {
            sourceEvent.feature.setId(this.idFeatureSelezionata);
         } else {
            /** Inserimento */
            /** Altrimenti ne assegno uno generata */
            this.idFeatureSelezionata = this.generateFeatureId();
            sourceEvent.feature.setId(this.idFeatureSelezionata);
         }

         if (this.selectedFeature == null) {
            this.idFeatureSelezionata = undefined;
            this.proprietaFeatureSelezionata = {};
         }

         this.endDraw();


      });

      /** Attivo la modalità di inserimento del punto */
      this.aggiungiPuntoDaAgganciare();

   }


   /**
   * concatProperties
   * @param   current   ???
   * @param   previous ???
   */
   concatProperties(current, previous) {
      if (!!previous) {
         if (!!current) {
            Object.entries(previous).forEach((el) => {
               const k = el[0];
               const v = el[1];
               if (k !== 'geometry') {
                  current[k] = v;
               }
            });
            return current;
         }
      }
      return {};
   }


   /**
   * initForms
   * Inizializza le form
   */
   initForms() {

      /** Inizializzazione pannello particella */
      this.pannelloAgganciaPuntoForm = this.formBuilder.group({
         epsg: this.EPSG_MAP
      });


      /** Resta in ascolto di cambiamenti al valore del controllo 'epsg' che indica il sistema di riferimento selezionato */
      this.pannelloAgganciaPuntoForm.controls['epsg'].valueChanges.subscribe(val => this.gestisciCambioEpsg());

      /** Chiudo il pannello particella */
      //this.pannelloParticellaAbilitato = false;

   }



   /**
   * Pulisce il layer di precisione
   */
  clearPrecisionLayer() {
   ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures().forEach((f: Feature) => {
      this.removeTooltip.emit(f.get('tooltip'));
      f.unset('tooltip');
   });


   ((this.precisionLayer as Layer).getSource() as VectorSource).clear();
}



   /**
   * Funzione per confermare il punto inserito
   * @param   feature oggetto geometria punto
   */
   confirmPoint(feature: Feature) {

      /** Confronto tipo e valore di feature.get('featureTag') e this.UNCONFIRMED_PRECISION_FEATURE_TAG */
      const ttCreation: boolean = feature.get('featureTag') === this.UNCONFIRMED_PRECISION_FEATURE_TAG;

      /** Se la feature è presente tra quelle non confermate */
      if (ttCreation) {
         /** Rimuove il valore UNCONFIRMED_PRECISION_FEATURE_TAG */
         feature.unset('featureTag');

      }

      this.endDraw();

      /** Cerco i vertici vicini solo per il punto inserito manualmente */
      // console.log("VV PROP", feature.get('verticiVicini'), "VA PROP", feature.get('verticeAgganciato'))

      if (!feature.get('verticiVicini') && !feature.get('verticeAgganciato'))
         this.gestisciPuntoInserito(feature);


   }


   /**
   * Per recuperare i vertici vicini al punto inserito
   */
   gestisciPuntoInserito(point: Feature){
      this.cercaVerticiVicini(point);
   }


   /**
   * Termina figura
   */
   endDraw() {

      this.servizioMappa.interruttoreInterazioneDisegno$.next({
         flag: false,
         //geometry: this.tipoGeometriaSelezionata
         geometry: GeometryType.POINT
      });
      this.servizioMappa.interruttoreInterazioneSelezione$.next(false);

   }

   /**
   * Trasforma una singola coordinata nel sistema di riferimento richiesto
   * @param   epsgTo  sistema di riferimento di destinazione
   * @param   feature feature di cui trasformare le coordinate
   */
   transformSinglePrecisionFeatureForVisualization(epsgTo: number, feature: Feature) {

      this.servizioMappa.transformCoords([(feature.getGeometry() as SimpleGeometry).getCoordinates()], this.EPSG_MAP, epsgTo)
         .subscribe((response) => {
            if (response.error !== 0) {
               console.error('ERRORE(APP)_transformCoords', response.message, response.traceback);
            }
            if (response.status !== 0) {
               console.error('ERRORE(PAR)_transformCoords', response.message);
            }
            if (response.error === 0 && response.status === 0) {
               const coords = (<GeoUtilsData>(response.data)).coords[0];
               feature.set('transformedCoords', coords);
            }
         });

   }


   /**
   * Generazione Random di un ID per la nuova feature inserita
   */
   generateFeatureId() {
      return Math.random().toString(36).substr(2, 9);
   }


   /**
   * Per avviare la modalità disegna punto appena il tool aggancia punto viene abilitato
   */
   aggiungiPuntoDaAgganciare(){

      //this.aggiungiPuntoParticellaClicked=true;

      this.changedEditMode.emit('none');

      this.startDraw();

   }


   /** Punto / Linea / Poligono */
   startDraw() {
      console.log('-- startDraw');
      this.servizioMappa.interruttoreInterazioneDisegno$.next({
         flag: true,
         geometry: GeometryType.POINT,
         style: this.STYLE_ID_PRECISION_LAYER,
         layer: this.precisionLayer
      });
   }


   /**
   * Gestisce il cambio del sistema di coordinate
   */
   gestisciCambioEpsg() {
      if(this.pannelloAgganciaPuntoForm.controls['epsg'].value === 4326){
         this.ttCoordX = 'Longitudine';
         this.ttCoordY = 'Latitudine';
      }else{
         this.ttCoordX = 'X';
         this.ttCoordY = 'Y';
      }

      this.onChangeSRID();
   }


   /**
   * Metodo richiamato quando viene modificato il sistema di riferimento
   */
   onChangeSRID() {
      this.selectedSRID = this.pannelloAgganciaPuntoForm.controls['epsg'].value;
      this.transformAllPrecisionFeaturesForVisualization(this.selectedSRID);
   }


   /**
   * Trasforma tutte le coordinate del layer di precisione nel sistema di riferimento richiesto
   * @param   epsgTo sistema di riferimento di destinazione
   */
   transformAllPrecisionFeaturesForVisualization(epsgTo: number) {
      const coords = [];
      this.insertedPrecisionFeatures(false).forEach((p) => {
         coords.push(p.getGeometry().getCoordinates());
      });

      this.servizioMappa.transformCoords(coords, this.EPSG_MAP, epsgTo)
         .subscribe((response) => {
            if (response.error !== 0) {
               console.error('ERRORE(APP)_transformCoords', response.message, response.traceback);
            }
            if (response.status !== 0) {
               console.error('ERRORE(PAR)_transformCoords', response.message);
            }
            if (response.error === 0 && response.status === 0) {
               const coords = (<GeoUtilsData>(response.data)).coords;
               const features = this.insertedPrecisionFeatures(false);
               for (var i = 0; i < features.length; i++) {
                  features[i].set('transformedCoords', coords[i]);
               }
            }
      });
   }


   /**
   * Ritorna un array con i punti inseriti nel layer di precisione, ordinati in base alla loro posizione
   * @returns array di feature di tipo Point
   */
   insertedPrecisionFeatures(verticiVicini:boolean=false) {
      let res;

      if(!verticiVicini){
         res = ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures()
            .filter((el: Feature) => { return el.getGeometry().getType() === 'Point'; })
            .filter((e:Feature) => {return !e.get('verticiVicini')});
      }else{
         res = ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatures()
            .filter((el: Feature) => { return el.getGeometry().getType() === 'Point'; })
            .filter((e:Feature) => {return e.get('verticiVicini')})
            .sort((a: Feature, b: Feature) => { if (a.get('precisionCounterPlaceholder') < b.get('precisionCounterPlaceholder')) { return -1; } else { return 1; } });
         }

      return res;
   }


   /**
   * Metodo che si sottoscrive al servizio per recuperare i riferimenti catastali di una particella
   */
   cercaVerticiVicini(f: Feature){

      if(!!f){
         this.puntoInserito = f;

         if(!!(f.getGeometry() as SimpleGeometry).getCoordinates() ){

            /** Trasformo le coordinate passate per poter aggiornare la posizione sulla mappa */
            this.servizioMappa.cercaVerticiViciniService((f.getGeometry() as SimpleGeometry).getCoordinates()).subscribe((response) => {
               if (response.error !== 0) {
                  console.error('ERRORE(APP)cercaVerticiViciniService', response.message, response.traceback);
                  this.nessunRisultatoShow=true;
               }
               if (response.status !== 0) {
                  console.error('ERRORE(PAR)cercaVerticiViciniService', response.message);
                  this.nessunRisultatoShow=true;
               }
               if (response.error === 0 && response.status === 0) {
                  console.log('TOOL-AGGANCIA-PUNTO - VERTICI-VICINI - RESPONSE', response);

                  /** Ordina e arrotonda */
                  this.verticiVicini = response.data;

                  if(this.verticiVicini.length == 0)
                     this.nessunRisultatoShow = true;
                  else{
                     this.nessunRisultatoShow = false;

                     this.verticiVicini = this.verticiVicini.slice(0, this.MAX_VERTICI_VICINI);

                     /** Mappatura dei risultati */
                     this.verticiVicini = this.verticiVicini.map(function(e) {
                        return {
                                 id: e.id,
                                 oggetto: e.oggetto,
                                 vertex: {
                                    type: e.vertex.type,
                                    coordinates: [
                                       e.vertex.coordinates[0],
                                       e.vertex.coordinates[1]
                                    ]
                                 },
                                 dist : Math.round(e.dist * 100) / 100
                              }
                     });


                     /** Costruisco l'array di coordinate da convertire */
                     var coordsArray = this.verticiVicini.map(function(e){
                        return e.vertex.coordinates;
                     });


                     /** Converto l'array di coordinate */
                     this.servizioMappa.transformCoords(coordsArray, this.EPSG_API_VERTICI_VICINI , this.EPSG_MAP)
                     .subscribe((response) => {

                        if (response.error !== 0) {
                           console.error('ERRORE(APP)_transformCoords', response.message, response.traceback);
                        }
                        if (response.status !== 0) {
                           console.error('ERRORE(PAR)_transformCoords', response.message);
                        }
                        if (response.error === 0 && response.status === 0) {

                           var convertedCoords = (<GeoUtilsData>(response.data)).coords;

                           /** Per ogni coppia di coordinate creo il vertice con le relative proprietà */
                           convertedCoords.forEach((coordinates, i) => {

                              /** Creo la feature punto */
                              f = this.createPointFeature(coordinates);

                              f.set('transformedCoords', coordinates);

                              let oggetto = this.verticiVicini[i].oggetto;
                              let dist = this.verticiVicini[i].dist;

                              f.set('oggetto',oggetto);
                              f.set('dist', dist);

                              /** Aggiungo alla feature la tooltip */
                              let ttPoint = this.createPointTooltip(i + 1, [coordinates[0],coordinates[1]]);
                              f.set('tooltip', ttPoint);

                              /** Aggiungo lo stile del layer precision alla feature */
                              f.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER_VERTICI_VICINI_BLUE));

                              /** Imposto una proprietà per rendere la feature già confermata */
                              f.set('notSetUnconfirmedTag', true);

                              /** Imposto una proprietà per identificare un vertice vicino */
                              f.set('verticiVicini', true);

                              /** numero per  */
                              f.set('precisionCounterPlaceholder', i + 1);

                              /** Aggiungo la feature al layer precision */
                              ((this.precisionLayer as Layer).getSource() as VectorSource).addFeature(f);

                           });

                        }

                     });
                  }
               }
            });
         }
      }
   }


   /**
   * Creazione di una feature di tipo Point.
   * @param   coords coordinate per creare il punto
   */
   createPointFeature(coords: number[]) {
      const feature = new Feature({
         geometry: new Point(coords)
      });
      let id = this.generateFeatureId();
      feature.setId(id);
      return feature;
   }

   /**
    * Generic array sorting
    *
    * @param property
    * @returns {Function}
    */
   sortByProperty(property){
      return function (x, y) {
         return ((x[property] === y[property]) ? 0 : ((x[property] > y[property]) ? 1 : -1));
      };
   };

  /**
   * Premuto bottone 'Annulla' (nel caso delle geometrie) o 'Chiudi' (nel caso della particella)
   */
  gestioneClickAnnullaFigura() {

   let content = "Chiudere il tool Aggancia Punto?";

   this.ebwModalService.openConfirmModal({ title: 'Attenzione', content: content }).result.then((result) => {
      if (result === EBWModalResult.Confirm) {
         this.cancelDraw();
         // this.isEdit = false;
      }
   }, (reason) => { });
}


   /**
   * Annulla le modifiche alla figura
   */
  cancelDraw() {

   /** Termino disegno*/
   // this.endDraw();

   /** Pulisco layer precisione */
   this.clearPrecisionLayer();

   /** Riabilito i tools nella toolbar */
   this.chiudiPannelloAgganciaPunto.emit();
}




   /**
   * Crea una tooltip nelle coordinate passate con valore del parametro indice.
   * @param   indice    valore tooltip
   * @param   posizione posizion tooltip
   * @returns           l'oggetto tooltip
   */
   createPointTooltip(indice: number, posizione: [number, number]): Overlay {
      const tooltipElement = document.createElement('div');
      tooltipElement.innerHTML = JSON.stringify(indice);
      tooltipElement.style.cssText = 'background-color:#FFFFFFBB; border:1px solid gray; border-radius:10px; font-weight:bold; padding:0px 5px; margin-bottom:2px;';
      const tooltip = new Overlay({
         element: tooltipElement,
         offset: [0, -7],
         positioning: OverlayPositioning.BOTTOM_CENTER,
         position: posizione
      });
      this.addTooltip.emit(tooltip);
      return tooltip;
   }


   /**
   * Consente di visualizzare la feature interessata
   * Si attiva nell'evento on hover della feature nel pannellino di gestione della selezione di feature multiple
   * @param f Feature interessata
   * @param e Evento con elemento html da illuminare
   */
   illuminaFeature(f, e) {
      let featureFromLayer = ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatureById(f.getId());
      featureFromLayer.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER_VERTICI_VICINI_YELLOW));

      e.target.style.backgroundColor="#ffd500";
      e.target.style.cursor="pointer";

   }

   /**
   * Ripristina lo stile di default
   * Si attiva nell'evento on hover della feature nel pannellino di gestione della selezione di feature multiple
   * @param f Feature interessata
   * @param e Evento con elemento html da spegnere
   */
   spegniFeature(f, e) {
      let featureFromLayer = ((this.precisionLayer as Layer).getSource() as VectorSource).getFeatureById(f.getId());
      featureFromLayer.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER_VERTICI_VICINI_BLUE));

      e.target.style.backgroundColor="#e9ecef";
      e.target.style.cursor="default";
   }

   /**
   * Gestione del vertice da agganciare
   * Si attiva nell'evento click della feature nel pannellino di gestione della selezione di feature multiple
   * @param f Feature interessata
   * @param e Evento con elemento html da spegnere
   */
   gestisciVerticeSelezionato(f,e){
      console.log("VERTICE VICINO SELEZIONATO --> ", f);

      /** Coordinate in 3857 del vertice a cui agganciare il punto inserito */
      let coords = f.getGeometry().getCoordinates();

      /** Pulisco il Layer di precisione */
      this.clearPrecisionLayer();

      this.verticiVicini={};
      this.nessunRisultatoShow=true;

      /** Creo il nuovo punto da agganciare al vertice vicino */
      let p = this.createPointFeature(coords);

      /** Aggiungo lo stile del layer precision alla feature */
      p.setStyle(this.servizioMappa.buildFeatureStyle(this.STYLE_ID_PRECISION_LAYER));

      /** Imposto una proprietà per rendere la feature già confermata */
      p.set('notSetUnconfirmedTag', true);

      /** Imposto una proprietà per identificare un vertice vicino */
      p.set('verticeAgganciato', true);

      /** Aggiungo la feature al layer precision */
      ((this.precisionLayer as Layer).getSource() as VectorSource).addFeature(p);

      console.log("TOOL AGGANCIA PUNTO - GESTISCI V SELEZIONATO EMIT--> feature ", p);
      /** Emissione del punto agganciato*/
      this.puntoAgganciato.emit(p);

      /** Chiusura pannello aggancia punto in automatico*/
      this.cancelDraw();

   }


}
