<!-- MODALE DI INFO -->
<ng-container *ngIf="message.type==='info'">
   <div class="modal-header bg-blu text-white">
      <h5 class="modal-title"> {{message.title}}</h5>
      <button type="button" class="text-white close" aria-label="Close"
         (click)="activeModal.dismiss(resultTypes.Cross)">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <p [innerHTML]="message.content"></p>
   </div>
   <div class="modal-footer">
      <button type="button" class="btn btn-bg-outline" (click)="activeModal.close(resultTypes.Close)">Chiudi</button>
   </div>
</ng-container>

<!-- MODALE DI WARNING -->
<ng-container *ngIf="message.type==='warning'">
   <div class="modal-header bg-yellow text-white">
      <h5 class="modal-title"> {{message.title}}</h5>
      <button type="button" class="close txt-white" aria-label="Close" (click)="activeModal.dismiss(resultTypes.Cross)">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <p [innerHTML]="message.content"></p>
   </div>
   <div class="modal-footer">
      <button type="button" class="btn btn-bg-outline" (click)="activeModal.close(resultTypes.Close)">Chiudi</button>
   </div>
</ng-container>

<!-- MODALE DI CONFERMA -->
<ng-container *ngIf="message.type==='confirm'">
   <div class="modal-header  bg-blu text-white">
      <h5 class="modal-title"> {{message.title}}</h5>
      <button type="button" class="text-white close" aria-label="Close"
         (click)="activeModal.dismiss(resultTypes.Cross)">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <p [innerHTML]="message.content"></p>
   </div>
   <div class="modal-footer">
      <button type="button" class="btn btn-bg-outline" (click)="activeModal.close(resultTypes.Cancel)">Annulla</button>
      <button type="button" class="btn bg-blu txt-white"
         (click)="activeModal.close(resultTypes.Confirm)">Conferma</button>
   </div>
</ng-container>

<!-- MODALE DI ERROR -->
<ng-container *ngIf="message.type==='error'">
   <div class="modal-header bg-red text-white">
      <h5 class="modal-title"> {{message.title}}</h5>
      <button type="button" class="text-white close" aria-label="Close"
         (click)="activeModal.dismiss(resultTypes.Cross)">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <p [innerHTML]="message.content"></p>
   </div>
   <div class="modal-footer">
      <button type="button" class="btn btn-bg-outline" (click)="activeModal.close(resultTypes.Close)">Chiudi</button>
   </div>
</ng-container>

<!-- MODALE DI SUCCESS -->
<ng-container *ngIf="message.type==='success'">
   <div class="modal-header  bg-blu text-white">
      <h5 class="modal-title"> {{message.title}}</h5>
      <button type="button" class="text-white close" aria-label="Close"
         (click)="activeModal.dismiss(resultTypes.Cross)">
         <span aria-hidden="true">&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <p [innerHTML]="message.content"></p>
   </div>
   <div class="modal-footer">
      <button type="button" class="btn btn-bg-outline" (click)="activeModal.close(resultTypes.Close)">Chiudi</button>
   </div>
</ng-container>

<!-- MODALE DI LOADING -->
<!-- <ng-container *ngIf="message.type==='loading'">
   <div class="modal-body d-flex align-items-center justify-content-center">
      <div class="spinner-border text-primary" role="status">
         <span class="sr-only">Loading...</span>
      </div>
      <p [innerHTML]="message.content" class="m-2 align-self-center font-weight-bold"></p>
   </div>
</ng-container> -->
