import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { faSort, faSortDown, faSortUp, faCog, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Observable } from "rxjs";
import * as FileSaver from "file-saver";
// import { TutorialComponent } from 'src/app/tutorial/tutorial.component';
import { EBWApiResponse } from "./model/api.response.model";
import { GeneraPDFService } from "./servizi/genera-pdf/genera-pdf.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { HttpHeaders } from "@angular/common/http";
import { UtilsService } from "./servizi/utils.service";
import { IConfigGlobaleList } from "./model/config-globale-list-model";
import { DatePipe } from "@angular/common";

/**
 * Componente per definire un elenco
 */
@Component({
  selector: "ebw-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit {
  /**
   * Variabile che memorizza il tipo di lista dell'elenco
   */
  isInitialized = false;
  /**
   * Tipologia della lista
   */
  _listType: string;
  /**
   * Opzioni per il numero di righe visualizzate
   */
  _rowsPerPageOptions: number[];
  /**
   * Array di icone da utilizzare nell'elenco
   */
  _fontawesomeIcons = {};
  /**
   * Protocollo indirizzo rest API (http, https)
   */
  _restApiProtocol: string;
  /**
   * Indirizzo IP e porta del server della REST API
   */
  _restApiIpPort: string;
  /**
   * Percorso della REST API
   */
  _restApiPath: string;
  /**
   * Percorso della REST API
   */
  _tableHeight: string = "200px";
  /**
   * Icona per il dropdown delle azioni non principali
   */
  _faCog = faCog;

  // allineamento in presenza di scroll
  alignItem: boolean = false;

  private _configGlobale: IConfigGlobaleList;

  @Input() waitForFirstRequest: boolean = false;

  /**
   * Imposta le opzioni per il numero di righe per pagina visualizzate
   */
  @Input() set rowsPerPageOptions(options: number[]) {
    this._rowsPerPageOptions = options;
  }
  /**
   * Imposta le icone che saranno utilizzate dall'elenco (es. per la rappresentazione dei bottoni)
   */
  @Input() set fontawesomeIcons(iconsArray: {}) {
    this._fontawesomeIcons = iconsArray;
  }
  /**
   * Parametri originali del filtro ricevuti dalla form.
   */
  @Input() initialParamsFilters: any;

  /**
   * Trigger usato per notificare il cambiamento dei filtri della form
   */
  @Input() set triggerInitialParams(rand: number) {
    // console.log('Initial params are changed');
    // Cambiati i filtri per la query  => reset elenco ed nuova richiesta alla REST API
    if (this.isInitialized) {
      this.setConfigType();
    }
  }
  /**
   * Stringa che identifica il tipo dell'elenco (utilizzato nella richiesta alla REST API)
   */
  @Input() set listType(type: string) {
    this._listType = type;
    // Cambiata tipologia elenco => reset elenco ed nuova richiesta alla REST API
    // if (this.isInitialized) {
    //    this.setConfigType();
    // }
  }
  @Input() set restApiProtocol(protocol: string) {
    this._restApiProtocol = protocol;
    // Cambiato indirizzo server REST API => reset elenco ed nuova richiesta alla REST API
    // if (this.isInitialized) {
    //    this.setConfigType();
    // }
  }
  /**
   * Indirizzo IP e porta del server della REST API
   */
  @Input() set restApiIpPort(ipPort: string) {
    this._restApiIpPort = ipPort;
    // Cambiato indirizzo server REST API => reset elenco ed nuova richiesta alla REST API
    // if (this.isInitialized) {
    //    this.setConfigType();
    // }
  }
  /**
   * Percorso della REST API
   */
  @Input() set restApiPath(path: string) {
    this._restApiPath = path;
    // Cambiato percorso REST API => reset elenco ed nuova richiesta alla REST API
    // if (this.isInitialized) {
    //    this.setConfigType();
    // }
  }

  /**
   * il config Globale da src\assets\config\config-globale.dev.json
   */
  @Input() set configGlobale(configGlobale: IConfigGlobaleList) {
    this._configGlobale = configGlobale;
    // console.log("set configGlobale",configGlobale);
    this.utilsService.configGlobale = configGlobale;
  }

  /**
   * Array di descrizione delle colonne da visualizzare nell'elenco
   */
  @Input() listFields: {
    header: string;
    width?: number;
    dbField: string;
    dbTable?: string;
    sortable: string;
    filterComparison: string;
    filter?: string[];
    lookupTable?: {};
    imageTable?: {};
    imageDimension?: { width: string; height: string };
    buttonTable?: {};
    reverseGeoCoding?: { url: string };
    formatDate: {
      stringFormat: string;
    };
    checkbox?: {
      showForKey: string;
      showForValue: string;
      is: boolean;
      id: string;
    };
  }[];
  /**
   * Trigger usato per notificare il cambiamento dei filtri della form
   */
  @Input() set triggerListFields(rand: number) {
    console.log("Columns are changed");
    // Cambiate le colonne => reset elenco ed nuova richiesta alla REST API
    // if (this.isInitialized) {
    //    this.setConfigType();
    // }
  }
  /**
   * Booleano per la visibilità del bottone export CSV dell'elenco
   */
  @Input() buttonCSVExport: boolean;

  /**
   * Booleano per la visibilità del bottone export XML dell'elenco
   */
  @Input() buttonXMLExport: boolean;

  /**
   * Booleano per la visibilità del bottone export PDF dell'elenco
   */
  @Input() buttonPDFExport: boolean;

  /**
   * Booleano per la visibilità del bottone export CSV dell'elenco
   */
  @Input() set tableHeight(height: string) {
    if (!height) {
      height = "200px";
    }

    this._tableHeight = height;
  }
  /**
   * Riceve il token
   */
  @Input() token: string;
  @Input() requestMethod: string;
  @Input() geoLocation: boolean;

  /**
   * Indica lo stato visivo del loader
   */
  loading: boolean = false;
  /**
   * Risultati ottenuti dalla richiesta alla REST API
   */
  results: {}[] = [];
  /**
   * Array di risultati mappati secondo la configurazione dell'elenco
   */
  mappedResults: any = [];
  /**
   * Numero di pagine totali (numero di record / record visualizzati per pagina)
   */
  totPages: number;
  /**
   * Pagina corrente dei risultati
   */
  currPage: number;
  /**
   * Selezione del numero di record visualizzati per pagina
   */
  rowsPerPage: number;
  /**
   * Array che mantiene le informazioni sull'ordinamento delle colonne
   */
  orderBy: {}[];
  /**
   * Array per la gestione delle icone di ordinamento per le colonne
   */
  orderIcons: {};
  /**
   * Arry che mantiene le informazioni sul filtro dinamico applicato ad ogni colonna
   */
  filterBy: {}[];
  /**
   * Oggetto utilizzato per la richiesta dei record alla REST API
   */
  bodyParams: {
    token?: string;
    select: string;
    orderBy: {
      field: string;
      order: string;
    }[];
    pagination: {
      reqPage: number;
      rowsPerPage: number;
      /*,totPages:number*/
    };
    filterBy: {
      field: string;
      // solo uno delle seguenti tipologie
      like?: string; // OPT
      eq?: string; // OPT
    }[];
  };
  // example
  // - orderBy : [{"field":"id","order":"asc"},{"field":"nome","order":"asc"}]
  // - pagination: {reqPage: 1, rowsPerPage: 10}
  // - filterBy: [{"field":"id", "like":"someText"},{"field":"sigla", "eq":"AP_BARI"}]

  /**
   * Emettitore dell'evento click su uno dei bottoni azione
   */
  @Output() actionClicked: EventEmitter<{
    rowId: string;
    action: string;
  }> = new EventEmitter();
  @Output() checkboxClicked: EventEmitter<{
    index: string;
    isChecked: boolean;
    record: any;
  }> = new EventEmitter();
  /**
   * Emettitore dell'evento Lista caricata
   */
  @Output() listLoaded: EventEmitter<boolean> = new EventEmitter();
  @Output() listValues: EventEmitter<any> = new EventEmitter();

  /**
   * Costruttore per il componente elenco
   * @param http servizio HTTP per le chiamate alla REST API
   */
  constructor(
    private http: HttpClient,
    private generaPDFService: GeneraPDFService,
    private utilsService: UtilsService,
    private datePipe: DatePipe
  ) {}
  /**
   * Funzione OnInit di Angular
   */
  ngOnInit() {
    this.setValuesInUtilsService();
    this.isInitialized = true;
    this.setConfigType();
  }

  onCellClick(row, index: number, columnType: string) {
    const value = { row: row, rowId: row["id"], action: "rowClick", index: index, columnType: columnType };
    this.actionClicked.emit(value);
  }

  private setValuesInUtilsService() {
    if (!!this._restApiProtocol && !!this._restApiIpPort) {
      this.utilsService.configGlobale.api.server.apiIp = this._restApiIpPort.split(":")[0];
      this.utilsService.configGlobale.api.server.apiPort = +this._restApiIpPort.split(":")[1];
      this.utilsService.configGlobale.api.server.apiProtocol = this._restApiProtocol;
    } else {
      this.utilsService.configGlobale.api.server.apiRelativePath = this._restApiPath; //  this.utilsService.
    }
    this.utilsService.token = this.token;
  }

  /**
   * Check per undefined.
   * @returns true se un valore è undefined (usata lato HTML)
   */
  isUndefined(val: any) {
    return typeof val === "undefined";
  }
  /**
   * Flag per mostrare la paginazione
   * @returns true se sono presenti dei results (usata lato HTML) e se le pagine da mostrare sono più di una
   */
  showPagination() {
    return !!this.results && this.totPages > 1;
  }

  /**
   * Flag per mostrare il bottone per l'esportazione del CSV
   */
  showCSVExport() {
    return this.results.length > 0 && this.buttonCSVExport;
  }

  /**
   * Flag per mostrare il bottone per l'esportazione dell'XML
   */
  showXMLExport() {
    return this.results.length > 0 && this.buttonXMLExport;
  }

  /**
   * Flag per mostrare il bottone per l'esportazione del PDF
   */
  showPDFExport() {
    return this.results.length > 0 && this.buttonPDFExport;
  }

  /**
   * Imposta e cerca la pagina richiesta
   */
  setPagination(currPage: any, rowsPerPage: any) {
    const queryFilters = this.initialParamsFilters || []; // se è undefined viene impostato a []
    this.orderIcons = this.initOrderIcons({}, this.listFields);
    this.results = [];
    this.mappedResults = [];
    this.bodyParams = {
      select: this._listType,
      orderBy: [],
      pagination: { reqPage: currPage, rowsPerPage },
      filterBy: queryFilters,
    };
    this.setResults(this.callApi(this.bodyParams));
  }

  /**
   * Preleva la configurazione dell'elenco. Svuota il contenuto della tabella, setta i nuovi header e lancia la richiesta delle REST API.
   */
  setConfigType() {
    // TODO i parametri passati sono cambiati: devono essere 'query'(filtri del form), 'confObj'(oggetto configurazione elenco[recuperato dal file di configurazione], 'configType'(stringa che identifica il tipo di elenco))
    this.rowsPerPage = this._rowsPerPageOptions[0];
    this.currPage = 1;
    this.totPages = 1;
    const queryFilters = this.initialParamsFilters || []; // se è undefined viene impostato a []
    this.orderIcons = this.initOrderIcons({}, this.listFields);
    this.results = [];
    this.mappedResults = [];
    this.bodyParams = {
      select: this._listType,
      orderBy: [],
      pagination: { reqPage: this.currPage, rowsPerPage: this.rowsPerPage },
      filterBy: queryFilters,
    };

    if (!this.waitForFirstRequest) {
      // token per gestire l'Auth
      this.setResults(this.callApi(this.bodyParams));
    } else {
      this.waitForFirstRequest = false;
    }
  }
  /**
   * Crea un Observable per la richiesta alle REST API
   * @param   bodyParams parametri della richiesta alla REST API
   * @returns Observable per prelevare il risutato
   */
  callApi(bodyParams: any): Observable<any> {
    bodyParams = this.reConvertResults(bodyParams);
    if (this.token) {
      this.bodyParams.token = this.token;
    }
    // this.http.get('https://putsreq.com/DAgd8oa70SYrOzWe6V76?name=Filo', { observe: "body", responseType: "text", params: _bodyParams }).subscribe(data => {
    //    console.log("RISPOSTA TORNATA", data)
    // });
    let apiUrl = "";
    // FN: se non sono passati protocollo, ip e porta utilizzo solo il path (dovrebbe essere costruito con il path relativo)
    if (!!this._restApiProtocol && !!this._restApiIpPort) {
      apiUrl = this._restApiProtocol + "://" + this._restApiIpPort;
    } else {
      apiUrl = this._restApiPath;
    }
    var http_options = {
      headers: new HttpHeaders({
        Authorization: "bearer " + this.token,
      }),
    };
    // console.log("---------bodyParams: ", bodyParams);
    // console.log("---------apiUrl: ", apiUrl);
    // console.log("---------http_options: ", http_options);

    if (this.requestMethod === "POST") {
      return this.http
        .post(apiUrl, { observe: "body", responseType: "text", params: bodyParams }, http_options)
        .pipe(debounceTime(500), distinctUntilChanged());
    } else {
      // let params = new HttpParams().set("alarms",paramValue).set("position", paramValue2);

      // console.log("call api params", this.initialParamsFilters);

      let params = new HttpParams();
      this.initialParamsFilters.forEach((el: Object) => {
        // console.log(el);
        // console.log(Object.keys(el)[0]);
        // console.log(Object.values(el)[0].toString());

        params = params.append(Object.keys(el)[0], Object.values(el)[0]);
      });

      console.log("call api params", params);

      return this.http.get(apiUrl, { headers: http_options.headers, params: params }).pipe(
        debounceTime(500),
        distinctUntilChanged()
        // map(this.parseResults),
        // tap({next: console.log})
      );
    }
  }

  // private parseResults(results) {
  //   // console.log("parseResults", results);
  //   const result = {};
  //   result["results"] = results
  //     .filter((result) => result.type === "patient")
  //     .map((result) => ({
  //       ...result,
  //       location:
  //         !!result.position && result.position.length > 0
  //           ? `${result.position[0].location.coordinates[0]} \n ${result.position[0].location.coordinates[1]}`
  //           : "",
  //     }));
  //   // console.log("parseResults: ", result);
  //   result["orderBy"] = [];
  //   result["filterBy"] = [];
  //   const paginationOptions = {};
  //   paginationOptions["reqPage"] = 1;
  //   paginationOptions["rowsPerPage"] = 10;
  //   paginationOptions["totPages"] = 1;
  //   result["pagination"] = paginationOptions;
  //   return result;
  // }

  /**
   * Popola l'array delle icone con quella dell'ordinamento generico
   * @param   obj    array di oggetti da popolare
   * @param   fields campi dell'oggetto della configurazione
   * @returns array di icone per l'ordinamento delle colonne
   */
  initOrderIcons(obj: any, fields: any) {
    fields.forEach((e: any) => {
      let key = e.dbField;
      if (!!e.dbTable) {
        key = `${e.dbTable}.${e.dbField}`;
      }
      obj[key] = faSort;
    });
    return obj;
  }
  /**
   * Registra un listener sulla richiesta alle REST API
   * @param   observable$ observable del risultato della richiesta alle API
   */
  setResults(observable$: Observable<any>) {
    this.loading = true;
    observable$.subscribe(
      (data: any) => {
        this.results = data.results;
        this.alignItem = this.setAlignTable();
        this.mappedResults = this.convertResults(data.results);
        if (!!this.mappedResults[0] && !!this.mappedResults[0].find((el) => el.coords)) {
          this.mappedResults.forEach((row) => {
            row.forEach((col) => {
              if (!!col.coords) {
                // to do call api
                // renema col.txt
                const geoLocationUrl = col.url;
                const params = new HttpParams().set("lat", col.coords[1]).set("lon", col.coords[0]);
                this.utilsService.getRequest(geoLocationUrl, params).subscribe((geoLocation) => {
                  if (!!geoLocation.error) {
                    col.txt = "-";
                  } else {
                    col.txt = geoLocation.streetName;
                  }
                });
              }
            });
          });
        }

        this.currPage = data.pagination.reqPage;
        this.totPages = data.pagination.totPages;
        this.rowsPerPage = data.pagination.rowsPerPage;
        // ordinamento
        this.filterBy = data.filterBy;
        // filtro
        this.orderBy = data.orderBy;
        this.loading = false;
      },
      (err: any) => {
        console.log(err);
        this.loading = false;
        this.listValues.emit(null);
      },
      () => {
        // console.log('HTTP Observable unsuscribed');
      }
    );
  }
  /**
   * Mappa i risultati delle REST API con le colonne scelte in configurazione ed eventuali lookupTable
   * @param   rows     record ottenuti dalla REST API
   * @param   isExport flag che indica se la mappatura è fatta per l'export (non vengono mappate immagini e bottoni)
   * @returns array di risultati mappati
   */
  convertResults(rows: any, isExport?: boolean): {}[] {
    isExport = isExport || false; // se è undefined viene impostato a false
    const tmpRes = [];
    rows.forEach((row: any) => {
      const tmpRow = [];
      this.listFields.forEach((el) => {
        if (!!el.reverseGeoCoding) {
          tmpRow.push({
            txt: "sta caricando...",
            coords: row?.lastPosition?.geometry?.coordinates,
            width: el.width,
            url: el.reverseGeoCoding.url,
          });
          return;
        }
        if (!!el.formatDate) {
          tmpRow.push({
            txt: this.datePipe.transform(row[el.dbField], el.formatDate.stringFormat),
            width: el.width,
          });
          return;
        }
        if (!!el.checkbox) {
          tmpRow.push({
            checkbox: el.checkbox.is,
            width: el.width,
            record: row,
            id: row[el.checkbox.id],
            show:
              el.checkbox.showForKey && el.checkbox.showForValue
                ? row[el.checkbox.showForKey] === el.checkbox.showForValue
                : true,
          });
          return;
        }
        if (!!el.lookupTable) {
          // se ha la lookupTable
          tmpRow.push({
            txt: el.lookupTable[row[el.dbField]],
            width: el.width,
          });
          return;
        }
        // se ha la imageTable
        if (!!el.imageTable && !isExport) {
          tmpRow.push({
            img: el.imageTable[row[el.dbField]],
            imageWidth: el.imageDimension.width,
            imageHeight: el.imageDimension.height,
            width: el.width,
            title: row[el.dbField],
            clickable: el.imageTable["clickable"],
            record: row,
          });
          return;
        }
        // se la buttonTable
        if (!!el.buttonTable && !isExport) {
          const buttons = row[el.dbField].split("|");
          const tmpMain: {}[] = [];
          const tmpSub: {}[] = [];
          for (const btn in buttons) {
            if (!!el.buttonTable[buttons[btn]]) {
              if (buttons[btn] === "A" || buttons[btn] === "V" || buttons[btn] === "POS") {
                tmpMain.push({
                  identifier: buttons[btn],
                  title: el.buttonTable[buttons[btn]].title,
                  faImage: this._fontawesomeIcons[el.buttonTable[buttons[btn]].faImage],
                  color: el.buttonTable[buttons[btn]].color,
                  action: el.buttonTable[buttons[btn]].action,
                  record_id: row.id,
                });
              } else {
                tmpSub.push({
                  identifier: buttons[btn],
                  title: el.buttonTable[buttons[btn]].title,
                  faImage: this._fontawesomeIcons[el.buttonTable[buttons[btn]].faImage],
                  color: el.buttonTable[buttons[btn]].color,
                  action: el.buttonTable[buttons[btn]].action,
                  record_id: row.id,
                });
              }
            }
          }
          tmpRow.push({
            mainButtons: tmpMain,
            subButtons: tmpSub,
            width: el.width,
          });
          return;
        }
        // caso testo semplice
        tmpRow.push({
          txt: row[el.dbField],
          width: el.width,
          record: row,
        });
      });
      tmpRes.push(tmpRow);
    });
    this.listLoaded.emit(true);
    this.listValues.emit(this.results);
    return tmpRes;
  }
  /**
   * Mappa inversamente i risultati delle REST API con le colonne scelte in configurazione ed eventuali lookupTable per l'utilizzo dei filtri
   * @param   params righe da riconvertire per fare la richiesta alla REST API
   * @returns array di record riconvertiti
   */
  reConvertResults(params) {
    if (Array.isArray(this.initialParamsFilters)) {
      params.filterBy
        .filter((e: any) => !!e.eq)
        .forEach((e: any) => {
          const field = this.listFields.find((f: any) => f.dbField === e.field);
          // controllo se esiste un field ed ha la lookupTable (nell'array ci sono anche i parametri del componente filtri)
          if (!!field && !!field.lookupTable) {
            for (const key in field.lookupTable) {
              if (field.lookupTable[key] === e.eq) {
                e.eq = key;
              }
            }
          }
        });
    }
    return params;
  }
  /**
   * Chiamata dai componenti di input. Gestisce l'aggiornamneto dell'oggetto per le richieste dei risultati
   * @param   event evento lato HTML
   */
  updateListByEvent(event: any) {
    // case type:
    // change (seleizone a tendina) --> select filtri e rows_per_page
    // click (icone) --> ordinamento
    // keyup (input text) --> filtri testuali
    // undefined (paginator) --> paginator

    switch (event.type) {
      case "change": {
        // select filtri o righePerPagina
        // controllo se è un select dei filtri in colonna
        if (event.target.classList.contains("select_filter")) {
          // recupero l'id dell'elemento
          const rawDbField = event.target.id;
          const dbField = rawDbField.replace("filter_", "");
          this.updateColumnsFilter(dbField, event.target.value);
        }

        // controllo se è il select delle righePerPagina
        if (event.target.classList.contains("rows_per_page")) {
          this.rowsPerPage = event.target.value;
          this.bodyParams.pagination.rowsPerPage = this.rowsPerPage;
        }
        break;
      }
      case "click": {
        // ordinamento colonne
        // controllo se è un ordinamento delle colonne
        if (event.target.classList.contains("order_columns")) {
          // recupero l'id dell'elemento
          const rawDbField = event.target.id;
          this.updateColumnsOrder(rawDbField);
        } else if (event.target.parentElement.classList.contains("order_columns")) {
          const rawDbField = event.target.parentElement.id;
          this.updateColumnsOrder(rawDbField);
        } else if (event.target.parentElement.parentElement.classList.contains("order_columns")) {
          const rawDbField = event.target.parentElement.parentElement.id;
          this.updateColumnsOrder(rawDbField);
        }
        break;
      }
      case "keyup": {
        // filtri testuali
        const rawDbField = event.target.id;
        const dbField = rawDbField.replace("filter_", "");
        this.updateColumnsFilter(dbField, event.target.value);
        break;
      }
      case undefined: {
        this.bodyParams.pagination.reqPage = event;
        break;
      }
    }

    // per tutte le moficihe tranne il paginatore la pagina richiesta deve essere la 1
    if (event.type === undefined) {
      // se ho modificato il paginator imposto la pagina selezionata
      this.bodyParams.pagination.reqPage = event;
    } else {
      // altrimenti imposto la visualizzazione della pagina 1
      this.bodyParams.pagination.reqPage = 1;
    }

    console.log("LIST PARAMS", this.bodyParams);
    this.setResults(this.callApi(this.bodyParams));
  }
  /**
   * Aggiornamento del'array dei filtri nell'oggetto per le richieste
   * @param   dbField campo del db su cui è stato impostato il filtro
   * @param   value   valore del filtro
   */
  updateColumnsFilter(dbField: string, value: string) {
    const fieldObj = this.listFields.find((el: any) => el.dbField === dbField);
    if (!!fieldObj.dbTable) {
      dbField = `${fieldObj.dbTable}.${fieldObj.dbField}`;
    }
    // recupero il tipo di comparatore
    const filterComparison = fieldObj.filterComparison;
    // se è settato il tipo di comparazione (quindi se è una stringa diversa da "none") lo aggiungo ai filtri
    if (!!filterComparison && filterComparison !== "none") {
      // preparo l'oggetto da inserire
      const field = { field: dbField };
      field[filterComparison] = value;
      // cerco di recuperare l'index dell'oggetto relativo al campo tra i filtri
      const index = this.bodyParams.filterBy.findIndex((el: any) => el.field === dbField);

      // se il valore è vuoto e c'era già il filtro lo rimuovo
      if (value == "" && index !== -1) {
        this.bodyParams.filterBy.splice(index, 1);
        return;
      }

      if (index !== -1) {
        // se esiste già sovrascrivo
        this.bodyParams.filterBy.splice(index, 1, field);
      } else {
        // altrimenti aggiungo
        this.bodyParams.filterBy.push(field);
      }
    }
  }
  /**
   * Aggiornamento del'array dell'ordinamento nell'oggetto per le richieste e di quello della parte grafica
   * @param   rawDbField identificativo della colonna su cui effettuare ordinamento (dato grezzo, si deve estrarre l'id pulito)
   */
  updateColumnsOrder(rawDbField: string) {
    let dbField = rawDbField.replace("order_", "");
    const fieldObj = this.listFields.find((el: any) => el.dbField === dbField);
    if (!!fieldObj.dbTable) {
      dbField = `${fieldObj.dbTable}.${fieldObj.dbField}`;
    }
    // controllo se ho già impostato il valore per l'ordinamento
    const index = this.bodyParams.orderBy.findIndex((e: any) => e.field === dbField);
    if (index !== -1) {
      // se esiste già lo modifico
      const orderType = this.bodyParams.orderBy.find((e: any) => e.field === dbField).order;
      switch (orderType) {
        case "asc":
          // se è asc cambio l'ordinamento
          this.bodyParams.orderBy.splice(index, 1, {
            field: dbField,
            order: "desc",
          });
          this.orderIcons[dbField] = faSortDown; // aggiorno la grafica
          break;
        case "desc":
          // se è desc lo rimuovo
          this.bodyParams.orderBy.splice(index, 1);
          this.orderIcons[dbField] = faSort; // aggiorno la grafica
          break;
      }
    } else {
      // altrimenti aggiungo
      this.bodyParams.orderBy.push({ field: dbField, order: "asc" });
      this.orderIcons[dbField] = faSortUp; // aggiorno la grafica
    }
  }

  /**
   * Costruisce l'icona in base alla presenza della specifica del nome della tabella
   * @param item l'elemento della lista
   */

  getOrderIcons(item: any) {
    let icon: IconDefinition;
    if (!!item.dbTable) {
      icon = this.orderIcons[item.dbTable + "." + item.dbField];
    } else {
      icon = this.orderIcons[item.dbField];
    }

    if (!icon) {
      icon = faSort;
    }

    return icon;
  }
  /**
   * Evento click su uno dei bottoni azione
   * @param   event evento da cui recuperare l'elemento html
   * @param   param azione da applicare al click
   * @param   id    id del record
   */
  onActionClick(event: any, param: any, id: any) {
    console.log("onActionClick", event);
    if (event.target.classList.contains("actionButtons")) {
      // recupero l'id dell'elemento
      const rawDbField = event.target.id;
      this.handleActionButtons(rawDbField, param, id);
    } else if (event.target.parentElement.classList.contains("actionButtons")) {
      const rawDbField = event.target.parentElement.id;
      this.handleActionButtons(rawDbField, param, id);
    } else if (event.target.parentElement.parentElement.classList.contains("actionButtons")) {
      const rawDbField = event.target.parentElement.parentElement.id;
      this.handleActionButtons(rawDbField, param, id);
    }
  }

  onCheckboxClick(index, isChecked, record) {
    this.checkboxClicked.emit({ index: index, isChecked: isChecked, record: record });
  }

  onCheckboxHeaderClick(isChecked: boolean, keyId: string) {
    if (this.results.length > 0) {
      this.results.forEach((r, i) => {
        isChecked ? this.setCheckboxValueTrue(r[keyId]) : this.setCheckboxValueFalse(r[keyId]);
        this.onCheckboxClick(i, isChecked, r);
      });
    }
  }

  private getElementById(id: string) {
    return document.getElementById(id);
  }

  private setCheckboxValueTrue(id: string) {
    (this.getElementById(id) as HTMLInputElement).checked = true;
  }
  private setCheckboxValueFalse(id: string) {
    (this.getElementById(id) as HTMLInputElement).checked = false;
  }
  /**
   * Chiamata per emettere la notificha che è stato premuto un bottone azione
   * @param   rawDbField ??
   * @param   param      azione
   * @param   id         id record cliccato
   */
  handleActionButtons(rawDbField, param, id) {
    console.log("emetto evento", { rowId: id, action: param });
    this.actionClicked.emit({ rowId: id, action: param });
  }
  /**
   * Funzione che crea un file con i dati dell'elenco
   * @param   onlyVisibleData flag che permette di esportare tutte le colonne scaricate o solamente le colonne visibili
   */
  exportCSV(onlyVisibleData: boolean) {
    const LIMITED_NUM_OF_ROWS_TO_EXPORT = Number.MAX_SAFE_INTEGER; // TODO da modificare

    const params = JSON.parse(JSON.stringify(this.bodyParams));
    params.pagination.reqPage = 1;
    params.pagination.rowsPerPage = LIMITED_NUM_OF_ROWS_TO_EXPORT;
    console.log("CSV QUERY PARAMS", params);
    this.callApi(params).subscribe((data: any) => {
      // data è formato da
      // - results : righe estratte dal db
      // - orderBy : [{"field":"id","order":"asc"},{"field":"nome","order":"asc"}]
      // - pagination: {reqPage: 1, rowsPerPage: 10, "totPages": 10}
      // - filterBy: [{"field":"id", "like":"someText"},{"field":"sigla", "eq":"AP_BARI"}]

      const exportParams = {
        listFields: this.listFields,
        items: data.results,
        type: "CSV",
      };

      const url: string = this.utilsService.creaURL("export", "list");
      this.utilsService.postRequest(url, exportParams).subscribe((exportData: any) => {
        const fileContent: Blob = new Blob([exportData.data.exportContent], {
          type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(fileContent, "export_" + new Date().getTime() + ".csv");
      });
    });
  }

  /**
   * Funzione che crea un file con i dati dell'elenco
   */
  exportXML() {
    const LIMITED_NUM_OF_ROWS_TO_EXPORT = Number.MAX_SAFE_INTEGER; // TODO da modificare

    const params = JSON.parse(JSON.stringify(this.bodyParams));
    params.pagination.reqPage = 1;
    params.pagination.rowsPerPage = LIMITED_NUM_OF_ROWS_TO_EXPORT;
    console.log("XML QUERY PARAMS", params);
    this.callApi(params).subscribe((data: any) => {
      // data è formato da
      // - results : righe estratte dal db
      // - orderBy : [{"field":"id","order":"asc"},{"field":"nome","order":"asc"}]
      // - pagination: {reqPage: 1, rowsPerPage: 10, "totPages": 10}
      // - filterBy: [{"field":"id", "like":"someText"},{"field":"sigla", "eq":"AP_BARI"}]

      const paramTipoCatasto = params.filterBy.find((el) => el.field == "riserve_demaniali.tipo");
      const tipoCatasto = !!paramTipoCatasto ? paramTipoCatasto.eq : null;

      const exportParams = {
        listFields: this.listFields,
        items: data.results,
        type: "XML",
        tipoCatasto,
      };

      const url: string = this.utilsService.creaURL("export", "list");
      this.utilsService.postRequest(url, exportParams).subscribe((exportData: any) => {
        const fileContent: Blob = new Blob([exportData.data.exportContent], {
          type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(fileContent, "export_" + new Date().getTime() + ".xml");
      });
    });
  }

  /**
   * Funzione che crea un file con i dati dell'elenco
   */
  exportPDF() {
    const LIMITED_NUM_OF_ROWS_TO_EXPORT = Number.MAX_SAFE_INTEGER; // TODO da modificare

    const params = JSON.parse(JSON.stringify(this.bodyParams));
    params.pagination.reqPage = 1;
    params.pagination.rowsPerPage = LIMITED_NUM_OF_ROWS_TO_EXPORT;
    console.log("PDF QUERY PARAMS", params);
    this.callApi(params).subscribe((data: any) => {
      // data è formato da
      // - results : righe estratte dal db
      // - orderBy : [{"field":"id","order":"asc"},{"field":"nome","order":"asc"}]
      // - pagination: {reqPage: 1, rowsPerPage: 10, "totPages": 10}
      // - filterBy: [{"field":"id", "like":"someText"},{"field":"sigla", "eq":"AP_BARI"}]

      const exportParams = {
        listFields: this.listFields,
        items: data.results,
        type: "PDF",
      };

      const url: string = this.utilsService.creaURL("export", "list");
      this.utilsService.postRequest(url, exportParams).subscribe((exportData: any) => {
        this.generaPDFService
          .esportaPDF("export_" + new Date().getTime(), exportData.data.exportContent)
          .subscribe((response: EBWApiResponse) => {
            console.log(response);
            if (response.status === 0 && response.error === 0) {
              setTimeout(() => window.open(response.data), 0);
            }
          });
      });
    });
  }

  private setAlignTable() {
    const desktopWidth = window.innerWidth;
    let res = false;
    if ((desktopWidth === 1360 || desktopWidth === 1366) && this.results.length > 4) {
      res = true;
    }
    if ((desktopWidth === 1400 || desktopWidth === 1440) && this.results.length >= 8) {
      res = true;
    }
    if ((desktopWidth === 1600 || desktopWidth === 1680) && this.results.length > 9) {
      res = true;
    }
    if (desktopWidth >= 1920 && this.results.length > 10) {
      res = true;
    }

    return res;
  }
}
