import { NGXLogger } from 'ngx-logger';
import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GestioneLoginService } from './../../servizi/login/gestione-login.service';
import { EbwModalService } from '../../library/ebw-modals-angular/ebw-modal.service';

@Component({
   selector: 'app-recupero-password',
   templateUrl: './recupero-password.component.html',
   styleUrls: ['./recupero-password.component.css']
})
export class RecuperoPasswordComponent {

   public recuperoPasswordForm: FormGroup;

   constructor(private fb: FormBuilder, private gestioneService: GestioneLoginService, private logger: NGXLogger, private ebwModalService: EbwModalService ) {
      this.recuperoPasswordForm = this.createSignupForm();
   }

   createSignupForm(): FormGroup {
      return this.fb.group(
         {  // M
            username: ['', Validators.required],
         }
      );
   }

   recuperaPassword(): void {
      console.log('Invio email in corso...');

      /* **** Decommentare per far funzionare il salvataggio ******/
      const jsonData = {
         username: this.recuperoPasswordForm.controls.username.value
      };

      this.gestioneService.recuperaPassword(jsonData).subscribe(
         (results: any) => {
            this.logger.info('Controllare la casella di posta dell\'utente specificato. ', results);
            this.ebwModalService.openSuccessModal({ title: 'Password recuperata!', content: 'Controllare la casella di posta dell\'utente specificato.' });
         },
         (err: any) => {
            console.log(err)
            if(err.status == 409) {
               this.ebwModalService.openErrorModal({title: 'Utente non trovato', content: 'Si prega di inserire un nome utente esistente!'})
            } else {
               this.ebwModalService.openErrorModal({title: 'Errore', content: 'Si è verificato un errore!'})
            }
         }
      );
   }
}
