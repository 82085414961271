<div class="px-3 h-75vh scroll-custom">
   <h5 class="p-15 txt-bg-blu">Trasformazione coordinate</h5>
   <form [formGroup]="formTrasformaCoordinate">
      <div class="row">
         <div class="col">
            <label class="font-weight-bold">Coordinate ingresso</label>
            <button class="btn bg-blu txt-white" placement="top" ngbTooltip="Imposta coordinate"
               (click)=impostaCoordinate()>
               <fa-icon [icon]="faMapMarkedAlt"></fa-icon> Imposta coordinate
            </button>
         </div>
      </div>

      <div class="row">
         <div class="col">
            <label class="font-weight-normal" for="epsgIn">Sistema di riferimento</label>
            <select class="form-control" id="epsgIn" placeholder="Sistema di riferimento"
               ngbTooltip="Sistema di riferimento" formControlName="epsgIn" (change)="aggiornataEPSGIngresso($event)">
               <option *ngFor="let opt of arraySR" [selected]="defaultSR.epsg == opt.epsg" [value]="opt.epsg">
                  {{opt.descrizione}} (EPGS: {{opt.epsg}})</option>
            </select>
         </div>
      </div>

      <div class="dropdown-divider"></div>

      <div class="row" *ngIf="getTipoSviluppoSR(this.formTrasformaCoordinate.value.epsgIn) == 'geografico'">
         <label class="font-weight-normal col-3" for="lonIn">Lon/Est</label>
         <input class="form-control col-2 input-degrees" type="number" step=".01" id="lonGradiIn" placeholder="Gradi"
            ngbTooltip="Gradi" formControlName="lonGradiIn" (change)="aggiornataLongitudine($event)" />
         <input class="form-control col-2 input-degrees" type="number" step=".01" id="lonPrimiIn" placeholder="Primi"
            ngbTooltip="Primi" formControlName="lonPrimiIn" (change)="aggiornataLongitudine($event)" />
         <input class="form-control col-2 input-degrees" type="number" step=".01" id="lonSecondiIn"
            placeholder="Secondi" ngbTooltip="Secondi" formControlName="lonSecondiIn"
            (change)="aggiornataLongitudine($event)" />
      </div>

      <div class="row" *ngIf="this.getTipoSviluppoSR(this.formTrasformaCoordinate.value.epsgIn) == 'piano'">
         <label class="font-weight-normal col-3" for="xIn">X</label>
         <input class="form-control col-7" type="number" step=".01" id="xIn" placeholder="X" ngbTooltip="X"
            formControlName="xIn" (change)="aggiornataLongitudine($event)" />
      </div>

      <div class="dropdown-divider"></div>

      <div class="row" *ngIf="getTipoSviluppoSR(this.formTrasformaCoordinate.value.epsgIn) == 'geografico'">
         <label class="font-weight-normal col-3" for="latIn">Lat/Nord</label>
         <input class="form-control col-2 input-degrees" type="number" step=".01" id="latGradiIn" placeholder="Gradi"
            ngbTooltip="Gradi" formControlName="latGradiIn" (change)="aggiornataLatitudine($event)" />
         <input class="form-control col-2 input-degrees" type="number" step=".01" id="latPrimiIn" placeholder="Primi"
            ngbTooltip="Primi" formControlName="latPrimiIn" (change)="aggiornataLatitudine($event)" />
         <input class="form-control col-2 input-degrees" type="number" step=".01" id="latSecondiIn" placeholder="Secondi"
            ngbTooltip="Secondi" formControlName="latSecondiIn" (change)="aggiornataLatitudine($event)" />
      </div>

      <div class="row" *ngIf="getTipoSviluppoSR(this.formTrasformaCoordinate.value.epsgIn) == 'piano'">
         <label class="font-weight-normal col-3" for="yIn">Y</label>
         <input class="form-control col-7" type="number" step=".01" id="yIn" placeholder="Y" ngbTooltip="Y"
            formControlName="yIn" (change)="aggiornataLatitudine($event)" />
      </div>

      <div class="dropdown-divider"></div>
      <div class="dropdown-divider"></div>

      <div class="row">
         <div class="col">
            <label class="font-weight-bold">Coordinate uscita</label>
         </div>
      </div>
      <div class="row">
         <div class="col">
            <label class="font-weight-normal" for="epsgOut">Sistema di riferimento</label>
            <select class="form-control" id="epsgOut" placeholder="Sistema di riferimento"
               ngbTooltip="Sistema di riferimento" formControlName="epsgOut" (change)="aggiornataEPSGUscita($event)">
               <option value="0">Scegli sistema di riferimento</option>
               <option *ngFor="let opt of arraySR" [value]="opt.epsg">{{opt.descrizione}} (EPSG: {{opt.epsg}})</option>
            </select>
         </div>
      </div>

      <div class="dropdown-divider"></div>

      <div *ngIf="getTipoSviluppoSR(this.formTrasformaCoordinate.value.epsgOut) == 'geografico'">
         <form name="rappresentazioneRadio">
            <div class="custom-control custom-radio custom-control-inline">
               <input type="radio" id="decimal" name="outputRadio" class="custom-control-input"
                  value="decimal"
                  [(ngModel)]="rapprSelected"
                  (change)="aggiornaRappresentazione('decimal')">
               <label class="custom-control-label" for="decimal">Decimali</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
               <input type="radio" id="degrees" name="outputRadio" class="custom-control-input"
                  value="degrees"
                  [(ngModel)]="rapprSelected"
                  (change)="aggiornaRappresentazione('degrees')">
               <label class="custom-control-label" for="degrees">Gradi</label>
            </div>
         </form>
      </div>

      <div class="dropdown-divider"></div>

      <div class="row" *ngIf="srUscitaGeografico">
         <label class="font-weight-normal col-3" for="lonOut">Lon/Est</label>
         <input class="form-control col-7" type="text" id="lonOut" placeholder="Longitudine" ngbTooltip="Longitudine"
            formControlName="lonOut" readonly />
         <button class="btn bg-blu txt-white col-1 btn-copy" ngbTooltip="Copia" (click)="copy('lonOut')">
            <fa-icon [icon]="faCopy"></fa-icon>
         </button>
      </div>

      <div class="row" *ngIf="srUscitaPiano">
         <label class="font-weight-normal col-3" for="xOut">X</label>
         <input class="form-control col-7" type="text" id="xOut" placeholder="X" ngbTooltip="X" formControlName="xOut"
            readonly />
         <button class="btn bg-blu txt-white col-1 btn-copy" ngbTooltip="Copia" (click)="copy('xOut')">
            <fa-icon [icon]="faCopy"></fa-icon>
         </button>
      </div>

      <div class="dropdown-divider"></div>

      <div class="row" *ngIf="srUscitaGeografico">
         <label class="font-weight-normal col-3" for="latOut">Lat/Nord</label>
         <input class="form-control col-7" type="text" id="latOut" placeholder="Latitudine" ngbTooltip="Latitudine"
            formControlName="latOut" readonly />
         <button class="btn bg-blu txt-white col-1 btn-copy" ngbTooltip="Copia" (click)="copy('latOut')">
            <fa-icon [icon]="faCopy"></fa-icon>
         </button>
      </div>

      <div class="row" *ngIf="srUscitaPiano">
         <label class="font-weight-normal col-3" for="yOut">Y</label>
         <input class="form-control col-7" type="text" id="yOut" placeholder="Y" ngbTooltip="Y" formControlName="yOut"
            readonly />
         <button class="btn bg-blu txt-white col-1 btn-copy" ngbTooltip="Copia" (click)="copy('yOut')">
            <fa-icon [icon]="faCopy"></fa-icon>
         </button>
      </div>

      <div class="dropdown-divider"></div>
      <div class="dropdown-divider"></div>

      <button [disabled]="this.formTrasformaCoordinate.value.epsgOut == 0" class="btn bg-blu txt-white"
         ngbTooltip="Trasforma coordinate" (click)="trasformaCoordinate()">
         <fa-icon [icon]="faSync"></fa-icon> Trasforma
      </button>
      <button [ngClass]="{'disabled': !formTrasformaCoordinate.valid}" class="btn bg-blu txt-white"
         ngbTooltip="Visualizza su mappa" (click)="visualizzaCoordinate()">
         <fa-icon [icon]="faMapMarkedAlt"></fa-icon> Visualizza
      </button>
      <button class="btn btn-bg-outline btn-sm m-1" ngbTooltip="Reset form" (click)="resetForm()">
         <fa-icon [icon]="faCircleNotch"></fa-icon> Reset
      </button>
   </form>
</div>
