<div class="modal-header bg-primary text-light">
   <h4 class="modal-title text-center" id="modal-basic-title"> Crea PDF </h4>
   <button type="button" class="close text-light" aria-label="Close" (click)="activeModal.close('Close')">
      <span aria-hidden="true">&times;</span>
   </button>
</div>

<div class="modal-body">
   <div class="row">
      <div class="col">
         <h4 class="text-center"> Seleziona le sezioni da includere nel PDF</h4>
         <!-- FORM -->
         <form [formGroup]="sezioniForm">
            <div class="form-check" *ngFor="let sezione of sezioni">
               <input type="checkbox" class="form-check-input" id="{{sezione.chiave}}"
                  formControlName="{{sezione.chiave}}">
               <label class="form-check-label" for="{{sezione.chiave}}">{{sezione.titolo}}</label>
            </div>
         </form>
      </div>
   </div>
   <!--
      <hr>
   <div class="row">
      <div class="col">
         <div>
            <h3>Sezioni Form</h3>
            <pre>Status: {{sezioniForm.status}}</pre>
            <pre>Dirty: {{sezioniForm.dirty}}</pre>
            <pre>Touched: {{sezioniForm.touched}}</pre>
            <pre>Untouched: {{sezioniForm.untouched}}</pre>
            <pre>Errors: {{sezioniForm.errors | json}}</pre>
            <pre>Value: {{sezioniForm.value | json}}</pre>
         </div>

      </div>
   </div>
-->
</div>

<div class="modal-footer">
   <div class="container-fluid">
      <div class="row">
         <div class="col-8 p-0 d-flex">
            <span class="badge badge-success align-self-center" *ngIf="controllerMessaggioFooter.success">
               <fa-icon aria-hidden="true" [icon]="faCheck" class="text-white"></fa-icon> {{messaggioFooter}}
            </span>
            <span class="badge badge-danger align-self-center" *ngIf="controllerMessaggioFooter.error">
               <fa-icon aria-hidden="true" [icon]="faTimes" class="text-white"></fa-icon> {{messaggioFooter}}
            </span>
            <span class="badge badge-warning align-self-center" *ngIf="controllerMessaggioFooter.warning">
               <fa-icon aria-hidden="true" [icon]="faExclamationTriangle" class="text-dark"></fa-icon>
               {{messaggioFooter}}
            </span>
         </div>
         <div class="col-2 p-0">
            <button type="button" ngbAutofocus class="btn bg-blu txt-white" (click)="confermaSelezione()"
               [disabled]="sezioniForm.valid? null : 'disabled'">Genera</button>
         </div>
         <div class="col-2 p-0">
            <button type="button" class="btn btn-bg-outline" (click)="activeModal.close('Close')">Annulla</button>
         </div>
      </div>
   </div>
</div>
