<div class="cont pointer">

   <!-- icona-bottone -->
   <div class="border rounded px-1 py-0 bg-blu txt-white icona-box" (click)="onClick()"
      [ngClass]="{'bg-primary': !panelOn, 'bg-dark': panelOn }">
      <fa-icon class="icona text-white" aria-hidden="true" [icon]="faIcons.faBell"></fa-icon>
   </div>

   <!-- badge con numero di notifiche non lette -->
   <div *ngIf="daLeggere>0" class="bg-danger text-white rounded num px-1 py-0">
      {{ daLeggere }}
   </div>

   <!-- pannello contenitore di tutte le notifiche -->
   <div *ngIf="panelOn" class="accordion panel panel-custom shadow rounded p-3" id="accordionExample"
      [ngStyle]="{width: notifiche.length>0 ? '500px' : 'max-content'}">

      <!-- messaggio in caso di elenco vuoto -->
      <div *ngIf="notifiche.length==0" class="text-center text-white my-3 mx-4">
         Non ci sono notifiche.
      </div>

      <!-- elenco di tutte le notifiche -->
      <div *ngFor="let notifica of notifiche" class="card">
         <div class="card-header p-1" [id]="'heading'+notifica.id"
            [ngClass]="{'list-group-item-primary': notifica.lettura==0}" (click)="salvaLettura(notifica.id)">
            <button class="btn btn-link text-decoration-none text-dark collapsed no-outline" type="button"
               data-toggle="collapse" [attr.data-target]="'#collapse'+notifica.id" aria-expanded="true"
               [attr.aria-controls]="'collapse'+notifica.id">
               <div class="d-inline-block rounded-circle text-white mr-3 iconaNotifica bg-blu">
                  <fa-icon [icon]="getTipoIcon(notifica.opzione_tipo)" class="align-middle"></fa-icon>
                  <div class="dot" [ngStyle]="{background: getColor(notifica.opzione_priorita)}"> </div>
               </div>
               <div class="d-inline-block txt-bg-blu" [ngClass]="{'font-weight-bold': notifica.lettura==0}">
                  {{ notifica.titolo }}
               </div>
            </button>
         </div>
         <div [id]="'collapse'+notifica.id" class="collapse" [attr.aria-labelledby]="'heading'+notifica.id"
            data-parent="#accordionExample">
            <div class="card-body">
               <div [innerHTML]="notifica.testo" class="testoNotifica"> </div>
               <div class="mt-4 text-right">
                  <span
                     class="ml-auto badge border badge-pill badge-light p-2">{{ notifica.inizio_data | date:'dd/MM/yyyy' }}</span>
               </div>
            </div>
         </div>
      </div>
   </div>

</div>