<!---------------------------------------------------------------------------------------------------------------------->
<!--                                                PANNELLO AGGANCIA PUNTO                                           -->
<!---------------------------------------------------------------------------------------------------------------------->

<!-- Il pannello viene mostrato solo se è stato inserito il punto del quale recuperare i vertici vicini -->


<div id="pannelloAgganciaPunto" *ngIf="insertedPrecisionFeatures().length > 0"
   class="container-fluid rounded py-2 border border-secondary bg-white">
   <div class="row no-gutters">
      <div class="col-10 text-left">
         <h5 class="m-0">Gestione Aggancia Punto</h5>
      </div>
      <div class="col-2 text-right">
         <button type="button" class="btn btn-sm btn-bg-outline" ngbTooltip="Chiudi" container="body"
            (click)="gestioneClickAnnullaFigura()">
            <fa-icon [icon]="faTimes"></fa-icon>
         </button>
      </div>
   </div>
   <hr class="my-1">


   <!--
   -- Riferimenti catastali form
   -->
   <form [formGroup]="pannelloAgganciaPuntoForm">

      <!-- EPSG -->
      <div class="row">
         <div class="col">
            <label>Sistema di Riferimento</label>
            <div class="input-group">
               <div class="input-group-prepend">
                  <div class="input-group-text">EPSG</div>
               </div>
               <select class="custom-select form-control" id="epsgSelect" formControlName="epsg" placement="top"
                  ngbTooltip="Seleziona EPSG">
                  <option *ngFor="let epsg of EPSG_LIST" [ngValue]="epsg"> {{ epsg }}</option>
               </select>
            </div>
         </div>
      </div>
      <!-- EPSG -->


      <!--
      -- Punto inserito
      -->
      <h6 class="mt-3">Punto Inserito</h6>
      <div class="container-fluid mt-1 p-1 rounded border border-light">

         <!-- Se sono già stati inseriti dei punti -->
         <ul *ngIf="insertedPrecisionFeatures().length > 0"
            class="list-group container-fluid coordsContainer list-group-flush pr-0" #coordsListContainer>
            <li *ngFor="let feature of insertedPrecisionFeatures(); let i=index" class="list-group-item m-0 px-0 py-1">

               <div class="row no-gutters">
                  <!-- <div class="col-1 my-auto text-center">
                     <span class="badge badge-pill badge-info badge-reposition">{{i+1}}</span>
                  </div> -->

                  <div class="col text-center mx-1">
                     <input type="text" class=" d-inline form-control" name="coord-x" placement="right" container="body"
                        readonly [ngbTooltip]="ttCoordX"
                        [value]="(!!feature.get('transformedCoords') ? feature.get('transformedCoords')[0] : '-')">
                  </div>

                  <div class="col text-center mx-1">
                     <input type="text" class="d-inline form-control" name="coord-y" placement="left" container="body"
                        readonly [ngbTooltip]="ttCoordY"
                        [value]="(!!feature.get('transformedCoords') ? feature.get('transformedCoords')[1] : '-')">
                  </div>


               </div>
            </li>
         </ul>
      </div>


      <!--
   -- Vertici Vicini
   -->
      <span *ngIf="verticiVicini.length == 0 && nessunRisultatoShow" class="badge badge-info">Nessun risultato trovato
         per il punto inserito.</span>

      <div *ngIf="verticiVicini.length > 0 && !nessunRisultatoShow">
         <h6 class="mt-3">Vertici Vicini</h6>
         <div><small> Selezionare il punto da agganciare </small></div>
         <!-- Se sono già stati inseriti dei punti -->
         <ul *ngIf="insertedPrecisionFeatures(false).length > 0"
            class="list-group container-fluid coordsContainer list-group-flush pr-0" #coordsListContainer>

            <li *ngFor="let feature of insertedPrecisionFeatures(true), let i=index"
               class="list-group-item m-0 px-0 py-1">

               <div class="row no-gutters">
                  <div class="col-1 my-auto text-center">
                     <span class="badge badge-pill badge-info badge-reposition">{{i+1}}</span>
                  </div>


                  <div class="col text-center mx-1">
                     <input type="text" class=" d-inline form-control" name="coord-x" placement="right" container="body"
                        style="cursor:default" readonly (mouseout)="spegniFeature(feature,$event)"
                        (mouseover)="illuminaFeature(feature,$event)"
                        (click)="gestisciVerticeSelezionato(feature,$event)"
                        [value]="((!!feature.get('oggetto')) ? feature.get('oggetto') + ' (' + feature.get('dist') + ')' : '-')">
                  </div>
               </div>
            </li>
         </ul>
      </div>



      <div class="row no-gutters mt-1">

         <div class="col text-center">
            <button type="button" class="btn bg-blu txt-white btn-sm" ngbTooltip="Chiudi"
               (click)="gestioneClickAnnullaFigura()">
               <fa-icon></fa-icon> Chiudi
            </button>
         </div>
      </div>

   </form>
</div>
