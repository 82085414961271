import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RaffinaDatiService } from 'src/app/servizi/raffina-dati.service';
import { Subscription, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UtenteAttivoService } from 'src/app/servizi/utente-attivo/utente-attivo.service';
import { EbwModalService } from 'src/app/library/ebw-modals-angular/ebw-modal.service';
import { GestioneLoginService } from 'src/app/servizi/login/gestione-login.service';
import { UtilsService } from 'src/app/servizi/utils.service';
import { map, tap } from 'rxjs/operators';
import { DecodificaData, DecretoData, EBWApiResponse, AutocompletamentoData } from 'src/app/modelli/api.response.model';
import { SolrFilters } from 'src/app/modelli/solr-filters';
import { ShrAutocompleteChoiceComponent } from '../shr-autocomplete-choice/shr-autocomplete-choice.component';
import { AutocompleteRicercaDatiCatastaliParams } from 'src/app/modelli/api.request.model';

@Component({
   selector: 'shr-ricerca-catastale',
   templateUrl: './shr-ricerca-catastale.component.html',
   styleUrls: ['./shr-ricerca-catastale.component.css']
})
export class ShrRicercaCatastaleComponent implements OnInit {
   /** Label dinamica per il campo sezione */
   labelSezione: string = 'Sezione/Sezione Urbana';
   /** array contenente tutte le sottoscrizioni */
   subscriptionArray: Subscription[] = [];

   /** Lista dei comuni di competenza della sezione selezionata */
   comuniCatastali$;
   /** Lista di sezioni di competenza del comune selezionato dall'utente */
   sezioniDiCompetenza$;

   /** Lista dei fogli di competenza della sezione selezionata */
   fogliDellaSezione: AutocompletamentoData[] = [];
   /** Lista dei fogli di competenza della sezione selezionata */
   fogliDellaSezione$;
   /** Lista di particelle del foglio selezionato */
   particelleDelFoglio$;

   /** L'utente ha competenza territoriale o può vedere tutti i comuni? */
   boolConCompetenzaTerritoriale = false;
   /** Parametro di configurazione per le ricerche solr */
   solrFilters: SolrFilters;
   /** Gestione visibilità spinner sezioni */
   isSezioniReady = true;
   /** Gestione visibilità spinner fogli */
   isFogliReady = true;
   /** Gestione visibilità spinner plle */

   isPlleReady = true;
   /** La sezione puà essere anche null per cui
     * non è sufficiente fare un controllo sul valore nullo per capire se è
     * stata selezionato o meno */
   @ViewChild('sezioneComponent', { static: false }) sezioneComponent: ElementRef;
   /** Componente Autocomplete Amministrazione */
   @ViewChild('comuniComponent', { static: false }) comuniComponent: ShrAutocompleteChoiceComponent;
   /** Componente Autocomplete Amministrazione */
   @ViewChild('comuniSelectComponent', { static: false }) comuniSelectComponent: ElementRef;

   /** Evento emesso dal componente per condividere con il parent le scelte sui controls */
   @Output() valuesChanged = new EventEmitter();

   /** array con i campi che devono essere segnati come obbligatori */
   @Input() mandatoryFields = [];

   /** Array con i campi che devono essere visualizzati */
   @Input() toVisualizeFields = [];

   /** Valore del tipo catasto se la relativa select non deve essere mostrata */
   @Input() valore_tipo_catasto: String;

   /** Form per definire i campi di ricerca */
   @Input() mainForm: FormGroup;

   @Input() comuneDefaultValue: { codice_catastale: string, term: string, id: number };
   @Input() sezioneDefaultValue: any;
   @Input() foglioDefaultValue: any;
   @Input() numeroParticellaDefaultValue: any;
   @Input() disabledSub: boolean = false;

   prepopolatedField = {
      tipo_catasto: true,
      cod_com: false,
      sezione: false,
      foglio: false,
      numero: false,
      denominatore: false,
      subalterno: false
   }


   constructor(
      private httpClient: HttpClient,
      private formBuilder: FormBuilder,
      public raffinaDatiService: RaffinaDatiService,
      private utilsService: UtilsService,
      private loginService: GestioneLoginService,
      private utenteAttivo: UtenteAttivoService,
      private ebwModalService: EbwModalService

   ) { }


   ngOnInit() {

      // this.raffinaDatiService.initAreeEDecodifiche();

      this.boolConCompetenzaTerritoriale = this.utenteAttivo.isConCompetenzaTerritoriale();

      this.solrFilters = { path: 'solr_comuni_catastali_gestiti' };

      this.comuniCatastali$ = this.getCodiciComuniCatastali(this.loginService.getPayload().amministrazioni_id).pipe(
         map((res) => res.data.map(function (val, index) {
            return { codice: val.codice_catastale_del_comune, descrizione: val.denominazione_in_italiano };
         })),
         tap(data => {

            if (!!this.comuneDefaultValue) {
               this.mainForm.controls['codcom'].setValue(this.comuneDefaultValue.codice_catastale);
            }
         })
      );

      if (this.toVisualize('tipo_catasto')) {
         this.subscriptionArray.push(this.mainForm.get('tipo_catasto').valueChanges.subscribe(
            (value: string) => {
               if (value === 'T') {
                  this.labelSezione = 'Sezione';
               } else if (value === 'F') {
                  this.labelSezione = 'Sezione Urbana';
               }

               this.mainForm.get('codcom').reset();
               if (this.comuniComponent) this.comuniComponent.resetInput();

               if (this.mainForm.get('codice_comune').value && value) {
                  /** Richiedo l'elenco delle sezioni */
                  this.sezioniDiCompetenza$ = this.scaricaDatiAutocomplete({
                     utente_id: this.loginService.getPayload().utenti_id,
                     tipo_catasto: value,
                     codice_comune: this.mainForm.get('codice_comune').value
                  }).pipe(

                     map((data: EBWApiResponse) => data.data as AutocompletamentoData[]));
               }
            }
         ));
      } else {
         this.mainForm.get('tipo_catasto').setValue(this.valore_tipo_catasto);
         this.mainForm.get('codcom').reset();
      }

      if (this.toVisualize('codcom')) {
         this.subscriptionArray.push(this.mainForm.get('codcom').valueChanges.subscribe((value: string) => {

            if (value == "null") {
               value = null;
            }

            /** this.mainForm.get('comune').patchValue( */
            this.mainForm.get('codice_comune').patchValue(value);


            if (this.comuniSelectComponent && !!this.comuniSelectComponent.nativeElement.options[this.comuniSelectComponent.nativeElement.selectedIndex]) {
               this.mainForm.get('comune').patchValue(this.comuniSelectComponent.nativeElement.options[this.comuniSelectComponent.nativeElement.selectedIndex].text);
            }

            this.mainForm.get('sezione').reset();

            this.sezioniDiCompetenza$ = EMPTY.pipe(
               map((data: EBWApiResponse) => [] as AutocompletamentoData[]));

            this.isSezioniReady = true;
            /** Richiedo l'elenco delle sezioni */
            if (this.mainForm.get('tipo_catasto').value && this.mainForm.get('codice_comune').value) {
               this.isSezioniReady = false;
               this.sezioniDiCompetenza$ = this.scaricaDatiAutocomplete({
                  utente_id: this.utenteAttivo.userInfo['utenti_id'],
                  tipo_catasto: this.mainForm.get('tipo_catasto').value,
                  codice_comune: this.mainForm.get('codice_comune').value
               }).pipe(
                  map((data: EBWApiResponse) => data.data as AutocompletamentoData[]),
                  tap(data => {
                     this.isSezioniReady = true;
                     // Ho un valore ma non ho ancora prepopolato
                     if (!!this.sezioneDefaultValue && !this.prepopolatedField.sezione) {
                        this.mainForm.controls['sezione'].setValue(this.sezioneDefaultValue)
                        this.prepopolatedField.sezione = true; // Ho prepopolato
                     }
                  })
               );
            }
         }));
      }

      if (this.toVisualize('sezione')) {
         this.subscriptionArray.push(this.mainForm.get('sezione').valueChanges.subscribe((value: string) => {
            this.mainForm.get('foglio').reset();

            this.fogliDellaSezione$ = EMPTY.pipe(map((data: EBWApiResponse) => [] as AutocompletamentoData[]));

            this.isFogliReady = true;
            if (this.mainForm.get('tipo_catasto').value && this.mainForm.get('codice_comune').value && this.mainForm.get('sezione').value != null) {
               this.isFogliReady = false;
               this.fogliDellaSezione$ = this.scaricaDatiAutocomplete({
                  utente_id: this.loginService.getPayload().utenti_id,
                  tipo_catasto: this.mainForm.get('tipo_catasto').value,
                  codice_comune: this.mainForm.get('codice_comune').value,
                  codice_sezione: value == "null" ? null : value
               }).pipe(
                  map((data: EBWApiResponse) => data.data as AutocompletamentoData[]),
                  tap(data => {
                     this.isFogliReady = true;

                     // Ho un valore ma non ho ancora prepopolato
                     if (!!this.foglioDefaultValue && !this.prepopolatedField.foglio) {
                        this.mainForm.controls['foglio'].setValue(this.foglioDefaultValue)
                        this.prepopolatedField.foglio = true; // Ho prepopolato
                     }

                  })
               );
            }
         }));
      }

      if (this.toVisualize('foglio')) {
         this.subscriptionArray.push(this.mainForm.get('foglio').valueChanges.subscribe((value: string) => {

            this.mainForm.get('numero').reset();
            this.particelleDelFoglio$ = EMPTY.pipe(
               map((data: EBWApiResponse) => [] as AutocompletamentoData[]));

            this.isPlleReady = true;
            if (this.mainForm.get('tipo_catasto').value && this.mainForm.get('codice_comune').value && this.mainForm.get('sezione').value != null) {
               this.isPlleReady = false;
               this.particelleDelFoglio$ = this.scaricaDatiAutocomplete({
                  utente_id: this.loginService.getPayload().utenti_id,
                  tipo_catasto: this.mainForm.get('tipo_catasto').value,
                  codice_comune: this.mainForm.get('codice_comune').value,
                  codice_sezione: this.mainForm.get('sezione').value == "null" ? null : this.mainForm.get('sezione').value,
                  codice_foglio: value == "null" ? null : value
               }).pipe(
                  map((data: EBWApiResponse) => data.data as AutocompletamentoData[]),
                  tap(data => {
                     this.isPlleReady = true;

                     // Ho un valore ma non ho ancora prepopolato
                     if (!!this.numeroParticellaDefaultValue && !this.prepopolatedField.numero) {
                        this.mainForm.controls['numero'].setValue(this.numeroParticellaDefaultValue)
                        this.prepopolatedField.numero = true; // Ho prepopolato
                     }

                  }));
            }
         }));
      }

      if (this.toVisualize('numero')) {
         this.subscriptionArray.push(this.mainForm.get('numero').valueChanges.subscribe((value: string) => {
            this.mainForm.get('denominatore').reset();
            this.mainForm.get('sub').reset();

         }));
      }

      if (this.disabledSub) {
         this.mainForm.get('tipo_catasto').valueChanges.subscribe((elem) => {
            if (elem == "F") {
               this.mainForm.get('sub').enable();
            } else {
               this.mainForm.get('sub').disable();
            }
         })

      }
   }



   /** Metodo chiamato sulla selezione dei comuni da autocomplete */
   onDoSelectionAutocomplete(item) {

      this.mainForm.get('codice_comune').patchValue((item && item.codice_catastale) ? item.codice_catastale : null);
      this.mainForm.get('comune').patchValue((item && item.term) ? item.term : null);
      this.mainForm.get('sezione').reset("");

      this.sezioniDiCompetenza$ = EMPTY.pipe(
         map((data: EBWApiResponse) => [] as AutocompletamentoData[]));

      this.isSezioniReady = true;
      /** Richiedo l'elenco delle sezioni */

      if (this.mainForm.get('tipo_catasto').value && this.mainForm.get('codice_comune').value) {
         this.isSezioniReady = false;

         this.sezioniDiCompetenza$ = this.scaricaDatiAutocomplete({
            utente_id: this.utenteAttivo.userInfo['utenti_id'],
            tipo_catasto: this.mainForm.get('tipo_catasto').value,
            codice_comune: this.mainForm.get('codice_comune').value
         }).
            pipe(
               map((data: EBWApiResponse) => data.data as AutocompletamentoData[]),
               tap(data => {
                  this.isSezioniReady = true;
                  // Ho un valore ma non ho ancora prepopolato
                  if (!!this.sezioneDefaultValue && !this.prepopolatedField.sezione) {
                     this.mainForm.controls['sezione'].setValue(this.sezioneDefaultValue)
                     this.prepopolatedField.sezione = true; // Ho prepopolato
                  }

               })
            );
      }


   }


   /**
     * Funzione per scaricare i dati di autocompletamento dei campi della form
     * @param params parametri passati
     * @returns lista di dati da utilizzare per l'autocompletamento
   */
   scaricaDatiAutocomplete(params: AutocompleteRicercaDatiCatastaliParams) {
      //params.codice_comune="G479";
      return this.utilsService.postRequest(this.utilsService.creaURL('catasto', 'distinct'), params);
   }

   /**
     * Comuni catastali collegati all'utente attivo
   */
   getCodiciComuniCatastali(id) {
      var params = {
         where: { 'id': id },
         'geometria': false,
         'token': this.loginService.getToken()
      }
      return this.utilsService.postRequest(this.utilsService.creaURL('catasto', 'comuni_catastali'), params);
   }

   isMandatory(fieldName): boolean {
      if (this.mandatoryFields.indexOf(fieldName) > -1) return true;
      return false;
   }

   /** ngOnDestroy */
   ngOnDestroy(): void {
      // rimuovo tutte le sottoscrizioni
      this.subscriptionArray.forEach(sub => sub.unsubscribe());
   }

   /** toVisualize */
   toVisualize(fieldName): boolean {
      if (this.toVisualizeFields.indexOf(fieldName) > -1) {
         return true;
      }

      return false;
   }

   /**Funzione chiamata per popolare l'autocomplete al back */
   public populateAutocomplete(obj) {
      this.comuniComponent.selectedItem(obj);
      this.onDoSelectionAutocomplete(obj);
   }

   /**
 * Funzione di ordinamento per array di decodifiche (Ordinamento crescente sul campo descrizione)
 * @param a decodifica 1
 * @param b decodifica 2
 */
   ordinamentoCrescenteDecodifiche(a: DecodificaData, b: DecodificaData) {
      const descA = a.descrizione.toUpperCase(); // ignora maiuscole e minuscole
      const descB = b.descrizione.toUpperCase(); // ignora maiuscole e minuscole
      return ((descA < descB) ? -1 : ((descA > descB) ? 1 : 0));
   }
}
