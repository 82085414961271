<nav id="sidebar"
     class="bg-dark text-white">
   <!-- barra di ricerca -->
   <!-- <div class="input-group px-3 pt-4 pb-5 border-bottom">
      <input #searchInput type="text" class="form-control" placeholder="Ricerca Pratiche o Particelle"
         aria-label="Cerca una pratica..." aria-describedby="cerca">
      <div class="input-group-append pointer" (click)="onCercaAttoClick(searchInput)">
         <span class="input-group-text" id="cerca">
            <fa-icon aria-hidden="true" [icon]="icons.faSearch"></fa-icon>
         </span>
      </div>
      <input #searchInput type="text" class="form-control" [inputFormatter]="attiParticelleFormatter"
         [ngbTypeahead]="attiParticelleSearch" [resultTemplate]="attiParticelleTemplate" aria-describedby="cerca"
         (selectItem)="selectedItem($event)" ngbTooltip="Scrivere qualche lettera per visualizzare l'elenco"
         placement="bottom" placeholder="Ricerca Particelle">
      <div class="input-group-append pointer" (click)="onCercaItemClick(searchInput)">
         <span class="input-group-text" id="cerca">
            <fa-icon aria-hidden="true" [icon]="icons.faSearch"></fa-icon>
         </span>
      </div>

   </div>
   <ng-template #attiParticelleTemplate let-r="result" let-t="term">
      <ngb-highlight [result]="r.descrizione" [term]="t">
      </ngb-highlight>
   </ng-template> -->
   <!-- elenco menu -->
   <ul class="list-unstyled scroll-list">
      <li *ngIf="isMenuVisible('home')"
          class="border-bottom">
         <img src="./../../../assets/img/logo_fgd_209.png"
              width="100%"
              alt="Fondazione Don Carlo Gnocchi">
      </li>
      <li class="border-bottom"
          *ngIf="isMenuVisible('home')">
         <a href="#"
            routerLink="/"> Home </a>
      </li>
      <li class="border-bottom"
          *ngIf="isMenuVisible('home')">
         <a href="#"
            routerLink="mappa/pazienti/lista"
            routerLinkActive="active-link">Pazienti</a>
      </li>
      <!-- <li class="border-bottom" *ngIf="isMenuVisible('bene_demaniale')">
         <a href="#benedemaniale" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Catasto</a>
         <ul class="collapse list-unstyled" id="benedemaniale">
            <li class="border-top" *ngIf="isMenuVisible('consulta_catasto')">
               <a href="#" [routerLink]="['/consulta-catasto']">Consulta</a>
            </li>
         </ul>
      </li> -->
   </ul>

</nav>