import { NGXLogger } from 'ngx-logger';
import { EBWApiResponse } from './../modelli/api.response.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { DecodificaData } from '../modelli/api.response.model';

/**
 * Servizio che espone metodi per decodificare sigle, decifrare codici
 * territoriali, trovare riferimenti a un atto, e più in generale tutti
 * ciò che ha lo scopo di raffinare dati grezzi proveniente da una API
 * (servizio utilizzato nella generazione dei Report dell'atto o della particella)
 */
@Injectable({
   providedIn: 'root'
})
export class RaffinaDatiService {

   /** json contenente tutte le decodifiche */
   private decodifiche: DecodificaData[] = [];

   /** json contenente tutte le aree */
   private codaree: any = {};

   /** getter delle decodifiche */
   public getDecodifiche(): DecodificaData[] {
      return this.decodifiche;
   }

   /** getter dei codici aree */
   public getCodAree(): any {
      return this.codaree;
   }

   /** costruttore */
   constructor(private http: HttpClient,
      private utils: UtilsService,
      private logger: NGXLogger) { }

   initAreeEDecodifiche() {

      const API_URL = this.utils.creaURL('atti_amministrativi',  'decifra' );
      // Richiama l'API per ottenere tutte le decodifiche
      this.utils.postRequest(API_URL, { tipo: 'DECODIFICA' }).subscribe((response: EBWApiResponse) => {
         if (response.error === 0 && response.status === 0) {
            this.decodifiche = response.data as DecodificaData[];
            //  console.log('DECOFIFICHE: ', this.decodifiche);
         } else {
            this.logger.error('RaffinaDatiService - DECODIFICHE: ', response.message, ' -> ', response.error);
         }
      });

      // Richiama l'API per ottenere tutti i codici aree
      this.utils.postRequest(API_URL, { tipo: 'COD_AREE' }).subscribe((response: EBWApiResponse) => {
         if (response.error === 0 && response.status === 0) {
            this.codaree = response.data;
         } else {
            this.logger.error('RaffinaDatiService - CODICI AREE: ', response.message, ' -> ', response.error);
         }
      });
   }


   /**
    * Richiama l'API per ottenere tutti i riferimenti
    * @param oracle_id identificativo dell'atto per cui cercare i riferimenti ad altri atti
    * @returns observable a cui sottoscriversi per ottenere response.data dell'API
    */
   getRiferimenti(oracle_id: number): Observable<any> {
      const API_URL = this.utils.creaURL('atti_amministrativi', 'riferimenti' );
      return this.utils.postRequest(API_URL, { oracle_id })
         .pipe(map((resp: any) => {
            if (resp.error === 0 && resp.status === 0) {
               console.log('getRiferimenti: ', resp.data);
               return resp.data;
            } else {
               this.logger.log('Errore getRiferimenti: ', resp);
               return {};
            }
         }))
         .pipe(catchError(error => {
            return throwError(`errore >>> ${error}`);
         }));
   }

   /**
    * Cerca diverse decodifiche con la possibilità di passargli un  tra le DECODIFICHE
    * @param tipo tipologia di decodifica (corrisponde al campo 'tipo' della tabella 'decodifiche')
    * @param funzioneOrdinamento funzione per ordinare la lista di decodifiche risultato, se NULL la lista non viene ordinata
    * @returns lista di decodifiche fitrata
    */
   ottieniDecodificheFiltrate(tipo: string, funzioneOrdinamento: (a: DecodificaData, b: DecodificaData) => number): DecodificaData[] {

      // console.log("this.decodifiche", this.decodifiche)
      if (tipo === null) {
         return [];
      }
      if (funzioneOrdinamento === null) {
         return this.decodifiche.filter((elem: DecodificaData) => elem.tipo === tipo);
      } else {
         return this.decodifiche.filter((elem: DecodificaData) => elem.tipo === tipo).sort(funzioneOrdinamento);
      }
   }


   /**
    * Cerca un record tra le DECODIFICHE
    * @param tipo tipologia di decodifica (corrisponde al campo 'tipo' della tabella 'decodifiche')
    * @param valore codice del quale cercare la descrizione
    * @returns la decodifica del codice
    */
   decodifica(tipo: string, valore: any): string {
      // se il valore è nullo, ritorno la stringa vuota
      if (valore === null || valore === '') { return ''; }
      // trasformo eventuali numeri in stringhe
      if (typeof valore === 'number') { valore = valore.toString(); }

      // cerco il record
      const record = this.decodifiche.find(rec => {
         return (rec.tipo === tipo && rec.codice === valore);
      });
      // se trovato, ritorno la sua descrizione
      if (record !== undefined) {
         if (record.descrizione === null) {
            return record.descrizione_estesa;
         } else {
            return record.descrizione;
         }
      } else {
         this.logger.warn('Impossibile trovare la decodifica per (', tipo, '|', valore, ')');
         return `${valore} (DECODIFICARE!)`;
      }
   }

   /**
    * Cerca un record tra le DECODIFICHE
    * @param tipo tipologia di decodifica (corrisponde al campo 'tipo' della tabella 'decodifiche')
    * @param valore codice del quale cercare la descrizione
    * @returns la decodifica estesa del codice
    */
   decodificaEstesa(tipo: string, valore: any): string {
      // se il valore è nullo, ritorno la stringa vuota
      if (valore === null || valore === '') { return ''; }
      // trasformo eventuali numeri in stringhe
      if (typeof valore === 'number') { valore = valore.toString(); }

      // cerco il record
      const record = this.decodifiche.find(rec => {
         return (rec.tipo === tipo && rec.codice === valore);
      });

      // se trovato, ritorno la sua descrizione
      if (record !== undefined) {
         return record.descrizione_estesa;
      } else {
         this.logger.warn('Impossibile trovare la decodifica per (', tipo, '|', valore, ')');
         return `${valore} (DECODIFICARE!)`;
      }
   }


   /**
    * Cerca un record tra le AREE
    * @param tipoCodice tipologia di aree (corrisponde al campo 'tipo' della vista 'decifra_codici_aree')
    * @param valore codice del quale cercare la denominazione
    * @param campoReturn (se non specificato viene ritornato il campo 'denominazione')
    * @returns la decifratura del codice
    */
   decifra(tipoCodice: string, valore: any, campoReturn = 'denominazione') {
      // se il valore è nullo, ritorno la stringa vuota
      if (valore === undefined || valore === '') { return ''; }

      // cerco il record
      const record = this.codaree.find((rec) => rec[tipoCodice] === valore);

      // se trovato, ritorno la sua descrizione
      if (record !== undefined) {
         return record[campoReturn];
      } else {
         this.logger.warn('Impossibile trovare la decodifica per (', tipoCodice, '=', valore, ')');
         return `${valore} (DECIFRARE!)`;
      }
   }

   /**
    * Restituisce la descrizione dello stato attuale dell'occupazione.
    *
    * @param codice - codice stato dell'occupazione.
    */
   decodificaStatoOccupazione(codice: number): string {
      const statesMap = new Map<string, string>([
         ['1', 'Da Accertare'],
         ['2', 'Risolta'],
         ['3', 'Accertata in campo'],
         ['4', 'Accertata in attesa dei dati geometrici e censuari aggiornati'],
         ['5', 'Accertata da trasformare in Opera Esistente di non interesse catastale'],
         ['6', 'Accertata in attesa di iscrizione al Modello 105'],
         ['7', 'Accertata in attesa di emissione dell\'ingiunzione di sgombero'],
         ['8', 'Accertata in attesa di regolarizzazione con atto concessorio'],
         ['9', 'Archiviata']
      ]);
      const value = statesMap.get(codice.toString());
      return  value ? value : '';
   }
}
