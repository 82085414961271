import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { transform, transformExtent } from 'ol/proj';
import { MapService } from '../../service/map.service';
import { faEraser } from '@fortawesome/free-solid-svg-icons';

declare var google: any; // serve ma non so perchè

/** Componente per la ricerca di indirizzi con Google */
@Component({
   selector: 'ebw-ricerca-google',
   templateUrl: './ricerca-google.component.html',
   styleUrls: ['./ricerca-google.component.scss']
})
export class RicercaGoogleComponent implements OnInit {

   /** Riferimento elemento input */
   @ViewChild('search', { static: false }) searchElementRef: ElementRef;

   /** Form ricerca indirizzi */
   formRicerca: FormGroup;

   /** Icona bottone cancellazione indirizzo selezionato */
   faEraser = faEraser;

   /** Emettitore evento per la selezione di un indirizzo */
   @Output() selezionatoRisultato: EventEmitter<{ addressInfo: {}[], location: number[], extent: number[] }> = new EventEmitter();

   /** Emettitore evento sul click per la rimuozione dell'indirizzo selezionato */
   @Output() rimuoviMarkerRicerca: EventEmitter<any> = new EventEmitter();

   /**
    * Costruttore del componente
    * @param googleMapsAPILoader servizio per il caricamento della libreria
    */
   constructor(private googleMapsAPILoader: MapsAPILoader) { }

   /** Caricamento form e autocomplete di Google */
   ngOnInit(): void {

      const form = {
         ricerca: new FormControl(null, Validators.required)
      };
      this.formRicerca = new FormGroup(form);

      this.googleMapsAPILoader.load().then(() => {
         const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {componentRestrictions: { country: 'it' } });

         autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            // verify result
            if (place.geometry === undefined || place.geometry === null) {
               return;
            }

            // console.log("RISULTATO Autocomplete ", place);

            const addressInfo = place.address_components;
            const location = transform([place.geometry.location.lng(), place.geometry.location.lat()], MapService.EPSG_4326_KEY, MapService.EPSG_3857_KEY);
            const extent = transformExtent([
               place.geometry.viewport.getSouthWest().lng(),
               place.geometry.viewport.getSouthWest().lat(),
               place.geometry.viewport.getNorthEast().lng(),
               place.geometry.viewport.getNorthEast().lat()
            ], MapService.EPSG_4326_KEY, MapService.EPSG_3857_KEY);

            this.selezionatoRisultato.emit({ addressInfo, location, extent });
         });
      });
   }

   /** Pulizia della casella di autocomplete e emettitore per la rimozione del marker dalla mappa */
   rimuoviMarker() {
      this.rimuoviMarkerRicerca.emit(true);
      this.formRicerca.patchValue({ ricerca: '' });
   }
}
