import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
   selector: '[displayMandatory]'
})
export class DisplayMandatoryDirective {

   private el: HTMLElement;

   // private defaultIsBold = false;
   // @HostBinding('style.backgroundColor') backgroundColor: string;

   /** costruttore */
   constructor(private elementRef: ElementRef) {
      this.el = this.elementRef.nativeElement;
   }

   @Input() set displayMandatory(shouldBold: boolean) {
      if (shouldBold === true) {
         // If condition is true add template to DOM
         this.el.style.fontWeight = 'bold';
         //   this.backgroundColor = 'red';
      } else {
         // Else remove template from DOM
         this.el.style.fontWeight = 'normal';
         // this.backgroundColor = 'yellow';
      }
   }
}
