import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MappaService } from './mappa/mappa.service';

@Injectable({
   providedIn: 'root',
})
export class RouterService {

   constructor(private router: Router, private activeRoute: ActivatedRoute, private MappaService: MappaService) {
   }

   /**funzioni che permettono il cambio pagina passandogli dei dati,senza che vengano visualizzati nella url */

   /**utilizzare quando si vuole andare in un path che è parent nella lista dei Routes */
   /** view deve essere per esempio 'mappa'  */
   public navigateTo(view: string, data?: any) {
      let route;
      route = this.router.config.find(c => c.path === view);
      if (data)
         route.data = data;

      this.router.navigate([view]).then(() => {
         route.data = {};
      });
   }

   /**utilizzare quando si vuole andare in un path che è children nella lista dei Routes */
   /** view deve essere per esempio 'mappa'  */
   public navigateToChild(view: string, data?: any) {
      var route;

      //serve per le route che sono concatenate, es: main-atti-module/gestione
      var searchView;
      var index = view.lastIndexOf('/');

      if (index !== -1) {
         searchView = view.substring(0, index);
      } else {
         searchView = view;
      }
      this.router.config.forEach(r => {
         if (r.children) {
            route = r.children.find(c => c.path.search(searchView) !== -1 || c.path === searchView);
            return true;
         }
      })


      if (!!route && !!data) {
         route.data = data;
      }

      // Se ci troviamo nella stessa rotta allora utilizzo il servizio per passare i dati senza dover ricaricare la pagina
      if (this.router.url.substring(1) == searchView && searchView == 'mappa') {
         this.MappaService.updateDataWithSameRoute.next(data);
      } else {
         this.router.navigateByUrl(view).then(() => {

            setTimeout(function () {
               route.data = {};
            }, 5000);
         });
      }

      // this.router.navigate([view]).then(() => {
      //    route.data = {};
      // });


   }

}
