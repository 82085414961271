import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { DecodificheResponse } from 'src/app/modelli/decodifiche-response';
import { SolrResponse } from '../../modelli/solr-response.sevice';
import * as FileSaver from 'file-saver';
import { ConfigService } from '../config/config.service';
import { GestioneLoginService } from '../login/gestione-login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../utils.service';
import { EBWApiResponse } from 'src/app/modelli/api.response.model';

const EMPTY = '';
const ZERO = 0;

@Injectable({
   providedIn: 'root'
})

export class GestioneAttiAmmService {
   currentTipoAtto$ = new Subject();
   currentSelectedValues$ = new Subject();
   cmdListener$ = new Subject();
   emptyForm$ = new Subject();

   getDatiAttoSubscription = new Subscription();
   API_URL: string;

   // ATTO AMMINISTRATIVO SELEZIONATO
   currentAttoAmministrativo: any;

   // salvo i valori dei componenti di tipo list-choice-template o le date che non sono direttamente inserite nel Form
   currentSelectedValues: {};


   constructor(private http: HttpClient,
               private router: Router,
               private gestioneLoginService: GestioneLoginService,
               private route: ActivatedRoute,
               private configService: ConfigService,
               private utils: UtilsService) {

      this.currentSelectedValues = {};
      // recupero i valori delle url da chamare dal file di configurazione config-atti-amm-dev.json

      this.currentAttoAmministrativo = null;
   }

   /**
    * Metodo chiamato dai component di autocomplete con richieste sul digit
    * a Solr
    */
   search(term: string, urlId: string): Observable<SolrResponse> {
      let apiUrl = '';
      if (urlId.toString() === 'solr') {
         // competenza:*|10|*
         apiUrl = `${this.API_URL[urlId]}?fq=term:*${term}*&q=*:*`;
      }
      if (term === '') {
         return new Observable<SolrResponse>();
      }
      return this.http.get<SolrResponse>(apiUrl);
   }

   /**
    * Chiamata alle rest api delle decodifiche per popolare i valori options dei form
    */
   initOptions(urlID: string) {
      // console.log('initOptions');
      const tipo = urlID;
      const data = { tipo };

      return this.utils.postRequest(this.API_URL[urlID], data);

   }

   /**
    * Questo servizio viene chiamato prima di inviare i dati al
    * servizio che restituisce l'elenco dei risultati per ottenere
    * il nome dei campi da applicare ed altre info
    */
   filterHandler(urlId: string, filters: any) {
      const tipo = urlId;
      const data = { data: filters };

      return this.utils.postRequest(this.API_URL[urlId], data);

   }

   /**
    * @param name nome del filtro
    * @param value valore del filtro
    * Questo mtodo viene chiamato ogni volta che un filtro del form
    * cambia valore
    * Fire Event: currentSelectedValues$
    */
   setCurrentSelectedValue(name: string, value: string) {
      this.currentSelectedValues[name] = value;

      // fire event: form value changed
      this.currentSelectedValues$.next(this.currentSelectedValues);
   }

   getDatiAtto(id: number) {
      return new Observable( observer => {

         var data1 = {
            token: this.gestioneLoginService.getToken(),
            where: {
               id: id
            },
            attributes: {
               oggetti: [],
               rel_atti_soggetti: []
            }
         };
         var url1 = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.view.atti_amministrativi.url);
         var promessa1 = this.utils.postRequest(url1, data1);

         var data2 = {
            token: this.gestioneLoginService.getToken(),
            where: {
               id: id
            },
            attributes: {
               atti_amministrativi: ['id'],
               rel_atti_soggetti: [],

               amministrazioni: [],
               gruppi: [],
               contestazioni: [],
               documenti: [],
               integrazioni: [],
               rate_canone: [],
               rel_atti_pdf: [],
               affidi: [],
               affidi_parziali: [],
               cessioni: [],
               domicili: [],
               incarichi: [],
               procure: [],
               stagionalita: [],
               simboli_sovrapposti: []
            }
         };
         var url2 = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.view.atti_amministrativi.url);
         var promessa2 = this.utils.postRequest(url2, data2);

         var data3 = {
            token: this.gestioneLoginService.getToken(),
            where: {
               id: id
            },
            attributes: {
               atti_amministrativi: ['id'],
               oggetti: [],

               amministrazioni: [],
               gruppi: [],
               contestazioni: [],
               documenti: [],
               integrazioni: [],
               rate_canone: [],
               rel_atti_pdf: [],
               affidi: [],
               affidi_parziali: [],
               cessioni: [],
               domicili: [],
               incarichi: [],
               procure: [],
               stagionalita: [],
               simboli_sovrapposti: []
            }
         };
         var url3 = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.view.atti_amministrativi.url);
         var promessa3 = this.utils.postRequest(url3, data3);

         forkJoin([promessa1, promessa2, promessa3]).subscribe(
            (results: any) => {
               var atto: any;
               //if(status && error)
               if(results[0].status == 0 && results[0].error == 0) {
                  atto = results[0].data[0];
               }

               if(results[1].status == 0 && results[1].error == 0) {
                  atto.oggetti = results[1].data[0].oggetti
               } else {
                  atto.oggetti = [];
               }
               if(results[2].status == 0 && results[2].error == 0) {
                  atto.rel_atti_soggetti = results[2].data[0].rel_atti_soggetti;
               } else {
                  atto.rel_atti_soggetti = []
               }

               var risposta: EBWApiResponse;
               if(atto) {
                  results[0].data[0] = atto;
                  //rifai ebwresponse
                  risposta = {
                     error: 0,
                     status: 0,
                     message: 'Atto Selezionato con Successo!',
                     data: [atto],
                     traceback: {},
                  }
               }

               observer.next(risposta)
               observer.complete()
            },
            err => {
               //ebw response
               var risposta: EBWApiResponse =  {
                  error: 1,
                  status: 1,
                  message: 'Errore selezione Atto!',
                  data: [],
                  traceback: err,
               };
               observer.next(risposta);
               observer.complete()
            }
         )
      })
   }

   getGeometrieAtto(id: number) {
      const data = {
         token: this.gestioneLoginService.getToken(),
         where: {
            id
         },
         attributes: {
            atti_amministrativi: ['id', 'geometria_ingombro', 'geometria_simbolo'],
            amministrazioni: [],
            gruppi: [],
            contestazioni: [],
            documenti: [],
            integrazioni: [],
            oggetti: [],
            oggetti_scopi: [],
            oggetti_tronchi: [],
            rate_canone: [],
            rel_atti_pdf: [],
            rel_atti_soggetti: [],
            soggetti: [],
            soggetti_contatti: [],
            soggetti_indirizzi: [],
            soggetti_albi: [],
            affidi: [],
            affidi_parziali: [],
            cessioni: [],
            domicili: [],
            incarichi: [],
            procure: [],
            stagionalita: [],
            simboli_sovrapposti: []
         }
      };

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.view.atti_amministrativi.url);
      return this.utils.postRequest(url, data);
   }

   getDatiOccupazione(id: number) {
      const data = {
         token: this.gestioneLoginService.getToken(),
         where: {
            id
         }
      };

      const url = this.utils.creaURL('occupazioni', 'view');
      return this.utils.postRequest(url, data);
   }

   getIndiceIstatAnnuale(anno) {
      const data = {
         token: this.gestioneLoginService.getToken(),
         action: 'get_indice_istat',
         anno
      };

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.profilo_economico.atti_amministrativi.url);
      return this.utils.postRequest(url, data);
   }

   /**
    * Records tb pecentuale_mora.
    * Info necessarie per il ritardato pagamento della rata nel profilo economico
    */
   getPercentualiMora() {
      const data = {
         token: this.gestioneLoginService.getToken(),
         action: 'get_percentuale_mora'
      };

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.profilo_economico.atti_amministrativi.url);
      return this.utils.postRequest(url, data);
   }


   /**
    * Metodo per il salvataggio della sezione rata del profilo economico
    * @param data : dati in arrivo
    */
   saveRata$(data: any) {
      data.token = this.gestioneLoginService.getToken();
      data.action = 'save_rata';

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.profilo_economico.atti_amministrativi.url);
      return this.utils.postRequest(url, data);
   }

   /**
    * Metodo per il salvataggio dell'atto amministrativo
    * @param data : dati in arrivo
    */
   saveAtto(data: any) {
      data.token = this.gestioneLoginService.getToken();
      data.action = 'save_atto';

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.save.atti_amministrativi.url);
      return this.utils.postRequest(url, data);
   }

   /**
    * Metodo per la chiusura della pratica
    * @param data : dati in arrivo
    */
   chiusuraPratica(data: any) {
      data.token = this.gestioneLoginService.getToken();
      data.action = 'chiusura_pratica';

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.chiusura_pratica.atti_amministrativi.url);
      return this.utils.postRequest(url, data);
   }

   /**
    * Metodo per la riconsegna
    * @param data : dati in arrivo
    */
   riconsegna(data: any) {
      data.token = this.gestioneLoginService.getToken();
      data.action = 'riconsegna';

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.riconsegna.atti_amministrativi.url);
      return this.utils.postRequest(url, data);
   }

   /**
    * Metodo per il salvataggio dell'atto amministrativo
    * @param data : dati in arrivo
    */
   savePassaggioDomanda(data: any) {
      data.token = this.gestioneLoginService.getToken();
      data.action = 'passaggio_domanda';
      console.log('data', data);

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.save.passaggio_domanda.url);
      return this.utils.postRequest(url, data);
   }

   /**
    * Metodo per effettuare il passaggio a subingresso
    * @param data
    */
   savePassaggioSubingresso(data: any) {
      data.token = this.gestioneLoginService.getToken();
      data.action = 'passaggio_subingresso'; // GB a cosa serve?

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.save.passaggio_subingresso.url);
      return this.utils.postRequest(url, data);
   }

   /**
    * Metodo per effettuare il passaggio a consegna
    * @param data
    */
   savePassaggioConsegna(data: any) {
      data.token = this.gestioneLoginService.getToken();
      data.action = 'passaggio_consegna'; // GB a cosa serve?

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.save.passaggio_consegna.url);
      return this.utils.postRequest(url, data);
   }

   aggiornaSimboloEAllineaTabellaInterscambio(data: any) {
      data.token = this.gestioneLoginService.getToken();
      data.action = 'localizza_pratica_save';

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.save.localizza_pratica_save.url);
      return this.utils.postRequest(url, data);
   }


   /** Metodo per l'inserimento/cancellazione record tb rel_pagamenti_f24_rate  */
   saveRelPagamentof24Rata(data: any) {
      data.token = this.gestioneLoginService.getToken();
      data.action = 'save_rel_pagamenti_f24_rata';

      const url = this.utils.creaUrlDaRoute(this.configService.getConfigAttiAmm().api.routes.profilo_economico.atti_amministrativi.url);
      return this.utils.postRequest(url, data);
   }

   /**
    *
    * @param content
    * @param type
    * @param fileName
    */
   exportFile(content: string, type: { type: string }, fileName: string) {
      // esempio
      // type =  {type: "text/plain;charset=utf-8"};
      // fileName = 'file_export_' + new  Date().getTime() + '.csv'
      const data: Blob = new Blob([content], type);
      FileSaver.saveAs(data, fileName);
   }



   /**
    *
    * @param current : numero o stringa da trasformare
    * @param default_value : setta la variabile al valore di default se questa è vuota
    * Metodo per trasformare un numero in una stringa formatta come segue:
    * 1. due decimali
    * 2. virgola come separatore dei decimali
    * 3. torna stringa vuota se valori = 0 o null
    */
   _toString(current, defaultValue?) {
      if (current === null || current === ZERO || current === EMPTY || current === 'NaN') {
         current = defaultValue;
         if (!defaultValue && defaultValue !== 0) {
            return EMPTY;
         }
      }

      current = Number(current).toFixed(2);
      current = current.toString().replace('.', ',');
      return current;
   }

   /**
    *
    * @param current : numero o stringa da trasformare
    * Metodo per trasformare una stringa in un numero formatto come segue:
    * 1. punto come separatore dei decimali
    * 2. torna 0 vuota se valore vuoto o null
    */
   _toNumber(current, returnValue?) {
      returnValue = returnValue ? returnValue : ZERO;
      if (current === null || current.toString() === EMPTY) {
         current = returnValue;
      }
      current = current.toString().replace(',', '.');
      return Number(current);
   }

   /**
    *
    * @param name: nome del campo
    * @return boolean
    * Valuta se un campo è vuoto.
    * Da utilizzare con i campi di tipo numerico
    */
   _numericoVuoto(val): boolean {
      // console.log('campo vuoto '+this.canoneForm.get(name).value);
      return this._toNumber(val) === null || val === ZERO || this._toString(val) === EMPTY;
   }

   /**
    * Funzione che permette il download del pdf
    */
   openPdf(id) {
      const data = {
         token: this.gestioneLoginService.getToken(),
         action: 'pdf',
         where: {
            atti_amministrativi_id: id
         }
      };

      const url = this.utils.creaURL('atti_amministrativi', 'pdf');
      return this.utils.postRequest(url, data);
   }


}
