<!-- elenco dei risultati -->
<ng-template #rt let-r="result" let-t="term">
   <ngb-highlight [result]="r.term" [term]="t.split(' ')"></ngb-highlight>
</ng-template>
<!-- fine elenco dei risultati -->


<div class="input-group mb-2">

   <input type="text" class="form-control"
      [id]="currentName"
      [name]="currentName"
      [(ngModel)]="_model"
      (selectItem)="selectedItem($event)"
      [ngbTypeahead]="search"
      [resultTemplate]="rt"
      [inputFormatter]="formatter"
      [ngbTooltip]="current_tips"
      autocomplete="off"
      type="search"
      placeholder="Comincia a scrivere per visualizzare i risultati"
      #instance="ngbTypeahead" />
   <div class="input-group-prepend">
      <div class="input-group-text" ngbTooltip="Cancella" (click)="_resetInput()">
         <fa-icon [icon]="faTimes" class="pr-1"></fa-icon>
      </div>
   </div>
   <!-- Model: {{ model | json }} -->
</div>
