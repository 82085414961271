import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class PatientMapDrawGeometryService {
  constructor() {}

  // onAddArea(areaInfo: string, areaType: string, userId: string) {
  //   const area = {};
  //   this.mapComponent.gestisciToolDisegno(area, areaInfo, areaType, true);
  // }
}
