import { Component, Output, EventEmitter, Input } from '@angular/core';
import { faTimesCircle, faInfoCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Feature } from 'ol';

/**
 * Componente per la selezione degli oggetti sulla mappa
 */
@Component({
   selector: 'ebw-selezione-oggetti',
   templateUrl: './selezione-oggetti.component.html',
   styleUrls: ['./selezione-oggetti.component.scss']
})
export class SelezioneOggettiComponent{
   /***********LASCIARE COSI' o PRENDERLI IN INPUT**************/
   faTimesCircle = faTimesCircle;
   faInfoCircle = faInfoCircle;
   faEdit = faEdit;
   /***********LASCIARE COSI' o PRENDERLI IN INPUT**************/
   /**
    * Array di oggetti selezionati
    */
   @Input() oggettiSelezionati: Feature[];
   /**
    * Emettitore dell'evento di click sul bottone di chiusura di un oggetto selezionato
    */
   @Output() chiusuraOggettoSelezionato: EventEmitter<any> = new EventEmitter();
   /**
    * Emettitore dell'evento di click sul bottone di modifica di un oggetto selezionato
    */
   @Output() modificaOggettoSelezionato: EventEmitter<any> = new EventEmitter();
   /**
    * Emettitore dell'evento di click sul bottone di info di un oggetto selezionato
    */
   @Output() visualizzaInfoOggettoSelezionato: EventEmitter<any> = new EventEmitter();
   /**
    * Emettitore per la rimozione di un oggetto selezionato
    */
   @Output() rimuoviOggettoSelezionato: EventEmitter<Feature> = new EventEmitter();
   /**
    * Costruttore per il componente di selezione
    * @param servizioMappa [description]
    * @param http          [description]
    */
   constructor() {}
   /**
    * Funzione per rimuovere un oggetto dalla selezione
    * @param oggetto feature da rimuovere
    */
   closeSelectedObject(oggetto: Feature) {
      this.rimuoviOggettoSelezionato.emit(oggetto);
      this.chiusuraOggettoSelezionato.emit(oggetto);
   }
   /**
    * Funzione per modificare le informazioni un oggetto selezionato
    * @param oggetto feature da modificare
    */
   modifySelectedObject(oggetto: Feature) {
      this.modificaOggettoSelezionato.emit(oggetto);
   }
   /**
    * Funzione per visualizzare le informazioni un oggetto selezionato
    * @param oggetto feature da visualizzare
    */
   viewInfoSelectedObject(oggetto: Feature) {
      this.visualizzaInfoOggettoSelezionato.emit(oggetto);
   }
}
