<div class="container-fluid p-1 bg-white border border-secondary rounded shadow" style="opacity: 0.7;">
   <div class="row no-gutters">
      <div class="col-1 text-center">
         <fa-icon [icon]="faMousePointer"></fa-icon>
      </div>
      <div class="col">
         <span>LON: <b>{{_coordinateCorrenti[0]}}</b></span>
      </div>
      <div class="col">
         <span>LAT: <b>{{_coordinateCorrenti[1]}}</b></span>
      </div>
   </div>
   <div class="row">
      <div class="col text-center">
         <span>Scala: <b>{{_risoluzioneCorrente}}</b></span>
      </div>
   </div>
</div>
