import { RouterService } from './../../servizi/router.service';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { MapService } from '../../library/ebw-map-angular/service/map.service';
import { EbwModalService } from 'src/app/library/ebw-modals-angular/ebw-modal.service';
import { GestioneAttiAmmService } from 'src/app/servizi/atti-amministrativi/gestione-atti-amm.service';
import { GestioneLoginService } from 'src/app/servizi/login/gestione-login.service';
import { UtenteAttivoService } from 'src/app/servizi/utente-attivo/utente-attivo.service';
import { UtilsService } from 'src/app/servizi/utils.service';
import { MappaComponent } from '../../mappa/mappa.component';
import Funzionalita from 'src/app/servizi/EBWIdFunzionalita';
import { RaffinaDatiService } from 'src/app/servizi/raffina-dati.service';

/** Componente che rappresenta il menù principale dell'applicativo */
@Component({
   selector: 'app-menu',
   templateUrl: './menu.component.html',
   styleUrls: ['./menu.component.scss'],
   providers: [NgbModal, MappaComponent]
})

export class MenuComponent implements OnInit {

   ricercaAttiParticelle: { id: string, descrizione: string, tipo: string }[] = [];

   /** icone FA */
   icons: any = {
      faSearch
   };

   constructor(private modal: NgbModal,
      private http: HttpClient,
      private loginService: GestioneLoginService,
      private utenteAttivoService: UtenteAttivoService,
      private gestioneAttiAmmService: GestioneAttiAmmService,
      private ebwModalService: EbwModalService,
      private servizioMappa: MapService,
      private utilService: UtilsService,
      private routerSrv: RouterService,
      private mappaComponent: MappaComponent,
      private raffinaDatiService: RaffinaDatiService,
    ) { }


   /** Array delle voci di menù visibili */
   menuItems = [
      'active',
      'mappa',
      'login',
      'registrazione',
      'pratiche',
      'soggetti',
      'beni_demaniali',
      'gestione_atti_amministrativi',
      'operazioni_atti_amministrativi_test',
      // 'variazione_soggetto_fisico',
      // 'variazione_soggetto_giuridico',
      'variazione_soggetto_test',
      'pratiche_import_export',
      'pratiche_soggetto',
      'consultazione_f24',
      'decreti',
      'catasto',
      'manufatti',
      'esenzione',
      'decreti',
      'portale_ordinanze',
      'download_manuali',
      'tutorial',
      'segnalazioni_Agea',
      'ricerca_manufatti',
      'dori',
      'demanio',
      'storage',
      'portale_amministrativo',
      'gestione_riserve'
   ];


   ngOnInit() {
      // this.raffinaDatiService.initAreeEDecodifiche();
   }

   attiParticelleFormatter = (x: any) => x.descrizione;
   attiParticelleSearch = (text$: Observable<string>) => {
      return text$.pipe(
         debounceTime(200),
         distinctUntilChanged(),
         switchMap((term): Observable<any> => {
            if (term === '') {
               return new Observable(ob => {
                  ob.next({
                     error: 1
                  });
                  ob.complete();
               });
            } else {
               console.log("TERM: ", term);
               const params = {
                  requests: [
                     {
                        core: 'particelle_demaniali',
                        term: term,
                        rows: '5'
                     }
                  ]
               };
               return this.utilService.postRequest(this.utilService.creaURL('autocomplete', 'ricerca_home_page'), params);
            }
         }),
         map(response => {
            if (response.error == 0 && response.data.length > 0) {
               const elenco_ricerca = response.data;
               this.ricercaAttiParticelle = [];
               elenco_ricerca.forEach(element => {
                  if (element.core == 'atti_amministrativi') {
                     this.ricercaAttiParticelle.push({
                        id: element.id,
                        descrizione: element.term,
                        tipo: 'atto'
                     })
                  }
                  if (element.core == 'particelle_demaniali') {
                     this.ricercaAttiParticelle.push({
                        id: element.id,
                        descrizione: element.term,
                        tipo: 'plla'
                     })
                  }
               });
               return this.ricercaAttiParticelle;
            } else {
               return [];
            }
         })
      );
   }

   /**
    * Validatore campo capitaneria di porto . Controlla che sia stata selezionata dall'elenco.
    */
   attiParticelleValidator(value): any {
      if (this.ricercaAttiParticelle && value != null && value !== '') {
         const finded = this.ricercaAttiParticelle.find(
            (e) => this.attiParticelleFormatter(e) == value
         );
         if (finded === undefined) {
            return null;
         } else {
            return finded;
         }
      }
      return null;
   }

   onDoSelectionAutocomplete(item) {
      console.log('onDoSelectionAutocomplete', item);
   }

   /**
    * Funzione utilizzata per verificare se una voce di menu'
    * deve essere visualizzata oppure no
    * @param {string} wich valore da controllare
    */
   public isMenuVisible(which: string): boolean {
      switch (which) {
         case 'home':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale() || this.utenteAttivoService.isCittadino());
         case 'demanio':
            return this.isMenuVisible('esenzione_inserimento_dati') || this.isMenuVisible('docol') || this.isMenuVisible('pratiche') || this.isMenuVisible('territorio');
         case 'esenzione_inserimento_dati':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.ESENZIONE_INSERIMENTO_DATI);
         case 'dori':
            return ((this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale()) || this.utenteAttivoService.isCittadino())
               && this.utenteAttivoService.isAbilitato(Funzionalita.DORI);
         case 'docol':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.DOCOL);
         case 'pratiche':
            return this.isMenuVisible('gestione_atti_amministrativi') || this.isMenuVisible('pratiche_import_export') || this.isMenuVisible('pratiche_non_gestite');
         case 'gestione_atti_amministrativi':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.PRATICHE.GESTIONE_ATTI_AMMINISTRATIVI);
         case 'pratiche_import_export':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.PRATICHE.IMPORT_EXPORT);
         case 'pratiche_non_gestite':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.PRATICHE.PRATICHE_NON_GESTITE);
         case 'territorio':
            return this.isMenuVisible('gestione_disegni');
         case 'gestione_disegni':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.DEMANIO.TERRITORIO_GESTIONE_DISEGNI);
         case 'bene_demaniale':
            return this.isMenuVisible('decreti') || this.isMenuVisible('consulta_catasto') || this.isMenuVisible('manufatti');
         case 'decreti':
            return this.isMenuVisible('gestione_decreti') || this.isMenuVisible('nuovo_decreto');
         case 'gestione_decreti':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.DEMANIO.DECRETI.DECRETI);
         case 'nuovo_decreto':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.DEMANIO.DECRETI.NUOVO);
         case 'consulta_catasto':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.DEMANIO.CONSULTA_CATASTO);
         case 'manufatti':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.DEMANIO.MANUFATTI);
         case 'spazi_marittimi':
            return this.isMenuVisible('portale_ordinanze_marittime');
         case 'portale_ordinanze_marittime':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.PORTALE_ORDINANZE);
         case 'segnalazioni_agea':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.AMMINISTRAZIONE_SISTEMA.SEGNALAZIONI_AGEA);
         case 'caricamento_pratiche':
            return this.isMenuVisible('docol') || this.isMenuVisible('invio_pratiche') || this.isMenuVisible('import_file');
         case 'invio_pratiche':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.PRATICHE.INVIO_FILE);
         case 'import_file':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.PRATICHE.IMPORT_FILE);
         case 'gestione_riserve':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.TERRITORIO.GESTIONE_RISERVE);
         case 'storage':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale() || this.utenteAttivoService.isCittadino())
               && this.utenteAttivoService.isAbilitato(Funzionalita.STORAGE);
         case 'download_manuali':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale() || this.utenteAttivoService.isCittadino())
               && this.utenteAttivoService.isAbilitato(Funzionalita.DOWNLOAD.APPLICATIVI_MANUALI);
         case 'tutorial':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.TUTORIAL);
         case 'portale_amministrativo':
            return (this.utenteAttivoService.isCapitaneriaDiPorto() || this.utenteAttivoService.isCentroOperativoNazionale() || this.utenteAttivoService.isAutoritaPortuale())
               && this.utenteAttivoService.isAbilitato(Funzionalita.PORTALE_AMMINISTRATIVO.PORTALE_AMMINISTRATIVO);
         default:
            return false;
      }
   }

   selectedItem(event) {
      // Posizionamento pratica su mappa
      const finded = this.attiParticelleValidator(event.item.descrizione);
      console.log("FINDED2", finded)
      if (finded) {
         const tipo = finded.tipo;
         const id = finded.id;
         const descrizione = finded.descrizione
         console.log("FINDED2 -TIPO ", tipo)
         if (tipo == 'atto') {
            this.gestioneAttiAmmService
               .getGeometrieAtto(id)
               .subscribe(
                  (data: any) => {
                     // disabilito tutti i layer appartenenti al gruppo sid-catasto
                     this.servizioMappa.layers.forEach(layer => {
                        if (layer.title == 'SID-Catasto') {
                           layer.visibility = false;
                        }
                     });
                     this.servizioMappa.layers.find(el => el.layer == 'Stato d\'uso').visibility = true;
                     this.gestioneAttiAmmService.currentAttoAmministrativo = data.data;

                     // Dati dell'atto
                     const datiAtto = this.gestioneAttiAmmService.currentAttoAmministrativo[0];

                     // Geometria
                     const geom = !!datiAtto.geometria_ingombro
                        ? datiAtto.geometria_ingombro
                        : datiAtto.geometria_simbolo;

                     if (!!geom) {
                        const mapData = {
                           mapAction: 'fit', //  Per Centrare la mappa sulla geometria
                           geom, // La mappa farà il fit sulla geometria dell'atto
                           controlliMappaAttivi: true,
                           descrizioneRicerca: descrizione
                        };

                        // Chiusura pagina ricerca apertura Mappa
                        this.routerSrv.navigateToChild('mappa', mapData);

                     } else {
                        this.ebwModalService.openWarningModal({
                           title: 'Attenzione',
                           content: 'Geometria mancante',
                        });
                     }
                  },
                  (error) => console.log(error)
               );
         } else {
            // Altrimenti è una particella
            // disabilito tutti i layer appartenenti al gruppo sid-catasto
            this.servizioMappa.layers.forEach(layer => {
               if (layer.title == 'SID-Catasto') {
                  layer.visibility = false;
               }
            });
            this.servizioMappa.layers.find(el => el.layer == 'Demaniale').visibility = true;
            const descrizione = finded.descrizione;
            console.log("FINDED2 -descr", finded, descrizione)
            const b = descrizione.split('/');
            const req = {
               comune: b[0],
               sezione: b[1],
               foglio: b[2],
               numero: b[3]
            };
            const url = this.utilService.creaURL('catasto', 'info_geometriche_particella');
            this.utilService.postRequest(url, req).subscribe((resp: any) => {
               console.log(resp);
               // Geometria
               if (resp.data && resp.data.length > 0) {
                  const mapData = {
                     mapAction: 'fit', //  Per Centrare la mappa sulla geometria
                     geom: resp.data[0].geom, // La mappa farà il fit sulla geometria dell'atto
                     controlliMappaAttivi: true,
                     descrizioneRicerca: descrizione
                  };

                  // Chiusura pagina ricerca apertura Mappa
                  this.routerSrv.navigateToChild('mappa', mapData);

               } else {
                  this.ebwModalService.openWarningModal({
                     title: 'Attenzione',
                     content: 'Geometria mancante',
                  });
               }
            });
         }
      } else {
         this.ebwModalService.openWarningModal({
            title: 'Attenzione',
            content: 'Atto o Particella non trovata!',
         });
      }
   }

   public onCercaItemClick(input: HTMLInputElement): void {
      const finded = this.attiParticelleValidator(input.value);
      console.log("FINDED1", finded)
      if (finded) {
         const a = finded.id.split('-');
         const tipo = a[0];
         const id = a[1];
         console.log("FINDED1 -TIPO", tipo)
        //  const report = this.modal.open(ReportCatastoComponent, {
        //     ariaLabelledBy: 'modal-basic-title',
        //     centered: true,
        //     windowClass: 'modalFull95'
        //  });
         console.log("FINDED2- descr", finded.descrizione)
         const descrizione = finded.descrizione.split(')')[0].split('(');
         const b = descrizione[1].split('/');
         const req = {
            codice_amministrativo: b[0],
            sezione: b[1],
            foglio: b[2],
            numero: b[3],
         };
        //  report.componentInstance.particellaParams = req;


      } else {
         this.ebwModalService.openWarningModal({
            title: 'Attenzione',
            content: 'Atto o Particella non trovata!',
         });
      }
   }

   /** Gestisce il click per aprire le segnalazioni agea */
   public onSegnalazioniAgeaClick(): void {
      window.open('https://www.sid.mit.gov.it/segnalazioni/index3.php?token=' + this.loginService.getToken());
   }

   /** Gestisce il click per aprire il portale amministrativo */
   public onPortaleAmministrativoClick(): void {
      if (this.utenteAttivoService.isAbilitato(Funzionalita.PORTALE_AMMINISTRATIVO.PORTALE_AMMINISTRATIVO)) {
         window.open(`${location.origin}amministrazione?token=${this.loginService.getToken()}`);
      }
   }
}
