import { Injectable, OnDestroy } from "@angular/core";
import { GestioneLoginService } from "./login/gestione-login.service";
import { Router } from "@angular/router";
// import { Subscription } from 'rxjs';
// import { EBWApiResponse, PayloadUtente } from '../modelli/api.response.model';
import { UtenteAttivoService } from "./utente-attivo/utente-attivo.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements OnDestroy {
  // authServiceSubscription = new Subscription();

  // canActivateSubscription = new Subscription();

  constructor(
    public authService: GestioneLoginService,
    private utenteAttivoService: UtenteAttivoService,
    public router: Router
  ) {}

  canActivate(): any {
    return new Promise((resolve, reject) => {
      // this.authServiceSubscription = this.authService.verifyJWTTest().subscribe(
      //    (data) => {
      //       if (localStorage.getItem('jwt')) {
      //          // decodifico il token generato
      //          this.authService.decodeJWT()
      //             .subscribe((resp: EBWApiResponse) => {
      //                //  this.utenteAttivoService.initUtenteAttivo();
      //                if (resp.error === 0 && resp.status === 0) {
      //                   // prelevo il payload
      //                   console.warn('PAYLOAD', resp.data.payload);
      //                   const payload = resp.data.payload.user;

      //                   if (atob(localStorage.getItem('payload')) !== 'undefined' && atob(localStorage.getItem('payload')) !== '') {

      //                     if (!!((JSON.parse(atob(localStorage.getItem('payload'))) as PayloadUtente).impersonificato_da)){
      //                       const impersonifiedByUser: PayloadUtente = (JSON.parse(atob(localStorage.getItem('payload'))) as PayloadUtente).impersonificato_da;
      //                       // imposto il campo impersonificato da
      //                       payload.impersonificato_da = impersonifiedByUser;
      //                     }
      //                   }

      //                   // salvo il payload nel servizio
      //                   this.authService.setPayload(payload);
      //                   // segnalo l'avvenuto login
      //                   // this.authService.segnalaLogin(payload);
      //                   this.utenteAttivoService.userInfo = payload;
      //                   this.utenteAttivoService.initFunzionalitaAbilitate();

      //                   resolve(true);
      //                } else {
      //                   // errore nel recuperare il payload
      //                   console.warn(resp);

      //                   this.router.navigate(['login']);
      //                   resolve(false);
      //                }
      //             });
      //       } else {
      //          resolve(true);
      //       }
      //    },
      //    error => {
      //       this.router.navigate(['login']);
      //       resolve(false);
      //    }
      // );
      if (this.authService.isLoggedIn() && !this.authService.isOnMobile()) {
        resolve(true);
      } else {
        this.router.navigate(["login"]);
        resolve(false);
      }
    });
  }

  ngOnDestroy() {
    // this.authServiceSubscription.unsubscribe();
  }
}
