import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EbwToastContainerComponent } from './ebw-toast-container/ebw-toast-container.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EbwToastService } from './ebw-toast.service';

/** Modulo che gestisce i messaggi toast nell'applicazione */
@NgModule({
   declarations: [EbwToastContainerComponent],
   imports: [CommonModule, NgbModule],
   providers: [EbwToastService],
   exports: [EbwToastContainerComponent]
})
export class EbwToastModule { }
