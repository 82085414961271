<div class="px-3 h-75vh scroll-custom">
   <h5 class="p-15 txt-bg-blu">Ricerca Geometrica</h5>

   <div class="row mt-5">
      <div class="col">

     <!-- TODO correggi 'BROWSE' -->

         <div class="form-group">


            <!-- <label for="file">Carica File CSV</label>
            <input class="form-control-file"
                  type="file"
                  accept=".csv"
                  id="file"   
                  [disabled]="toolDisegnoOpen" 
                  
                  (change)="onLoad($event)"> -->

            <div class="custom-file">
               <input 
               type="file" 
               accept=".csv"
               class="custom-file-input form-control-file" 
               id="validatedCustomFile"
               [disabled]="toolDisegnoOpen" 
               (change)="onLoad($event)"
               #inputFile
               >
               <label class="custom-file-label" for="validatedCustomFile">Carica File CSV</label>
               
            </div>      
         </div>
      </div>
   </div>
  

   <div *ngIf="geomObjs && geomObjs.length > 0">
      <h6 class="bg-light border border-secondary text-secondary text-center py-1 mb-3 mt-5">
        
         <span> Elenco geometrie </span>

      </h6>
      <div *ngFor="let obj of geomObjs;">
         <table class="table table-sm table-bordered">
           
            <tbody>
               <tr style = "height: 41px;">
                 
                  <td class = "pl-2 " style="width:100%">
                     {{ (obj.currentObj && obj.currentObj.nome != '') ? obj.currentObj.nome : 'Geometria senza nome' }}
                  </td> 
                 <!-- TODO (NASCOSTO) -->
                  <td style = "display:none">     
                     <button 
                        class="btn btn-orange" 
                        ngbTooltip="Modifica geometria" 
                        (click)="callToolDisegnoEdit(obj)" 
                      
                        [disabled]="toolDisegnoOpen ? true : false"> 

                        <!-- TEST -->

                        <fa-icon [icon]="icons.faMapMarkedAlt"                       
                        ></fa-icon>
                     </button>
                  </td>  
                  <td>
                     <button 
                        class="btn btn-bg-outline ml-1" 
                        ngbTooltip="Ricerca Particelle" 
                        (click)="onSearch('particelle', obj.geom)"
                        [disabled]="toolDisegnoOpen ? true : false">

                        <!-- TEST -->
                        <fa-icon [icon]="icons.faSearch"></fa-icon>
                     </button>
                  </td> 
                  <td>
                     <button 
                        class="btn btn-bg-outline ml-1" 
                        ngbTooltip="Ricerca BTS" 
                        (click)="onSearch('bts', obj.geom)">
                        <fa-icon [icon]="icons.faBroadcastTower"></fa-icon>
                     </button>
                  </td>  
               </tr>               
            </tbody>
         </table>
      </div>
   </div>

   <!-- $event.target.files -->
   <!-- <div class="row mt-5">
      
      <div class="col text-center">
         <button type="button" class="btn btn-bg-outline m-1"
            (click)="onLoad()"
            [disabled]="toolDisegnoOpen"> 
            <fa-icon [icon]="icons.faFileUpload"></fa-icon>
            Carica Geometrie
         </button>

      </div>
   </div> -->


   <div class="row mt-4">
      <div class="col-2 text-center ml-1">
         <a class="btn btn-light btn-design-tool p-1 ml-3" (click)="callToolDisegno()">
            <fa-icon aria-hidden="true" [id]="'action_design_tool'" class="actionButtons" [title]="'Apri Tool di Disegno'" [icon]="icons.faMapMarkedAlt" size="2x"></fa-icon>
         </a>
      </div>

      <!-- 
      <div *ngIf="!geometriaIsChanged; else elseBlock" class="col text-left d-flex align-items-center">
         <span class="align-middle"> <label style="font-size: 14px; font-weight: bold;">Inserisci geometria</label> </span>
      </div>
      <ng-template #elseBlock>
         <div class="col text-left d-flex align-items-center">
            <span class="align-middle">
               <label style="font-size: 14px; font-weight: bold;">Geometria acquisita</label>
               <fa-icon aria-hidden="true" class="text-success ml-3" [icon]="icons.faCheck" size="2x"></fa-icon>
            </span>
         </div>
      </ng-template> 
      -->

      <div class="col text-left d-flex align-items-center">
         <span class="align-middle"> <label style="font-size: 14px; font-weight: bold;">Inserisci geometria</label> </span>
      </div>
   </div>

   <div class="row mt-5">
      
      <div class="col text-center">
         <button type="button" class="btn btn-bg-outline m-1"
            (click)="onReset()"
            [disabled]="!geomObjs || geomObjs.length == 0 || toolDisegnoOpen"> 
            <fa-icon [icon]="icons.faCircleNotch"></fa-icon>
            Reset
         </button>

      </div>
      <!-- <div class="col text-center">
         <button type="button" class="btn bg-blu txt-white m-1"
            (click)="onSearch('particelle')"
            [disabled]="!geometriaIsChanged || toolDisegnoOpen"> 
            <fa-icon [icon]="icons.faSearch"></fa-icon>
            Ricerca
         </button>

      </div>

      <div class="col text-center">
         <button type="button" class="btn bg-blu txt-white m-1"
            (click)="onSearch('bts')"
            [disabled]="!geometriaIsChanged || toolDisegnoOpen"> 
            <fa-icon [icon]="icons.faSearch"></fa-icon>
            Ricerca BTS
         </button> 

      </div>-->
   </div> 


   <div *ngIf="resultList && resultList.length > 0">
      <h6 class="bg-light border border-secondary text-secondary text-center py-1 mb-3 mt-5">
         <span *ngIf="searchType == 'particelle'"> Identificativi particelle recuperate </span>
         <span *ngIf="searchType == 'bts'"> Identificativi BTS recuperate </span>

         <button class="btn btn-bg-outline ml-2" ngbTooltip="Copia tutto" (click)="popolaInput('all')">
            <fa-icon [icon]="icons.faCopy"></fa-icon>
         </button>

      </h6>
      <div *ngFor="let el of resultList;">
         <table class="table table-sm table-hover table-bordered">
            <tbody *ngIf="searchType == 'particelle'">
               <tr ngbTooltip="Copia" style="cursor:pointer; height: 41px;" (click)="popolaInput('single', el.properties.name)">
                  <td style="width:100%" class="pl-2 partToCopy">{{ el.properties.name }}</td>
                  <td>

                  
                     <button *ngIf="searchType == 'particelle'"
                        class="btn  btn-bg-outline" 
                        ngbTooltip="Visualizza Report" 
                        (click)="viewReport(el)"
                       
                        [disabled]=false>
                        <fa-icon [icon]="icons.faArchive"                       
                        ></fa-icon>
                     </button>
                  </td>  
               </tr>               
            </tbody>
            <tbody *ngIf="searchType == 'bts'">
               <tr ngbTooltip="Copia" style="cursor:pointer" (click)="popolaInput('single', el.properties.name)">
                  <td style="width:100%" class="partToCopy">{{ el.properties.name }}</td>
               </tr>               
            </tbody>
         </table>
      </div>
   </div>
</div>



