import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ControllerFormService } from 'src/app/servizi/controller-form.service';
import { Params } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
   // tslint:disable-next-line:component-selector
   selector: 'shr-tabs',
   templateUrl: './shr-tabs.component.html',
   styleUrls: ['./shr-tabs.component.css']
})
/**
 * Component che lavora con un json di configurazione
 * che gli viene passato tra i parametri del component.
 * In particolare parametri @Input:
 * - tabs:
 *     "tabs":[
            { "name" : "Ricerca per dati catastali", "key": "D", "related": "filtri-catasto"},
            { "name" : "Ricerca temporale", "key": "T", "related": "filtri-temporali"}
         ]
 *  name -> Nome mostrato sul tab
 *  key -> chiave utilizzata come id del tab
 *  related -> indica il div html da attivare
 *
 * - default_tab: indica quale tab è attivo all'avvio della pagina
 * - current_page: è il nome della pagina che utilizza il componente
 *
 * La pagina che lo utilizza può stare in ascolto dell'evento
 * descritto sotto dove eventiService è ControllerEventiService
 * (vedi per es filtri-decreti-components)
 *     this.tabChange$ = this.eventiService.tabChange$.subscribe(
      (data) => {
         console.log('TRab selected ' + data['selected'] );
       }
   );
 */
export class ShrTabsComponent implements OnInit, OnDestroy {

   // tslint:disable-next-line:no-input-rename
   @Input() default_tab: string;
   @Input() tabs: string;
   @Input() current_page: string;

   SelectedTab: string;
   //  tabChangeSubscription = new Subscription();
   systemTabChangeSubscription = new Subscription();

   constructor(private controllerFormService: ControllerFormService) {
   }

   ngOnInit() {
      /*
    this.tabChangeSubscription = this.route.params.subscribe(
       (params: Params) => {
          //this.current_page = params['pagina'];
         // this._onCickTab(this.default_tab);
       }
    );*/
      /** Questo sotto viene chiamato se fosse necessario cambiare il tab da codice */
      this.systemTabChangeSubscription = this.controllerFormService.systemTabChange$.subscribe(
         (params: Params) => {
            this.current_page = params['pagina'];
            this._onCickTab(params['selected_tab']);
         }
      );
   }
   /**
    * Sul click
    */
   _onCickTab(selected: string) {
      const data = { 'page': this.current_page, 'selected': selected };
      //console.log('click tab----'+ JSON.stringify(data));
      this.controllerFormService.tabChange$.next(data);
      this.SelectedTab = selected;
   }

   /**
    * Restituisce la chiave del tab selezionato
    */
   _getSelectedTab() {
      if (this.SelectedTab == null) { return this.default_tab; }
      return this.SelectedTab;
   }

   _getCurrentPage() {
      return this.current_page;
   }

   ngOnDestroy(): void {
      // this.tabChangeSubscription.unsubscribe();
      this.systemTabChangeSubscription.unsubscribe();
   }
}
