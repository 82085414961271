<div>
  <div ngbDropdown placement="top-left" class="d-inline-block">
    <button class="btn bg-blu txt-white" id="dropdown" ngbDropdownToggle>
      <fa-icon [icon]="faMap"></fa-icon
      ><span class="px-2">{{ _titoloLayerAttivo }}</span>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdown">
      <button
        *ngFor="let layer of _layerSfondo"
        ngbDropdownItem
        (click)="cambiaLayerSfondo(layer)"
        class="btn"
      >
        <fa-icon [icon]="faMap"></fa-icon
        ><span class="px-2">{{ layer.get(titoloLayerKey) }}</span>
      </button>
    </div>
  </div>
</div>
