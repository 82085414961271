import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateDbFieldsPipe } from "../servizi/pipes/translate-db-fields.pipe";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [],
  exports: [
    TranslateDbFieldsPipe,
    NgbModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
  ],
  declarations: [TranslateDbFieldsPipe],
  providers: [],
})
export class SharedModule {}
