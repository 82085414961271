import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { faTimes, faExclamationTriangle, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { CustomValidators } from 'src/app/condivisa/custom-validators';
import { GeneraPDFSezione } from '../genera-pdf.model';

/** Modale che permette all'utente di scegliere quali parti del report esportare */
@Component({
   selector: 'app-genera-pdf',
   templateUrl: './genera-pdf.component.html',
   encapsulation: ViewEncapsulation.None,
   styleUrls: ['./genera-pdf.component.css']
})
export class GeneraPDFComponent implements OnInit, OnDestroy {

   /** icona errore */
   faTimes = faTimes;

   /** icona warning */
   faExclamationTriangle = faExclamationTriangle;

   /** icona success */
   faCheck = faCheck;

   /** Messaggio da mostrare nel footer della modale */
   messaggioFooter: string = '';

   /** JSON che regola la visualizzazione dei messaggi nel footer della modale */
   controllerMessaggioFooter = {
      error: false,
      warning: false,
      success: false,
   };

   /** Form dei campi di ricerca */
   sezioniForm: FormGroup;

   /** Sezioni selezionabili per l'utente */
   @Input() public sezioni: GeneraPDFSezione[] = [];

   /** Booleano per selezionare automaticamente tutte le sezioni */
   @Input() public selezionaTutto: boolean = false;

   /** Array che contiene le sottoscrizioni */
   subsArray: Subscription[] = [];

   constructor(
      public activeModal: NgbActiveModal,
      private logger: NGXLogger,
      private formBuilder: FormBuilder) { }

   /** ngOnInit */
   ngOnInit() {
      if (this.selezionaTutto) {
         this.sezioni.forEach(sezione => sezione.selezionato = true);
      }

      const group = {};
      this.sezioni.forEach(sezione => {
         group[sezione.chiave] = new FormControl(sezione.selezionato);
      });

      this.sezioniForm = this.formBuilder.group(group);
      this.sezioniForm.setValidators(CustomValidators.atLeastOneValidatorCheckbox);

      const formStatus$ = this.sezioniForm.statusChanges.subscribe((status) => {
         switch (status) {
            case 'VALID':
               this.controllerMessaggioFooter.error = false;
               this.controllerMessaggioFooter.warning = false;
               this.controllerMessaggioFooter.success = false;
               this.messaggioFooter = '';
               break;
            case 'INVALID':
               this.controllerMessaggioFooter.warning = true;
               this.messaggioFooter = this.sezioniForm.errors.almeno_uno.messaggio;
               break;
         }
      });

      const formValues$ = this.sezioniForm.valueChanges.subscribe((value) => {
         this.sezioni.forEach((sezione) => {
            sezione.selezionato = value[sezione.chiave];
         });
         // this.logger.info(value);
      });

      this.subsArray.push(formValues$);
      this.subsArray.push(formStatus$);
   }

   /** ngOnDestroy */
   ngOnDestroy(): void {
      /** rimuovo tutte le sottoscrizioni */
      this.subsArray.forEach((sub) => sub.unsubscribe());
   }

   /** Funzione richiamata alla conferma di creazione del report */
   confermaSelezione() {
      this.activeModal.close(this.sezioni);
   }

}
