
import { RouterService } from './servizi/router.service';
import { LoaderInterceptor } from './interceptor/loading.interceptor.service';
import { LoaderService } from './interceptor/loader.service';

// moduli
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { MapModule } from './library/ebw-map-angular/map.module';
import { EbwModalModule } from './library/ebw-modals-angular/ebw-modal.module';
import { EbwToastModule } from './library/ebw-toast-angular/ebw-toast.module';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

// componenti
import { AppComponent } from './app.component';
// home
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './home/menu/menu.component';
import { HeaderComponent } from './home/header/header.component';
// login
import { LoginComponent } from './login/login.component';
import { RegistrazioneComponent } from './login/registrazione/registrazione.component';
import { SimulazioneUtenteComponent } from './login/simulazione-utente/simulazione-utente.component';
import { RecuperoPasswordComponent } from './login/recupero-password/recupero-password.component';


import { MappaComponent } from './mappa/mappa.component';
import { NullToZeroPipe } from './condivisa/null-to-zero.pipe';
import { NullToEmptyStringPipe } from './condivisa/null-to-empty-string.pipe';
import { FilterPipe } from './condivisa/filter.pipe';

// servizi
import { HttpClient } from '@angular/common/http';
import { RenderEngineService } from 'src/app/servizi/render-engine.service';
import { ConfigService } from 'src/app/servizi/config/config.service';
import { GestioneLoginService } from './servizi/login/gestione-login.service';
import { MappaService } from './servizi/mappa/mappa.service';
import { ShrTabsComponent } from './condivisa/shr-tabs/shr-tabs.component';

import { NotificheComponent } from './home/header/notifiche/notifiche.component';
import { ControllerMessaggiService } from './servizi/controller-messaggi.service';
import { RaffinaDatiService } from './servizi/raffina-dati.service';
import { UtilsService } from './servizi/utils.service';
// import { VerificaAbilitazioneService } from './servizi/verifica-abilitazione.service';
// import { HubFormComponent } from './hub-form/hub-form.component';
import { UtenteAttivoService } from './servizi/utente-attivo/utente-attivo.service';

/** Ebw npm  */
import { GeneraPDFModule } from './genera-pdf/genera-pdf.module';
import { ShrComponentsModule } from './condivisa/shr-components.module';


import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';


// import { CommonModule } from '@angular/common';

// import { LoaderComponent } from './loader/loader.component';
// import { RiconsegnaComponent } from './mappa/sidebar/consegna/riconsegna/riconsegna.component';

import { FilterActivePipe } from './servizi/pipes/filter-active.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PatientDataService } from './servizi/patient/patient-data.service';
import { PatientMapShowGeometryService } from './servizi/patient/patient-map-show-geometry.service';
import { PatientMapDrawGeometryService } from './servizi/patient/patient-map-draw-geometry.service';
import { PatientDataLoaderService } from './servizi/patient/patient-data-loader.service';
import { DoNotShowSidebarOnRefreshGuard } from './servizi/patient/do-not-show-sidebar-on-refresh.guard';
import { SharedModule } from './shared/shared.module';
import { PrivacyComponent } from './login/privacy/privacy.component';
// import { AngularDraggableModule } from 'angular2-draggable';


/** NgModule */
@NgModule({
   declarations: [
      AppComponent,
      RegistrazioneComponent,
      HomeComponent,
      SimulazioneUtenteComponent,

      MappaComponent,
      LoginComponent,
      MenuComponent,
      ShrTabsComponent,
      RecuperoPasswordComponent,
      NullToZeroPipe,
      NullToEmptyStringPipe,
      FilterPipe,
      HeaderComponent,
      NotificheComponent,
      FilterActivePipe,
      PrivacyComponent
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      SharedModule,
      AppRoutingModule,
      HttpClientModule,
      ReactiveFormsModule,

      ShrComponentsModule,
      EbwModalModule,
      EbwToastModule,
      GeneraPDFModule,
      MapModule.forRoot({ apiKey: 'AIzaSyCdyezcZaV1ecwddhkmSNrOw1Z1FBBKArA', libraries: ['places'] }),
      LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, enableSourceMaps: true, serverLogLevel: NgxLoggerLevel.OFF, disableConsoleLogging: false }),

      NgxSpinnerModule

      // AngularDraggableModule
   ],
   providers: [
      HttpClient,
      GestioneLoginService,
      RenderEngineService,
      ConfigService,
      MappaService,
      ControllerMessaggiService,
      RaffinaDatiService,
      UtilsService, 
      // VerificaAbilitazioneService,
      UtenteAttivoService,
      NgbActiveModal,
      DatePipe,
      NgbDateNativeAdapter,
      LoaderService,
      RouterService,
      { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
      {
         provide: NgbDateAdapter,
         useClass: NgbDateNativeAdapter
      },
      PatientDataService,
      PatientMapShowGeometryService,
      PatientMapDrawGeometryService,
      PatientDataLoaderService,
      DoNotShowSidebarOnRefreshGuard,
      { provide: LOCALE_ID, useValue: "it-IT" }

   ],
   entryComponents: [

      // RecuperoPasswordComponent,

      // ReportCatastoComponent,

      // ReportCatastoFabbricatiComponent,
   ],
   bootstrap: [AppComponent],
   schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
   constructor(){
      registerLocaleData(localeIT);
   }
}
