<div class="container-fluid h-100 d-flex flex-column bg-light-custom">
  <div class="row bg-don-g justify-content-between p-3">
    <div class="col">
      <img src="./../../assets/img/logo_fdg_278_50.png"
           height="60"
           alt="Fondazione Don Gnocchi">
    </div>
    <div class="col text-right">
      <p class="text-don-g font-weight-bold">Assistenza alla mobilità outdoor dei pazienti</p>
    </div>
  </div>

  <div class="row d-flex flex-grow-1 align-items-center justify-content-center bg-don-g">
    <div class="col-10 col-sm-6 col-md-4">
      <div class="login-wrapper p-3 my-auto bg-white rounded shadow">

        <h4 class="mt-2 font-weight-bold">Accesso al sistema</h4>

        <form #f="ngForm"
              class="mx-2 mt-2 mb-2 txt-bg-blu">
          <div class="form-group">
            <label for="username"
                   class="text-muted">Codice Cliente</label>
            <input type="text"
                   #username
                   class="form-control border-right-0 border-top-0 border-left-0"
                   id="username"
                   ngModel
                   name="username"
                   required
                   placeholder="inserisci il codice"
                   (keyup.enter)="onKeyPressed($event)">
          </div>

          <!-- <div class="form-group">
                  <label for="password">Password</label>
                  <input type="password" class="form-control" id="password" ngModel name="password" required
                     placeholder="Password" (keypress)="onKeyPressed($event)">
               </div> -->

          <div *ngIf="loginError">
            <span class="badge badge-danger"> {{loginError}} </span>
          </div>

          <button [disabled]="!showButton"
                  type="button"
                  class="btn bg-primary text-white shadow d-block mx-auto"
                  (click)="onLogin()"
                  [disabled]="!f.valid">
            LOGIN
          </button>
          <hr>

          <div class="mt-3 text-center text-muted">
            Se non disponi di un codice registrati inviando una mail da
            <button type="button"
                    class="btn btn-link">
              <a [href]="emailString"
                 target="_blank"
                 rel="noopener noreferrer">qui</a>
            </button>
          </div>
          <hr>
          <div class="mt-0 text-center text-muted">
            Se ti serve l'app (Android), scaricala da qui:
            <a [href]="showButton? downloadAPKurl:''"
               [ngClass]="showButton ? null: 'disabled'"
               class="btn btn-outline-primary border-0 shadow font-weight-bold">
              Scarica FDGapp
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template>
  <div class="card col-10 col-sm-8 col-md-6">
    <div class="card-body">
      <h4 class="card-title font-weight-bold">Accesso al sistema</h4>
      <h5 class="card-subtitle m-3 font-weight-bold">Consenso al tratamento dei dati</h5>
      <section style="height:50vh; overflow: auto;">
        <h5>
          INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI DEI CANDIDATI
        </h5>
        <p>
          La presente informativa viene fornita ai sensi dell’art. 13 del D.lgs. n. 196 del 30 giugno 2003 “Codice in materia di protezione dei
          dati
          personali” (“Codice Privacy”) e successive modifiche, nonché in conformità alle disposizioni del Regolamento UE 2016/679 (“GDPR”) fatte
          naturalmente salve tutte le integrazioni e modifiche che potrebbero rendersi necessarie a seguito di interventi legislativi nazionali
          ed
          europei nonché all’emanazione di provvedimenti e linee guida da parte del Garante per la Protezione dei Dati Personali e altre autorità
          nazionali ed europee in campo privacy.
        </p>
        <h6>
          1. CONTATTI DEL TITOLARE DEL TRATTAMENTO

        </h6>
        <p>
          Titolare del trattamento dei dati personali è EBWorld S.r.l. – Strada degli Olmi 16/4 61122 Pesaro (PU). Il Titolare del trattamento
          potrà
          essere contattato via e-mail all’indirizzo info@ebw.it o telefonicamente al numero 0721 172 2210.
        </p>
        <h6>
          2. FINALITÀ DEL TRATTAMENTO E BASE GIURIDICA
        </h6>
        <p>
          I Suoi dati personali saranno trattati esclusivamente per permettere una fase di test delle funzionalità previste nel progetto
          sperimentale
          (POC) "Soluzione di assistenza alla mobilità outdoor dei pazienti". Il progetto sperimentale è stato commissionato da Fondazione Don
          Gnocchi.
          A
          seguito di Sua esplicita approvazione, i dati personali e, in particolare la posizione GPS rilevata tramite app installata sul suo
          smartphone,
          verranno elaborati e visualizzati su una postazione operatore. Lo scopo è di tracciare i suoi movimenti e le relative caratteristiche
          sul
          territorio nazionale durante l'arco della giornata. Questi dati saranno accessibili a lei e a tutti i soggetti che partecipano a questa
          fase
          di
          test, dopo aver ricevuto le credenziali necessarie.
        </p>
        <h6>
          3. MODALITÀ DEL TRATTAMENTO
        </h6>
        <p>
          Il trattamento dei dati personali avverrà mediante strumenti manuali, informatici o telematici, idonei comunque a garantirne la
          sicurezza
          e
          la
          riservatezza.
        </p>
        <h6>
          4. PERIODO DI CONSERVAZIONE
        </h6>
        <p>
          I dati verranno conservati per la durata del progetto e comunque per un periodo non superiore a sei mesi dalla registrazione sul
          sistema.
        </p>
        <h6>
          5. POSSIBILI DESTINATARI E CATEGORIE DI DESTINATARI DEI SUOI DATI PERSONALI
        </h6>
        <p>
          I dati potranno essere resi noti a dipendenti e collaboratori della Società, in qualità di Incaricati del trattamento, operano nel
          rispetto
          delle istruzioni impartite dalla Società. I Suoi dati personali potranno inoltre essere trattati da soggetti terzi, di cui la Società
          si
          avvale
          ai fini delle finalità indicate al punto 2. In ogni caso tali soggetti opereranno in qualità di Titolari del trattamento autonomi.

        </p>
      </section>
      <div class="card-footer d-flex justify-content-end">
        <a href="#"
           class="card-link font-weight-bold">RIFIUTO</a>
        <a href="#"
           class="card-link font-weight-bold">ACCETTO</a>
      </div>
    </div>
  </div>
</ng-template>