<div class="container">
   <div class="row">
      <div class="col my-5">

         <!-- titolo -->
         <h3 class="text-primary mb-3"> Simulazione Utente </h3>

         <!-- loading spinner -->
         <div *ngIf="!ready" class="text-center my-5 py-5">
            <div class="mb-2">
               <strong> Loading... </strong>
            </div>
            <div class="spinner-grow text-primary loading" role="status">
               <span class="sr-only">Loading...</span>
            </div>
         </div>

         <!-- tabella utenti -->
         <table *ngIf="ready" class="table table-sm table-hover mb-5 tabFiltered table-bordered">
            <thead class="bg-light">
               <tr>
                  <th scope="col">
                     <div class="d-flex justify-content-between">
                        Nome
                        <fa-icon class="pointer" [ngClass]="{sorted: sorted=='nome'}" (click)="sort('nome')"
                           [icon]="sorted=='nome' ? (sortDir==1 ? sortDn : sortUp) : sortNo"></fa-icon>
                     </div>
                  </th>
                  <th scope="col">
                     <div class="d-flex justify-content-between">
                        Cognome
                        <fa-icon class="pointer" [ngClass]="{sorted: sorted=='cognome'}" (click)="sort('cognome')"
                           [icon]="sorted=='cognome' ? (sortDir==1 ? sortDn : sortUp) : sortNo"></fa-icon>
                     </div>
                  </th>
                  <th scope="col">
                     <div class="d-flex justify-content-between">
                        Username
                        <fa-icon class="pointer" [ngClass]="{sorted: sorted=='username'}" (click)="sort('username')"
                           [icon]="sorted=='username' ? (sortDir==1 ? sortDn : sortUp) : sortNo"></fa-icon>
                     </div>
                  </th>
                  <th scope="col">
                     <div class="d-flex justify-content-between">
                        Ruolo
                        <fa-icon class="pointer" [ngClass]="{sorted: sorted=='ruolo'}" (click)="sort('ruolo')"
                           [icon]="sorted=='ruolo' ? (sortDir==1 ? sortDn : sortUp) : sortNo"></fa-icon>
                     </div>
                  </th>
                  <th scope="col">
                     <div class="d-flex justify-content-between">
                        Gruppo
                        <fa-icon class="pointer" [ngClass]="{sorted: sorted=='gruppo'}" (click)="sort('gruppo')"
                           [icon]="sorted=='gruppo' ? (sortDir==1 ? sortDn : sortUp) : sortNo"></fa-icon>
                     </div>
                  </th>
                  <th scope="col">
                     <div class="d-flex justify-content-between">
                        Amministrazione
                        <fa-icon class="pointer" [ngClass]="{sorted: sorted=='amministrazione'}"
                           (click)="sort('amministrazione')"
                           [icon]="sorted=='amministrazione' ? (sortDir==1 ? sortDn : sortUp) : sortNo"></fa-icon>
                     </div>
                  </th>
                  <th scope="col">
                     <div class="d-flex justify-content-between">
                     </div>
                  </th>
               </tr>
               <tr>
                  <th scope="col">
                     <div class="form-group form-inline mb-0">
                        <input class="form-control form-control-sm w-100" type="text" #fNome
                           [ngClass]="{filtered: fNome.value!==''}"
                           (keyup)="filtraUtentiAbil(fNome,fCogn,fUser,fRuol,fGrup,fAmmi)" />
                     </div>
                  </th>
                  <th scope="col">
                     <div class="form-group form-inline mb-0">
                        <input class="form-control form-control-sm w-100" type="text" #fCogn
                           [ngClass]="{filtered: fCogn.value!==''}"
                           (keyup)="filtraUtentiAbil(fNome,fCogn,fUser,fRuol,fGrup,fAmmi)" />
                     </div>
                  </th>
                  <th scope="col">
                     <div class="form-group form-inline mb-0">
                        <input class="form-control form-control-sm w-100" type="text" #fUser
                           [ngClass]="{filtered: fUser.value!==''}"
                           (keyup)="filtraUtentiAbil(fNome,fCogn,fUser,fRuol,fGrup,fAmmi)" />
                     </div>
                  </th>
                  <th scope="col">
                     <div class="form-group form-inline mb-0">
                        <input class="form-control form-control-sm w-100" type="text" #fRuol
                           [ngClass]="{filtered: fRuol.value!==''}"
                           (keyup)="filtraUtentiAbil(fNome,fCogn,fUser,fRuol,fGrup,fAmmi)" />
                     </div>
                  </th>
                  <th scope="col">
                     <div class="form-group form-inline mb-0">
                        <input class="form-control form-control-sm w-100" type="text" #fGrup
                           [ngClass]="{filtered: fGrup.value!==''}"
                           (keyup)="filtraUtentiAbil(fNome,fCogn,fUser,fRuol,fGrup,fAmmi)" />
                     </div>
                  </th>
                  <th scope="col">
                     <div class="form-group form-inline mb-0">
                        <input class="form-control form-control-sm w-100" type="text" #fAmmi
                           [ngClass]="{filtered: fAmmi.value!==''}"
                           (keyup)="filtraUtentiAbil(fNome,fCogn,fUser,fRuol,fGrup,fAmmi)" />
                     </div>
                  </th>
                  <th scope="col" class="colmin">
                     <div class="form-group form-inline mb-0">
                        <input disabled class="form-control form-control-sm w-100" type="text" />
                     </div>
                  </th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let utente of utentiView | slice:0:50">
                  <td class="py-1">
                     <ngb-highlight [result]="utente.nome" [term]="fNome.value"></ngb-highlight>
                  </td>
                  <td class="py-1">
                     <ngb-highlight [result]="utente.cognome" [term]="fCogn.value"></ngb-highlight>
                  </td>

                  <td class="py-1">
                     <ngb-highlight [result]="utente.username" [term]="fUser.value"></ngb-highlight>
                  </td>
                  <td class="py-1">
                     <ngb-highlight [result]="utente.ruolo" [term]="fRuol.value"></ngb-highlight>
                  </td>
                  <td class="py-1">
                     <ngb-highlight [result]="utente.gruppo" [term]="fGrup.value"></ngb-highlight>
                  </td>
                  <td class="py-1">
                     <ngb-highlight [result]="utente.amministrazione" [term]="fAmmi.value"></ngb-highlight>
                  </td>
                  <td class="py-0 colmin">
                     <button type="button" class="btn btn-sm btn-bg-outline py-0 my-1" (click)="onSimula(utente)"
                        ngbTooltip="Simula questo utente" placement="top" container="body">
                        Simula </button>
                  </td>
               </tr>
            </tbody>
         </table>

      </div>
   </div>
</div>