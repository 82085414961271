<div [formGroup]="mainForm">
   <!-- Tipo catasto -->
   <div class="row">
      <div class="col-6" *ngIf="toVisualize('tipo_catasto')" [ngClass]="{'col-12': !toVisualize('codcom')  }">
         <label for="selectTipoCatasto" [ngClass]="{'font-weight-bold': isMandatory('tipo_catasto')}">Tipo
            catasto</label>
         <!--<div><small> Filtro obbligatorio.</small></div>-->

         <select class="form-control" id="selectTipoCatasto" formControlName="tipo_catasto" placement="top"
            ngbTooltip="Seleziona campo Tipo Catasto dall'elenco">
            <option [value]="null">- Seleziona Tipo Catasto</option>
            <option
               *ngFor="let tipoCatasto of raffinaDatiService.ottieniDecodificheFiltrate('tipi_catasto', ordinamentoCrescenteDecodifiche)"
               [value]="tipoCatasto.codice">
               {{ tipoCatasto.descrizione_estesa }}</option>
         </select>
      </div>
      <!-- fine Tipo catasto -->
      <!-- Comune -->
      <div class="col-6" *ngIf="toVisualize('codcom')" [ngClass]="{'col-12': !toVisualize('tipo_catasto')  }">
         <label for="selectTipoCatasto" [ngClass]="{'font-weight-bold': isMandatory('codcom')}">Comune</label>
         <!--<div><small> Filtro obbligatorio.</small></div>-->
         <select class="form-control" id="selectComune" formControlName="codcom" placement="top"
            *ngIf="boolConCompetenzaTerritoriale" #comuniSelectComponent ngbTooltip="Seleziona campo Comune dall'elenco"
            [attr.disabled]="(comuniCatastali$| async)?.length > 0? null : true">
            <option [value]="null">- Seleziona Comune</option>
            <option *ngFor="let comune of ( comuniCatastali$ | async )" [value]="comune.codice">
               {{ comune.descrizione }}</option>
         </select>
         <input type="hidden" formControlName="codice_comune" />
         <input type="hidden" formControlName="comune" />

         <shr-autocomplete-choice #comuniComponent *ngIf="!boolConCompetenzaTerritoriale"
            formControlName="comuni_catastali" current_tips="Scrivere qualche lettera per visualizzare l'elenco"
            [model]="comuneDefaultValue" [filters]="solrFilters" (doSelection)="onDoSelectionAutocomplete($event)">
         </shr-autocomplete-choice>

      </div>
      <!-- fine Comune -->
   </div>


   <!--
   <div class="row">

   </div> -->



   <div class="row">
      <!-- Sezione -->
      <div class="col-4" *ngIf="toVisualize('sezione')"
         [ngClass]="{'col-6': !toVisualize('foglio') || !toVisualize('numero'), 'col-12': !toVisualize('foglio') && !toVisualize('numero') }">
         <label for="selectSezione" [ngClass]="{'font-weight-bold': isMandatory('sezione')}">{{labelSezione}}</label>
         <!--<div><small> Filtro obbligatorio.</small></div>-->
         <!-- <div class="d-inline" *ngIf="!isSezioniReady">
            <div class="spinner-border spinner-border-sm ml-3 mb-1 text-warning" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            </div> -->
         <select class="form-control" id="selectSezione" formControlName="sezione" placement="top"
            ngbTooltip="Seleziona campo Sezione dall'elenco" #sezioneComponent
            [attr.disabled]="(sezioniDiCompetenza$| async)?.length > 0? null : true">
            <option value="">- Seleziona Sezione</option>
            <option *ngFor="let sezione of ( sezioniDiCompetenza$ | async )" [value]="sezione.codice">
               {{ sezione.descrizione }}</option>
         </select>
      </div>
      <!-- fine Sezione -->

      <!-- Foglio -->
      <div class="col-4" *ngIf="toVisualize('foglio')"
         [ngClass]="{'col-6': !toVisualize('numero') || !toVisualize('sezione'), 'col-12': !toVisualize('sezione') && !toVisualize('numero')}">
         <label for="selectSezione" [ngClass]="{'font-weight-bold': isMandatory('foglio')}">Foglio</label>

         <!-- <div class="d-inline" *ngIf="!isFogliReady">
            <div class="spinner-border spinner-border-sm ml-3 mb-1 text-warning" role="status">
               <span class="sr-only">Loading...</span>
            </div>
         </div> -->
         <select class="form-control" id="selectFoglio" formControlName="foglio" placement="top"
            ngbTooltip="Seleziona campo Foglio dall'elenco"
            [attr.disabled]="(fogliDellaSezione$| async)?.length > 0? null : true">
            <option [value]="null">- Seleziona Foglio</option>
            <option *ngFor="let foglio of ( fogliDellaSezione$ | async )" [value]="foglio.codice">
               {{ foglio.descrizione }}</option>
         </select>
      </div>
      <!-- fine Foglio -->

      <!-- Plla -->
      <div class="col-4" *ngIf="toVisualize('numero')"
         [ngClass]="{'col-6': !toVisualize('foglio') || !toVisualize('sezione'),'col-12': !toVisualize('sezione') && !toVisualize('foglio') }">
         <label for="inputParticella" [ngClass]="{'font-weight-bold': isMandatory('numero')}">Numero</label>
         <!-- <div class="d-inline" *ngIf="!isPlleReady">
         <div class="spinner-border spinner-border-sm ml-3 mb-1 text-warning" role="status">
            <span class="sr-only">Loading...</span>
         </div>
      </div> -->
         <select class="form-control" id="selectParticella" formControlName="numero" placement="top"
            ngbTooltip="Seleziona campo Particella dall'elenco"
            [attr.disabled]="(particelleDelFoglio$| async)?.length > 0? null : true">
            <option [value]="null">- Seleziona Numero</option>
            <option *ngFor="let plla of ( particelleDelFoglio$ | async )" [value]="plla.codice">
               {{ plla.descrizione }}</option>
         </select>
      </div>
   </div>
   <!--
   <div class="row" >

   </div>

   <div class="row">

   </div> -->
   <div class="row">
      <div class="col-6" *ngIf="toVisualize('denominatore')" [ngClass]="{'col-12': !toVisualize('denominatore')  }">
         <label for="inputDenominatore"
            [ngClass]="{'font-weight-bold': isMandatory('denominatore')}">Denominatore</label>
         <input class="form-control" type="text" id="inputDenominatore" placeholder="Denominatore"
            formControlName="denominatore" placement="top" ngbTooltip="Denominatore">
      </div>
      <!-- fine Denominatore -->

      <!-- Subalterno -->
      <div class="col-6" *ngIf="toVisualize('sub')" [ngClass]="{'col-12': !toVisualize('sub')  }">
         <label for="inputSubalterno" [ngClass]="{'font-weight-bold': isMandatory('sub')}">Subalterno</label>
         <input class="form-control" type="text" id="inputSubalterno" placeholder="Subalterno" formControlName="sub"
            placement="top" ngbTooltip="Subalterno">
      </div>
   </div>
   <!-- fine Subalterno -->

</div>
