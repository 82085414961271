import { Injectable } from '@angular/core';
import BaseLayer  from 'ol/layer/Base';

/**
 * Tipologie di layer
 */
export enum TipologiaLayer {
   BASE = 'BASE',
   WMS = 'WMS',
   VECTOR = 'VECTOR',
   TEMP = 'TEMP',
}

/**
 * Servizio che contiene tutte le funzionalità basilari per lavorare con la mappa e con i layer
 */
@Injectable({
   providedIn: 'root'
})
export class MappaBaseService {

   /** Chiave per il sistema di riferimento EPSG 4326 */
   EPSG_4326_KEY = 'EPSG:4326';
   /** Chiave per il sistema di riferimento EPSG 3857 */
   EPSG_3857_KEY = 'EPSG:3857';

   /** Chiave per la proprietà titolo aggiunta ai layer */
   TITOLO_LAYER_KEY = 'TITLE';

   /** Chiave per la tipologia di layer aggiunta ai layer */
   TIPOLOGIA_LAYER_KEY = 'TYPE';

   /** Array che contiene tutti i layers */
   layers: BaseLayer[];

   /** Costruttore */
   constructor() {
      this.layers = [];
   }

   inizializzaMappa() {
   }

   /**
    * Aggiunge un layer all'array dei layers
    * @param layer Layer da inserire
    */
   aggiungiLayer(layer: BaseLayer) {
      this.layers.push(layer);
   }

   // TODO controllare funzionamento
   /**
    * Rimuove il layer dall'array dei layers
    * @param layer Layer da rimuovere
    */
   rimuoviLayer(layer: BaseLayer) {
      this.layers.splice(this.layers.indexOf(layer), 1);
   }

   /**
    * Imposta la visibilità del layer a false
    * @param layer Layer da nascondere
    */
   nascondiLayer(layer: BaseLayer) {
      layer.setVisible(false);
   }

   /**
    * Imposta la visibilità del layer a true
    * @param layer Layer da mostrare
    */
   mostraLayer(layer: BaseLayer) {
      layer.setVisible(true);
   }

   /**
    * Inverte la visibilità del layer passato (true -> false e false -> true)
    * @param layer Layer da modificare
    */
   invertiVisibilitàLayer(layer: BaseLayer) {
      layer.setVisible(!layer.getVisible());
   }

   /**
    * Nasconde tutti i layers
    */
   nascondiTuttiLayer() {
      for (const layerCorrente of this.layers) {
         this.nascondiLayer(layerCorrente);
      }
   }

   /**
    * Mostra tutti i layers
    */
   mostraTuttiLayer() {
      for (const layerCorrente of this.layers) {
         this.mostraLayer(layerCorrente);
      }
   }

   /**
    * Nasconde tutti i layer di tipologia WMS
    */
   nascondiTuttiLayerWMS() {
      this.layers.filter(this.isLayerWMS).forEach(layer => this.nascondiLayer(layer));
   }

   /**
    * Aumenta l'opacita del layer di 0.1
    * @param layer layer che si vuole modificare
    */
   aumentaOpacitàLayer(layer: BaseLayer) {
      if (layer.getOpacity() < 1) {
         layer.setOpacity(layer.getOpacity() + 0.1);
      }
   }

   /**
    * Diminiusce l'opacita del layer di 0.1
    * @param layer layer che si vuole modificare
    */
   diminuisciOpacitàLayer(layer: BaseLayer) {
      if (layer.getOpacity() > 0) {
         layer.setOpacity(layer.getOpacity() - 0.1);
      }
   }

   /**
    * Aumenta la posizione del layer passato all'interno dell'array dei layers
    * @param layer layer che si vuole spostare di posizione
    */
   aumentaPosizioneLayer(layer: BaseLayer) {
      const index = this.layers.indexOf(layer);
      [this.layers[index], this.layers[index + 1]] = [this.layers[index + 1], this.layers[index]];
   }

   /**
    * Diminuisce la posizione del layer passato all'interno dell'array dei layers
    * @param layer layer che si vuole spostare di posizione
    */
   diminuisciPosizioneLayer(layer: BaseLayer) {
      const index = this.layers.indexOf(layer);
      [this.layers[index - 1], this.layers[index]] = [this.layers[index], this.layers[index - 1]];
   }

   /*
   disegnaSuLayer() {

   }

   disegnaSuLayerConStile() {

   }*/

   /**
    *
    */
   aumentaZoom() {

   }

   /**
    *
    */
   diminiuisciZoom() {

   }

   /**
    * Filtro da utilizzare con gli stream per ottenere solo layers di tipologia BASE
    * @param layer layer sotto verifica
    * @returns true se la tipologia è uguale altrimenti {false}
    */
   isLayerBase(layer: BaseLayer): boolean {
      return layer.get(this.TIPOLOGIA_LAYER_KEY) === TipologiaLayer.BASE;
   }

   /**
    * Filtro da utilizzare con gli stream per ottenere solo layers di tipologia WMS
    * @param layer layer sotto verifica
    */
   isLayerWMS(layer: BaseLayer): boolean {
      return layer.get(this.TIPOLOGIA_LAYER_KEY) === TipologiaLayer.WMS;
   }

   /**
    * Filtro da utilizzare con gli stream per ottenere solo layers di tipologia VECTOR
    * @param layer layer sotto verifica
    */
   isLayerVECTOR(layer: BaseLayer): boolean {
      return layer.get(this.TIPOLOGIA_LAYER_KEY) === TipologiaLayer.VECTOR;
   }

   /**
    * Filtro da utilizzare con gli stream per ottenere solo layers di tipologia TEMP
    * @param layer layer sotto verifica
    */
   isLayerTEMP(layer: BaseLayer): boolean {
      return layer.get(this.TIPOLOGIA_LAYER_KEY) === TipologiaLayer.TEMP;
   }

}
