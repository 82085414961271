import { GeoUtilsRequest, EBWApiResponse } from '../model/api.model';
import { HttpClient } from '@angular/common/http';
import BaseLayer from 'ol/layer/Base';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { SimpleGeometry } from 'ol/geom';
import { Style, Stroke, Fill, Icon, RegularShape } from 'ol/style';
import { FeatureStyle, StyleFill, StyleStroke, StyleImage } from '../model/style.model';
import { Map, Feature, View } from 'ol';
import * as proj from 'ol/proj';
import Units from 'ol/proj/Units';
import IconOrigin from 'ol/style/IconOrigin';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { Coordinate } from 'ol/coordinate';
import GeometryType from 'ol/geom/GeometryType';
import { MapUtilsService } from './map-utils.service';

/**
 * Funzione layer. BASE per i layer che saranno sempre negli strati inferiori
 */
export enum PosizioneLayer {
   BASE = 'BASE',
   OVERLAY = 'OVERLAY'
}

/**
 * Tipo di layer
 *
 * WORLD --> layer che rappresentano il mondo (OSM + GOOGLE + TOMTOM + BING...)
 *
 * WMS --> layer WMS
 *
 * VECTOR --> layer vettoriali
 *
 * TEMP --> layer temporanei per localizzazione, disegni temporanei, ....
 */
export enum TipoLayer {
   WORLD = 'WORLD',
   WMS = 'WMS',
   VECTOR = 'VECTOR',
   TEMP = 'TEMP'
}

/**
 * Servizio della libreria mappa
 */
@Injectable({
   providedIn: 'root'
})
export class MapService {

   /**
    * Chiave per il titolo aggiunto ai layer
    */
   static TITOLO_LAYER_KEY = 'titolo';

   /**
    * Chiave per il tipo aggiunto ai layer
    */
   static TIPO_LAYER_KEY = 'tipo';

   /**
    * Chiave per la posizione aggiunta ai layer
    */
   static POSIZIONE_LAYER_KEY = 'posizione';

   /**
    * Chiave per il tipo di mappa Google aggiunto ai layer
    */
   static TIPOLOGIA_GOOGLE_LAYER_KEY = 'tipoGoogle';

   /**
    * Chiave per il gruppo di layer di appartenenza.
    */
   static GRUPPO_LAYER_KEY = 'gruppo';

   /**
    * Chiave per l'identificazione di feature nel layer temporaneo.
    */
   static FEATURE_TAG_KEY = 'featureTag';

   /**
    * Chiave per la sorgente dove recuperare gli oggetti da selezionare
    */
   static DATA_URL_LAYER_KEY = 'dataUrl';

   /**
    * Identificatore del sistema di riferimento EPSG:4326
    */
   static EPSG_4326_KEY = 'EPSG:4326';

   /**
    * Identificatore del sistema di riferimento EPSG:3857
    */
   static EPSG_3857_KEY = 'EPSG:3857';

   /**
    * Chiave da utilizzare nelle configurazioni per impostare il clientId
    */
   static CLIENT_ID_TYPE = 'CLIENT_ID';

   /**
    * Chiave per la visibilità aggiunta ai layer
    */
   static VISIBILITA_LAYER_KEY = 'visible';
   /**
    * Chiave per la popupTemplate ai layer
    */
   static POPUP_TEMPLATE_KEY = 'popupTemplate';

   /**
    * Coordinate di centro mappa per il riposizionamento
    */
   coordinateCentroMappa: Coordinate;

   /**
    * Zoom della mappa per il riposizionamento
    */
   zoom: number;

   /**
    * Risoluzione della mappa per il riposizionamento
    */
   risoluzione: number;

   /**
    * Layers attivi nella mappa
    */
   layers: {
      tab: string,
      title: string,
      layer: string,
      visibility: boolean
   }[] = [];

   /**
    * Subject di punti selezionati
    */
   coordinateSelezionate$ = new Subject<[number, number][]>();
   /**
    * Subject per la ricerca delle feature
    */
   ricercaOggetti$ = new Subject<{ location: string[] | BaseLayer[], geometry: SimpleGeometry }>();

   /**
    * Subject utilizzato per attivare/disattivare la modalità di selezione
    */
   interruttoreInterazioneSelezione$ = new Subject<boolean>();

   /**
    * Subject utilizzato per la modalità di disegno
    */
   interruttoreInterazioneDisegno$ = new Subject<{ flag: boolean, geometry: GeometryType, style?: string | Style, layer?: BaseLayer | string }>();

   /**
    * Subjet utilizzato per l'inserimento di feature da GeoJSON
    * @param geojson dove geojson è l'oggetto contenente i dati e layer il livello sul quale devono essere aggiunti
    * @param layer livello dove devono essere agigunte le features
    */
   inserimentoFeaturesDaGeojson$ = new Subject<{ geojson: {}, layer: BaseLayer }>();

   /**
    * Subject utilizzato per demandare il salvataggio del disegno creato sulla mappa
    */
   salvataggioFeaturesDisegno$ = new Subject<Feature[]>();

   /**
    * Array degli stili utilizzabili nella mappa
    */
   stiliMappa: FeatureStyle[];

   /**
    * Indirizzo del backend
    */
   urlBackendAPI: string;

   /**
    * Costruttore
    * @param http gestione di chiamate HTTP
    */
   constructor(private http: HttpClient,
      private mapUtils: MapUtilsService) { }

   /**
    * funzione per impostare le stili utilizzabili nella mappa
    * @param stili stili utilizzabili nella mappa
    */
   impostaStili(stili: FeatureStyle[]) {
      this.stiliMappa = stili;
   }

   /**
    * Imposta l'indirizzo del backend per le API
    * @param url url del backend
    */
   impostaURLBackend(url: string) {
      this.urlBackendAPI = url;
   }

   /**
    * Costruisce lo stile partendo da un id dello stile presente in configurazione
    * @param idStileConfigurazione stringa contenente l'id dello stile presente in configurazione
    * @returns lo stile creato o uno sitle vuoto
    */
   buildFeatureStyle(idStileConfigurazione: string): Style {
     let configStyle: FeatureStyle[] =[];
     if (!!this.stiliMappa){
      configStyle = this.stiliMappa.filter((s) => s.id === idStileConfigurazione);
     }
      if (configStyle.length > 0) {
         const fill = this.createStyleFill(configStyle[0].fill);
         const image = this.createStyleImage(configStyle[0].image);
         const stroke = this.createStyleStroke(configStyle[0].stroke);
         return new Style({
            fill: !!fill ? fill : undefined,
            image: !!image ? image : undefined,
            stroke: !!stroke ? stroke : undefined
         });
      } else {
         return new Style();
      }
   }

   /**
    * Crea lo stile del riempimento (per poligoni).
    * @param jsonStyle configurazione dello stile.
    * @returns stile Fill.
    */
   createStyleFill(jsonStyle: StyleFill): Fill {
      return !!jsonStyle ?
         new Fill({
            color: jsonStyle.color
         })
         : undefined;
   }

   /**
    * Crea lo stile per le linee.
    * @param jsonStyle configurazione dello stile.
    * @returns stile Stroke.
    */
   createStyleStroke(jsonStyle: StyleStroke): Stroke {
      return !!jsonStyle ?
         new Stroke({
            color: jsonStyle.color,
            width: jsonStyle.width,
            lineCap: !!jsonStyle.lineCap ? (jsonStyle.lineCap as CanvasLineCap) : 'round',
            lineJoin: !!jsonStyle.lineJoin ? (jsonStyle.lineJoin as CanvasLineJoin) : 'round',
            lineDash: jsonStyle.lineDash,
            lineDashOffset: !!jsonStyle.lineDashOffset ? jsonStyle.lineDashOffset : 0,
         })
         : undefined;
   }

   /**
    * Crea lo stile 'image'.
    * @param jsonStyle configurazione dello stile.
    * @returns stile Icon o RegularShape.
    */
   createStyleImage(jsonStyle: StyleImage): Icon | RegularShape {
      if (jsonStyle === undefined) {
         return undefined;
      }
      switch (jsonStyle.imageType) {
         case 'icon':
            return new Icon({
               opacity: !!jsonStyle.opacity ? jsonStyle.opacity : 1,
               rotation: !!jsonStyle.rotation ? jsonStyle.rotation : 0,
               scale: !!jsonStyle.scale ? jsonStyle.scale : 1,
               anchor: !!jsonStyle.anchor ? jsonStyle.anchor : [0.5, 0.5],
               anchorOrigin: !!jsonStyle.anchorOrigin ? (jsonStyle.anchorOrigin as IconOrigin) : ('top-left' as IconOrigin),
               anchorXUnits: !!jsonStyle.anchorXUnits ? <IconAnchorUnits>jsonStyle.anchorXUnits : <IconAnchorUnits>'fraction',
               anchorYUnits: !!jsonStyle.anchorYUnits ? <IconAnchorUnits>jsonStyle.anchorYUnits : <IconAnchorUnits>'fraction',
               src: jsonStyle.src
            });
         case 'shape':
            return new RegularShape({
               fill: !!jsonStyle.fill ? this.createStyleFill(jsonStyle.fill) : undefined,
               stroke: !!jsonStyle.stroke ? this.createStyleStroke(jsonStyle.stroke) : undefined,
               points: jsonStyle.points,
               radius: jsonStyle.radius,
               radius1: jsonStyle.radius1,
               radius2: jsonStyle.radius2,
               angle: !!jsonStyle.angle ? jsonStyle.angle : 0,
            });
      }
   }

   // FILTRI //

   /**
    * Filtro da utilizzare con gli stream per ottenere solo layers di posizione BASE
    * @param layer layer sotto verifica
    * @returns true se la posizione è uguale altrimenti {false}
    */
   isLayerBase(layer: BaseLayer): boolean {
      return layer.get(MapService.POSIZIONE_LAYER_KEY) === PosizioneLayer.BASE;
   }

   /**
    * Filtro da utilizzare con gli stream per ottenere solo layers di posizione OVERLAY
    * @param layer layer sotto verifica
    * @returns true se la posizione è uguale altrimenti {false}
    */
   isLayerOverlay(layer: BaseLayer): boolean {
      return layer.get(MapService.POSIZIONE_LAYER_KEY) === PosizioneLayer.OVERLAY;
   }

   /**
    * Filtro da utilizzare con gli stream per ottenere solo layers di tipo WORLD
    * @param layer layer sotto verifica
    * @returns true se il tipo è uguale altrimenti {false}
    */
   isLayerWorld(layer: BaseLayer): boolean {
      return layer.get(MapService.TIPO_LAYER_KEY) === TipoLayer.WORLD;
   }

   /**
    * Filtro da utilizzare con gli stream per ottenere solo layers di tipologia WMS
    * @param layer layer sotto verifica
    */
   isLayerWMS(layer: BaseLayer): boolean {
      return layer.get(MapService.TIPO_LAYER_KEY) === TipoLayer.WMS;
   }

   /**
    * Filtro da utilizzare con gli stream per ottenere solo layers di tipologia VECTOR
    * @param layer layer sotto verifica
    */
   isLayerVector(layer: BaseLayer): boolean {
      return layer.get(MapService.TIPO_LAYER_KEY) === TipoLayer.VECTOR;
   }

   /**
    * Filtro da utilizzare con gli stream per ottenere solo layers di tipologia TEMP
    * @param layer layer sotto verifica
    */
   isLayerTemp(layer: BaseLayer): boolean {
      return layer.get(MapService.TIPO_LAYER_KEY) === TipoLayer.TEMP;
   }

   /**
    * Funzione che converte un array di coordinate da un sistema di ri
    * @param coords array di coordinate da convertire
    * @param from_SRID SRID di partenza
    * @param to_SRID SRID di destinazione
    * @returns Observable da gestire
    */
   transformCoords(coords: number[][], fromSRID: number, toSRID: number): Observable<EBWApiResponse> {
      const pathAPI = '/geo_utils/transform_coords';
      const params: GeoUtilsRequest = { coords, fromSRID, toSRID };
      // console.log('GB PARAMS -->', params);
      return this.mapUtils.postRequest(this.urlBackendAPI + pathAPI, params);
   }


   /**
    * // TODO
    * Funzione per la trasformazione dell'unità di misura del raggio del cerchio in metri
    * @param radius Raggio da convertire in metri
    * @param viewMappa View della mappa
    * @returns Raggio in metri
    */
   convertiRaggioInMetri(radius: number, viewMappa: View): number {

      // console.log('GB convertiRaggioInMetri');

      const equatorResolution = viewMappa.getResolution();
      const centerResolution = proj.getPointResolution('EPSG:3857', equatorResolution, viewMappa.getCenter());

      const rad = +Units.METERS * (centerResolution / equatorResolution) * radius;
      // var meters = Math.round(rad); /// 1000 per km;
      const meters = parseFloat(rad.toFixed(3));
      console.log('GB RAGGIO (Prima): ', radius);
      console.log('GB METRI (Dopo): ', meters);
      return meters;

   }

   /**
    * // TODO
    * Funzione per la trasformazione del raggio del cerchio da metri all'unità di misura necessaria alla creazione della geometria
    * @param meters Metri da convertire nel raggio del cerchio
    * @param viewMappa View della mappa
    * @returns Raggio per OpenLayers
    */
   convertiMetriInRaggioOl(meters: number, viewMappa: View): number {
      console.log('GB convertiMetriInRaggioOl');
      const equatorResolution = viewMappa.getResolution();
      const centerResolution = proj.getPointResolution('EPSG:3857', equatorResolution, viewMappa.getCenter());
      const rad = meters; // * 1000 per km
      const radius = (rad / +Units.METERS) * (equatorResolution / centerResolution);

      console.log('GB METRI (Prima): ', meters);
      console.log('GB RAGGIO (Dopo): ', radius);
      return radius;
   }

   /**
    * Funzione che date in ingresso le coordinate di un punto, ne restituisce i riferimenti catastali
    * @param pointCoords Coordinate del punto
    * @returns Riferimenti catastali
    */
   cercaRiferimentiCatastaliService(pointCoords: Coordinate): Observable<EBWApiResponse> {

      const pathAPI = '/catasto/getInfoTerFab';
      const params = {
         geometry: `POINT(${pointCoords[0]} ${pointCoords[1]})`,
         srid: 3857
      }
      return this.mapUtils.postRequest(this.urlBackendAPI + pathAPI, params);
   }

   /**
    * Funzione che date in ingresso le coordinate di un punto, ne restituisce i riferimenti catastali
    * @param pointCoords Coordinate del punto
    * @returns Riferimenti catastali
    */
   cercaVerticiViciniService(pointCoords: Coordinate): Observable<EBWApiResponse> {
      console.log('MAP SERVICE - POINT COORDS', pointCoords);

      const pathAPI = '/disegno/vertici_vicini';

      const params = {
         where:
         {
            coordinata: {
               x: pointCoords[0],
               y: pointCoords[1],
               srid: 3857
            }
         }
      };

      return this.mapUtils.postRequest(this.urlBackendAPI + pathAPI, params);
   }

}
