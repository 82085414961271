import { RouterService } from "./../servizi/router.service";
import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import BaseLayer from "ol/layer/Base";
import ConfigMappaJSON from "src/assets/config/config-mappa.dev.json";
import ConfiGlobaleJson from "src/assets/config/config-globale.dev.json";
import { IConfigMappa } from "../library/ebw-map-angular/model/config-mappa.model";
import { MapComponent } from "../library/ebw-map-angular/map.component";
import { PosizioneLayer } from "../library/ebw-map-angular/service/map.service";
import { Subscription, forkJoin, Observable } from "rxjs";
import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router, UrlSegment } from "@angular/router";
import { GeoJSON, WKT } from "ol/format";
import { MapService } from "../library/ebw-map-angular/service/map.service";
import { Feature } from "ol";
import { EbwModalService } from "src/app/library/ebw-modals-angular/ebw-modal.service";
import { UtilsService } from "src/app/servizi/utils.service";
import { GestioneLoginService } from "src/app/servizi/login/gestione-login.service";
import { HttpClient } from "@angular/common/http";
import { CatastoData, EBWApiResponse } from "src/app/modelli/api.response.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UtenteAttivoService } from "../servizi/utente-attivo/utente-attivo.service";
// import { OggettiComponent } from './sidebar/oggetti/oggetti.component';
// import { OccupazioneComponent } from './sidebar/occupazione/occupazione.component';
import { MappaService } from "../servizi/mappa/mappa.service";
// import { IngiunzioneComponent } from './sidebar/ingiunzione/ingiunzione.component';
import { Layer, Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Circle as CircleStyle, Fill, Stroke, Style, RegularShape, Text } from "ol/style";
import { Point, LineString, Circle, Polygon, SimpleGeometry, MultiPolygon } from "ol/geom";
import { getPointResolution, transform } from "ol/proj";
import { METERS_PER_UNIT } from "ol/proj/Units";
import { extend } from "ol/extent";
// import { RiconsegnaComponent } from './sidebar/consegna/riconsegna/riconsegna.component';
import ResizeObserver from "resize-observer-polyfill";
import Geometry from "ol/geom/Geometry";
import { IConfigGlobale } from "../modelli/config-globale.model";
import { MapUtilsService } from "../library/ebw-map-angular/service/map-utils.service";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { PatientDataService } from "../servizi/patient/patient-data.service";
import { ReadOptions } from "ol/format/Feature";
import {
  ACTION,
  IHandleFeature,
  IHandleLayer,
  PatientMapShowGeometryService,
} from "../servizi/patient/patient-map-show-geometry.service";
import { PatientDataLoaderService } from "../servizi/patient/patient-data-loader.service";

/** Componente Mappa (utilizza la il componente EBW-MAP) */
@Component({
  selector: "app-mappa",
  templateUrl: "./mappa.component.html",
  styleUrls: ["./mappa.component.scss"],
})
export class MappaComponent implements AfterViewInit, OnDestroy, OnInit {
  /** Variabile che conterrà le configurazioni della mappa */
  configMappa: IConfigMappa;
  configGlobale: IConfigGlobale;

  /** Sottoscrizione agli eventi lanciati dal routing verso la mappa */
  initSubscription: Subscription;
  initSubscriptionData: Subscription;

  /** Bottone per tornare alla pagina di ricerca */
  showBackButton = false;

  /* Booleano per aprire un solo report alla volta dalla mappa */
  reportIsOpen: boolean = false;

  /** */
  rilievoOverlay: VectorLayer;

  /** Observer per resize della mappa */
  sizeObserver: ResizeObserver;

  /** Booleano per controllare se provengo dalla login oppure no */
  login: boolean = false;

  @Output() geometryChanged = new EventEmitter<any>();

  /** Evento emesso nel caso della gestione delle ordinanze e dei vincoli, quando è stata confermata una geometria dal tool di disegno */
  @Output() toolDisegnoElementoConfermatoMappaEmitter = new EventEmitter<any>();

  /** Nella gestione delle ordinanze e dei vincoli serve per chiudere la modale contenente la mappa quando viene premuto 'Annulla' nel pannello coordinate del tool di disegno */
  @Output() toolDisegnoChiusuraMappaEmitter = new EventEmitter<any>();

  /** Dati in arrivo */
  @Input() mapInputData: any = {};

  @Input() geometrieIngiunzioneStato = undefined;

  /** Visibilità controlli mappa */
  @Input() controlliMappaAttivi = true;

  /** Variabile per impostare l'altezza della mappa */
  @Input() altezza = { height: "calc(100vh - 72px)" };
  @Input() checkQueryString = true;

  /** Riferimento alla mappa generica */
  @ViewChild("mappa") mappa: MapComponent;

  /** L'oggetto viene passato in input ad associa-particella per la visualizzazione */
  public newParticella: any;

  /** Indica se il sidebar laterale è aperto o no. */
  rightSBarOpened: boolean = true;
  loadOggetti: boolean = false;
  loadIngiunzione: boolean = false;
  loadOccupazione: boolean = false;
  loadRiconsegna: boolean = false;
  loadParticella: boolean = false;
  attoId!: number;
  attoTipo!: string;

  private subs: Subscription[] = [];

  /** Costruttore del componente */
  constructor(
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private servizioMappa: MapService,
    public utils: UtilsService,
    public loginService: GestioneLoginService,
    private httpClient: HttpClient,
    private modal: NgbModal,
    private utenteAttivo: UtenteAttivoService,
    private ebwModalService: EbwModalService,
    private servizioSidWrapperMappa: MappaService,
    private routerSrv: RouterService,
    private mapUtilsService: MapUtilsService,
    private router: Router,
    private patientDataService: PatientDataService,
    private patientDataLoaderService: PatientDataLoaderService,
    private patientMapShowGeometryService: PatientMapShowGeometryService
  ) {
    this.configMappa = ConfigMappaJSON as IConfigMappa;
    this.configGlobale = ConfiGlobaleJson as IConfigGlobale;
    // this.subs.push(
    //   this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
    //     this.rightSBarOpened = event.url.includes("pazienti");
    //   })
    // );
  }

  ngOnInit() {
    this.servizioSidWrapperMappa.updateDataWithSameRoute.subscribe((data) => this.routerSubscribe(data));
    this.initSubscriptionData = this.route.data.subscribe((data) => this.routerSubscribe(data));
    this.mapUtilsService.configGlobale = this.configGlobale;
    this.subscriptions();
  }

  private subscriptions() {
    this.subs.push(
      this.servizioSidWrapperMappa.clearMapLayer.subscribe({
        next: (data) => this.pulisciLayer(data.id, data.reload),
      })
    );
    this.updatePatientPosition();
    this.insertFeaturePatientOnMapLayer();

    this.subs.push(
      this.patientMapShowGeometryService.handleLayer$.subscribe({
        next: (res: IHandleLayer) => {
          switch (res.action) {
            case ACTION.ADD:
              this.mappa?.mappaOpenLayer?.addLayer(res.layer);
              break;
            case ACTION.REMOVE:
              this.mappa?.mappaOpenLayer?.removeLayer(res.layer);
              break;
          }
        },
      })
    );

    this.subs.push(
      this.patientMapShowGeometryService.patientListValues$.subscribe({
        next: (results: any) => {
          const patientsId = results.map((r) => r.id);
          // console.log("patientsId: ",patientsId);
          const patientLayer = this.mappa.layers.find(
            (layer) => layer.get(MapService.TITOLO_LAYER_KEY) == "PatientPositions"
          );
          if (!!patientLayer && patientLayer.get(MapService.TIPO_LAYER_KEY) === "VECTOR") {
            const patientsFeatures = ((patientLayer as Layer).getSource() as VectorSource).getFeatures();
            // console.log("patientsFeatures: ",patientsFeatures);
            patientsFeatures.forEach((f) => {
              // console.log("feature id: ",f.getId());
              if (!patientsId.includes(f.getId())) {
                // console.log("remove feature: ", f);
                ((patientLayer as Layer).getSource() as VectorSource).removeFeature(f);
              }
            });
          }
        },
        error: (error) => console.log("dentro mappa, listValues error: ", error),
      })
    );
    this.subs.push(
      this.patientMapShowGeometryService.patientPathFeature$.subscribe({
        next: (res: IHandleFeature) => {
          const patientPathLayer = this.mappa?.mappaOpenLayer
            ?.getLayers()
            .getArray()
            .find((layer) => layer.get(MapService.TITOLO_LAYER_KEY) == "PatientPaths");
          // console.log("+++++ layer  : ", patientPathLayer);
          if (!!patientPathLayer) {
            const pathFeatures = ((patientPathLayer as Layer).getSource() as VectorSource).getFeatures();
            // console.log("feature: ", res.feature);
            pathFeatures.forEach((f) => {
              // console.log("feature: ", f.getId());
              if (res.feature.id == f.getId()) {
                if (res.action === ACTION.ADD) {
                  this.patientMapShowGeometryService.styleSelectedPath(f);
                  const geometry: Geometry =
                    this.patientMapShowGeometryService.createGeoJsonGeometryFromFeature(res.feature);
                  this.mappa.mappaOpenLayer.getView().fit(geometry as SimpleGeometry);
                } else if (res.action == ACTION.REMOVE) {
                  this.patientMapShowGeometryService.styleUnselectedPath(f);
                }
              }
            });
          }
        },
      })
    );
    this.subs.push(
      this.patientMapShowGeometryService.patientPathsFeatureColl$.subscribe({
        next: (results: any) => {
          const pathIds = results.features.map((f) => f.id);
          const patientPathLayer = this.mappa?.mappaOpenLayer
            ?.getLayers()
            .getArray()
            .find((layer) => layer.get(MapService.TITOLO_LAYER_KEY) == "PatientPaths");

          if (!!patientPathLayer) {
            if (pathIds.length > 0) {
              (patientPathLayer as Layer).setSource(
                this.patientMapShowGeometryService.createVectorSourceFromGeoJson(results)
              );
            } else {
              const fakeData = { type: "FeatureCollection", features: [] };
              (patientPathLayer as Layer).setSource(
                this.patientMapShowGeometryService.createVectorSourceFromGeoJson(fakeData)
              );
            }
          }
        },
        error: (error) => console.log("dentro mappa, patient path Values error: ", error),
      })
    );

    this.subs.push(
      this.patientMapShowGeometryService.patientHeatmapFeatureColl$.subscribe({
        next: (results: any) => {
          const pathIds = results.features.map((f) => f.id);
          const patientPathsHeatmap = this.mappa?.mappaOpenLayer
            ?.getLayers()
            .getArray()
            .find((layer) => layer.get(MapService.TITOLO_LAYER_KEY) == "PatientPathsHeatmap");
          // console.log("pathIds: ", pathIds);
          if (!!patientPathsHeatmap) {
            if (pathIds.length > 0) {
              (patientPathsHeatmap as Layer).setSource(
                this.patientMapShowGeometryService.createVectorSourceFromGeoJson(results)
              );
            } else {
              const fakeData = { type: "FeatureCollection", features: [] };
              (patientPathsHeatmap as Layer).setSource(
                this.patientMapShowGeometryService.createVectorSourceFromGeoJson(fakeData)
              );
            }
          }
        },
      })
    );
  }

  /** gestione della barra di scorrimento laterale */
  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.overflow = "hidden";
    // redirect to patient list
    this.router.navigateByUrl("/mappa/pazienti/lista");
  }

  /** OnDestroy */
  ngOnDestroy() {
    this.elementRef.nativeElement.ownerDocument.body.style.overflow = "unset";
    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
    }
    if (this.initSubscriptionData) {
      this.initSubscriptionData.unsubscribe();
    }

    if (!!this.sizeObserver) {
      this.sizeObserver.disconnect();
    }
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  private insertFeaturePatientOnMapLayer() {
    this.subs.push(
      this.servizioSidWrapperMappa.insertFeatureMapLayer.subscribe((res) => {
        const layerPatient = this.mappa.layers.find(
          (layer) => layer.get(MapService.TITOLO_LAYER_KEY) == res.layer
        );
        if (!!layerPatient && layerPatient.get(MapService.TIPO_LAYER_KEY) === "VECTOR") {
          const patientsFeatures = ((layerPatient as Layer).getSource() as VectorSource).getFeatures();
          const patientFeatureIsPresent = patientsFeatures.find(
            (f) => f.getId() === res.feature.properties.id
          );
          const geometryReadOptiones: ReadOptions = {
            dataProjection: MapService.EPSG_4326_KEY,
            featureProjection: MapService.EPSG_3857_KEY,
          };
          const geometry = new GeoJSON().readGeometry(res.feature.geometry, geometryReadOptiones);
          if (!patientFeatureIsPresent) {
            const patientFeature = new Feature({ geometry: geometry });
            patientFeature.setId(res.feature.id);
            patientFeature.setProperties(res.feature.properties);
            this.updateFeatureStyle(
              patientFeature,
              this.configMappa.vectorLayers.find((l) => l.titolo === res.layer).customStyles,
              this.servizioMappa
            );
            ((layerPatient as Layer).getSource() as VectorSource).addFeature(patientFeature);
          }
          this.mappa.mappaOpenLayer.getView().fit(geometry as SimpleGeometry, { minResolution: 10 });
        }
      })
    );
  }

  private updatePatientPosition() {
    this.subs.push(
      this.patientDataService.patientPositionWS$.subscribe((res) => {
        const layerPatient = this.mappa.layers.find(
          (layer) => layer.get(MapService.TITOLO_LAYER_KEY) == "PatientPositions"
        );
        if (!!layerPatient) {
          //  console.log("--MappaComponent layerFOUND: ",res);
          const patientsFeatures = (layerPatient as VectorLayer).getSource().getFeatures();
          const patientFeature = patientsFeatures.find((feature) => feature.getId() == res.id);
          if (!!patientFeature) {
            const featureProperties = patientFeature.getProperties();

            featureProperties.time = res.time;
            featureProperties.status = res.status;
            // featureProperties.positionStatus = res.positionStatus;
            patientFeature.setProperties(featureProperties);

            const geometryReadOptiones: ReadOptions = {
              dataProjection: MapService.EPSG_4326_KEY,
              featureProjection: MapService.EPSG_3857_KEY,
            };
            const geometry: Geometry = new WKT().readGeometry(
              `POINT(${res.lon} ${res.lat})`,
              geometryReadOptiones
            );
            patientFeature.setGeometry(geometry);
            this.updateFeatureStyle(
              patientFeature,
              this.configMappa.vectorLayers.find((l) => l.titolo === "PatientPositions").customStyles,
              this.servizioMappa
            );

            if (this.patientMapShowGeometryService.trackPatient) {
              this.mappa.mappaOpenLayer.getView().fit(geometry as SimpleGeometry);
            }
          }
        }
      })
    );
  }

  private updateFeatureStyle(feature: Feature, customStyles, mapService) {
    const properties = feature.getProperties();
    const featureStyle = [];

    Object.keys(properties).forEach((propKey) => {
      // get the value
      let propValue = properties[propKey];
      // loop styles
      let confStyles = customStyles.filter((style) => {
        // check condition for the style
        let checkIfStylePresent = style.condition.find((cond) => {
          if (cond.field == propKey) {
            if (!!cond.eq) {
              return propValue == cond.eq;
            } else if (!!cond.gt) {
              return propValue >= cond.gt;
            } else if (!!cond.lt) {
              return propValue <= cond.lt;
            } // add other compare types
          }
          return false;
        });
        // if check passed
        if (!!checkIfStylePresent) {
          return true;
        }
        return false;
      });
      confStyles.forEach((el) => {
        el.style.forEach((styleKey) => {
          featureStyle.push(mapService.buildFeatureStyle(styleKey));
        });
      });
      feature.setStyle(featureStyle);
    });
  }

  routerSubscribe(data: any) {
    console.log("routerSubscribe PARAMS", data);
    if (data && Object.keys(data).length !== 0) {
      if (data.login) {
        this.login = data.login;
      } else {
        this.mapInputData = data;
        // this.showBackButton = true;

        if (!this.mapInputData.controlliMappaAttivi) {
          this.controlliMappaAttivi = this.mapInputData.controlliMappaAttivi;
        }
        this.showBackButton = true;

        if (!!this.mappa && this.mappa._mappaPronta) {
          this.gestisciAzioniMappa();
          this.showBackButton = false;
        }
      }
    }
  }

  /** Inizializza le configurazioni e aggiunge i layers alla mappa quando questa è pronta */
  leggiConfigurazioniSpecifiche() {
    this.configMappa.wmsLayers
      .filter((layer) => layer.posizione === PosizioneLayer.OVERLAY)
      .forEach((layerJSON) => {
        const layer = this.mappa.creaLayerWMS(layerJSON);
        this.mappa.aggiungiLayerAllaMappa(layer);
      });
    this.configMappa.xyzLayers
      .filter((layer) => layer.posizione === PosizioneLayer.OVERLAY)
      .forEach((layerJSON) => {
        const layer = this.mappa.creaLayerXYZ(layerJSON);
        this.mappa.aggiungiLayerAllaMappa(layer);
      });
    this.configMappa.vectorLayers
      .filter((layer) => layer.posizione === PosizioneLayer.OVERLAY)
      .forEach((layerJSON) => {
        const layer = this.mappa.createLayerVector(layerJSON);
        this.mappa.aggiungiLayerAllaMappa(layer);
      });

    // Aggiunge la visibilità dei layer alla sessione
    const session: {
      tab: string;
      title: string;
      layer: string;
      visibility: boolean;
    }[] = [];
    const defaultContent = this.configMappa.sceltaRappresentazione;
    this.mappa.mappaOpenLayer
      .getLayers()
      .getArray()
      .forEach((baseLayer: BaseLayer) => {
        defaultContent.forEach((tab) => {
          // Solo se la tab è visibile
          if (
            (this.utenteAttivo.isCittadino() && tab.visibility.indexOf("cittadino") > -1) ||
            (!this.utenteAttivo.isCittadino() && tab.visibility.indexOf("amministrazione") > -1)
          ) {
            tab.content.forEach((group) => {
              if (
                group.values.findIndex(
                  (el) => el === baseLayer.getProperties()[MapService.TITOLO_LAYER_KEY]
                ) >= 0
              ) {
                session.push({
                  tab: tab.tab_id,
                  title: group.group_name,
                  layer: baseLayer.getProperties()[MapService.TITOLO_LAYER_KEY],
                  visibility: baseLayer.getProperties()[MapService.VISIBILITA_LAYER_KEY],
                });
              }
            });
          }
        });
      });

    // questo serve pr il layer dori
    this.aggiungiLayerRilievoDori();

    // imposto il listener per il resize della mappa
    this.sizeObserver = new ResizeObserver(() => {
      this.mappa.mappaOpenLayer.updateSize();
    });
    this.sizeObserver.observe(this.mappa.olmapdiv.nativeElement);

    // Memorizzo la visibilità dei layer in sessione
    if (!!this.servizioMappa.layers && this.servizioMappa.layers.length == 0) {
      this.servizioMappa.layers = session;
    }
    this.mappa.layerAggiornati(this.servizioMappa.layers);

    // Se sono presenti dei dati di input
    if (!!this.mapInputData && Object.keys(this.mapInputData).length > 0) {
      this.gestisciAzioniMappa();
    }
  }

  gestisciAzioniMappa() {
    console.log("this.mapInputData.mapAction", this.mapInputData);
    switch (this.mapInputData.mapAction) {
      /** Centrare la mappa sulla geometria */
      case "fit":
        if (this.mapInputData.geom) {
          var geom: Geometry | Geometry[];
          /** Il cerchio va gestito diversamente, per il posizionamento è sufficiente recuperarne il centro */
          /** Sono stati gestiti i cerchi che vengono 'inviati' dalla modale nella sezione modifica del portale delle
               ordinanze, quindi viene gestito il formato dell'oggetto che arriva da questo componente (ordinanze -> map-modal-component)*/
          if (!!this.mapInputData.geom.type && this.mapInputData.geom.type == "Circle") {
            geom = new Point(this.mapInputData.geom.coordinates);
          } else {
            geom = new GeoJSON().readGeometry(this.mapInputData.geom, {
              dataProjection: MapService.EPSG_3857_KEY,
              featureProjection: MapService.EPSG_3857_KEY,
            });
          }
          this.mappa.viewMappa.fit(geom as SimpleGeometry);
          if (!!!this.mapInputData.ordinanze) {
            if (this.mappa.viewMappa.getZoom() > 18) {
              this.mappa.viewMappa.setZoom(18);
            }
          } else {
            /** Nel caso delle ordinanze riduco lo zoom */
            if (this.mappa.viewMappa.getZoom() != 16) {
              this.mappa.viewMappa.setZoom(16);
            }
          }

          /**
           * Se è una variazione geometrica o un vincolo voglio fare il fit senza vedere il disegno
           * perché lo gestisco nel tool di disegno
           */
          if (!!!this.mapInputData.variazione_geometriche && !!!this.mapInputData.ordinanze) {
            this.mappa.aggiungiLayerTemporaneo();

            const feat = new Feature({ geometry: geom });

            /** Se si tratta di una consegna (sto effettuando una riconsegna) o sto gestendo un vincolo
             * voglio vedere la geometria precaricata in rosso */

            if (this.mapInputData.atto_tipo == "C" || this.mapInputData.vincolo) {
              feat.setStyle(this.servizioMappa.buildFeatureStyle("RedFill"));
            } else {
              feat.setStyle(this.servizioMappa.buildFeatureStyle("BlueFill"));
            }
            ((this.mappa.tmpLayer as Layer).getSource() as VectorSource).addFeature(feat);
          }
        }
        // else if (this.mapInputData.bbox){
        // TODO   CHI USA QUESTO IF?
        // }

        break;
      case "disegno_dori":
        this.disegnaRilievoDori(
          this.mapInputData.draws,
          this.mapInputData.bbox,
          this.mapInputData.srs,
          this.mapInputData.session_id
        );
        break;

      default:
        break;
    }

    this.attoId = this.mapInputData.atto_id;
    this.attoTipo = this.mapInputData.atto_tipo;

    if (this.mapInputData.hasOwnProperty("variazione_geometriche")) {
      switch (this.attoTipo) {
        case "F":
        case "L":
          this.loadOggetti = this.mapInputData.variazione_geometriche;
          this.rightSBarOpened = this.loadOggetti;
          break;
        case "IS":
          this.loadIngiunzione = this.mapInputData.variazione_geometriche;
          this.rightSBarOpened = this.loadIngiunzione;

          break;
        case "OCCUP":
          this.loadOccupazione = this.mapInputData.variazione_geometriche;
          this.rightSBarOpened = this.loadOccupazione;
          break;

        case "C":
          this.loadOggetti = this.mapInputData.variazione_geometriche;
          this.rightSBarOpened = this.loadOggetti;
          break;
      }
    } else {
      /**
       * //TODO
       * fare anche per gli altri tipi di atto??
       * non ho this.attoTipo, è undefined se sono in inserimento. Ma nel caso dell'ingiunzione devo comunque aprire la sidebar
       */
      switch (this.attoTipo) {
        // La sidebar deve aprirsi sia se sono in inserimento che in modifica
        // Vedi ingiunzione-sgombero.component.ts  --> atto_tipo: (!!this.atto.tipi_atti_amministrativi_codice) ? this.atto.tipi_atti_amministrativi_codice : 'IS'
        case "IS":
          this.loadIngiunzione = true;
          this.rightSBarOpened = this.loadIngiunzione;

          break;
        case "AP":
          this.gestisciSidebarParticella(true);
          break;

        case "C":
          this.loadRiconsegna = true;
          this.rightSBarOpened = this.loadRiconsegna;
          break;
      }
    }
    // aggiorna la visibilità layers che potrebbero essere stati modificati prima di aprire la mappa
    this.mappa.layerAggiornati(this.servizioMappa.layers);
  }

  public gestisciSidebarParticella(evt: any) {
    this.loadParticella = evt;
    this.rightSBarOpened = this.loadParticella;
    this.showBackButton = evt;
  }
  /**
   * Cambia la visibilità del layer
   * @param   layer layer a cui cambiare la visibilità
   */
  cambiaStatoLayer(layer: BaseLayer) {
    this.mappa.cambiaVisibilitaLayer(layer, !layer.getVisible());
  }

  chiudiObj(obj: Feature) {
    console.log("spec --> chiudi", obj);
  }

  modificaObj(obj: Feature) {
    console.log("spec --> modifica", obj);
  }

  visualizzaObj(obj: Feature) {
    console.log("spec --> info", obj);

    const properties = obj.getProperties();

    console.log("REPORT IS OPEN", this.reportIsOpen, properties);
    if (this.reportIsOpen) {
      return;
    }

    switch (properties.report) {
      case "Catasto":
        this.reportIsOpen = true;
        this.utils
          .postRequest(this.utils.creaURL("catasto", "view"), {
            where: {
              tipo_catasto: "T", // FN sono sempre particelle terreni?
              codice_comune: properties.comune,
              codice_sezione: properties.sezione,
              codice_foglio: properties.foglio,
              numero: properties.numero,
              tipo_report: properties.report,
            },
            token: this.loginService.getToken(),
          })
          .subscribe((response: EBWApiResponse) => {
            const particella = response.data[0] as CatastoData;

            //  const report = this.modal.open(ReportCatastoComponent, {
            //     ariaLabelledBy: 'modal-basic-title',
            //     centered: true,
            //     windowClass: 'modalFull95'
            //  });
            //  report.componentInstance.particellaParams = particella;
            this.reportIsOpen = false;
          });
        break;
      default:
        break;
    }
  }

  openPdfAtto(id: any) {
    const params = {
      where: {
        atti_amministrativi_id: id,
      },
    };

    this.utils.postRequest(this.utils.creaURL("atti_amministrativi", "pdf"), params).subscribe(
      (resp: any) => {
        if (resp.error === 0 && resp.status === 0) {
          if (resp.data && resp.data.trim() !== "") {
            setTimeout(() => window.open(resp.data, "_blank"), 0);
          } else {
            this.ebwModalService.openWarningModal({
              title: "Attenzione",
              content: "Il documento di report non è stato trovato.",
            });
          }
        } else {
          this.ebwModalService.openWarningModal({
            title: "Attenzione",
            content: "Il documento di report non è stato trovato.",
          });
        }
      },
      () => {}
    );
  }

  /**
   * Funzione per tornare alla pagina di ricerca dopo un posizionamento
   *
   */
  onBackButtonClick() {
    this.routerSrv.navigateToChild(this.mapInputData.backPath, this.mapInputData);
  }

  gestisciChiusuraToolDisegno(evt: any) {
    if (!!evt && !!evt.type) {
      switch (evt.type) {
        /** Nel caso dell'ordinanza devo emettere l'elemento confermato alla modale esterna -> map-modal.component */
        case "ordinanza":
          this.toolDisegnoChiusuraMappaEmitter.emit(evt);
          break;

        /** Nel caso del vincolo devo emettere l'elemento confermato alla modale esterna -> map-modal.component */
        case "vincolo":
          this.toolDisegnoChiusuraMappaEmitter.emit(evt);
          break;
      }
    }
  }

  /** Gestisce l'elemento restituito dal tool di disegno
   *
   *  evt = {
   *    geom: geoJsonGeom,
   *    type: this.currentObjType,
   *    currentObj: this.currentObj
   *  };
   */
  gestisciElementoConfermato(evt: any) {
    if (!!evt) {
      let feature = {
        geometry: evt.geom,
        properties: {
          type: evt.type.split("|")[1],
          name: evt.currentObj.name,
          label: evt.currentObj.name,
        },
        type: "Feature",
      };
      switch (evt.type.split("|")[0]) {
        /** Nel caso del vincolo devo emettere l'elemento confermato alla modale esterna -> map-modal.component */
        case "area":
          this.patientDataService.addPatientGeometry(evt.type.split("|")[2], feature).subscribe({
            next: (res) => {
              // refresh areas list, PatientLayerAreas, remove layerDisegno
              this.patientDataLoaderService.loadPatientAreasList = null;
              ((this.mappa.layerDisegno as Layer).getSource() as VectorSource).clear();
            },
          });
          break;
        case "path":
          this.patientDataService.addPatientGeometry(evt.type.split("|")[2], feature).subscribe({
            next: (res) => {
              // refresh safe paths list, PatientLayerSafePaths, remove layerDisegno
              this.patientDataLoaderService.loadPatientSafePathsList = null;
              ((this.mappa.layerDisegno as Layer).getSource() as VectorSource).clear();
            },
          });
          break;
      }
    }
    /* if("oggetti" && !!viewchild.oggetti)
      oggetti.salvaNuovaGeometria(evt)

      il salvataggio avviene quando premo SALVA dalla sidebar
      copia il codice in tool-disegno dentro salva.click del componente #1052

      */
  }

  getElementoDisegnoConfermato(evt: any) {
    let res: any;
    if (!!evt) {
      // TO DO verificare se ancora utilizzata questa funzione
      // to do "area"
      //  switch (evt) {
      //     // case 'oggetto':
      //     //    this.oggettiComponent.gestisciElementoConfermato(evt);
      //     //    break;
      //     // case 'occupazione':
      //     //    this.occupazioneComponent.gestisciElementoConfermato(evt);
      //     //    break;
      //     case 'ingiunzione':
      //        res = this.ingiunzioneComponent.getElementoConfermatoDisegno();
      //        break;
      //  }
    }
    return res;
  }

  /** Per aprire il tool di disegno dall'esterno passando l'oggetto da gestire */
  /**
   * @param obj oggetto passato
   * @param type tipo dell'oggetto da gestire, ed esempio : Ingiunzione, occupazione, ecc..
   * @param defaultTool Parametro opzionale che indica se il tool di disegno deve essere aperto con un strumento preselezionato
   * @param closeAll Parametro opzionale che indica se i pulsanti 'Annulla' e 'X' devono chiudere sia il pannello delle coordinate che la toolbar
   * @param data Dati aggiuntivi
   */
  gestisciToolDisegno(obj: any, type: any, defaultTool?: any, closeAll?: any, data?: any) {
    this.mappa.gestisciToolDisegno(obj, type, defaultTool, closeAll, data);
  }

  /** Metodo usato per calcolare la superficie di una geometria */
  calcolaSuperficie(geom: any) {
    return this.mappa.calcolaSuperficie(geom);
  }

  /** Per evidenziare la geometria delle particelle sulla mappa (riconsegna totale e parziale) */
  tematizzaArea(geoms: any) {
    this.mappa.tematizzaArea(geoms);
  }

  /** Per ripulire un layer */
  pulisciLayer(layerName: any, reload = false) {
    this.mappa.pulisciLayer(layerName, reload);
  }

  /** Disegna le geometrie sulla mappa
   * Le Geometrie delle occupazioni sono geometrie puntuali
   * @param obj array di occupazioni da visualizzare
   * @param type indica il tipo della feature da caricare, ad esempio occupazioni, ingiunzioni, oggetti, ecc..
   */
  caricaFeatures(obj: any, type: any) {
    console.log("+++ CARICA FEATURES", obj, type);
    let geom;

    obj.forEach((el) => {
      switch (type) {
        case "oggetti":
          if (el.geometria_oggetto) {
            geom = new GeoJSON().readGeometry(el.geometria_oggetto, {
              dataProjection: MapService.EPSG_3857_KEY,
              featureProjection: MapService.EPSG_3857_KEY,
            });
          } else if (el.geometria_area) {
            geom = new GeoJSON().readGeometry(el.geometria_area, {
              dataProjection: MapService.EPSG_3857_KEY,
              featureProjection: MapService.EPSG_3857_KEY,
            });
          }
          break;

        case "occupazioni":
          if (el.geometria_occupazione) {
            geom = new GeoJSON().readGeometry(el.geometria_occupazione, {
              dataProjection: MapService.EPSG_3857_KEY,
              featureProjection: MapService.EPSG_3857_KEY,
            });
          }
          break;

        case "ingiunzioni":
          if (!!el.geom) {
            geom = new GeoJSON().readGeometry(el.geom, {
              dataProjection: MapService.EPSG_3857_KEY,
              featureProjection: MapService.EPSG_3857_KEY,
            });
          }
          if (!!el.geometria_ingombro) {
            geom = new GeoJSON().readGeometry(el.geometria_ingombro, {
              dataProjection: MapService.EPSG_3857_KEY,
              featureProjection: MapService.EPSG_3857_KEY,
            });
          } else if (!!el.geometria_simbolo) {
            geom = new GeoJSON().readGeometry(el.geometria_simbolo, {
              dataProjection: MapService.EPSG_3857_KEY,
              featureProjection: MapService.EPSG_3857_KEY,
            });
          }
          break;

        case "ordinanze":
          // Quello che viene passato è già una geometria
          if (!!el && !!el.type && el.type == "Polygon") {
            geom = el;
          } else if (!!el && !!el.type && el.type == "Circle") {
            geom = el;
          }
          break;

        case "vincoli":
          // Quello che viene passato è già una geometria
          if (!!el && !!el.type && el.type == "Polygon") {
            geom = el;
          }

          break;
      }

      if (!!geom) {
        let feat;

        if (type == "ordinanze" || type == "vincoli") {
          // Il cerchio dovrebbe esserci solo nelle ordinanze
          if (!!geom.type && geom.type === "Circle") {
            /** Recupero il raggio del cerchio */
            var circleRadius = geom.radius;

            /** Recupero il centro del cerchio */
            var circleCenter = geom.coordinates;

            /**
             * Creazione di una feature di tipo Cerchio.
             * @param   coords coordinate per creare il punto
             */

            const feature = new Feature({
              geometry: new Circle(circleCenter, circleRadius),
            });

            const p = feature.getProperties();

            /** Salvo il raggio in metri tra le proprietà */
            p["radiusMeters"] = circleRadius;

            feat = feature;
          }

          // Anche nei vincoli bisogna gestire il poligono in input così
          else if (!!geom.type && geom.type === "Polygon") {
            feat = new Feature({
              geometry: new Polygon([geom.coordinates]),
            });
          }
        }

        // Da MultiPolygon a Polygon (il tool di disegno gestisce i Polygon)
        else if (!!geom.getType() && geom.getType() === "MultiPolygon") {
          const tmp = geom.getPolygons()[0];
          feat = new Feature({ geometry: tmp });
        } else {
          feat = new Feature({ geometry: geom });
        }

        console.log("+++ BlueFill 2");
        feat.setStyle(this.servizioMappa.buildFeatureStyle("BlueFill"));

        if (!!el.atto_id) {
          feat.setId(el.atto_id);
        } else {
          console.log("el.id", el.id);
          feat.setId(el.id);
        }

        this.mappa.aggiungiFeature(this.mappa.layerDisegno, feat);
      }
    });
  }

  /** Disegna ogni oggetto sulla mappa
   * @param obj array di oggetti da visualizzare
   */
  caricaOggetti(obj) {
    obj.forEach((el) => {
      if (el.geometria_oggetto) {
        const geom = new GeoJSON().readGeometry(el.geometria_oggetto, {
          dataProjection: MapService.EPSG_3857_KEY,
          featureProjection: MapService.EPSG_3857_KEY,
        }) as SimpleGeometry;

        /**
         * Se è una variazione geometrica voglio fare il fit senza vedere il disegno
         * perché lo gestisco nel tool di disegno
         */

        let feat;

        // Da MultiPolygon a Polygon (il tool di disegno gestisce i Polygon)
        if (geom.getType() === "MultiPolygon") {
          const tmp = (geom as MultiPolygon).getPolygons()[0];
          feat = new Feature({ geometry: tmp });
        } else {
          feat = new Feature({ geometry: geom });
        }

        feat.setStyle(this.servizioMappa.buildFeatureStyle("BlueFill"));
        feat.setId(el.id);

        this.mappa.aggiungiFeature(this.mappa.layerDisegno, feat);
      }
    });
  }

  onGeometryChanged(evt) {
    this.geometryChanged.emit(evt);
  }

  /**
   * Funzione per parsare i dati provenienti dal dori
   * @param draws punto_dori:1475788170,4917410180,5,255,0,0,2,0,0,0,OR001(6); linea_dori:1475788170,4917410180,1475786330,4917413680,0,255,0,3;
   *
   */
  parseRilevazioniDori(draws) {
    const disegni = {
      punti: [],
      linee: [],
      cerchi: [],
    };
    const righe = draws.split(";");
    righe.forEach((element) => {
      if (element.includes("punto_dori")) {
        element = element.substring(element.indexOf(":") + 1);
        const parametri = element.split(",");
        if (parametri.length < 11) {
          console.log("Errore parseRilevazioniDori punto:", element);
          return;
        }
        // Diviso 1000 perche i valori passati sono in millimetri
        const coord = [Number(parametri[0]) / 1000, Number(parametri[1]) / 1000];
        const testo = parametri[10];
        disegni.punti.push({
          coord,
          testo,
        });
      }
      if (element.includes("linea_dori")) {
        element = element.substring(element.indexOf(":") + 1);
        const parametri = element.split(",");
        if (parametri.length < 8) {
          console.log("Errore parseRilevazioniDori linea: ", element);
          return;
        }
        const coordLinea = [];
        for (let i = 0; i < parametri.length - 4; i++) {
          if (i % 2 == 1) {
            continue;
          }
          const coord = [Number(parametri[i]) / 1000, Number(parametri[i + 1]) / 1000];
          coordLinea.push(coord);
        }
        disegni.linee.push(coordLinea);
      }
      if (element.includes("cerchio_dori")) {
        element = element.substring(element.indexOf(":") + 1);
        const parametri = element.split(",");
        if (parametri.length < 11) {
          console.log("Errore parseRilevazioniDori cerchio: ", element);
          return;
        }
        const coord = [Number(parametri[0]) / 1000, Number(parametri[1]) / 1000];
        const testo = parametri[10];
        const raggio = Number(parametri[2]);
        disegni.cerchi.push({
          coord,
          raggio,
          testo,
        });
      }
    });
    console.log("parseRilevazioniDori disegni", disegni);
    return disegni;
  }

  /**
   * Metodo per aggiungere un punto nella mappa
   * @param coordinate [x, y] coordinate del punto
   * @param icon path dell'icona
   * @param label testo
   */
  aggiungiPunto(coordinate, icon, label) {
    const iconFeature = new Feature({
      geometry: new Point(coordinate),
    });

    const stroke = new Stroke({ color: "#E600FF", width: 2 });
    // var fill = new ol.style.Fill({color: 'red'});

    const iconStyle = [
      new Style({
        image: new RegularShape({
          // fill: fill,
          stroke,
          points: 4,
          radius: 10,
          radius2: 0,
          angle: 0,
        }),
      }),
      new Style({
        text: new Text({
          text: label,
          offsetY: -10,
          font: "Bold 15px Arial",
          fill: new Fill({
            color: "#0000FF",
          }),
        }),
      }),
    ];
    iconFeature.setStyle(iconStyle);
    this.rilievoOverlay.getSource().addFeatures([iconFeature]);
  }

  /**
   * Metodo per inserire il disegno di una linea
   * @param coordinate [[x, y], [x, y]] coordinate del punto
   */
  aggiungiLinea(coordinate) {
    const stroke = new Stroke({ color: "green", width: 2 });

    const lineStyle = [
      new Style({
        stroke,
      }),
    ];
    const lineFeature = new Feature({
      geometry: new LineString(coordinate),
      name: "linea",
    });
    lineFeature.setStyle(lineStyle);
    this.rilievoOverlay.getSource().addFeatures([lineFeature]);
  }

  /**
   * Metodo per disegnare cerchio
   * @param coordinate [x, y] coordinate del punto
   * @param icon path per l'icona
   * @param label testo
   * @param raggio raggio
   * @param resolution
   */
  aggiungiCerchio(coordinate, icon, label, raggio, resolution) {
    let rd = Math.floor(parseInt(raggio) / 1000);
    if (rd < 1) {
      rd = 1;
    }
    coordinate = [parseFloat(coordinate[0]), parseFloat(coordinate[1])];

    // **AGGIUSTAMENTO PER DISEGNARE UN CERCHIO CON RAGGIO IN METRI
    const view = this.mappa.mappaOpenLayer.getView();
    const projection = view.getProjection();
    const resolutionAtEquator = view.getResolution();
    const pointResolution = getPointResolution(projection, resolutionAtEquator, coordinate);
    const resolutionFactor = resolutionAtEquator / pointResolution;
    rd = (rd / METERS_PER_UNIT.m) * resolutionFactor;

    const iconFeature = new Feature({
      geometry: new Circle(coordinate, rd),
    });

    const iconStyle = [
      new Style({
        fill: new Fill({ color: [0, 255, 0, 0.1] }),
        stroke: new Stroke({ color: [0, 255, 0, 1], width: 2 }),
      }),
      new Style({
        text: new Text({
          text: label,
          offsetY: -10,
          font: "Bold 15px Arial",
          fill: new Fill({
            color: "#0000FF",
          }),
        }),
      }),
    ];

    iconFeature.setStyle(iconStyle);
    this.rilievoOverlay.getSource().addFeatures([iconFeature]);
  }

  /**
   *
   * @param draws
   */
  disegnaRilievoDori(draws, bbox, srs, sessionId) {
    let epsg;
    if (srs == "GENS:italy_si40_zone1_mm") {
      epsg = 3003;
    } else if (srs == "GENS:italy_si40_zone2_mm") {
      epsg = 3004;
    } else {
      epsg = 4326;
    }
    const disegni = this.parseRilevazioniDori(draws);
    const res = this.mappa.viewMappa.getResolution();
    this.rilievoOverlay.getSource().clear();

    let subscribePoints = new Observable((ob) => {
      ob.next({ data: { coords: [] } });
      ob.complete();
    });
    let subscribeLines = new Observable((ob) => {
      ob.next({ data: { coords: [] } });
      ob.complete();
    });
    let subscribeCircles = new Observable((ob) => {
      ob.next({ data: { coords: [] } });
      ob.complete();
    });

    if (disegni.punti.length > 0) {
      const p = disegni.punti.map((punto) => {
        return punto.coord;
      });
      subscribePoints = this.servizioMappa.transformCoords(p, epsg, 3857);
    }

    if (disegni.linee.length > 0) {
      const tmp = [];
      disegni.linee.forEach((linea) => {
        tmp.push(linea[0]);
        tmp.push(linea[1]);
      });
      subscribeLines = this.servizioMappa.transformCoords(tmp, epsg, 3857);
    }

    if (disegni.cerchi.length > 0) {
      const c = disegni.cerchi.map((cerchio) => {
        return cerchio.coord;
      });
      subscribeCircles = this.servizioMappa.transformCoords(c, epsg, 3857);
    }

    forkJoin([subscribePoints, subscribeLines, subscribeCircles]).subscribe((result: any) => {
      if (result[0].data.coords.length > 0) {
        disegni.punti.forEach((punto, index) => {
          this.aggiungiPunto(result[0].data.coords[index], "images/punto_certo.svg", punto.testo);
        });
      }

      if (result[1].data.coords.length > 0) {
        let i = 0;
        disegni.linee.forEach((linea) => {
          this.aggiungiLinea([result[1].data.coords[i], result[1].data.coords[i + 1]]);
          i += 2;
        });
      }

      if (result[2].data.coords.length > 0) {
        disegni.cerchi.forEach((cerchio, index) => {
          this.aggiungiCerchio(
            result[2].data.coords[index],
            "images/punto_certo.svg",
            cerchio.testo,
            cerchio.raggio,
            res
          );
        });
      }

      // centro la mappa
      // prendo l'estensione di tutte le feature
      let extent;
      this.rilievoOverlay
        .getSource()
        .getFeatures()
        .forEach((feature, index) => {
          if (index == 0) {
            extent = feature.getGeometry().getExtent();
          } else {
            extent = extend(extent, feature.getGeometry().getExtent());
          }
        });
      console.log("exxtent", extent);
      if (extent.length == 4) {
        this.mappa.viewMappa.fit(extent);
      }
    });
  }

  aggiungiLayerRilievoDori() {
    this.rilievoOverlay = new VectorLayer({
      source: new VectorSource(),
      // map: map,
      style(feature, resolution) {
        const zz = this.mappa.zoom; // map.getView().getZoom();
        let rad = 0;
        if (zz == 17) {
          rad = 6;
        } else if (zz == 18) {
          rad = 17;
        } else if (zz == 19) {
          rad = 15;
        } else if (zz == 20) {
          rad = 15;
        } else if (zz == 21) {
          rad = 30;
        } else if (zz >= 22) {
          rad = 55;
        }
        let simbolo = new CircleStyle({
          radius: rad,
          fill: new Fill({
            color: "rgba(0,255,0,0.2)",
          }),
          stroke: new Stroke({
            color: "#00FF00",
            width: 2,
          }),
        });
        if (
          feature.getProperties()["Web Field"] &&
          feature.getProperties()["Web Field"].indexOf("punto_certo:") == 0
        ) {
          // il cerchio è più piccolo
          rad = rad - rad * 0.4;
          simbolo = new CircleStyle({
            radius: rad,
            fill: new Fill({
              color: "rgba(209,0,101,0.5)",
            }),
            stroke: new Stroke({
              color: "#D10065",
              width: 2,
            }),
          });
          /*
               // per disegnare una croce rossa
               simbolo = new ol.style.RegularShape({
               fill: new ol.style.Fill({
               color: 'rgba(255,0,0,0.2)'
               }),
               stroke: new ol.style.Stroke({
               color: '#FF0000',
               width: 2
               }),
               stroke: new Stroke({
                  color: '#D10065',
                  width: 2
               })
            })
            /*
            // per disegnare una croce rossa
            simbolo = new ol.style.RegularShape({
            fill: new ol.style.Fill({
            color: 'rgba(255,0,0,0.2)'
            }),
            stroke: new ol.style.Stroke({
            color: '#FF0000',
            width: 2
            }),
            points: 4,
            radius: 10,
            radius2: 0,
            angle: 0
            })*/
        }
        return [
          new Style({
            stroke: new Stroke({
              color: "#ff0000",
              width: 3,
            }),
            fill: new Fill({
              color: "rgba(255,0,0,0.1)",
            }),
            image: simbolo,
          }),
        ];
      },
    });
    this.mappa.mappaOpenLayer.addLayer(this.rilievoOverlay);
  }
}
