<div
  class="container-fluid p-1 rounded border border-secondary bg-white shadow"
>
  <form [formGroup]="formRicerca">
    <div class="row no-gutters">
      <div class="col-11 my-auto txt-center txt-bg-blu">
        <label class="font-weight-bold" for="ricerca"
          >Ricerca indirizzo e POI</label
        >
      </div>
      <div class="col-1 text-right">
        <button
          class="btn bg-blu txt-white float-right btn-sm"
          ngbTooltip="Rimuovi marker"
          (click)="rimuoviMarker()"
        >
          <fa-icon [icon]="faEraser"></fa-icon>
        </button>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <input
          class="form-control"
          type="text"
          id="ricerca"
          placeholder="Ricerca"
          ngbTooltip="Ricerca"
          formControlName="ricerca"
          size="35"
          #search
        />
      </div>
    </div>
  </form>
</div>
