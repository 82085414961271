<!--  Legenda -->
<h5> Legenda </h5>

<div class="px-3"></div>

<nav ngbNav
     #navLegenda="ngbNav"
     class="nav-tabs"
     [destroyOnHide]="true">
  <!-- Tabs -->
  <ng-container ngbNavItem
                *ngFor="let tab of configMappa.legenda">
    <!-- <div *ngIf="isTabVisible(tab.nome_tab)" id="{{tab.nome_tab}}"> -->
    <!-- Titolo Tab -->
    <a ngbNavLink>{{tab.nome_tab}}</a>
    <!-- Gruppi -->
    <ng-template ngbNavContent>
      <div class="tabContent"
           *ngIf="isTabVisible(tab.nome_tab)"
           id="{{tab.nome_tab}}">
        <div class="mt-3"
             *ngIf="tab.nome_tab == 'SID' && !activeLayers(tab.nome_tab)">
          <small class="ml-1">Non ci sono layers attivi</small>
        </div>
        <div *ngFor="let tabGroup of tab.content">
          <!-- Visibilità gruppo -->
          <div *ngIf="isGroupVisible(tab.nome_tab,tabGroup.group_name)">
            <!-- Titolo Gruppo -->
            <h5 class="mt-5 ml-3 text-primary">{{tabGroup.title}}</h5>
            <!-- Legenda Gruppo-->
            <div *ngIf="isLegendaGroupVisible(tab.nome_tab,tabGroup.group_name)">
              <div class="ml-1 row align-items-center"
                   *ngFor="let img of tabGroup.legenda">
                <div class="col-md-3">
                  <img *ngIf="img.path != 'routesdensity.png' && img.path != 'legenda.png' ; else elseBlock"
                       src="{{tab.src_dir + img.path}}"
                       width="56"
                       height="56">
                  <ng-template #elseBlock> <img src="{{tab.src_dir + img.path}}"></ng-template>
                </div>
                <div class="col-md-9"><span>{{img.descrizione}}</span></div>
              </div>
            </div>
            <small class="ml-1"
                   *ngIf="tabGroup.legenda.length <= 0">Legenda gruppo non presente</small>
            <!-- Layers -->
            <div *ngFor="let tabLayer of tabGroup.layers">
              <!-- Visibilità Layer -->
              <div *ngIf="isLayerVisible(tab.nome_tab, tabGroup.group_name, tabLayer.layer_name)">
                <!-- Nome layer -->
                <h6 class="mt-5 ml-1">{{tabLayer.nome}}</h6>
                <small class="ml-1"
                       *ngIf="tabLayer.legenda.length <= 0">Legenda livello non presente</small>
                <!-- Legenda Layer -->
                <!-- TODO altezza fissa + scroll -->
                <div class="row ml-1 align-items-center"
                     *ngFor="let img of tabLayer.legenda">
                  <div class="col-md-3">
                    <img *ngIf="img.path != 'routesdensity.png' && img.path != 'legenda.png' ; else elseBlock"
                         src="{{tab.src_dir + img.path}}"
                         width="56"
                         height="56">
                    <ng-template #elseBlock> <img src="{{tab.src_dir + img.path}}"></ng-template>
                  </div>
                  <div class="col-md-9"><span>{{img.descrizione}}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- </div> -->
  </ng-container>
</nav>

<div [ngbNavOutlet]="navLegenda" class="mt-2"></div>
