import { NGXLogger } from 'ngx-logger';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { GestioneLoginService } from '../login/gestione-login.service';
import { UtilsService } from '../utils.service';
import { HttpClient } from '@angular/common/http';
import { EBWApiResponse, PayloadUtente } from 'src/app/modelli/api.response.model';
import { concatMap, map, tap } from 'rxjs/operators';

// TODO non sarebbe bene creare un servizio assieme (inglobato) al servizio di login? (per evitare dati duplicati in giro per l'app)
/**
 * Questo servizio server ad ottenere tutte le info relative all'utente collegato ogni volta che viene eseguito il Login. Le info sono quele salvate sul jwt token.
 */
@Injectable({
   providedIn: 'root'
})

export class UtenteAttivoService implements OnDestroy {

   /** Variabile che contiene tutte le  */
   userInfo!: PayloadUtente;

   /** Funzionalita abilitate per l'utente collegato */
   funzionalitaAbilitate = [];

   /** subscription per attendere il login */
   loginSubs!: Subscription;


   constructor(private loginService: GestioneLoginService,
      private logger: NGXLogger,
      private utilsService: UtilsService,
      private httpClient: HttpClient) {
         this.userInfo =  this.loginService.getPayload();
         this.initUtenteAttivo();
   }

   /** ngOnDestroy */
   ngOnDestroy(): void {
      this.loginSubs.unsubscribe();
   }

   // initUtenteAttivo() {
   //    // resto in ascolto dell'eventuale login
   //    this.loginSubs = this.loginService.ascoltaLogin().subscribe((loginResp: any) => {
   //       // se il login è avvenuo con successo
   //       if (loginResp.status === 'ok') {
   //          // decodifico il token, per prelevare le informazioni sull'utente
   //          this.loginService.decodeJWT().subscribe(
   //             (decodeResp: any) => {
   //                // se la decodifica è andata a buon fine
   //                if (decodeResp.status === 'ok') {
   //                   this.userInfo = decodeResp.decoded.payload.user;
   //                } else {
   //                   this.logger.error('decodeErrorStatus: ', decodeResp);
   //                }
   //             },
   //             (decodeError: string) => { this.logger.error('decodeError: ', decodeError); }
   //          );
   //       } else {
   //          this.logger.error('loginErrorStatus: ', loginResp);
   //       }
   //    }, (loginError: string) => { this.logger.error('loginError: ', loginError); });
   // }


   initUtenteAttivo(): void {
      // resto in ascolto dell'eventuale login
      this.loginSubs = this.loginService.ascoltaLogin().subscribe((loginResp: any) => {
         // se il login è avvenuo con successo
         if (loginResp.utenti_id ) {
            // decodifico il token, per prelevare le informazioni sull'utente
            this.loginService.decodeJWT().subscribe(
               (decodeResp: any) => {
                  // se la decodifica è andata a buon fine
                  if (decodeResp.status === 0 ) {
                     // this.userInfo = decodeResp.data.payload.user;
                     this.userInfo =  this.loginService.getPayload();
                     this.initFunzionalitaAbilitate();
                  } else {
                     this.logger.error('decodeErrorStatus: ', decodeResp);
                  }
               },
               (decodeError: string) => { this.logger.error('decodeError: ', decodeError); }
            );
         } else {
            this.logger.error('loginErrorStatus: ', loginResp);
         }
      }, (loginError: string) => { this.logger.error('loginError: ', loginError); });
   }


   /**
    * Metodo per verificare se una funzionalità è abilitata per un certo utente.
    * Da richiamare preventivamente, si occupa di valorizzare abilitazioni[funzId] con true/false.
    * @param funzId id della funzionalità da verificare (campo 'id' della tabella 'funzionalita')
    */
   initFunzionalitaAbilitate(): void {
      // tolgo ssotto perchè se mi loggo con la stessa applicazione ma con un altro utente deve rifare il controllo
      // if(this.funzionalitaAbilitate.length>0) {
      //    return;
      // }

      const API_URL = this.utilsService.creaURL('funzionalita', 'abilitate');
      const payload = this.userInfo;
      const params = {
         ruoli_id: payload.ruoli_id,
         gruppi_id: payload.gruppi_id,
         utenti_id: payload.utenti_id
      };
      this.utilsService.postRequest(API_URL, params)
         .subscribe(resp => {
            if (resp.error === 0 && resp.status === 0) {
               this.funzionalitaAbilitate = resp.data;
            }
         }
      );
   }

   getInfo(): PayloadUtente {
      return this.userInfo;
   }

   /**
    * Metodo per verificare se la funzionalità è abilitata per l'utente collegato
    * @param funzionalitaId id della funzionalità
    */
   isAbilitato( funzionalitaId: number ): boolean {
      if (!this.funzionalitaAbilitate || this.funzionalitaAbilitate.length === 0) { return false; }
      if ( this.funzionalitaAbilitate.find( (el) => el.funzionalita_id === funzionalitaId )) {
            return true;
      } else {
            return false;
      }
   }

   /** Indica quando è Cittadino */
   isCittadino(): boolean {
      return this.userInfo.bool_cittadino;
   }

   /** Indica quando è Amministratore */
   isAdministrator(): boolean {
      return this.userInfo.ruoli_id === 3;
   }

   /** Indica quando l'amministrazione ha una competenza territoriale (il CON e il cittadino non hanno competenza territoriale per es) */
   isConCompetenzaTerritoriale(): boolean {
      return this.userInfo.bool_competenza_territoriale;
   }

   /** Indica quando è Sicilia */
   isSicilia(): boolean {
      return false; //this.userInfo.bool_is_sicilia;
   }

   /**
    * Torna true se l'operatore collegato fa parte di una amministrazione di tipo Autorità Portuale
    */
   isAutoritaPortuale(): boolean {
      return this.userInfo.gruppi_id === 3;
   }

   /**
    * Torna true se l'operatore collegato fa parte di una amministrazione di tipo Autorità Portuale
    */
   isCapitaneriaDiPorto(): boolean {
      return this.userInfo.gruppi_id === 2;
   }

   /**
    * Torna true se l'operatore collegato fa parte di una amministrazione di tipo Autorità Portuale
    */
   isCentroOperativoNazionale(): boolean {
      return true; //this.userInfo.gruppi_id === 1;
   }

   /**
    * Torna true se l'utente ha il diritto di svolgere la funzionalità passata
    */
   hasRight(name: string): boolean {
      if ( this.userInfo.rights && this.userInfo.rights[name] ) {
         return true;
      }
      return false;
   }

   /*
   Ritorno l'id dell'amministrazione a vui appartiene l'utente
   */
   getAmministrazioneId(): number {
      return this.getInfo().amministrazioni_id;
   }

   /**
    * Comuni catastali collegati all'utente attivo
    */
   getCodiciComuniCatastali(id: any): Observable<EBWApiResponse> {
      return this.utilsService.postRequest(this.utilsService.creaURL('catasto', 'comuni_catastali'), {
         where: { id },
         geometria: false
      }).pipe(
         map(res => res),
            // concatMap((res: { timeout: number }) => this.http.get(`http://test.localhost/api.php?timeout=${+res.timeout + 3}`)),

      );
   }
}
