import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { UtilsService } from '../utils.service';


@Injectable({
   providedIn: 'root'
})

export class GestioneUtentiService {

   utentiSubject = new Subject();
   validationResult;
   validationResult2 = "ciao";

   constructor(//private http: HttpClient,
      private utils: UtilsService) { }

   getElencoPost(jsonData: any) {

      return this.utils.postRequest( this.utils.creaURL('atti_amministrativi', 'elenco') , jsonData );
      // return this.http.post<any>(API_URL['get_elenco'],
      //    jsonData);
   }
   salvaUtente(datiUtente: object): Observable<any> {


      return this.utils.postRequest( this.utils.creaURL('utenti', 'save') , datiUtente );
      // return this.http.post<any>(API_URL['utenti_save'],
      //    datiUtente,
      //    { responseType: 'text' as 'json' })
   }




}
