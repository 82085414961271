<div class="reg-wrapper bg-white rounded  shadow p-4">
   <div class="container-fluid">
      <div class="row">
         <div class="col">
            <div class="m-3 text-center">
               <img src="./../../../assets/img/fdg_logo.PNG" alt="Sid">
            </div>
            <h3 class="text-center text-blusid">Fondazione Don Carlo Gnocchi</h3>
            <p class="text-center">ONLUS</p>

            <form [formGroup]="utenteForm" #utenteFormElem class="mx-3 mt-5 mb-3">

               <!-- NOME -->
               <div class="form-group">
                  <label class="font-weight-bold"> Nome </label>
                  <input type="text" formControlName="nome" class="form-control" placement="top" ngbTooltip="Nome"
                     placeholder="Nome">
                  <small class="text-danger" *ngIf="utenteForm.get('nome').invalid && utenteForm.get('nome').touched">
                     Inserire valore campo.
                  </small>
               </div>

               <!-- COGNOME -->
               <div class="form-group">
                  <label class="font-weight-bold"> Cognome </label>
                  <input type="text" formControlName="cognome" class="form-control" ngbTooltip="Cognome"
                     placeholder="Cognome">
                  <small class="text-danger"
                     *ngIf="utenteForm.get('cognome').invalid && utenteForm.get('cognome').touched">
                     Inserire valore campo.
                  </small>
               </div>

               <!-- DATA DI NASCITA -->
               <div class="form-group">
                  <label class="font-weight-bold"> Data di Nascita </label>
                  <div class="form-group">
                     <div class="input-group">
                        <input class="form-control" placeholder="GG/MM/AAAA" ngbTooltip="Data di nascita"
                           formControlName="data_nascita" ngbDatepicker #d="ngbDatepicker"
                           [minDate]="{year: 1900, month: 1, day: 1}">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                              <fa-icon aria-hidden="true" [icon]="faCalendarAlt"></fa-icon>
                           </button>
                        </div>
                     </div>
                     <small class="text-danger"
                        *ngIf="utenteForm.get('data_nascita').invalid && utenteForm.get('data_nascita').touched">
                        Inserire valore campo.
                     </small>
                  </div>
               </div>

               <!-- COMUNE DI NASCITA -->
               <div class="form-group">
                  <label class="font-weight-bold" for="comNas">Comune di Nascita:</label>
                  <input id="comNas" type="text" class="form-control" placeholder="Comune di nascita"
                     formControlName="codcom_nascita" [inputFormatter]="comuneFormatter" [ngbTypeahead]="comuneSearch"
                     [resultTemplate]="comnasTemplate" ngbTooltip="Scrivere qualche lettera per visualizzare l'elenco">
                  <small class="text-danger"
                     *ngIf="utenteForm.get('codcom_nascita').invalid && utenteForm.get('codcom_nascita').touched">
                     Inserire valore campo.
                  </small>
               </div>
               <ng-template #comnasTemplate let-r="result" let-t="term">
                  <ngb-highlight [result]="comuneFormatter(r)" [term]="t"></ngb-highlight>
               </ng-template>

               <!-- SESSO -->
               <div class="form-group">
                  <label class="font-weight-bold"> Sesso </label>
                  <div class="custom-control custom-radio">
                     <input type="radio" id="customRadioF" name="sesso" class="custom-control-input "
                        formControlName="sesso" value="F" [checked]=true>
                     <label class="custom-control-label" for="customRadioF">Donna</label>
                  </div>
                  <div class="custom-control custom-radio">
                     <input type="radio" id="customRadioM" name="sesso" class="custom-control-input "
                        formControlName="sesso" value="M">
                     <label class="custom-control-label" for="customRadioM">Uomo</label>
                  </div>
                  <small class="text-danger" *ngIf="utenteForm.get('sesso').invalid">
                     Selezionare il campo Sesso.
                  </small>
               </div>

               <!-- CODICE FISCALE -->
               <div class="form-group">
                  <label class="font-weight-bold"> Codice Fiscale </label>
                  <input type="text" formControlName="codice_fiscale" class="form-control" ngbTooltip="Codice Fiscale"
                     placeholder="Codice Fiscale" minlength="16" maxlength="16">
                  <small class="text-danger"
                     *ngIf="utenteForm.get('codice_fiscale').invalid && utenteForm.get('codice_fiscale').touched"
                     [ngClass]="utenteForm.controls['codice_fiscale'].hasError('required') || utenteForm.controls['codice_fiscale'].hasError('maxlenght') || utenteForm.controls['codice_fiscale'].hasError('minlength')  ? 'text-danger' : ''">
                     Inserire un codice fiscale valido.
                  </small>
               </div>

               <!-- COMUNE DI RESIDENZA -->
               <div class="form-group">
                  <label for="comRes" class="font-weight-bold">Comune di Residenza:</label>
                  <input id="comRes" type="text" class="form-control" formControlName="codcom_residenza"
                     [inputFormatter]="comuneFormatter" [ngbTypeahead]="comuneSearch" [resultTemplate]="comresTemplate"
                     placeholder="Comune di residenza" ngbTooltip="Scrivere qualche lettera per visualizzare l'elenco">
                  <small class="text-danger"
                     *ngIf="utenteForm.get('codcom_residenza').invalid && utenteForm.get('codcom_residenza').touched">
                     Inserire valore campo.
                  </small>
               </div>
               <ng-template #comresTemplate let-r="result" let-t="term">
                  <ngb-highlight [result]="comuneFormatter(r)" [term]="t"></ngb-highlight>
               </ng-template>

               <!-- INDIRIZZO -->
               <div class="form-group">
                  <label> Indirizzo </label>
                  <input type="text" formControlName="indirizzo" class="form-control" ngbTooltip="Indirizzo"
                     placeholder="Indirizzo">
                  <small class="text-danger"
                     *ngIf="utenteForm.get('indirizzo').invalid && utenteForm.get('indirizzo').touched">
                     Inserire valore campo.
                  </small>
               </div>

               <!-- TELEFONO -->
               <div class="form-group">
                  <label> Telefono </label>
                  <input type="text" formControlName="telefono" class="form-control" ngbTooltip="Telefono"
                     placeholder="Telefono">
                  <small class="text-danger"
                     *ngIf="utenteForm.get('telefono').invalid && utenteForm.get('telefono').touched">
                     Inserire valore campo.
                  </small>
               </div>

               <!-- EMAIL -->
               <div class="form-group">
                  <label class="font-weight-bold"> Email </label>
                  <input type="text" formControlName="email" class="form-control" ngbTooltip="Email"
                     placeholder="Email">
                  <small class="text-danger" *ngIf="utenteForm.get('email').invalid && utenteForm.get('email').touched">
                     Inserire una Email valida.
                  </small>
               </div>

               <!-- TIPO -->
               <div class="form-group">
                  <label class="font-weight-bold"> Tipo </label>
                  <div class="custom-control custom-radio">
                     <input type="radio" id="customRadioC" name="tipo" class="custom-control-input" value="C"
                        formControlName="tipo" [checked]=true>
                     <label class="custom-control-label" for="customRadioC">Cittadino</label>
                  </div>
                  <div class="custom-control custom-radio">
                     <input type="radio" id="customRadioT" name="tipo" class="custom-control-input" value="T"
                        formControlName="tipo">
                     <label class="custom-control-label" for="customRadioT">Tecnico</label>
                  </div>
                  <small class="text-danger" *ngIf="utenteForm.get('tipo').invalid && utenteForm.get('tipo').touched">
                     Inserire valore campo.
                  </small>
               </div>

               <!-- DATI DEL TECNICO -->
               <div *ngIf="utenteForm.get('tipo').value=='T'" class="border rounded p-4 pb-1 mb-3">
                  <!-- NUMERO DI ISCRIZIONE -->
                  <div class="form-group">
                     <label class="font-weight-bold"> Numero di iscrizione </label>
                     <input type="text" formControlName="numero_iscrizione" class="form-control"
                        ngbTooltip="Numero di iscrizione" placeholder="Numero di iscrizione">
                     <small class="text-danger"
                        *ngIf="utenteForm.get('numero_iscrizione').invalid && utenteForm.get('numero_iscrizione').touched">
                        Inserire valore campo.
                     </small>
                  </div>

                  <!-- ALBO DI ISCRIZIONE -->
                  <div class="form-group">
                     <label class="font-weight-bold"> Albo di iscrizione </label>
                     <input type="text" formControlName="albo_iscrizione" class="form-control"
                        ngbTooltip="Albo di iscrizione" placeholder="Albo di iscrizione">
                     <small class="text-danger"
                        *ngIf="utenteForm.get('albo_iscrizione').invalid && utenteForm.get('albo_iscrizione').touched">
                        Inserire valore campo.
                     </small>
                  </div>

                  <!-- DATA DI ISCRIZIONE -->
                  <div class="form-group">
                     <label class="font-weight-bold"> Data di iscrizione </label>
                     <div class="form-group">
                        <div class="input-group">
                           <input class="form-control" placeholder="GG/MM/AAAA" ngbTooltip="Data di iscrizione"
                              formControlName="data_iscrizione" ngbDatepicker #d2="ngbDatepicker">
                           <div class="input-group-append">
                              <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                                 <fa-icon aria-hidden="true" [icon]="faCalendarAlt"></fa-icon>
                              </button>
                           </div>
                        </div>
                        <small class="text-danger"
                           *ngIf="utenteForm.get('data_iscrizione').invalid && utenteForm.get('data_iscrizione').touched">
                           Inserire valore campo.
                        </small>
                     </div>
                  </div>
               </div>


               <!-- USERNAME -->
               <div class="form-group">
                  <label class="font-weight-bold"> Username </label>
                  <input type="text" formControlName="username" class="form-control" ngbTooltip="Username"
                     placeholder="Username">
                  <small class="row col"
                     *ngIf="utenteForm.get('username').invalid && utenteForm.controls['username'].hasError('required') && (utenteForm.get('username').dirty || utenteForm.get('username').touched)"
                     [ngClass]="'text-danger'">
                     Inserire valore campo.
                  </small>
                  <small class="row col" *ngIf="utenteForm.controls['username'].hasError('isNotUnique')"
                     [ngClass]="'text-danger'">
                     Username gi&agrave; esistente.
                  </small>
               </div>

               <!-- PASSWORD -->
               <div class="form-group">
                  <label class="font-weight-bold"> Password </label>
                  <input ngbTooltip="Password" formControlName="password" type="password" class="form-control"
                     [ngClass]="utenteForm.controls['password'].invalid && (utenteForm.get('password').dirty || utenteForm.get('password').touched) ? 'is-invalid' : ''"
                     placeholder="Password">
                  <small class="row col"
                     *ngIf="utenteForm.get('password').invalid && (utenteForm.get('password').dirty || utenteForm.get('password').touched)"
                     [ngClass]="utenteForm.controls['password'].hasError('required') ? 'text-danger' : 'text-success'">
                     Inserire la Password.
                  </small>
                  <small class="row col"
                     *ngIf="utenteForm.get('password').invalid && (utenteForm.get('password').dirty || utenteForm.get('password').touched)"
                     [ngClass]="utenteForm.controls['password'].hasError('required') || utenteForm.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                     La password deve avere almeno 8 caratteri.
                  </small>
                  <small class="row col"
                     *ngIf="utenteForm.get('password').invalid && (utenteForm.get('password').dirty || utenteForm.get('password').touched)"
                     [ngClass]="utenteForm.controls['password'].hasError('required') || utenteForm.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                     La password deve contenere almeno un numero.
                  </small>
                  <small class="row col"
                     *ngIf="utenteForm.get('password').invalid && (utenteForm.get('password').dirty || utenteForm.get('password').touched)"
                     [ngClass]="utenteForm.controls['password'].hasError('required') || utenteForm.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                     La password deve contenere almeno una lettera maiuscola.
                  </small>
                  <small class="row col"
                     *ngIf="utenteForm.get('password').invalid && (utenteForm.get('password').dirty || utenteForm.get('password').touched)"
                     [ngClass]="utenteForm.controls['password'].hasError('required') || utenteForm.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                     La password deve contenere almeno una lettera minuscola.
                  </small>
                  <small class="row col"
                     *ngIf="utenteForm.get('password').invalid && (utenteForm.get('password').dirty || utenteForm.get('password').touched)"
                     [ngClass]="utenteForm.controls['password'].hasError('required') || utenteForm.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                     La password deve contenere almeno un carattere speciale.
                  </small>
               </div>

               <!-- CONFERMA PASSWORD -->
               <div class="form-group">
                  <label class="font-weight-bold"> Conferma Password </label>
                  <input id="conferma_password" ngbTooltip="Conferma Password" formControlName="conferma_password"
                     type="password" class="form-control"
                     [ngClass]="utenteForm.controls['conferma_password'].invalid && (utenteForm.get('password').dirty || utenteForm.get('password').touched) ? 'is-invalid' : ''"
                     placeholder="Password">
                  <small *ngIf="utenteForm.get('conferma_password').invalid &&
                              (utenteForm.get('conferma_password').dirty || utenteForm.get('conferma_password').touched) &&
                              utenteForm.controls['conferma_password'].hasError('required')" class="text-danger">
                     Inserire il campo Conferma Password.
                  </small>
                  <small class="text-danger"
                     *ngIf="utenteForm.controls['conferma_password'].hasError('NoPasswordMatch') && utenteForm.get('conferma_password').invalid && (utenteForm.get('conferma_password').dirty || utenteForm.get('conferma_password').touched)">
                     Le password non corrispondono.
                  </small>
               </div>


               <!-- INDIETRO E REGISTRATI -->
               <div class="form-group mt-5 mb-2 text-right">
                  <button type="button" (click)="back()" class="btn bg-blu txt-white left"> Indietro </button>
                  <button type="button" (click)="salvaUtente()" [disabled]="!utenteForm.valid"
                     class="btn bg-blu txt-white"> Registrati </button>
               </div>

            </form>
         </div>
      </div>
   </div>
</div>
