<ul class="nav nav-tabs" id="myTab" role="tablist">
   <li class="nav-item" *ngFor="let current of tabs" >
      <a (click)="_onCickTab(current.key)"
         [ngClass]="{'nav-link': true, 'active': _getSelectedTab()===current.key}"
         [id]="current.related + '-tab'"
         data-toggle="tab"
         [href]="'#'+ current.related"
         role="tab">
         {{ current.name }}
      </a>
   </li>
</ul>
