<h5>Scelta Rappresentazione</h5>
<div class="px-3">

  <nav ngbNav
       #navSceltaRapp="ngbNav"
       class="nav-tabs"
       [destroyOnHide]="false">
    <ng-container ngbNavItem
                  *ngFor="let tab of tabVisibili; let tab_i = index">
      <a ngbNavLink>{{tab.tab_name}}</a>
      <ng-template ngbNavContent>
        <div class="tabContent">
          <p class="font-weight-bold"
             [innerHtml]='tab.title'></p>
          <div [formGroup]="forms[tab_i]"
               ng-scrollbars>
            <div *ngFor="let content of tab.content">

              <div *ngIf="content.type == 'radio'">
                <span *ngIf="content.title.length > 0"><br /></span>
                <span class="font-weight-bold"
                      [innerHtml]="content.title"></span>
                <div class="custom-control custom-radio">
                  <input type="radio"
                         class="custom-control-input"
                         id="vuoto"
                         value="null"
                         [formControlName]="content.group_name"
                         mdbInput>
                  <label class="custom-control-label"
                         for="vuoto">Vuoto</label>
                  <div *ngFor="let value of content.values; let i = index">
                    <input type="radio"
                           class="custom-control-input"
                           id="{{value}}"
                           [value]="value"
                           [formControlName]="content.group_name"
                           mdbInput
                           [attr.disabled]="forms[tab_i].controls[content.group_name].disabled? '' : null"
                           [checked]="content.checked == value">
                    <label class="custom-control-label"
                           for="{{value}}">{{content.labels[i]}}</label>
                  </div>
                </div>
              </div>

              <div *ngIf="content.type == 'checkbox'">
                <span *ngIf="content.title.length > 0"><br /></span>
                <span class="font-weight-bold"
                      [innerHtml]="content.title"></span>
                <div *ngFor="let value of content.values; let i = index">
                  <input type="checkbox"
                         id="{{value}}"
                         [formControl]="forms[tab_i]['controls'][content.group_name]['controls'][i]"
                         [attr.disabled]="forms[tab_i].controls[content.group_name].disabled? '' : null">
                  <label class="form-check-label check-label"
                         for="{{value}}">{{content.labels[i]}}</label>
                </div>
              </div>

              <div *ngIf="content.type == 'select'">
                <span *ngIf="content.title.length > 0"><br /></span>
                <span class="font-weight-bold"
                      [innerHtml]="content.title"></span>
                <select class="browser-default custom-select"
                        [attr.disabled]="forms[tab_i].controls[content.group_name].disabled? '' : null"
                        [formControlName]="content.group_name">
                  <option value="null"> </option>
                  <option *ngFor="let value of content.values; let i = index"
                          [value]="value"
                          [selected]="content.checked == value">
                    {{content.labels[i]}}
                  </option>
                </select>
              </div>

            </div>
          </div>
          <div class="dropdown-divider"></div>

          <button class="btn btn-secondary"
                  ngbTooltip="Conferma"
                  (click)=confirm()>
            Conferma
          </button>
        </div>
      </ng-template>
    </ng-container>
  </nav>

  <div [ngbNavOutlet]="navSceltaRapp"
       class="mt-2"></div>
</div>
