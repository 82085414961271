import { SolrFilters } from './../../modelli/solr-filters';
import { Component, OnInit, Input, ViewChild, EventEmitter, Output, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControllerFormService } from 'src/app/servizi/controller-form.service';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
   // tslint:disable-next-line:component-selector
   selector: 'shr-autocomplete-choice',
   templateUrl: './shr-autocomplete-choice.component.html',
   styleUrls: ['./shr-autocomplete-choice.component.css'],
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => ShrAutocompleteChoiceComponent),
         multi: true
      }
   ]
})
export class ShrAutocompleteChoiceComponent implements ControlValueAccessor, OnInit {
   currentVal;
   disabled: boolean;

   @Input() current_url: string;
   @Input() current_name: string;
   @Input() current_tips: string;
   @Input() current_tips_pos: string;
   @Input() filters: SolrFilters;
   @Input() current_page: string;
   @Input() selected_tab: string;
   @Input() set model(item: any) {
      this._model = item;
      if (item != null) {
         this.doSelection.emit(item);
      }
      this.cd.detectChanges();
   }

   /** Evento emesso quando l'utente seleziona un recod della ricerca veloce */
   @Output() doSelection = new EventEmitter<any>();

   _model: any = null;

   currentName: string;
   public result: any;
   emptyResult: {}[];
   errorResult: {}[];
   // public model: SolrResponseDoc;

   @ViewChild('instance', { static: false }) instance: NgbTypeahead;
   focus$ = new Subject<string>();
   click$ = new Subject<string>();

   /** icona delete */
   faTimes = faTimes;

   get value(): any {
      return this.currentVal;
   }
   writeValue(item: any): void {
      this.selectedItem(item);
   }
   registerOnChange(fn: any): void {
      this.onChange = fn;
   }
   registerOnTouched(fn: any): void {
      return;
   }
   setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
   }
   onChange(item) {
      console.log('onchange' + item);
      this.selectedItem(item);
   }

   ngOnInit(): void {
      this.currentName = this.current_name;
      this.emptyResult = [{ term: 'Nessun risultato', id: -1 }];
      this.errorResult = [{ term: 'Errore', id: -1 }];

   }

   constructor(private controllerFormService: ControllerFormService, private cd: ChangeDetectorRef) { }

   /**
    * Metodo chiamato sul digit del campo:
    * query è la stringa di ricerca
    * filters sono i riferimenti al core sole da chiamare e alla modalità (competenza)
    */
   search = (text$: Observable<string>) =>
      text$.pipe(
         debounceTime(200),
         distinctUntilChanged(),
         switchMap(query => this.controllerFormService.search(query, this.filters)),
         map(
            res => (typeof (res.response) === 'undefined' || res.response.docs.length === 0) ? this.emptyResult : res.response.docs
         )
      )

   formatter = (x: { term: string }) => x.term;

   selectedItem(item: any) {
      this._model = item;
      this.currentVal = item;
      // const id = item && item.item ? item.item.id : null;
      const obj = item ? item.item : null;
      if (!!this.instance) {
         this.instance.writeValue(obj);
      }

      if (!!obj) {
         this.doSelection.emit(obj);
      }
      // this.doSelection.emit({ id: id });
   }

   getVal() {
      return this.currentVal;
   }

   /** Metodo per cancellare il contenuto dello input di ricerca */
   _resetInput() {
      this.instance.writeValue(undefined);
      this.doSelection.emit({ id: '-1' });
   }

   /** Metodo pubblico per cancellare il contenuto dello input di ricerca */
   resetInput() {
      this._resetInput();
   }

}
